<header class="wrapper" data-test="WM_HOME.HEADER">
  <wm-wrapper>
    <app-header-home *ngIf="headerType === headerTypes.HOME; else breadcrumbs"></app-header-home>
    <ng-template #breadcrumbs>
      <app-header-breadcrumbs></app-header-breadcrumbs>
    </ng-template>
  </wm-wrapper>
</header>
<ng-container *ngIf="headerType === headerTypes.BREADCRUMBS_WITH_DRAWER">
  <div class="drawer" [ngClass]="mode.code" *ngIf="drawerOpened" [@enterAnimation]>
    <app-background [useAgencyBackgroundColor]="true">
      <app-brand-menu
        class="brand-menu"
        (closeDrawer)="closeDrawer()"
        [drawerOpened]="drawerOpened"
      ></app-brand-menu>
    </app-background>
    <div class="floating-handle-wrapper" (click)="toggleDrawer($event)">
      <mat-icon class="drawer-handle"> drag_handle </mat-icon
      >
    </div>
  </div>
  <div class="drawer-handle-wrapper" [ngClass]="mode.code" (click)="toggleDrawer($event)">
    <mat-icon class="drawer-handle"> drag_handle </mat-icon>
  </div>
</ng-container>

<app-news-sidebar
  [className]="headerType"
  [ngClass]="drawerOpened ? 'opened' : 'closed'"
></app-news-sidebar>

<!-- uncomment for @questionnaire -->
<div
  *ngIf="questionaireAnimationState"
  class="questionaire-animation"
  [@questionnaireAnimation]="questionaireAnimationState"
  [style.bottom]="animationStartingPositionBottom"
  [style.right]="animationStartingPositionRight">
</div>
<!-- ---------------------------- -->

<div
  *ngIf="!isExternalAgency || (isExternalAgency && (hasCurrentProductTours$ | async))"
  class="help-menu-pip"
  [class.grow]="
    !(isProductTourReminderDisplayed$ | async) &&
    !isHelpMenuOpened &&
    !helpMenuWasJustDragged &&
    !showMenuInfotip
  "
  (click)="onHelpMenuClick()"
  [cdkDragDisabled]="isHelpMenuOpened"
  (cdkDragStarted)="onHelpMenuDragStart()"
  (cdkDragEnded)="onHelpMenuDragEnd($event)"
  cdkDrag
  #menuContainer
>
  <span translate="WM_HOME.HEADER.HELP"></span>

  <div *ngIf="helpMenuNotificationsCount" class="notification-marker">
    <span>{{ helpMenuNotificationsCount }}</span>
  </div>

  <div
    *ngIf="showMenuInfotip"
    (click)="preventClick($event)"
    (mousedown)="preventClick($event)"
    class="help-menu-infotip-wrapper"
  >
    <div class="help-menu-infotip">
      <div class="close-button-wrapper">
        <img src="assets/icons/x-white.svg" (click)="closeMenuInfotip($event)" />
      </div>
      <div class="outer-circle">
        <div class="inner-circle"></div>
      </div>
      <div class="help-menu-infotip-content">
        <h6 class="wm-h6" translate="WM_HOME.HEADER.HELP"></h6>
        <p class="wm-subtitle-small" translate="WM_HOME.HEADER.HELP_MENU_INFOTIP"></p>
      </div>
    </div>
    <div class="help-menu-infotip-triangle"></div>
  </div>

  <div
    *ngIf="isHelpMenuOpened"
    class="menu-over-dot"
    [class.turned-right]="turnHelpMenuRight"
    [class.turned-down]="turnHelpMenuDown"
    [class.notified]="helpMenuNotificationsCount"
  >
    <div class="content" [class.notified]="helpMenuNotificationsCount">
      <h6
        *ngIf="
          shouldDisplaySection([sections.help, sections.helpProductTour]) &&
          (hasCurrentProductTours$ | async)
        "
        class="wm-h6"
        [class.disabled]="!shouldEnableSection([sections.help, sections.helpProductTour])"
        [class.notified]="helpMenuNotificationsCount"
        translate="WM_HOME.HEADER.PRODUCT_TOUR"
        (click)="runProductTours()"
      ></h6>
      <h6
        *ngIf="
          (showLinkToSitemap$ | async) &&
          shouldDisplaySection([sections.help, sections.helpSitemap])
        "
        routerLink="/sitemap"
        (click)="closeHelpMenu()"
        [class.notified]="helpMenuNotificationsCount"
        translate="WM_HOME.HEADER.SITEMAP"
        class="wm-h6"
      ></h6>
      <h6
        *ngIf="shouldDisplaySection([sections.help, sections.helpTrainingPortal])"
        (click)="goToTrainingPortal()"
        [class.disabled]="!shouldEnableSection([sections.help, sections.helpTrainingPortal])"
        [class.notified]="helpMenuNotificationsCount"
        translate="WM_HOME.HEADER.TRAINING_PORTAL"
        (mouseover)="goToIconSrc = 'assets/icons/go-to-hover.svg'"
        (mouseout)="goToIconSrc = 'assets/icons/go-to-white.svg'"
        class="wm-h6"
      >
        <img [src]="goToIconSrc" />
      </h6>
      <h6
        *ngIf="
          showContextualHelpButton &&
          shouldDisplaySection([sections.help, sections.helpContextualHelp])
        "
        class="wm-h6"
        [class.notified]="helpMenuNotificationsCount"
        translate="WM_HOME.HEADER.SHOW_CONTEXTUAL_HELP"
        (click)="openContextualHelpDrawer()"
      >
        <div
          *ngIf="(globalPage$ | async)?.isNewContent || (localPage$ | async)?.isNewContent"
          class="notification-dot"
        ></div>
      </h6>
      <!-- uncomment for @questionnaire -->
      <h6
        *ngIf="(showQuestionnaireMenuItem$ | async)"
        (click)="goToQuestionnaire()"
        [class.notified]="helpMenuNotificationsCount"
        class="wm-h6">
        <div *ngIf="showNotificationForQuestionnaire$ | async" class="notification-dot"></div>
        Content survey
      </h6>
      <!-- ----------------------------------------- -->
      <h6
        *ngFor="let customItem of helpMenuCustomItems$ | async"
        class="wm-h6"
        [class.notified]="helpMenuNotificationsCount"
        (click)="onHelpMenuCustomItemClick(customItem?.id)"
      >
        {{ customItem?.text }}
      </h6>
    </div>
    <div class="triangle" [class.notified]="helpMenuNotificationsCount"></div>
  </div>

  <wm-wrapper *ngIf="!isDuringE2E">
    <app-product-tour-reminder
      [class]="closeReminderTour.class"
      (click)="$event.stopPropagation()"
    ></app-product-tour-reminder>
  </wm-wrapper>
</div>

<div
  *ngIf="shouldDisplayProjectBuilderBall$ | async"
  class="project-builder-ball"
  #projectBuilderMenuContainer
  [class.grow]="!projectBuilderBallWasJustDragged"
  (click)="onProjectBuilderBallClick()"
  (cdkDragStarted)="onProjectBuilderBallDragStart()"
  (cdkDragEnded)="onProjectBuilderBallDragEnd($event)"
  cdkDrag
>
  <span translate="WM_HOME.HEADER.TASK"></span>

  <wm-wrapper>
    <div class="guide-tasks-ball"
      (click)="$event.stopPropagation()"
      >
      <wm-guide-step
        [guideName]="'PROJECT_BUILDER_TASKS_BALL_TOUR'"
        [stepName]="'tasksBall'"
        >
      </wm-guide-step>
    </div>
  </wm-wrapper>

  <ng-container *ngIf="tasksUnderProjectBuilderBall$ | async as tasks">
    <div *ngIf="tasks.length > 1" class="notification-marker">
      <span>{{ tasks.length }}</span>
    </div>

    <div
      *ngIf="isProjectBuilderMenuOpened"
      class="menu-over-dot"
      [class.turned-right]="turnProjectBuilderMenuRight"
      [class.turned-down]="turnProjectBuilderMenuDown"
    >
      <div class="content">
        <h6
          *ngFor="let task of tasks"
          class="wm-h6"
          (click)="openProjectBuilderTaskModal(task, $event)"
        >
          {{ task.name }}
        </h6>
      </div>
      <div class="triangle"></div>
    </div>
  </ng-container>
</div>
