import { ModeSettings, Project, Selection } from 'app/interfaces';

export interface ProjectsState {
  projects: Project[];
  selections: Selection[],
  loading: boolean;
  projectModeOn: boolean;
  projectModeSettings: ModeSettings[];
  error: unknown;
}

export const projectsFeatureKey = 'projects';

export const initialState: ProjectsState = {
  projects: [],
  selections: [],
  projectModeOn: true,
  projectModeSettings: [],
  loading: false,
  error: null,
};
