import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { get } from 'lodash-es';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { ClientManagementDrawerMode, Market } from 'app/state/clients';
import { Team, WmMember, UsersFacade } from 'app/state/users';

import { defaults } from '@config/defaults';
import { MarketOption } from '../markets-management/markets-management.component';

@Component({
  selector: 'app-market-drawer',
  templateUrl: './market-drawer.component.html',
  styleUrls: ['./market-drawer.component.scss'],
})
export class MarketDrawerComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() editedMarket: Market;
  @Input() availableMarkets: MarketOption[];
  @Input() clientName: string;
  @Input() set drawerMode(mode: ClientManagementDrawerMode) {
    this.editMode =
      mode === ClientManagementDrawerMode.RECREATE_MARKET ||
      mode === ClientManagementDrawerMode.EDIT_MARKET;
    this.allowTeamAssign =
      mode === ClientManagementDrawerMode.RECREATE_MARKET ||
      mode === ClientManagementDrawerMode.NEW_MARKET;
    this.myselfRequired = this.allowTeamAssign;
  }

  @Output() closeDrawer = new EventEmitter<void>();
  @Output() saveMarket = new EventEmitter<Partial<Market>>();

  public myself$ = this.usersFacade.myself$;
  public owners$ = this.usersFacade.owners$;
  public members$ = this.usersFacade.members$;
  public isLoading$ = this.usersFacade.loading$;

  public editMode: boolean;
  public allowTeamAssign: boolean;
  public myselfRequired: boolean;
  public marketForm: UntypedFormGroup;
  public marketStatusList = defaults.marketsStates;
  private userTeams: Team[] = [];
  public filteredTeams: Team[] = [];
  public selectedTeam: Team;
  public selectedOwners: WmMember[] = [];
  public selectedMembers: WmMember[] = [];
  readonly requiredOwnersNumber = defaults.requiredTeamOwnersNumber;
  readonly maxDescriptionCharactersNumber = 110;

  get marketName(): string {
    return this.marketForm.get('market').value?.name || '';
  }
  get marketStatus(): AbstractControl {
    return this.marketForm.get('status');
  }
  get assignTeam(): boolean {
    return this.marketForm.get('assignTeam').value;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private usersFacade: UsersFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.afterInit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.editedMarket) {
      this.afterInit();
    }
  }

  private afterInit(): void {
    this.createForm();
    this.usersFacade.resetUsers();

    if (this.editMode && this.editedMarket) {
      this.setMarketValues();
    }

    if (this.allowTeamAssign) {
      this.getUserTeams();
    }
  }

  private createForm(): void {
    this.marketForm = this.formBuilder.group({
      market: [{ value: null, disabled: this.editMode }, Validators.required],
      status: [null, Validators.required],
      assignTeam: [false],
      teamName: [''],
    });
  }

  private setMarketValues(): void {
    const market = this.availableMarkets.find(
      (availableMarket) => availableMarket.code === this.editedMarket.code
    );

    const status = this.marketStatusList.find(
      (marketStatus) =>
        marketStatus.name?.toLocaleLowerCase() === this.editedMarket.status?.toLocaleLowerCase()
    );

    this.marketForm.patchValue({
      market,
      status,
    });

    this.selectedOwners = this.editedMarket.owners;
    this.selectedMembers = this.editedMarket.members;
  }

  private getUserTeams(): void {
    this.usersFacade.getUserTeams();
    this.filterUserTeams();

    this.usersFacade.userTeams$
      .pipe(untilComponentDestroyed(this))
      .subscribe((userTeams) => (this.userTeams = userTeams));
  }

  private filterUserTeams(): void {
    this.marketForm.controls['teamName'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe((teamName: string) => {
        this.filteredTeams = this.userTeams.filter((team) =>
          team.displayName.toLowerCase().includes(teamName.trim().toLowerCase())
        );

        this.changeDetector.detectChanges();
      });
  }

  public handleClose(): void {
    this.closeDrawer.emit();
  }

  public handleSave(): void {
    const market = {
      code: get(this.marketForm, 'value.market.code', this.editedMarket?.code),
      status: get(this.marketForm, 'value.status.name'),
    };
    const useExistingTeam = get(this.marketForm, 'value.assignTeam');

    const marketUsers = useExistingTeam
      ? {
          useExistingTeam,
          existingTeamName: this.selectedTeam.displayName,
          existingTeamId: this.selectedTeam.id,
        }
      : { owners: this.selectedOwners, members: this.selectedMembers };

    this.saveMarket.emit({ ...market, ...marketUsers });
  }

  public getUsers(name: string, getOwners?: boolean) {
    this.usersFacade.getUsers(name, getOwners);
  }

  public addOwner(owner: WmMember): void {
    this.selectedOwners = [...this.selectedOwners, owner];
  }

  public addMember(member: WmMember): void {
    this.selectedMembers = [...this.selectedMembers, member];
  }

  public deleteOwner(ownerId: WmMember['id']): void {
    this.selectedOwners = this.selectedOwners.filter((owner) => owner.id !== ownerId);
  }

  public deleteMember(memberId: WmMember['id']): void {
    this.selectedMembers = this.selectedMembers.filter((member) => member.id !== memberId);
  }

  public selectTeam(team: Team): void {
    this.selectedTeam = team;
  }
}
