import { Component, ElementRef } from '@angular/core';

import { registerMediaPlanComparisonApp } from 'src/single-spa/mp-comparison';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface MediaPlanComparisonAppState {
  pristine: boolean;
}

const appState: MediaPlanComparisonAppState = { pristine: true };

@Component({
  selector: 'app-mp-comparison-wrapper',
  templateUrl: './mp-comparison-wrapper.component.html',
  styleUrls: ['./mp-comparison-wrapper.component.scss'],
})
export class MediaPlansComparisonWrapperComponent extends MicroAppWrapper {
  protected appName = 'MediaPlansComparison';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: MediaPlanComparisonAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerMediaPlanComparisonApp(params);
  }
}
