import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  UrlSegment,
  UrlSegmentGroup,
  Route,
  UrlMatchResult,
} from '@angular/router';

import { config } from './config';
import { environment } from 'src/environments/environment';

import {
  AuthGuard,
  CanEditGuard,
  ClientGuard,
  ClientAndBrandGuard,
  ErrorGuard,
  LivePanelGuard,
  MaintenanceGuard,
  MarketManagementGuard,
  ModuleGuard,
  PermissionsGuard,
  ResetBrandGuard,
  ResetClientGuard,
  ResetCurrentProjectGuard,
  TitleGuard,
} from './core/guards';
import { CanDeactivateAudiences } from './modules-wrappers/audiences-wrapper/audiences-wrapper-deactivate.guard';

import {
  AudiencesWrapperComponent,
  AudienceGalaxyWrapperComponent,
  AppsWrapperComponent,
  BudgetWrapperComponent,
  CampaignsUiWrapperComponent,
  DocumentationWrapperComponent,
  RetailCompassWrapperComponent,
  GrowthOpportunitiesWrapperComponent,
  MarketAdminWrapperComponent,
  ScorecardsWrapperComponent,
  NewsWrapperComponent,
  TouchpointsWrapperComponent,
  YouPageWrapperComponent,
  ScorecardsQuestionsManagementWrapperComponent,
  LoginWrapperComponent,
  MediaMarketingMixWrapperComponent,
  MediaPlansComparisonWrapperComponent,
  MediaPlansResultsWrapperComponent,
  MediaPlansUploaderWrapperComponent,
  SitemapWrapperComponent,
  ProvokeBriefWrapperComponent,
  VelocityWrapperComponent,
  GeographWrapperComponent,
  CxiAudiencesWrapperComponent,
  CampaignsSetupWrapperComponent,
  ExpressScenariosWrapperComponent,
  AudienceTranslatorWrapperComponent,
  TouchpointsUIWrapperComponent,
  AiCenterWrapperComponent,
} from './modules-wrappers';

import {
  ClientCreationComponent,
  ClientManagementComponent,
  PersonalLayoutComponent,
  LayoutComponent,
  MarketsManagementComponent,
  UserSpaceComponent,
  UserSettingsComponent,
  MaintenanceComponent,
  LoginComponent,
} from './components';
import { FullsizeMenuComponent } from './components/fullsize-menu/fullsize-menu.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { CartWrapperComponent } from './modules-wrappers/cart-wrapper/cart-wrapper.component';
import { NoMaintenanceGuard } from './core/guards/no-maintenance.guard';
import { YouGuard } from './core/guards/you.guard';
import { ErrorComponent } from './components/error/error.component';
import { FourMoresWrapperComponent } from './modules-wrappers/4mores-wrapper/4mores-wrapper.component';
import { OsPayWrapperComponent } from 'app/modules-wrappers/os-pay-wrapper/os-pay-wrapper.component';
import { DataStrategyDeckWrapperComponent } from './modules-wrappers/data-strategy-deck-wrapper/data-strategy-deck-wrapper.component';
import { GrowthPathWrapperComponent } from './modules-wrappers/growth-path-wrapper/growth-path-wrapper.component';
import { ProjectsResolver } from './core/resolvers/projects.resolver';
import { ProductsStatsWrapperComponent } from 'app/modules-wrappers/products-stats-wrapper/products-stats-wrapper.component';
import { ConsumerExperienceInsightsWrapperComponent } from './modules-wrappers/consumer-experience-insights-wrapper/consumer-experience-insights-wrapper.component';
import { InfluencerViewerWrapperComponent } from 'app/modules-wrappers/influencer-viewer-wrapper/influencer-viewer-wrapper.component';
import { ClientRequestComponent } from 'app/components/client-request/client-request.component';
import { PendingClientComponent } from './components/client-creation/components/pending-client/pending-client.component';
import { NEW_CLIENT_ROUTE } from './state/clients';
import { BrandRouteMatcherGuard } from './core/guards/brand-route-matcher.guard';

const headerTypes = config.constants.HEADER_TYPES;

/**
 * This custom route matcher function for handling brand-specific URLs is a fix for a problem [WMO-10933](https://wmglobaldev.atlassian.net/browse/WMO-10933).
 *
 * The matcher is desigend to accommodate two main URL patterns:
 * 1. `brands/:brandId` - where `:brandId` is a dynamic segmnet representing a specific brand.
 * 2. `default-brand` - a fixed path segment representing a default brand.
 *
 * The function handles additional URL segments beyond the main brand identifier, allowing
 * for more complex routing scenarios. These additional segments are captured but not consumed,
 * enabling further processing down the routing chain.
 *
 * The UrlMatchResult contains the following properties:
 * - consumed: An array of UrlSegments that were matched by this function.
 * - posParams: An object containing the positional parameters extracted from the URL.
 *   This includes:
 *     - brandId: The brand identifier segment (either a specific ID or null for 'default-brand').
 *     - unconsumedSegments: A string concatenation of any additional segments in the URL beyond the brand identifier.
 */
export function brandRouteMatcher(
  urlSegments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult {
  if (urlSegments.length === 0) {
    return null;
  }

  const brandsSegment = 'brands';
  const defaultBrandSegment = 'defaultbrand';
  const path = urlSegments[0].path;
  // Check for 'brands/:brandId' or 'default-brand'
  const isCorrectPath =
    (path === brandsSegment && urlSegments.length >= 2) || path === defaultBrandSegment;

  if (!isCorrectPath) {
    return null;
  }

  const isDefaultBrand = path === defaultBrandSegment;
  const brandIdSegment = isDefaultBrand ? new UrlSegment(null, {}) : urlSegments[1];
  const consumedSegments = isDefaultBrand ? [urlSegments[0]] : [urlSegments[0], urlSegments[1]];

  const unconsumedSegments: string[] = [];
  for (let i = isDefaultBrand ? 1 : 2; i < urlSegments.length; i++) {
    unconsumedSegments.push(urlSegments[i].path);
  }

  const hasAnyUnconsumedSegments = unconsumedSegments.length > 0;

  return {
    consumed: consumedSegments,
    posParams: {
      brandId: brandIdSegment,
      unconsumedSegments: new UrlSegment(
        hasAnyUnconsumedSegments ? unconsumedSegments.join('/') : null,
        {}
      ),
    },
  };
}

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [NoMaintenanceGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [ErrorGuard],
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginComponent,
      },
      {
        path: '**',
        canActivate: [AuthGuard],
        data: {
          headerType: headerTypes.HOME,
          titles: config.titles.DEFAULT,
        },
        children: [
          {
            path: '**',
            component: LoginWrapperComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        // all auth-guarded routes display header, therefore they have to provide
        // data.headerType (on any level of routing) - many examples below.
        // all microfrontends wrappers should have assigned MaintenanceGuard and
        // applicationId in data params - many examples below.
        path: '',
        canActivate: [MaintenanceGuard],
        data: {
          applicationId: environment.appsIds.appId,
        },
        component: LayoutComponent,
        children: [
          {
            path: '',
            canActivate: [YouGuard, TitleGuard],
            pathMatch: 'full',
            component: PersonalLayoutComponent,
            data: {
              headerType: headerTypes.HOME,
              titles: config.titles.DEFAULT,
            },
          },
          {
            path: 'you',
            canActivate: [YouGuard, TitleGuard],
            data: {
              headerType: headerTypes.HOME,
              titles: config.titles.YOU_NEW,
            },
            children: [
              {
                path: '**',
                component: YouPageWrapperComponent,
              },
            ],
          },
          {
            path: 'user',
            component: UserSpaceComponent,
            children: [
              { path: '', redirectTo: 'settings', pathMatch: 'full' },
              {
                path: 'settings',
                component: UserSettingsComponent,
                canActivate: [TitleGuard],
                data: {
                  headerType: headerTypes.HOME,
                  titles: config.titles.USER_SETTINGS,
                },
              },
            ],
          },
          {
            path: 'market-admin',
            canActivate: [MaintenanceGuard, PermissionsGuard, TitleGuard],
            data: {
              applicationId: environment.appsIds.marketAdminAppId,
              entrancePermissions: [
                {
                  key: config.constants.USER_PERMISSION_KEYS.CAN_MANAGE_MARKET,
                  appId: environment.appsIds.appId,
                },
              ],
              headerType: headerTypes.HOME,
              titles: config.titles.MARKET_ADMIN,
            },
            children: [
              {
                path: '**',
                component: MarketAdminWrapperComponent,
              },
            ],
          },
          {
            path: 'apps',
            canActivate: [MaintenanceGuard, TitleGuard],
            data: {
              headerType: headerTypes.HOME,
              applicationId: environment.appsIds.appsAppId,
              titles: config.titles.APPS,
            },
            children: [
              {
                path: 'list',
                component: AppsWrapperComponent,
              },
              {
                path: 'admin-panel',
                component: AppsWrapperComponent,
              },
              {
                path: '**',
                component: AppsWrapperComponent,
              },
            ],
          },
          {
            path: 'os-pay',
            canActivate: [MaintenanceGuard, PermissionsGuard, TitleGuard],
            data: {
              breadcrumbs: {
                app: 'WM_HOME.HEADER.OS_PAY',
                section: 'WM_HOME.HEADER.OS_PAY',
                text: null,
              },
              headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
              applicationId: environment.appsIds.osPayId,
              titles: config.titles.OS_PAY,
              entrancePermissions: [
                {
                  key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  appId: environment.appsIds.osPayId,
                },
              ],
            },
            children: [
              {
                path: '**',
                component: OsPayWrapperComponent,
              },
            ],
          },
          {
            path: 'products-stats',
            canActivate: [TitleGuard, PermissionsGuard, MaintenanceGuard],
            data: {
              headerType: headerTypes.HOME,
              applicationId: environment.appsIds.productsStatsAppId,
              titles: config.titles.PRODUCTS_STATS,
              entrancePermissions: [
                {
                  appId: environment.appsIds.productsStatsAppId,
                  key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                },
              ],
            },
            children: [
              {
                path: '**',
                component: ProductsStatsWrapperComponent,
              },
            ],
          },
          {
            path: 'documentation',
            canActivate: [MaintenanceGuard, TitleGuard],
            data: {
              headerType: headerTypes.NONE,
              applicationId: environment.appsIds.documentationProxyAppId,
              titles: config.titles.DOCUMENTATION,
            },
            children: [
              {
                path: '**',
                component: DocumentationWrapperComponent,
              },
            ],
          },
          {
            path: 'news',
            canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
            data: {
              headerType: headerTypes.HOME,
              applicationId: environment.appsIds.newsSettingsAppId,
              moduleKey: config.constants.MODULE_KEYS.NEWS,
              titles: config.titles.NEWS,
            },
            children: [
              {
                path: '**',
                component: NewsWrapperComponent,
              },
            ],
          },
          {
            path: 'project-builder',
            canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
            data: {
              headerType: headerTypes.HOME,
              applicationId: environment.appsIds.growthPathAppId,
              moduleKey: config.constants.MODULE_KEYS.GROWTH_PATH,
              titles: config.titles.GROWTH_PATH,
            },
            children: [
              {
                path: '**',
                component: GrowthPathWrapperComponent,
              },
            ],
          },
          {
            path: 'ai-center',
            canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
            data: {
              entrancePermissions: [
                {
                  key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  appId: environment.appsIds.aiCenterAppId,
                },
              ],
              applicationId: environment.appsIds.aiCenterAppId,
              headerType: headerTypes.HOME,
              titles: config.titles.AI_CENTER,
              moduleKey: config.constants.MODULE_KEYS.AI_CENTER,
            },
            children: [
              {
                path: '**',
                component: AiCenterWrapperComponent,
              },
            ],
          },
          {
            path: 'sitemap',
            canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
            data: {
              headerType: headerTypes.NONE,
              applicationId: environment.appsIds.sitemapAppId,
              moduleKey: config.constants.MODULE_KEYS.SITEMAP,
              titles: config.titles.SITEMAP,
            },
            children: [
              {
                path: '**',
                component: SitemapWrapperComponent,
              },
            ],
          },
          {
            path: 'clients',
            canDeactivate: [ResetClientGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'client-creation',
              },
              {
                path: 'client-creation',
                component: ClientCreationComponent,
                canActivate: [TitleGuard],
                data: {
                  headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
                  titles: config.titles.CLIENT_CREATION,
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: NEW_CLIENT_ROUTE,
                  },
                  {
                    path: NEW_CLIENT_ROUTE,
                    component: ClientManagementComponent,
                    canActivate: [PermissionsGuard],
                    data: {
                      entrancePermissions: [
                        {
                          key: config.constants.USER_PERMISSION_KEYS.CAN_ADD_CLIENT,
                          appId: environment.appsIds.clientManagementAppId,
                        },
                      ],
                    },
                  },
                  {
                    path: ':clientId',
                    canActivateChild: [ClientGuard],
                    data: {
                      withDetails: true,
                    },
                    children: [
                      {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'client-management',
                      },
                      {
                        path: 'client-management',
                        component: ClientManagementComponent,
                        canActivate: [CanEditGuard],
                      },
                      {
                        path: 'markets-management',
                        canActivate: [MarketManagementGuard],
                        component: MarketsManagementComponent,
                      },
                      {
                        path: 'pending',
                        data: {
                          withDetails: false,
                        },
                        component: PendingClientComponent,
                      },
                    ],
                  },
                ],
              },
              {
                path: ':clientId',
                canActivateChild: [ClientGuard],
                canDeactivate: [ResetClientGuard],
                data: {
                  headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'markets',
                  },
                  {
                    path: 'markets',
                    canActivate: [TitleGuard],
                    component: FullsizeMenuComponent,
                    data: {
                      titles: config.titles.MARKETS_LIST,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'client-request/:clientId',
            canActivate: [PermissionsGuard],
            canDeactivate: [ResetClientGuard],
            data: {
              entrancePermissions: [
                {
                  key: config.constants.USER_PERMISSION_KEYS.CAN_ACCEPT_CLIENT,
                  appId: environment.appsIds.clientManagementAppId,
                },
              ],
            },
            children: [
              {
                path: '',
                canActivate: [ClientGuard, TitleGuard],
                component: ClientRequestComponent,
                data: {
                  headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
                  // don't allow when client is already accepted
                  limitToNotAccepted: true,
                  titles: config.titles.CLIENT_REQUEST,
                },
              },
            ],
          },
          {
            // path: 'brands/:brandId',
            matcher: brandRouteMatcher,
            canActivate: [BrandRouteMatcherGuard],
            canActivateChild: [ClientAndBrandGuard],
            resolve: { projects: ProjectsResolver },
            canDeactivate: [ResetCurrentProjectGuard, ResetBrandGuard, ResetClientGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [TitleGuard],
                component: FullsizeMenuComponent,
                data: {
                  headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
                  titles: config.titles.MODULES_LIST,
                },
              },
              {
                path: 'audiences',
                canActivate: [MaintenanceGuard, LivePanelGuard, ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.audiencesAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.MAXIMIZE,
                  titles: config.titles.AUDIENCES,
                },
                children: [
                  {
                    path: '**',
                    component: AudiencesWrapperComponent,
                    canDeactivate: [CanDeactivateAudiences],
                  },
                ],
              },
              {
                path: 'budget',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.budgetAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.TRANSFORM',
                    app: 'WM_HOME.NAVIGATION_MAIN.BUDGET',
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.BUDGET,
                  titles: config.titles.BUDGET,
                },
                children: [
                  {
                    path: '**',
                    component: BudgetWrapperComponent,
                  },
                ],
              },
              {
                path: 'briefs',
                redirectTo: 'campaigns',
              },
              {
                path: 'express-scenarios',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard, PermissionsGuard],
                data: {
                  applicationId: environment.appsIds.expressScenariosAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ENTER_EXPRESS_SCENARIOS,
                      appId: environment.appsIds.expressScenariosAppId,
                    },
                  ],

                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.EXPRESS_SCENARIOS',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.MAXIMIZE,
                  titles: config.titles.EXPRESS_SCENARIOS,
                },
                children: [
                  {
                    path: '**',
                    component: ExpressScenariosWrapperComponent,
                  },
                ],
              },
              {
                path: 'campaigns',
                canActivate: [MaintenanceGuard, LivePanelGuard, ModuleGuard],
                data: {
                  applicationId: environment.appsIds.campaignsAppId,
                  moduleKey: config.constants.MODULE_KEYS.MAXIMIZE,
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'list',
                  },
                  {
                    path: 'list',
                    canActivate: [TitleGuard],
                    data: {
                      applicationId: environment.appsIds.appId,
                      headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                      breadcrumbs: {
                        category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                        app: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS_UI',
                        section: null,
                        text: null,
                      },
                      titles: config.titles.CAMPAIGNS_UI,
                    },
                    children: [
                      {
                        path: '**',
                        component: CampaignsUiWrapperComponent,
                      },
                    ],
                  },
                  {
                    path: 'setup',
                    canActivate: [TitleGuard],
                    data: {
                      applicationId: environment.appsIds.appId,
                      headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                      breadcrumbs: {
                        category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                        app: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS_SETUP',
                        section: null,
                        text: null,
                      },
                      titles: config.titles.CAMPAIGNS_SETUP,
                    },
                    children: [
                      {
                        path: '**',
                        component: CampaignsSetupWrapperComponent,
                      },
                    ],
                  },
                  {
                    path: 'comparison',
                    canActivate: [TitleGuard],
                    data: {
                      applicationId: environment.appsIds.mediaPlanComparisonAppid,
                      headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                      breadcrumbs: {
                        category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                        app: 'WM_HOME.NAVIGATION_MAIN.MP_COMPARISON',
                        section: null,
                        text: null,
                      },
                      titles: config.titles.MP_COMPARISON,
                    },
                    children: [
                      {
                        path: '**',
                        component: MediaPlansComparisonWrapperComponent,
                      },
                    ],
                  },
                  {
                    path: 'custom',
                    canActivate: [TitleGuard],
                    data: {
                      applicationId: environment.appsIds.appId,
                      headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                      breadcrumbs: {
                        category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                        app: 'WM_HOME.NAVIGATION_MAIN.MP_UPLOADER',
                        section: null,
                        text: null,
                      },
                      titles: config.titles.MP_UPLOADER,
                    },
                    children: [
                      {
                        path: '**',
                        component: MediaPlansUploaderWrapperComponent,
                      },
                    ],
                  },
                  {
                    path: 'results',
                    canActivate: [TitleGuard],
                    data: {
                      applicationId: environment.appsIds.appId,
                      headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                      breadcrumbs: {
                        category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                        app: 'WM_HOME.NAVIGATION_MAIN.MP_RESULTS',
                        section: null,
                        text: null,
                      },
                      titles: config.titles.MP_RESULTS,
                    },
                    children: [
                      {
                        path: '**',
                        component: MediaPlansResultsWrapperComponent,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'provoke-brief',
                canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.provokeBriefAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.provokeBriefAppId,
                    },
                  ],
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.PROVOKE_BRIEF',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.PROVOKE_BRIEF,
                  titles: config.titles.PROVOKE_BRIEF,
                },
                children: [
                  {
                    path: '**',
                    component: ProvokeBriefWrapperComponent,
                  },
                ],
              },
              {
                path: 'long-term-growth-simulator',
                canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.velocityAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.velocityAppId,
                    },
                  ],
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.TRANSFORM',
                    app: 'WM_HOME.NAVIGATION_MAIN.VELOCITY',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.VELOCITY,
                  titles: config.titles.VELOCITY,
                },
                children: [
                  {
                    path: '**',
                    component: VelocityWrapperComponent,
                  },
                ],
              },
              {
                path: 'live-audiences',
                canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.cxiAudiencesAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.cxiAudiencesAppId,
                    },
                  ],
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.MAXIMIZE',
                    app: 'WM_HOME.NAVIGATION_MAIN.CXI_AUDIENCES',
                    section: null,
                    text: null,
                  },
                  titles: config.titles.CXI_AUDIENCES,
                },
                children: [
                  {
                    path: '**',
                    component: CxiAudiencesWrapperComponent,
                  },
                ],
              },
              {
                path: 'retail-compass',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.TRANSFORM',
                    app: 'WM_HOME.NAVIGATION_MAIN.SINGLE_SOURCE',
                    section: null,
                    text: null,
                  },
                  applicationId: environment.appsIds.retailCompassAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  moduleKey: config.constants.MODULE_KEYS.RETAIL_COMPASS,
                  titles: config.titles.RETAIL_COMPASS,
                },
                children: [
                  {
                    path: '**',
                    component: RetailCompassWrapperComponent,
                  },
                ],
              },
              {
                path: 'touchpoints',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.touchpointsAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.MAXIMIZE,
                  titles: config.titles.TOUCHPOINTS,
                },
                children: [
                  {
                    path: '**',
                    component: TouchpointsWrapperComponent,
                  },
                ],
              },
              {
                path: 'touchpoints-ui',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard, PermissionsGuard],
                data: {
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.touchpointsUIAppId,
                    },
                  ],
                  applicationId: environment.appsIds.touchpointsUIAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.MAXIMIZE,
                  titles: config.titles.TOUCHPOINTS,
                },
                children: [
                  {
                    path: '**',
                    component: TouchpointsUIWrapperComponent,
                  },
                ],
              },
              {
                path: 'growth-opportunities',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.growthOpportunitiesAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.UNLOCK',
                    app: 'WM_HOME.NAVIGATION_MAIN.OPPORTUNITIES',
                    section: null,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.OPPORTUNITIES,
                  titles: config.titles.GROWTH_OPPORTUNITIES,
                },
                children: [
                  {
                    path: '**',
                    component: GrowthOpportunitiesWrapperComponent,
                  },
                ],
              },
              {
                path: 'xlator',
                canActivate: [ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.audienceTranslatorAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.MAXIMIZE',
                    app: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_TRANSLATOR',
                    section: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_TRANSLATOR',
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.AUDIENCE_TRANSLATOR,
                  titles: config.titles.AUDIENCE_TRANSLATOR,
                },
                children: [
                  {
                    path: '**',
                    component: AudienceTranslatorWrapperComponent,
                  },
                ],
              },
              {
                path: 'audience-galaxy',
                canActivate: [MaintenanceGuard, TitleGuard],
                data: {
                  breadcrumbs: {
                    category: null,
                    app: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_GALAXY',
                    section: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_GALAXY',
                    text: null,
                  },
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.AUDIENCE_GALAXY,
                  applicationId: environment.appsIds.audienceGalaxyId,
                },
                children: [
                  {
                    path: '**',
                    component: AudienceGalaxyWrapperComponent,
                  },
                ],
              },
              {
                path: 'scorecards',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.scorecardsAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.UNLOCK',
                    app: 'WM_HOME.NAVIGATION_MAIN.SCORECARDS',
                    section: 'WM_HOME.NAVIGATION_MAIN.SCORECARDS',
                    hideSection: true,
                    text: null,
                  },
                  moduleKey: config.constants.MODULE_KEYS.SCORECARDS,
                  titles: config.titles.SCORECARDS,
                },
                children: [
                  {
                    path: '**',
                    component: ScorecardsWrapperComponent,
                  },
                ],
              },
              {
                path: 'cart',
                canActivate: [MaintenanceGuard, TitleGuard],
                data: {
                  applicationId: environment.appsIds.cartAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  breadcrumbs: {
                    text: 'WM_HOME.NAVIGATION_MAIN.CART',
                    app: null,
                    category: null,
                    section: null,
                  },
                  titles: config.titles.CART,
                },
                children: [
                  {
                    path: '**',
                    component: CartWrapperComponent,
                  },
                ],
              },
              {
                path: 'media-marketing-mix',
                canActivate: [MaintenanceGuard, TitleGuard],
                data: {
                  breadcrumbs: {
                    app: 'WM_HOME.HEADER.MMM',
                    section: 'WM_HOME.HEADER.MMM',
                    text: null,
                  },
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.MMM,
                  applicationId: environment.appsIds.mediaMarketingMixAppId,
                },
                children: [
                  {
                    path: '**',
                    component: MediaMarketingMixWrapperComponent,
                  },
                ],
              },
              {
                path: 'sog',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.FOUR_MORES',
                    section: 'WM_HOME.NAVIGATION_MAIN.FOUR_MORES',
                    hideSection: true,
                    text: null,
                  },
                  applicationId: environment.appsIds.fourMoresAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.FOUR_MORES,
                  moduleKey: config.constants.MODULE_KEYS.FOUR_MORES,
                },
                children: [
                  {
                    path: '**',
                    component: FourMoresWrapperComponent,
                  },
                ],
              },
              {
                path: 'data-strategy-deck',
                canActivate: [MaintenanceGuard, ModuleGuard, TitleGuard],
                data: {
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.TRANSFORM',
                    app: 'WM_HOME.NAVIGATION_MAIN.DATA_STRATEGY_DECK',
                    section: null,
                    text: null,
                  },
                  applicationId: environment.appsIds.dataStrategyDeckAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.DATA_STRATEGY_DECK,
                  moduleKey: config.constants.MODULE_KEYS.DATA_STRATEGY_DECK,
                },
                children: [
                  {
                    path: '**',
                    component: DataStrategyDeckWrapperComponent,
                  },
                ],
              },
              {
                path: 'consumer-experience-insights',
                canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
                data: {
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.consumerExperienceInsightsAppId,
                    },
                  ],
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.UNLOCK',
                    app: 'WM_HOME.NAVIGATION_MAIN.CONSUMER_EXPERIENCE_INSIGHTS',
                    section: null,
                    text: null,
                  },
                  applicationId: environment.appsIds.consumerExperienceInsightsAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.CONSUMER_EXPERIENCE_INSIGHTS,
                  moduleKey: config.constants.MODULE_KEYS.CONSUMER_EXPERIENCE_INSIGHTS,
                },
                children: [
                  {
                    path: '**',
                    component: ConsumerExperienceInsightsWrapperComponent,
                  },
                ],
              },
              {
                path: 'geograph',
                canActivate: [TitleGuard, PermissionsGuard, MaintenanceGuard],
                data: {
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  applicationId: environment.appsIds.geographAppId,
                  titles: config.titles.GEOGRAPH,
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.TRANSFORM',
                    app: 'WM_HOME.NAVIGATION_MAIN.GEOGRAPH',
                    section: null,
                    text: null,
                  },
                  entrancePermissions: [
                    {
                      appId: environment.appsIds.geographAppId,
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                    },
                  ],
                },
                children: [
                  {
                    path: '**',
                    component: GeographWrapperComponent,
                  },
                ],
              },
              {
                path: 'influencer-viewer',
                canActivate: [PermissionsGuard, MaintenanceGuard, TitleGuard],
                data: {
                  entrancePermissions: [
                    {
                      key: config.constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                      appId: environment.appsIds.influencerViewerAppId,
                    },
                  ],
                  breadcrumbs: {
                    category: 'WM_HOME.NAVIGATION_MAIN.OPTIMISE',
                    app: 'WM_HOME.NAVIGATION_MAIN.INFLUENCER_VIEWER',
                    section: null,
                    text: null,
                  },
                  applicationId: environment.appsIds.influencerViewerAppId,
                  headerType: headerTypes.BREADCRUMBS_WITH_DRAWER,
                  titles: config.titles.INFLUENCER_VIEWER,
                  moduleKey: config.constants.MODULE_KEYS.INFLUENCER_VIEWER,
                },
                children: [
                  {
                    path: '**',
                    component: InfluencerViewerWrapperComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'sqm',
            canActivate: [MaintenanceGuard, PermissionsGuard, TitleGuard],
            data: {
              entrancePermissions: [
                {
                  key: config.constants.USER_PERMISSION_KEYS.CAN_VIEW_SQM,
                  appId: environment.appsIds.scorecardsAppId,
                },
              ],
              breadcrumbs: {
                text: 'WM_HOME.NAVIGATION_MAIN.SCORECARDS_QUESTIONS_MANAGEMENT',
                app: null,
                category: null,
                section: null,
              },
              applicationId: environment.appsIds.scorecardsAppId,
              headerType: headerTypes.BREADCRUMBS_WITHOUT_DRAWER,
              titles: config.titles.SCORECARDS_QUESTION_MANAGEMENT,
            },
            children: [
              {
                path: '**',
                component: ScorecardsQuestionsManagementWrapperComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
