import { createReducer, on, Action } from '@ngrx/store';

import * as fromNews from './news-sidebar.actions';
import { NewsSidebarState, initialState } from './news-sidebar.model';


const reducer = createReducer(
  initialState,
  on(fromNews.openNewsSidebar, (state: NewsSidebarState) => ({
    ...state,
    opened: true,
  })),
  on(fromNews.closeNewsSidebar, (state: NewsSidebarState) => ({
    ...state,
    opened: false,
  })),
  on(fromNews.getMyNews, (state: NewsSidebarState) => ({
    ...state,
    loading: true,
    loadingError: false,
  })),
  on(fromNews.getMyNewsSuccess, (state: NewsSidebarState, {myNews}) => ({
    ...state,
    myNews,
    loading: false,
  })),
  on(fromNews.getNewsImagesSuccess, (state: NewsSidebarState, {newsImages}) => {
    const imagesMap = newsImages.reduce((acc, currentItem) => {
      acc[currentItem.id] = currentItem;
      return acc;
    }, {});

    const myNews = state.myNews.map(item => ({
      ...item,
      imageObject: imagesMap[item.id],
    }));

    return {
      ...state,
      myNews,
    };
  }),
  on(fromNews.getMyNewsFailure, (state: NewsSidebarState) => ({
    ...state,
    loading: false,
    loadingError: true,
  })),
  on(fromNews.setPersonalMainVisited, (state: NewsSidebarState) => ({
    ...state,
    personalMainVisited: true,
  }))
);

export function newsSidebarReducer(state: NewsSidebarState = initialState, action: Action) {
  return reducer(state, action);
}
