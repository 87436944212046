import { Directive, ElementRef, Input, OnInit, Renderer2, } from '@angular/core';
import { UsersFacade } from 'app/state/users';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { hasPermissionInApp } from './utils';

/**
* This directive checks whenever user has certain permission (or any from array of permissions)
* and hides or shows DOM element
*
* @param {string} applicationId - ID of app in which user should have permission
* @param {string | string[]} permissions - Permission key or keys (warning: currently it checks if user has ANY of them)
*/
@Directive({ selector: '[appHasPermissionInApp]' })
export class HasPermissionInAppDirective extends OnDestroyMixin implements OnInit {
  @Input() applicationId: string;
  @Input() permissions: string | string[];

  hidden: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private usersFacade: UsersFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.usersFacade.myself$
      .pipe(untilComponentDestroyed(this))
      .subscribe((user) => {
        const hasPermission = hasPermissionInApp(user, this.applicationId, this.permissions);
        this.setHidden(!hasPermission);
      });
  }

  private setHidden(value: boolean): void {
    if (this.hidden === value) {
      return;
    }

    this.hidden = value;
    this.renderer[value ? 'addClass' : 'removeClass'](this.el.nativeElement, 'hidden');
  }
}
