import { Component, ElementRef } from '@angular/core';

import { registerBudgetApp } from 'src/single-spa/budget';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-budget-wrapper',
  templateUrl: './budget-wrapper.component.html',
  styleUrls: ['./budget-wrapper.component.scss'],
})
export class BudgetWrapperComponent extends MicroAppWrapper {
  protected appName = 'Budget';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerBudgetApp(params);
  }
}
