import { HttpErrorResponse } from '@angular/common/http';
import { WmMember } from 'app/state/users';

export interface Client {
  id?: string;
  logo?: string;
  logoUrl?: string;
  name: string;
  mine?: boolean;
  markets?: Market[];
  categoryNames?: string[];
  // new (v3)
  description?: string;
  createdBy?: string;
  createdAt?: string;
  isAccepted: boolean;
}

export interface Market {
  id: string;
  name: string;
  code: string;
  status: string;
  region?: number;
  currency?: string;
  mine: boolean;
  isArchived: boolean;
  pending?: boolean;
  isClientAdmin?: boolean;
  creationStatus?: string;
  members?: WmMember[];
  owners?: WmMember[];
  useExistingTeam?: boolean;
  existingTeamId?: string;
  existingTeamName?: string;
  isTeamDeleted?: boolean;
  redirectToWppOpen?: boolean;
  moveToWppOpen?: boolean;
}

export interface Region {
  name: string;
  id: number;
  markets: Market[];
}

export interface Category {
  name: string;
  id: string;
  isBrandCategory: boolean;
}

export interface ClientsErrorResponse {
  message: string;
  metadata: {
    clientId: string;
    marketCode: string;
  };
}

export interface CreateMarketPayload {
  countryCode?: string;
  clientId: string;
  market?: { state: string };
  newMarket?: Partial<Market>;
}

export enum CLIENT_CREATION_RESPONSE_TYPE {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export interface ClientCreationRejectPayload {
  id: Client['id'];
  rejectReason: string;
}

export interface ClientCreationAcceptPayload {
  id: Client['id'];
}

export interface ClientCreationRequestState {
  type: CLIENT_CREATION_RESPONSE_TYPE | null;
  loading: boolean;
  success: boolean;
  error: HttpErrorResponse | null;
}

export interface ClientsState {
  clients: Client[];
  selectedClient: Client;
  categories: Category[];
  loading: boolean;
  isPollingActive: boolean;
  error: HttpErrorResponse;
  clientCreationRequestState: ClientCreationRequestState;
}

export const initialState: ClientsState = {
  clients: [],
  selectedClient: null,
  categories: [],
  loading: false,
  isPollingActive: false,
  error: null,
  clientCreationRequestState: {
    type: null,
    loading: false,
    success: false,
    error: null,
  },
};

export const clientsFeatureKey = 'clients';

export enum ClientManagementDrawerMode {
  RECREATE_MARKET = 'recreateMarket',
  EDIT_MARKET = 'editMarket',
  NEW_MARKET = 'newMarket',
  BRAND = 'brand',
}

export const NEW_CLIENT_ROUTE = 'new';
