import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const getContextualHelpState = (state: AppState) => state.contextualHelp;

export const selectCurrentTag = createSelector(
  getContextualHelpState,
  (state) => state.currentTag
);

export const selectGlobalPage = createSelector(
  getContextualHelpState,
  (state) => state.currentGlobalPage
);

export const selectLocalPage = createSelector(
  getContextualHelpState,
  (state) => state.currentLocalPage
);

export const selectPathsToUnclearPages = createSelector(
  getContextualHelpState,
  (state) => state.pathsToUnclearPages
);

export const selectLoading = createSelector(
  getContextualHelpState,
  (state) => state.loading
);

export const selectMatomoSection = createSelector(
  getContextualHelpState,
  (state) => state.matomoSection
);

export const selectContextualHelpTrackingSuffix = createSelector(
  getContextualHelpState,
  (state) => state.contextualHelpTrackingSuffix
);
