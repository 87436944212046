import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModeCodes } from '@mads/wm-ng-components';

import { environment } from '@environment';
import { UsersFacade } from 'app/state/users';
import { BrandsFacade } from 'app/state/brands';
import { ClientsFacade } from 'app/state/clients';
import * as navigationConfigs from '@config/box';
import {
  CurrentActiveMainNav,
  NavigationConfig,
  NavigationConfigFunctions,
  NavigationItem,
  UserSectionNavigationItem,
} from '@config';
import { ModeService } from './mode.service';

const modeNavigationMap = {
  [ModeCodes.WAVEMAKER]: navigationConfigs.wavemakerNavigation,
  [ModeCodes.MONDELEZ]: navigationConfigs.mondelezNavigation,
  [ModeCodes.LOREAL]: navigationConfigs.lorealNavigation,
};

@Injectable()
export class NavigationService {
  public navigationConfig$: Observable<NavigationConfig>;
  public mainNavigation$: Observable<NavigationItem[]>;
  public dashboardNavigation$: Observable<NavigationItem[]>;
  public avatarNavigation$: Observable<NavigationItem[]>;
  public clientCreationNavigation$: Observable<NavigationItem[]>;
  public userSectionNavigation$: Observable<UserSectionNavigationItem[]>;
  public userSettingsNavigation$: Observable<NavigationItem[]>;

  public currentActiveMainNav$ = new BehaviorSubject<CurrentActiveMainNav>(null);
  public mainConfigFunction$ = new BehaviorSubject<NavigationConfigFunctions['main']>(null);

  constructor(
    private userFacade: UsersFacade,
    private brandsFacade: BrandsFacade,
    private clientsFacade: ClientsFacade,
    private modeService: ModeService
  ) {
    this.navigationConfig$ = combineLatest([
      this.userFacade.myself$,
      this.clientsFacade.selectedClient$,
      this.brandsFacade.selectedBrand$,
      this.modeService.mode$,
      this.currentActiveMainNav$,
    ]).pipe(
      map(([user, client, brand, mode, currentActiveMainNav]) => {
        const {
          dashboard,
          main,
          userSection,
          clientCreation,
          avatarNavigation,
          userSettings,
        } = this.getAgencyConfigFunctions(mode.code);

        const mainConfig = main(brand?.country?.code, user);

        this.mainConfigFunction$.next(main);

        return {
          dashboard: dashboard(user),
          main: this.toggleActiveStatus(mainConfig, currentActiveMainNav),
          avatarNavigation: avatarNavigation(user),
          userSection: userSection(),
          clientCreation: clientCreation(client, user),
          userSettings: userSettings(user),
        };
      })
    );

    this.mainNavigation$ = this.navigationConfig$.pipe(map((config) => config.main));
    this.dashboardNavigation$ = this.navigationConfig$.pipe(map((config) => config.dashboard));
    this.avatarNavigation$ = this.navigationConfig$.pipe(map((config) => config.avatarNavigation));
    this.clientCreationNavigation$ = this.navigationConfig$.pipe(
      map((config) => config.clientCreation)
    );
    this.userSectionNavigation$ = this.navigationConfig$.pipe(map((config) => config.userSection));
    this.userSettingsNavigation$ = this.navigationConfig$.pipe(
      map((config) => config.userSettings)
    );
  }

  private getAgencyConfigFunctions(modeCode: ModeCodes): NavigationConfigFunctions {
    // return modeNavigationMap[ModeCodes.LOREAL]; // uncomment to change navigation locally - for easier development
    if (!environment.isWMAgency && (environment.appName === 'WMOS' || environment.appName === 'WMHome')) {
      return navigationConfigs.wavemakerNavigation;
    }

    return modeNavigationMap[modeCode];
  }

  private toggleActiveStatus(
    items: NavigationItem[],
    currentActiveMainNav: CurrentActiveMainNav
  ): NavigationItem[] {
    return items?.map((navigationItem) => {
      const isTitleMatching = navigationItem.id === currentActiveMainNav?.id;
      const isChildrenTitleMatching = navigationItem.nav?.some(
        (navItem) => navItem.id === currentActiveMainNav?.id
      );

      return !navigationItem.nav
        ? navigationItem
        : {
            ...navigationItem,
            opened: currentActiveMainNav?.deactivate
              ? isChildrenTitleMatching
              : isTitleMatching || isChildrenTitleMatching,
            nav: this.toggleActiveStatus(navigationItem.nav, currentActiveMainNav),
          };
    });
  }

  public closeAllNavigationItems(): void {
    this.currentActiveMainNav$.next(null);
  }

  public closeNavigationItem(id: NavigationItem['id']): void {
    this.currentActiveMainNav$.next({ id, deactivate: true });
  }

  public openMainNavigationItem(id: NavigationItem['id']): void {
    this.currentActiveMainNav$.next({ id });
  }
}
