import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ApplicationsFacade } from 'app/state/applications/applications.facade';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as applicationsActions from 'src/app/state/applications/applications.actions';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(
    private applicationsFacade: ApplicationsFacade,
    private actions$: Actions,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const appId: string = route.data.applicationId;

    if (!appId) {
      return true;
    }

    this.applicationsFacade.getApplication({ id: appId });

    return this.actions$.pipe(
      ofType(applicationsActions.getApplicationFailure, applicationsActions.getApplicationSuccess),
      take(1),
      map((action) => {
        if (
          action.type === applicationsActions.getApplicationSuccess.type &&
          action.application.maintenance
        ) {
          return this.router.createUrlTree(['maintenance'], { queryParams: { appId } });
        }

        return true;
      })
    );
  }
}
