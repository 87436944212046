import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-tile',
  templateUrl: './single-tile.component.html',
  styleUrls: ['./single-tile.component.scss'],
})
export class SingleTileComponent {
  @Input() public client: any;
  public clientType = 'WM_HOME.CAROUSEL.CLIENT_TYPE.ADVERTISER';

  public getPath(clientId: string): string {
    return `/clients/${clientId}/markets`;
  }
}
