import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'audience-galaxy',
  windowLibraryName: 'audienceGalaxyApp',
  baseHref: '/brands/:brandId/audience-galaxy/',
  assetsPath: environment.audienceGalaxyAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.audienceGalaxyAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.audienceGalaxyAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['audience-galaxy']),
};

export const registerAudienceGalaxyApp = (customParams?: any) => registerApp({ ...config, customParams });
