import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { compact, flatten } from 'lodash-es';

import { ProjectsHttpService } from 'app/core/http/projects-http.service';
import * as fromProjects from './projects.actions';
import { ProjectsFacade } from './projects.facade';
import { UsersFacade } from '../users';
import { checkIfUserIsInvolvedInProject, getNewestProjectForBrand } from './projects.utils';
import { ProjectBuilderService } from 'app/core/project-builder-service';

@Injectable()
export class ProjectsEffects {

  constructor(
    private actions$: Actions,
    private http: ProjectsHttpService,
    private projectsFacade: ProjectsFacade,
    private usersFacade: UsersFacade,
    private projectBuilderService: ProjectBuilderService
  ) {}

  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.getProjects),
      switchMap(payload =>
        this.http.getProjects(payload.clientId).pipe(
          map((projects) => fromProjects.getProjectsSuccess({ projects })),
          catchError((err) => of(fromProjects.getProjectsFail(err)))
        )
      )
    )
  );

  // project mode is off by default, but we want it to turn on when user is involved in a project:
  getProjectsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.getProjectsSuccess),
      withLatestFrom(this.projectsFacade.modeSettings$, this.usersFacade.myself$),
      map(([{projects}, settings, myself]) => {
        const newSettings = compact(flatten(projects.map(project => {
          const shouldSwitchOn = checkIfUserIsInvolvedInProject(project, myself);
          if (!shouldSwitchOn) { return null; }

          return project.brand.map(brandId => {
            const setting = settings.find(setting => setting.brandId === brandId);

            // dont change already saved settings if this project is not newer then settings , even if user is involved:
            if (setting && new Date(setting.newestProjectCreation) >= new Date(project.createdAt)) { return null; }

            // if there were no manual settings or there came newer project where user is involved, change settings
            // there may be many 'newer' projects so get proper date
            const newestProjectCreation = getNewestProjectForBrand(projects, brandId).createdAt; 
            return {
              brandId,
              modeOn: true,
              newestProjectCreation,
            };
          });
        })));
        return fromProjects.setProjectModeBulk({ newSettings });
      }),
    )
  );

  broadcastProjectBuilderSettingChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjects.setProjectMode, fromProjects.setProjectModeBulk, fromProjects.selectProject, fromProjects.propagateSettingsToMicrofrontends),
      withLatestFrom(this.projectsFacade.modeSettings$, this.projectsFacade.selections$),
      map(([payload, modeSettings, selections]) => {
        this.projectBuilderService.broadcastProjectBuilderSettingChange({ projectModeOn: true, selections, modeSettings });
      })
    ), { dispatch: false }
  );
}
