import { Component } from '@angular/core';
import { CLOSE_TOUR_REMINDER } from '@config/project-guide';
import { GuideService } from '@mads/wm-ng-components';

@Component({
  selector: 'app-product-tour-reminder',
  templateUrl: './product-tour-reminder.component.html',
  styleUrls: ['./product-tour-reminder.component.scss'],
})
export class TourReminderComponent {
  readonly closeReminderTour = CLOSE_TOUR_REMINDER;

  constructor(private guideService: GuideService) {}

  public dontShowTourReminderAgain() {
    this.guideService.hideTourReminder();
    this.guideService.dontShowTourReminderAgain();
  }
}
