import { Client } from './clients.model';

export const prepareClientFormData = (client: Client): FormData => {
  const clientData = new FormData();

  clientData.append('name', client.name);
  clientData.append('description', client.description);

  if (client.markets) {
    clientData.append('markets', JSON.stringify(client.markets));
  }

  if (client.logo) {
    clientData.append('logo', client.logo);
  }

  if (client.logoUrl) {
    clientData.append('logoUrl', client.logoUrl);
  }

  return clientData;
};
