interface Countries {
  [symbol: string]: {
    currency: string;
    region: number;
    dateLocale?: string;
    numberLocale?: string;
    name: string;
    isWithoutLivePanel?: boolean;
  };
}

export const countries: Countries = {
  FR: {
    currency: '€',
    region: 1,
    dateLocale: 'fr',
    numberLocale: 'fr',
    name: 'France',
  },
  GB: {
    currency: '£',
    region: 1,
    dateLocale: 'en-gb',
    numberLocale: 'en-gb',
    name: 'United Kingdom',
  },
  DE: {
    currency: '€',
    region: 1,
    dateLocale: 'de',
    numberLocale: 'de',
    name: 'Germany',
  },
  NL: {
    currency: '€',
    region: 1,
    dateLocale: 'nds-nl',
    numberLocale: 'nds-nl',
    name: 'Netherlands',
  },
  IT: {
    currency: '€',
    region: 1,
    dateLocale: 'it',
    numberLocale: 'it',
    name: 'Italy',
  },
  ES: {
    currency: '€',
    region: 1,
    dateLocale: 'es-es',
    numberLocale: 'es-es',
    name: 'Spain',
  },
  PL: {
    currency: 'zł',
    region: 1,
    dateLocale: 'pl',
    numberLocale: 'pl',
    name: 'Poland',
  },
  AT: {
    currency: '€',
    region: 1,
    name: 'Austria',
  },
  BE: {
    currency: '€',
    region: 1,
    name: 'Belgium',
  },
  CH: {
    currency: 'CHF',
    region: 1,
    name: 'Switzerland',
  },
  CZ: {
    currency: 'Kč',
    region: 1,
    name: 'Czechia',
  },
  DK: {
    currency: 'kr.',
    region: 1,
    name: 'Denmark',
  },
  FI: {
    currency: '€',
    region: 1,
    name: 'Finland',
  },
  GR: {
    currency: '€',
    region: 1,
    name: 'Greece',
  },
  HR: {
    currency: 'kn',
    region: 1,
    dateLocale: 'en-gb',
    numberLocale: 'en-gb',
    name: 'Croatia',
  },
  LT: {
    currency: '€',
    region: 1,
    name: 'Lithuania',
  },
  NO: {
    currency: 'kr',
    region: 1,
    name: 'Norway',
  },
  PT: {
    currency: '€',
    region: 1,
    name: 'Portugal',
  },
  RO: {
    currency: 'L',
    region: 1,
    name: 'Romania',
  },
  RS: {
    currency: '€',
    region: 1,
    name: 'Serbia',
  },
  SE: {
    currency: 'kr',
    region: 1,
    name: 'Sweden',
  },
  SK: {
    currency: '€',
    region: 1,
    name: 'Slovakia',
  },
  TR: {
    currency: '₺',
    region: 1,
    name: 'Turkey',
  },
  UA: {
    currency: '₴',
    region: 1,
    name: 'Ukraine',
  },
  ZA: {
    currency: '£',
    region: 1,
    name: 'South Africa',
  },
  AE: {
    currency: 'د.إ',
    region: 1,
    name: 'United Arab Emirates',
  },
  EE: {
    currency: '€',
    region: 1,
    name: 'Estonia',
  },
  HU: {
    currency: 'Ft',
    region: 1,
    name: 'Hungary',
  },
  IE: {
    currency: '€',
    region: 1,
    name: 'Ireland',
  },
  LV: {
    currency: '€',
    region: 1,
    name: 'Latvia',
  },
  SA: {
    currency: 'ر.س',
    region: 1,
    name: 'Saudi Arabia',
  },
  RU: {
    currency: '₽',
    region: 1,
    name: 'Russian Federation',
  },
  SI: {
    currency: '€',
    region: 1,
    name: 'Slovenia',
    isWithoutLivePanel: true,
  },
  EG: {
    currency: 'E£',
    region: 1,
    name: 'Egypt',
  },
  KW: {
    currency: 'د.ك',
    region: 1,
    name: 'Kuwait',
  },
  QA: {
    currency: 'ر.ق',
    region: 1,
    name: 'Qatar',
    isWithoutLivePanel: true,
  },
  MA: {
    currency: 'DH',
    region: 1,
    name: 'Morocco',
  },
  LB: {
    currency: 'ل.ل.',
    region: 1,
    name: 'Lebanon',
    isWithoutLivePanel: true,
  },
  NG: {
    currency: '₦',
    region: 1,
    name: 'Nigeria',
  },
  GH: {
    currency: 'GH₵',
    region: 1,
    name: 'Ghana',
  },
  CI: {
    currency: 'XAF',
    region: 1,
    name: 'Ivory Coast',
  },
  CM: {
    currency: 'XAF',
    region: 1,
    name: 'Cameroon',
  },
  ET: {
    currency: 'ብር',
    region: 1,
    name: 'Ethiopia',
  },
  KE: {
    currency: 'KSh',
    region: 1,
    name: 'Kenya',
  },
  TZ: {
    currency: 'TSh',
    region: 1,
    name: 'Tanzania',
  },
  UG: {
    currency: 'USh',
    region: 1,
    name: 'Uganda',
  },
  JO: {
    currency: 'JOD',
    region: 1,
    name: 'Jordan',
    isWithoutLivePanel: true,
  },
  IQ: {
    currency: 'د.ع',
    region: 1,
    name: 'Iraq',
  },
  TN: {
    currency: 'د.ت',
    region: 1,
    name: 'Tunisia',
  },
  AU: {
    currency: '$',
    region: 2,
    dateLocale: 'en-au',
    numberLocale: 'en-au',
    name: 'Australia',
  },
  IN: {
    currency: '₹',
    region: 2,
    dateLocale: 'hi',
    numberLocale: 'hi',
    name: 'India',
  },
  CN: {
    currency: '¥',
    region: 2,
    dateLocale: 'zh',
    numberLocale: 'zh',
    name: 'China',
  },
  HK: {
    currency: '$',
    region: 2,
    name: 'Hong Kong',
  },
  ID: {
    currency: 'Rp',
    region: 2,
    name: 'Indonesia',
  },
  JP: {
    currency: '¥',
    region: 2,
    name: 'Japan',
  },
  KR: {
    currency: '₩',
    region: 2,
    name: 'South Korea',
  },
  MY: {
    currency: 'RM',
    region: 2,
    name: 'Malaysia',
  },
  NZ: {
    currency: '$',
    region: 2,
    name: 'New Zealand',
  },
  PH: {
    currency: '₱',
    region: 2,
    name: 'Philippines',
  },
  SG: {
    currency: '$',
    region: 2,
    name: 'Singapore',
  },
  TH: {
    currency: '฿',
    region: 2,
    name: 'Thailand',
  },
  TW: {
    currency: 'NT$',
    region: 2,
    name: 'Taiwan',
  },
  VN: {
    currency: '₫',
    region: 2,
    name: 'Viet Nam',
  },
  PK: {
    currency: 'Rs',
    region: 2,
    name: 'Pakistan',
  },
  LK: {
    currency: 'රු',
    region: 2,
    name: 'Sri Lanka',
  },
  AR: {
    currency: '$',
    region: 3,
    name: 'Argentina',
  },
  BR: {
    currency: 'R$',
    region: 3,
    name: 'Brazil',
  },
  CL: {
    currency: '$',
    region: 3,
    name: 'Chile',
  },
  CO: {
    currency: '$',
    region: 3,
    name: 'Colombia',
  },
  DO: {
    currency: '$',
    region: 3,
    name: 'Dominican Republic',
    isWithoutLivePanel: true,
  },
  EC: {
    currency: 'S/.',
    region: 3,
    name: 'Ecuador',
  },
  PA: {
    currency: 'B/.',
    region: 3,
    name: 'Panama',
    isWithoutLivePanel: true,
  },
  PE: {
    currency: 'S/',
    region: 3,
    name: 'Peru',
  },
  UY: {
    currency: '$',
    region: 3,
    name: 'Uruguay',
  },
  MX: {
    currency: '$',
    region: 3,
    name: 'Mexico',
  },
  PR: {
    currency: 'ps',
    region: 3,
    name: 'Puerto Rico',
  },
  CR: {
    currency: '₡',
    region: 3,
    name: 'Costa Rica',
  },
  PY: {
    currency: '₲',
    region: 3,
    name: 'Paraguay',
  },
  CY: {
    currency: '€',
    region: 1,
    name: 'Cyprus',
  },
  YE: {
    currency: '﷼',
    region: 1,
    name: 'Yemen',
  },
  CA: {
    currency: ' ',
    region: 4,
    dateLocale: 'en-ca',
    numberLocale: 'en-ca',
    name: 'Canada',
  },
  US: {
    currency: '$',
    region: 4,
    dateLocale: 'en-us',
    numberLocale: 'en-us',
    name: 'United States of America',
  },
  KH: {
    currency: '៛',
    region: 2,
    name: 'Cambodia'
  },
  LA: {
    currency: '₭N',
    region: 2,
    name: 'Laos'
  },
  MM:{
    currency: 'K',
    region: 2,
    name: 'Myanmar'
  },
  NP: {
    currency: 'रू',
    region: 2,
    name: 'Nepal'
  },
  OM: {
    currency: 'ر.ع',
    region: 2,
    name: 'Oman'
  },
  KZ: {
    currency: '₸',
    region: 1,
    name: 'Kazakhstan'
  },
  BG: {
    currency: 'лв',
    region: 1,
    name: 'Bulgaria'
  },
  IL: {
    currency: '₪',
    region: 1,
    name: 'Israel'
  },
  LU: {
    currency: '€',
    region: 1,
    name: 'Luxembourg'
  },
  AO: {
    currency: 'Kz',
    region: 1,
    name: 'Angola'
  },
  BF: {
    currency: 'CFA',
    region: 1,
    name: 'Burkina Faso'
  },
  CD: {
    currency: 'CDF',
    region: 1,
    name: 'Congo (Democratic Republic of the)'
  },
  ZM: {
    currency: 'ZMW',
    region: 1,
    name: 'Zambia'
  },
  CG: {
    currency: 'XAF',
    region: 1,
    name: 'Congo (Republic of the)'
  },
  MZ: {
    currency: 'MZN',
    region: 1,
    name: 'Mozambique'
  },
  GT: {
    currency: 'Q',
    region: 3,
    name: 'Guatemala'
  },
  HN: {
    currency: 'HNL',
    region: 3,
    name: 'Honduras'
  },
  NI: {
    currency: 'NIO',
    region: 3,
    name: 'Nicaragua'
  },
  SV: {
    currency: '$',
    region: 3,
    name: 'El Salvador'
  }
};
