import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { GuideService } from '@mads/wm-ng-components';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { Client, ClientsFacade } from 'app/state/clients';
import { RouterFacade } from 'app/state/router';

import { CLIENT_MANAGEMENT_TOUR, CLIENT_MANAGEMENT_TOUR_NAME } from 'app/config/project-guide';
import { BrandsFacade } from 'app/state/brands';
import { atLeastOneOfFields } from '../../client-creation.validators';
import { LOGOS_LIMIT } from '../add-logo-dialog/add-logo-dialog.model';

const INPUTS_MAX_LENGTH = 40;
const TEXTAREAS_MAX_LENGTH = 255;

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss'],
})
export class ClientManagementComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public isLoading$ = this.clientsFacade.loading$.pipe(
    tap(() => this.changeDetectionRef.detectChanges())
  );
  public selectedLogoUrl$ = this.brandsFacade.selectedLogoUrl$;

  public isEdited = false;
  public client: Client;
  public clientsList: Client[];
  public clientForm: UntypedFormGroup;
  readonly inputsMaxLength = INPUTS_MAX_LENGTH;
  readonly textareasMaxLength = TEXTAREAS_MAX_LENGTH;
  readonly clientGuideTour = CLIENT_MANAGEMENT_TOUR;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientsFacade: ClientsFacade,
    private formBuilder: UntypedFormBuilder,
    private guideService: GuideService,
    private routerFacade: RouterFacade,
    private brandsFacade: BrandsFacade,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isEdited = !!this.activatedRoute.parent.snapshot.params.clientId;
    this.clientsFacade.resetClients();
    this.brandsFacade.resetLogosSettings();

    this.createForm(this.isEdited);
    this.getClients();

    this.isEdited ? this.getClient() : (this.clientsFacade.resetClient(), this.startGuideTour());
  }

  public ngOnDestroy(): void {
    if (this.isEdited) {
      super.ngOnDestroy();

      return;
    }

    this.guideService.endTour();
    localStorage.setItem(CLIENT_MANAGEMENT_TOUR_NAME, 'true');

    super.ngOnDestroy();
  }

  private createForm(isEdited: boolean): void {
    this.clientForm = this.formBuilder.group(
      {
        name: [
          {
            value: '',
            disabled: isEdited,
          },
          Validators.compose([Validators.maxLength(INPUTS_MAX_LENGTH), Validators.required]),
        ],
        description: ['', Validators.maxLength(TEXTAREAS_MAX_LENGTH)],
        logo: [null],
        logoUrl: [null],
      },
      { validators: [atLeastOneOfFields(this.isEdited, 'logo', 'logoUrl')] }
    );

    this.filterClientsList();
  }

  get clientName(): string {
    return this.clientForm.get('name').value;
  }

  private getClients(): void {
    this.clientsFacade.clients$.pipe(untilComponentDestroyed(this)).subscribe((clients) => {
      this.clientsList = clients;
      this.changeDetectionRef.detectChanges();
    });
  }

  private getClient(): void {
    this.clientsFacade.selectedClient$
      .pipe(
        untilComponentDestroyed(this),
        filter((client) => !!client)
      )
      .subscribe((client) => {
        this.client = client;
        this.fillClientForm(client);
      });
  }

  private fillClientForm(client: Client): void {
    const logo = null;
    this.clientForm.patchValue({ ...client, logo });
  }

  private filterClientsList(): void {
    this.clientForm.controls['name'].valueChanges
      .pipe(debounceTime(500), untilComponentDestroyed(this))
      .subscribe((value) => {
        const clientName = value.trim();
        this.clientsFacade.getFilteredClients(clientName);
      });
  }

  public loadClientLogo(logo: File): void {
    this.clientForm.patchValue({
      logo,
      logoUrl: null,
    });
  }

  public loadClientLogoUrl(logoUrl: string): void {
    this.brandsFacade.setSelectedLogoUrl(logoUrl);

    this.clientForm.patchValue({
      logoUrl,
      logo: null,
    });
  }

  public removeClientLogoUrl(): void {
    this.brandsFacade.setSelectedLogoUrl(null);
  }

  public saveClient(): void {
    // getRawValue() returns the value of the form, including the disabled controls

    this.isEdited
      ? this.clientsFacade.updateClient({ ...this.clientForm.getRawValue() }, this.client.id)
      : this.clientsFacade.createClient({ ...this.clientForm.getRawValue() });
  }

  public navigateToMainPage(): void {
    this.routerFacade.changeRoute({
      linkParams: ['/'],
    });
  }

  public navigateToClient(clientId: string): void {
    this.routerFacade.changeRoute({
      linkParams: ['clients', 'client-creation', clientId, 'markets-management'],
    });
  }

  private startGuideTour(): void {
    if (localStorage.getItem(CLIENT_MANAGEMENT_TOUR_NAME)) {
      return;
    }

    const guideSteps = Object.values(this.clientGuideTour).map((step) => step.id);
    this.guideService.startTour(guideSteps);
  }

  public getLogos(): void {
    const name = this.clientForm.get('name').value;

    if (!name) {
      return;
    }

    this.brandsFacade.getLogos(name, LOGOS_LIMIT);
  }
}
