import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { UsersFacade } from 'src/app/state/users';
import { checkIfPermissionSatisfiesRequirements } from 'app/shared/utils';

/* If user has only one required permission (or only one required value of one required permission)
 this guard will let user pass. In other words, if you specify a couple of required permissions
 or permissions values, this guard won't check if user has every specified permission. It will
 check if user has some required permission.
*/
@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(private router: Router, private usersFacade: UsersFacade) {}

  redirectToAccessDenied() {
    // TODO: redirect user to Access denied page
    const urlTree = this.router.createUrlTree(['/']);
    return urlTree;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.usersFacade.loading$.pipe(
      filter((loading) => !loading),
      take(1),
      switchMap(() => {
        return this.usersFacade.myself$.pipe(
          map((user) => {
            if (!user || !user.userRoles) {
              return this.redirectToAccessDenied();
            }
            const isUserAuthorized = user.userRoles
              .filter(
                (role) =>
                  route.data.entrancePermissions.some(
                    (permission) => permission.appId === role.applicationId
                  )
              )
              .some((role) =>
                role.permissions.some((permission) =>
                  checkIfPermissionSatisfiesRequirements(
                    permission,
                    route.data.entrancePermissions
                  )
                )
              );

            if (!isUserAuthorized) {
              return this.redirectToAccessDenied();
            }
            return true;
          })
        );
      })
    );
  }
}
