import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { Theme } from '@wppopen/components-library'


@Injectable()
export class WppOpenThemeHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getTheme(): Observable<{data: Theme}> {
    const request = this.http.get<{data: Theme}>(
      `${WppOpenThemeHttpService.WPP_OPEN_API_URL}/api/tenants/current/theme`,
      {headers: {
        'x-tenant-prefix': WppOpenThemeHttpService.WPP_OPEN_TENANT_PREFIX
      }}
    );
    return this.handleRequest<{data: Theme}>(request);
  }
}
