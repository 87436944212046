import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Page } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contextual-help-page',
  templateUrl: './contextual-help-page.component.html',
  styleUrls: ['./contextual-help-page.component.scss'],
})
export class ContextualHelpPageComponent implements OnChanges {

  @Input() page: Page;

  public sanitizedContent: SafeHtml;
  public imgSrc = 'assets/icons/go-to-default.svg';

  @ViewChild('wikiContentWrapper') wikiContentWrapper: ElementRef<HTMLElement>;
  @ViewChild('articleContainer') articleContainer: ElementRef<HTMLElement>;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  public ngOnChanges(): void {
    this.sanitizedContent = this.parseContent(this.page.render);
  }

  private parseContent(content: Page['render']): SafeHtml {
    const srcParsed = this.addDomainToAttributes('src="', content);
    const hrefsParsed = this.addDomainToAttributes('href="', srcParsed);
    return this.sanitizer.bypassSecurityTrustHtml(hrefsParsed);
  }

  public addScrollableAnchors(): void {
    const { nativeElement } = this.wikiContentWrapper;
    this.removeElementsByClass('toc-anchor', nativeElement);
    const allHrefs = nativeElement.getElementsByTagName('a');
    this.addHrefClickListeners(allHrefs);

    this.articleContainer.nativeElement.scrollTop = 0;
  }

  public goToWiki(path: string): void {
    window.open(`${environment.wikiDomain}/${path}`, '_blank');
  }

  public addHrefClickListeners(allHrefs: HTMLCollectionOf<HTMLAnchorElement>): void {
    for (let i = 0; i < allHrefs.length; i++) {
      const item = allHrefs.item(i);
      const url = item.getAttribute('href');
      if (url.includes(environment.wikiDomain) && url.includes('#')) {
        item.addEventListener('click', (ev: MouseEvent) => {
          ev.preventDefault();
          const idOfElem = url.split('#').pop();
          const elem = this.wikiContentWrapper.nativeElement.querySelector(`#${idOfElem}`);
          elem?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }
  private addDomainToAttributes(attribute: string, htmlInput: string): string {
    const splitted = htmlInput.split(attribute);
    return splitted.reduce((alreadyParsedHtml, nextPart, idx) => {
      if (idx === 0) { return nextPart; }
      if (nextPart.startsWith('http')
        || nextPart.startsWith('www')
        || nextPart.startsWith('https')
        || nextPart.startsWith('mailto:')
      ) {
        return `${alreadyParsedHtml}${attribute}${nextPart}`;
      }
      return `${alreadyParsedHtml}${attribute}${environment.wikiDomain}${nextPart}`;
    }, '');
  }

  private removeElementsByClass(className: string, mainNode: HTMLElement): void {
    const elementsToRemove = mainNode.getElementsByClassName(className);
    while (elementsToRemove.length > 0) {
      elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
    }
  }
}
