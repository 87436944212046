import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BrandsFacade } from 'app/state/brands';
import { combineLatest } from 'rxjs';
import { FileUploadState, WmFileUploadComponent } from '@mads/wm-ng-components';

import { ACCEPTED_FILE_TYPES, DialogOutputData } from './add-logo-dialog.model';

@Component({
  selector: 'app-add-logo-dialog',
  templateUrl: './add-logo-dialog.component.html',
  styleUrls: ['./add-logo-dialog.component.scss'],
})
export class AddLogoDialogComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public logo: File;
  public fileUploadState: FileUploadState = FileUploadState.INITIAL;
  public acceptedFileTypes = ACCEPTED_FILE_TYPES.join(', ');
  public dialogOutputData: DialogOutputData;
  public selectedLogoUrl: string;
  public logos: string[];
  public error = false;

  public isLogosLoading$ = this.brandsFacade.isLogosLoading$;
  public logos$ = this.brandsFacade.logos$;
  public selectedLogoUrl$ = this.brandsFacade.selectedLogoUrl$;

  @ViewChild('wmFileUpload') wmFileUpload: WmFileUploadComponent;

  constructor(
    public dialogRef: MatDialogRef<AddLogoDialogComponent>,
    public brandsFacade: BrandsFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscribeLogosData();
  }

  public loadLogo(event: Event): void {
    this.fileUploadState = FileUploadState.UPLOADING;
    this.logo = event[0];

    if (this.logo) {
      this.fileUploadState = FileUploadState.COMPLETED;
      this.selectedLogoUrl = null;
      this.error = false;
    }
  }

  public removeLogo(): void {
    this.logo = null;
    this.fileUploadState = FileUploadState.INITIAL;
  }

  public uploadError(): void {
    this.error = true;
  }

  public saveDialog() {
    this.dialogOutputData = {
      logo: this.logo,
      logoUrl: this.selectedLogoUrl,
    };

    this.dialogRef.close(this.dialogOutputData);
  }

  public selectLogo(newLogoUrl: string): void {
    this.selectedLogoUrl = newLogoUrl;
    this.removeLogo();
    this.wmFileUpload.onRemoveFile();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public subscribeLogosData(): void {
    combineLatest([this.logos$, this.selectedLogoUrl$])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([logos, selectedLogoUrl]) => {
        this.logos = logos;

        if (this.fileUploadState === FileUploadState.COMPLETED) {
          return;
        }

        this.selectedLogoUrl = selectedLogoUrl;
      });
  }
}
