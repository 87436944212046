import { DomSanitizer } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { mapValues } from 'lodash-es';

@Injectable()
export class MatIconService {
  public icons = {
    wm_maximize: {
      name: 'wm-maximize',
      path: '/assets/icons/maximize.svg',
    },
    wm_minimize: {
      name: 'wm-minimize',
      path: '/assets/icons/minimize.svg',
    },
    wm_notification: {
      name: 'wm-notification',
      path: '/assets/icons/notification.svg',
    },
    wm_play: {
      name: 'wm-play',
      path: '/assets/icons/play.svg',
    },
    wm_list: {
      name: 'wm-list',
      path: '/assets/icons/list.svg',
    },
    wm_tiles: {
      name: 'wm-tiles',
      path: '/assets/icons/tiles.svg',
    },
  };

  constructor(
    @Inject(MatIconRegistry) private iconRegistry: MatIconRegistry,
    @Inject(DomSanitizer) private sanitizer: DomSanitizer
  ) {}

  public init(): void {
    mapValues(this.icons, (icon) =>
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      )
    );
  }
}
