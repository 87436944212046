import { createReducer, on, Action } from '@ngrx/store';
import { uniqBy } from 'lodash-es';

import * as fromBrandsActions from './brands.actions';
import { initialState, BrandsState } from './brands.model';

const reducer = createReducer(
  initialState,
  on(fromBrandsActions.getBrands, (state) => ({
    ...state,
    loading: true,
    brands: initialState.brands,
    error: initialState.error,
  })),
  on(
    fromBrandsActions.createBrand,
    fromBrandsActions.updateBrand,
    fromBrandsActions.deleteBrand,
    (state) => ({
      ...state,
      loading: true,
      error: initialState.error,
    })
  ),
  on(fromBrandsActions.resetError, (state) => ({ ...state, error: initialState.error })),
  on(fromBrandsActions.getBrandsSuccess, (state, { brands }) => ({
    ...state,
    brands,
    loading: false,
  })),
  on(fromBrandsActions.createBrandSuccess, (state, { brand }) => ({
    ...state,
    loading: false,
    brands: [brand, ...state.brands],
  })),
  on(
    fromBrandsActions.getBrand,
    (state) => ({
      ...state,
      error: initialState.error,
      loading: true,
    })
  ),
  on(
    fromBrandsActions.getBrandsFailure,
    fromBrandsActions.createBrandFailure,
    fromBrandsActions.deleteBrandFailure,
    fromBrandsActions.getBrandFailure,
    fromBrandsActions.updateBrandFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),
  on(fromBrandsActions.getBrandSuccess, (state, { brand }) => ({
    ...state,
    loading: false,
    selectedBrand: brand,
    // we want to add singularily downloaded brand to brands for caching purposes:
    brands: uniqBy([...state.brands, brand], 'id'),
  })),
  on(fromBrandsActions.updateBrandSuccess, (state, { updatedBrand }) => ({
    ...state,
    loading: false,
    brands: state.brands.map((brand) => (brand.id === updatedBrand.id ? updatedBrand : brand)),
  })),
  on(fromBrandsActions.deleteBrandSuccess, (state, { brandId }) => {
    const brands = state.brands.filter((brand) => brand.id !== brandId);

    return {
      ...state,
      loading: false,
      brands,
    };
  }),
  on(fromBrandsActions.resetBrand, (state) => ({ ...state, loading: false, selectedBrand: initialState.selectedBrand })),
  on(fromBrandsActions.getLogos, (state) => ({ ...state, isLogosLoading: true })),
  on(fromBrandsActions.getLogosSuccess, (state, { logos }) => ({ ...state, logos, isLogosLoading: false })),
  on(fromBrandsActions.getLogosFailure, (state, { error }) => ({ ...state, isLogosLoading: false, error })),
  on(fromBrandsActions.setSelectedLogoUrl, (state, { selectedLogoUrl }) => ({
    ...state,
    selectedLogoUrl,
  })),
  on(fromBrandsActions.resetLogosSettings, (state) => ({
    ...state,
    logos: null,
    selectedLogoUrl: null,
  }))
);

export function brandsReducer(state: BrandsState | undefined, action: Action): BrandsState {
  return reducer(state, action);
}
