import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { of, EMPTY } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';

import { first } from 'lodash-es';

import { constants } from '@config/constants';
import { NewsHttpService } from 'app/core/http/news-http.service';
import { AppState } from 'app/state/index';

import * as fromNews from './news-sidebar.actions';
import { NewsArticle, MyNewsResponse } from './news-sidebar.model';

@Injectable()
export class NewsSidebarEffects {
  constructor(
    private actions$: Actions,
    private http: NewsHttpService,
    private store$: Store<AppState>
  ) {}

  getMyNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNews.getMyNews),
      withLatestFrom(this.store$),
      switchMap(([{ limit }, {users}]) => {
        const { myself } = users;
        return this.http.getMyNews({limit , market: myself.country.symbol}).pipe(
          mergeMap((response: MyNewsResponse) => {
            if (response.articles.length > 0 ) {
              return [
                fromNews.getMyNewsSuccess({ myNews: response.articles }),
                fromNews.getNewsImages({ newsIds: response.articles.map(article => article.id) }),
              ];
            }
            return [ fromNews.getMyNewsSuccess({ myNews: response.articles }) ];
          }),
          catchError((err) => {
            return of(fromNews.getMyNewsFailure(err));
          })
        );
      })
    )
  );

  getNewsImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNews.getNewsImages),
      switchMap((payload) =>
        this.http.getNewsImages(payload.newsIds.join(',')).pipe(
          map(newsImages => {
            return fromNews.getNewsImagesSuccess({ newsImages });
          }),
          catchError(error => of(fromNews.getNewsImagesFail({ error })))
        )
      )
    )
  );

  getMyNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNews.getMyNewsSuccess),
      switchMap(({ myNews }) => {
        const latestNews = first(myNews);
        const latestSavedDate = localStorage.getItem('latestNewsDate');

        return new Date(latestNews.publishedDate).getTime() >
        new Date(latestSavedDate).getTime()
          ? of(fromNews.openNewsSidebar())
          : EMPTY;
      })
    )
  );

  newsSidebarOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNews.openNewsSidebar),
      withLatestFrom(this.store$),
      switchMap(([action, store]) => {
        const news = store.newsSidebar.myNews;

        if (!news.length) {
          return of(fromNews.getMyNews({ limit: constants.NEWS_LIMIT }));
        }
        const latestNews: NewsArticle = first(news);
        localStorage.setItem('latestNewsDate', latestNews.publishedDate);

        return EMPTY;
      })
    )
  );
}
