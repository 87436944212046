import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs';

import { constants } from '@config/constants';

// TODO
// The steps from this tour are in two apps (Home, You).
// It is not possible to combine steps from more than one app.
// I hope the following code is temporary.

@Component({
  selector: 'app-header-home-guide-project-builder',
  templateUrl: './header-home-guide-project-builder.component.html',
  styleUrls: ['./header-home-guide-project-builder.component.scss']
})
export class HeaderHomeGuideProjectBuilderComponent implements OnInit {
  public isOnYouPage = false;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router.url),
      )
      .subscribe(() => {
        this.isOnYouPage = this.router.url === constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE;
      });
  }
}
