import { createReducer, on, Action } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { countries } from '@config/countries';
import { defaults } from '@config/defaults';

import * as fromUsers from './users.actions';
import { UsersState, initialState, SimplifiedPermission } from './users.model';

import { cloneDeep, findIndex, isArray, reduce, set, uniq, flatten } from 'lodash-es';

const mergePermissions = (data: SimplifiedPermission[]): SimplifiedPermission[] => {
  return reduce(
    data,
    (acc, permission) => {
      const idxOfAlreadySavedDuplicate = findIndex(acc, savedPermission => savedPermission.key === permission.key);
      if (idxOfAlreadySavedDuplicate < 0) { return [...acc, permission]; }

      const alreadySavedDuplicate = acc[idxOfAlreadySavedDuplicate];
      const values = isArray(alreadySavedDuplicate.values) // "if its not a type has permission"
        && uniq([
          ...alreadySavedDuplicate.values,
          ...permission.values,
        ]);
      if (!values) { return acc; } // it was a duplicated type has permission, so just skip it
      const newAcc = cloneDeep(acc);
      set(newAcc, `[${idxOfAlreadySavedDuplicate}.values]`, values);
      return newAcc;
    },
    []
  );
};

const reducer = createReducer(
  initialState,
  on(fromUsers.getMyself, fromUsers.getUsers, fromUsers.getUserTeams, (state: UsersState) => ({
    ...state,
    loading: true,
  })),
  on(fromUsers.getMyselfSuccess, (state: UsersState, { user }) => {
    const rolesInContextualHelp = user.userRoles.filter(role =>
      role.applicationId === environment.appsIds.contextualHelpAppId);
    const hasContextualHelpAccess = rolesInContextualHelp
      .some(role => role.permissions.some(permission => permission.key === 'CAN_VIEW_CONTENT'));
    const isContextualHelpEditor = rolesInContextualHelp
      .some(role => role.permissions.some(permission => permission.key === 'CAN_MANAGE_CONTENT'));

    const myself = {
      ...user,
      avatar:
        environment.profilePicturesApiUrl && user.userPrincipalName
          ? `${environment.profilePicturesApiUrl}/${user.userPrincipalName}`
          : null,
      dateLocale: countries[user.country.symbol]?.dateLocale
        ? countries[user.country.symbol].dateLocale
        : defaults.defaultLocale.dateLocale,
      numberLocale: countries[user.country.symbol]?.numberLocale
        ? countries[user.country.symbol].numberLocale
        : defaults.defaultLocale.numberLocale,
      permissions: mergePermissions(
        flatten(
          (user.userRoles || [])
            .filter((role) => role?.applicationId === environment.appsIds.appId)
            .map((role) => role?.permissions)
        )
      ),
      hasContextualHelpAccess,
      isContextualHelpEditor,
    };

    return {
      ...state,
      myself,
      loading: false,
    };
  }),
  on(fromUsers.getUsersSuccess, (state, { users, getOwners }) =>
    getOwners
      ? { ...state, owners: users, loading: false }
      : { ...state, members: users, loading: false }
  ),
  on(fromUsers.getUserTeamsSuccess, (state, { userTeams }) => ({
    ...state,
    userTeams,
    loading: false,
  })),
  on(
    fromUsers.getMyselfFailure,
    fromUsers.getUsersFailure,
    fromUsers.getUserTeamsFailure,
    (state: UsersState) => ({
      ...state,
      loading: false,
    })
  ),
  on(fromUsers.resetUsers, (state) => ({ ...state, owners: [], members: [] })),
  on(fromUsers.logout, (state: UsersState) => ({
    ...state,
    myself: null,
  })),
  on(fromUsers.saveTargetPath, (state, { path }) => ({ ...state, targetPath: path })),
  on(fromUsers.toggleBrandMenuMode, (state) => ({ ...state, simplifiedBrandMenuOn: !state.simplifiedBrandMenuOn })),
  on(fromUsers.getDefaultMarket, (state) => ({
    ...state,
    loadingDefaultMarketId: true,
  })),
  on(fromUsers.getDefaultMarketSuccess, (state, { defaultMarketId }) => ({
    ...state,
    defaultMarketId,
    loadingDefaultMarketId: false,
  })),
  on(fromUsers.getDefaultMarketFailure,
    (state, { error }) => ({ ...state, loadingDefaultMarketId: false })
  )
);

export function usersReducer(state: UsersState = initialState, action: Action) {
  return reducer(state, action);
}
