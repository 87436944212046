<div class="wrapper">
  <div [routerLink]="['/']" class="app-logo" [ngClass]="currentMode?.code">
    <img [src]="currentLogoPath$ | async" alt="agency logo" />
  </div>

  <div class="dashboard-nav">
    <ul class="dashboard-nav__list">
      <ng-container *ngFor="let navItem of dashboardNav$ | async">
        <li
          *ngIf="!navItem.hidden"
          [ngClass]="{
            'current-page': isPathActive(navItem.path) && !navItem.external,
            disabled: navItem.disabled
          }"
        >
          <a
            *ngIf="navItem.external; else localLink"
            [href]="navItem.path"
            (click)="callTracker(navItem.trackerPayload)"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{navItem.title | translate}}
          </a>

          <ng-template #localLink>
            <a
              *ngIf="navItem.reloadOnClick else casualLink"
              (click)="redirectWithChildAppReload(navItem.path)"
              [attr.translate]="navItem.title"
              [routerLink]="navItem.path"
              [translate]="navItem.title"
            >
            </a>
          </ng-template>

          <ng-template #casualLink>
            <a
              [routerLink]="navItem.path"
              [attr.translate]="navItem.title"
              [translate]="navItem.title"
            >
            </a>
          </ng-template>
          <span *ngIf="navItem.isNew" class="wm-chip wm-uppercase wm-left-space">{{'WM_HOME.HEADER.NEW' | translate}}</span>
          <app-header-home-guide-project-builder *ngIf="navItem.title==='WM_HOME.HEADER.PROJECT_BUILDER'"></app-header-home-guide-project-builder>
        </li>
      </ng-container>
    </ul>
  </div>
  <app-header-news-and-avatar [displayNews]="currentRoute !== constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE"></app-header-news-and-avatar>
</div>
