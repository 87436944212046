import { Component, ElementRef } from '@angular/core';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppParams, MicroAppWrapper } from 'app/modules-wrappers/micro-app-wrapper';
import { registerProductsStatsApp } from '../../../single-spa/products-stats';

@Component({
  selector: 'app-products-stats-wrapper',
  templateUrl: './products-stats-wrapper.component.html',
  styleUrls: ['./products-stats-wrapper.component.scss'],
})
export class ProductsStatsWrapperComponent extends MicroAppWrapper {
  protected appName = 'Products stats';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerProductsStatsApp(params);
  }
}
