import { environment } from 'src/environments/environment';

import {
  matchingBrandPathname,
  MicrofrontendAppConfig,
  registerApp,
} from './utils';

const config: MicrofrontendAppConfig = {
  name: 'audiencetranslator-container',
  windowLibraryName: 'audiencetranslator',
  baseHref: '/brands/:brandId/xlator',
  assetsPath: environment.audienceTranslatorAppUrl.replace('main.js', ''),
  appUrl: environment.audienceTranslatorAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', ''),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.audienceTranslatorAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['xlator']),
};

export const registerAudienceTranslatorApp = (customParams?: any) => registerApp({ ...config, customParams });
