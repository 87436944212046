import { Component, ElementRef } from '@angular/core';

import { registerCampaignsSetupApp } from 'src/single-spa/campaigns-setup';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface CampaignsSetupAppState {
  pristine: boolean;
}

const appState: CampaignsSetupAppState = { pristine: true };

@Component({
  selector: 'app-campaigns-setup-wrapper',
  templateUrl: './campaigns-setup-wrapper.component.html',
  styleUrls: ['./campaigns-setup-wrapper.component.scss'],
})
export class CampaignsSetupWrapperComponent extends MicroAppWrapper {
  protected appName = 'Campaigns Setup';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: CampaignsSetupAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerCampaignsSetupApp(params);
  }
}
