<div class="market-header__container">
  <h3 class="wm-h3 market-header__name">{{ market?.name }}</h3>
  <div class="market-header__details">
    <div class="market-header__info">
      <p
        class="wm-overline market-header__info-title"
        translate="WM_HOME.MARKET_MANAGEMENT.HEADER_STATUS"
      ></p>
      <app-market-status [status]="market?.status"></app-market-status>
    </div>
    <div class="market-header__info">
      <p
        class="wm-overline market-header__info-title"
        translate="WM_HOME.MARKET_MANAGEMENT.HEADER_OWNERS"
      ></p>
      <div class="market-header__users" data-test="WM_HOME.MARKET_MANAGEMENT.HEADER_OWNERS">
        <ng-container *ngFor="let owner of market.owners | slice: 0:displayedUsersNumber">
          <img
            wmTooltip
            [contentTemplate]="tooltipOwners"
            [src]="profilePictureApiUrl + '/' + owner.userPrincipalName"
            class="market-header__users-image"
            [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
          />
          <ng-template #tooltipOwners
            ><div class="market-header__tooltip">
              <img
                [src]="profilePictureApiUrl + '/' + owner.userPrincipalName"
                class="market-header__tooltip-image"
                [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
              />
              <div class="market-header__tooltip-info">
                <h6 class="market-header__tooltip-name">{{ owner.displayName }}</h6>
                <p class="market-header__tooltip-email">{{ owner.email }}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div
          *ngIf="market.owners?.length > displayedUsersNumber"
          class="market-header__users-image market-header__users-image--empty"
        >
          <span> {{ market.owners.length - displayedUsersNumber }}+</span>
        </div>
      </div>
    </div>
    <div class="market-header__info">
      <p
        class="wm-overline market-header__info-title"
        translate="WM_HOME.MARKET_MANAGEMENT.HEADER_TEAM"
      ></p>
      <div class="market-header__users" data-test="WM_HOME.MARKET_MANAGEMENT.HEADER_MEMBERS">
        <ng-container *ngFor="let member of market.members | slice: 0:displayedUsersNumber">
          <img
            wmTooltip
            [contentTemplate]="tooltipMembers"
            [src]="profilePictureApiUrl + '/' + member.userPrincipalName"
            class="market-header__users-image"
            [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
          />
          <ng-template #tooltipMembers
            ><div class="market-header__tooltip">
              <img
                [src]="profilePictureApiUrl + '/' + member.userPrincipalName"
                class="market-header__tooltip-image"
                [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
              />
              <div class="market-header__tooltip-info">
                <h6 class="market-header__tooltip-name">{{ member.displayName }}</h6>
                <p class="market-header__tooltip-email">{{ member.email }}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!market.members?.length"> - </ng-container>
        <div
          *ngIf="market.members?.length > displayedUsersNumber"
          wmTooltip
          [contentTemplate]="tooltipList"
          class="market-header__users-image market-header__users-image--empty"
        >
          <span> {{ market.members.length - displayedUsersNumber }}+</span>
        </div>
        <ng-template #tooltipList
          ><div
            *ngFor="let member of market.members | slice: displayedUsersNumber"
            class="market-header__tooltip market-header__tooltip--list"
          >
            <img
              [src]="profilePictureApiUrl + '/' + member.userPrincipalName"
              class="market-header__tooltip-image"
              [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
            />
            <div class="market-header__tooltip-info">
              <h6 class="market-header__tooltip-name">{{ member.displayName }}</h6>
              <p class="market-header__tooltip-email">{{ member.email }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
