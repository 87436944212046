import { hasUserFeatureAccess, SimplifiedPermission } from '@mads/wm-ng-components';

import { environment } from 'src/environments/environment';
import { constants } from 'app/config/constants';
import { User } from 'app/state/users';
import { UserNavigationSection, UserSectionNavigationItem } from '@config';
import { isArray, some, isEmpty, includes, flatten } from 'lodash-es';

export const checkFeatureFlagAccess = (
  user: User,
  featureFlag: string,
  appId: string = environment.appsIds.appId
): boolean => {
  if (!featureFlag) {
    return true;
  }

  return hasUserFeatureAccess(user, appId, featureFlag);
};

export const checkAccessToModule = (user: User, moduleKey: string): boolean =>
  user.userRoles.some((role) => {
    const isAppValid = role.applicationId === environment.appsIds.appId;
    const isCountryValid = !role.countries.length || role.countries.includes(user.country.symbol);

    if (!isAppValid || !isCountryValid) {
      return false;
    }

    return role.permissions.some(
      (permission) =>
        permission.key === constants.USER_PERMISSION_KEYS.CAN_ACCESS_MODULE &&
        permission.values.includes(moduleKey)
    );
  });

/**
 * Checks whenever user has certain permission.
 */
export const hasPermission = (user: User, permission: string): boolean => {
  if (!user || !isArray(user.permissions) || isEmpty(user.permissions)) {
    return false;
  }

  return some(user.permissions, perm => perm.key === permission);
};

/**
 * Checks whenever user has certain permission (or **any** from array of permissions) associated to certain app.
 */
export const hasPermissionInApp = (
  user: User,
  applicationId: string,
  requiredPermissions: string | string[]
): boolean => {
  if (!user || isEmpty(user.userRoles)) {
    return false;
  }

  const appRoles = user.userRoles.filter((role) => role.applicationId === applicationId);

  if (!appRoles.length) {
    return false;
  }

  const appPermissions = flatten(appRoles.map(role => role.permissions));

  if (isArray(requiredPermissions)) {
    return some(appPermissions, ({key}) => includes(requiredPermissions, key));
  }

  return some(appPermissions, ({key}) => key === requiredPermissions);
};

export const getAvatarUrl = (userEmailAddressAsUPN: string): string => {
  return `${environment.profilePicturesApiUrl}/${userEmailAddressAsUPN}`;
};

export const checkIfPermissionSatisfiesRequirements = (
  permission: SimplifiedPermission,
  requiredPermissions: SimplifiedPermission[]
): boolean => some(requiredPermissions, requiredPermission => {
  const requiredPermissionMatchesPermission = requiredPermission.key === permission.key;
  if (!requiredPermissionMatchesPermission) { return false; }
  // when there are no values required and keys were already checked, return true:
  if (isEmpty(requiredPermission.values)) { return true; }
  // when values are required, check if owned permission has at least one required value:
  return some(requiredPermission.values, requiredValue => includes(permission.values, requiredValue));
});

export const checkSingleChoicePermission = (
  user: User,
  permissionKey: string,
  appId: string = environment.appsIds.clientManagementAppId
): boolean =>
  user?.userRoles.some(
    (role) =>
      appId === role.applicationId &&
      role.permissions.some((permission) => permission.key === permissionKey)
  );

export const getSection = (
    sections: UserNavigationSection,
    sectionsNames: string[]
  ): UserSectionNavigationItem => {
  const section = sections?.find(({ name }) => name === sectionsNames[0]);

  if (sectionsNames?.length === 1) {
    return section;
  }

  const childSections = section?.children;
  const [, ...childNames] = sectionsNames;

  return getSection(childSections, childNames);
};

export const getNthIdxOf = (str: string, pattern: string, ocurrence: number): number => {
  let idx = -1;
  while (ocurrence-- && idx++ < str.length) {
    idx = str.indexOf(pattern, idx);
    if (idx < 0) { break; }
  }
  return idx;
};

export const statusToMessage = {
  403: 'You don\'t have permission for this ',
  404: 'There is no such ',
};
