<wm-wrapper>
  <div
    class="maintenance"
    *ngIf="{ maintenancePageAppName: maintenancePageAppName$ | async } as viewData"
  >
    <div class="level">
      <div class="content">
        <div class="gears">
          <div class="gears-static" [style.background-image]="gearsUrl"></div>

          <div class="gear-system-1">
            <div class="gear15" [style.background-image]="gearsUrl"></div>
            <div class="gear14" [style.background-image]="gearsUrl"></div>
            <div class="gear13" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="gear-system-2">
            <div class="gear10" [style.background-image]="gearsUrl"></div>
            <div class="gear3" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="gear-system-3">
            <div class="gear9" [style.background-image]="gearsUrl"></div>
            <div class="gear7" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="gear-system-4">
            <div class="gear6" [style.background-image]="gearsUrl"></div>
            <div class="gear4" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="gear-system-5">
            <div class="gear12" [style.background-image]="gearsUrl"></div>
            <div class="gear11" [style.background-image]="gearsUrl"></div>
            <div class="gear8" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="gear1" [style.background-image]="gearsUrl"></div>

          <div class="gear-system-6">
            <div class="gear5" [style.background-image]="gearsUrl"></div>
            <div class="gear2" [style.background-image]="gearsUrl"></div>
          </div>

          <div class="chain-circle" [style.background-image]="gearsUrl"></div>
          <div class="chain"></div>
          <div class="weight" [style.background-image]="gearsUrl"></div>
        </div>

        <div class="title">
          <div class="logo">
            <img class="logo--agency" [src]="currentLogoPath$ | async" alt="agency logo" />
          </div>
          <h3 class="wm-h4">
            {{
              'WM_HOME.MAINTENANCE.DESCRIPTION_PART_1'
                | translate: { applicationName: viewData?.maintenancePageAppName | translate }
            }}
            <br />
            {{ 'WM_HOME.MAINTENANCE.DESCRIPTION_PART_2' | translate }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</wm-wrapper>
