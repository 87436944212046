import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, take, map, withLatestFrom } from 'rxjs/operators';

import { Brand, BrandsFacade } from 'app/state/brands';
import { ClientsFacade } from 'app/state/clients';
import { UsersFacade } from 'app/state/users';
import * as actions from 'app/state/brands/brands.actions';
import { checkSingleChoicePermission } from 'app/shared/utils';
import { constants } from '@config/constants';

@Injectable()
export class CanEditGuard implements CanActivate {
  constructor(
    private actions$: Actions,
    private brandFacade: BrandsFacade,
    private clientsFacade: ClientsFacade,
    private router: Router,
    private usersFacade: UsersFacade
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const { brandId } = route.params;
    const { isBrandEdited } = route.data;

    return isBrandEdited ? this.canEditBrand(brandId) : this.canEditClient();
  }

  private canEditClient(): Observable<boolean | UrlTree> {
    return this.clientsFacade.selectedClient$.pipe(
      withLatestFrom(this.usersFacade.myself$),
      take(1),
      map(([client, user]) => {
        const pendingUrlTree = this.router.createUrlTree([
          `/clients/client-creation/${client?.id}/pending`,
        ]);

        if (!client?.isAccepted) {
          return pendingUrlTree;
        }

        // @TODO: still valid? (WMO-9651)
        return client?.markets.some((market) => market.isClientAdmin) ||
          (client?.mine &&
            checkSingleChoicePermission(user, constants.USER_PERMISSION_KEYS.CAN_ADD_CLIENT) &&
            client?.isAccepted)
          ? true
          : this.redirect();
      })
    );
  }

  private canEditBrand(brandId: Brand['id']): Observable<boolean | UrlTree> {
    if (!brandId) {
      return of(this.redirect());
    }

    return this.brandFacade.selectedBrand$.pipe(
      take(1),
      switchMap((brand) => {
        if (this.validateBrand(brand, brandId)) {
          return of(true);
        }

        this.brandFacade.getBrand(brandId);

        return this.actions$.pipe(
          ofType(actions.getBrandSuccess, actions.getBrandFailure),
          take(1),
          switchMap((action) => {
            if (action.type === actions.getBrandFailure.type) {
              return of(this.redirect());
            }

            return of(this.validateBrand(action.brand, brandId) ? true : this.redirect());
          })
        );
      })
    );
  }

  private redirect(): UrlTree {
    return this.router.createUrlTree(['/']);
  }

  private validateBrand(brand: Brand, brandId: Brand['id']): boolean {
    return brand && brand.id === brandId && brand.isClientAdmin;
  }
}
