import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'audiences-app',
  windowLibraryName: 'audiencesApp',
  assetsPath: environment.audiencesAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/audiences',
  // assetsPath: 'http://localhost:4350/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4350/main.js',
  appUrl: environment.audiencesAppUrl,
  matchingStrategy: matchingBrandPathname(['audiences']),
};

export const registerAudiencesApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
