import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Brand } from 'app/state/brands';

import { defaults } from '@config/defaults';
import { UsersFacade } from 'app/state/users';
import { map } from 'rxjs';
import { getHasCanAddBrand } from './utils';
import { environment } from '@environment';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss'],
})
export class BrandsListComponent {
  @Input() brands: Brand[];
  @Input() isLoading: boolean;
  @Output() addBrand = new EventEmitter<void>();
  @Output() editBrand = new EventEmitter<Brand>();
  @Output() deleteBrand = new EventEmitter<Brand>();

  public activeBrandId: string;
  readonly creationStatus = defaults.creationStatus;

  hasCanAddBrand$ = this.usersFacade.myself$.pipe(map(getHasCanAddBrand));

  constructor(private usersFacade: UsersFacade) {}

  public activateBrand(brandId: Brand['id']): void {
    this.activeBrandId = this.activeBrandId !== brandId ? brandId : '';
  }

  public handleAddBrand(hasCanAddBrand: boolean): void {
    if (hasCanAddBrand) {
      this.addBrand.emit();
      return;
    }

    window.open(environment.formsUrl, '_blank');
  }

  public handleEditBrand(brand: Brand, hasCanAddBrand: boolean): void {
    if (!hasCanAddBrand) {
      return;
    }

    this.editBrand.emit(brand);
  }

  public handleDeleteBrand(brand: Brand, hasCanAddBrand: boolean): void {
    if (!hasCanAddBrand) {
      return;
    }

    this.deleteBrand.emit(brand);
  }
}
