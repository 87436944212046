<app-entity-menu-header [entity]="brand">
  <div class="info">
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.CATEGORIES"></h5>
      <ul *ngIf="brand.categories" class="list list--categories">
        <li *ngFor="let category of brand.categories" class="label">
          {{ category.name }}
        </li>
      </ul>
    </div>
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.WEBSITE"></h5>
      <a *ngIf="brand.websiteUrl" [href]="brand.websiteUrl" data-test="WM_HOME.HEADER.WEBSITE">
        {{ brand.websiteUrl }}
      </a>
    </div>
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.SOCIAL_MEDIA"></h5>
      <ul *ngIf="brand.socialMediaLinks" class="list" data-test="WM_HOME.HEADER.SOCIAL_MEDIA">
        <li *ngFor="let media of brand.socialMediaLinks">
          <a [href]="media.url">{{ media.name }}</a>
        </li>
      </ul>
    </div>
  </div>

  <i-feather
    *ngIf="brand.isClientAdmin"
    name="edit-3"
    class="edit-button"
    editButton
    [routerLink]="['/clients/client-creation', brand.clientId, 'markets-management']"
    [queryParams]="{ open: true, activeMarket: brand.country.code, brandId: brand.id, drawerMode: DrawerMode.BRAND }"
  ></i-feather>
</app-entity-menu-header>
<div class="breakline-header">
  <span
    class="wm-h6"
    translate="WM_HOME.HEADER.PLANNING"
  ></span>
  <button
    mat-stroked-button
    class="wm-button-tertiary"
    (click)="handleGoToCart()"
    translate="WM_HOME.NAVIGATION_MAIN.CART"
  ></button>
</div>

<div *ngIf="hasAccessToSimplifiedBrandMenu$ | async" class="brand-menu-mode-toggle">
  <p class="wm-s-500" translate="WM_HOME.HEADER.BRAND_MODE_SWITCH"></p>
  <mat-slide-toggle
    class="wm-button-toggle"
    [ngModel]="(simplifiedBrandMenuOn$ | async)"
    (change)="toggleBrandMenuMode()"
  ></mat-slide-toggle>
</div>

<nav class="menu">
  <ng-container *ngIf="(simplifiedBrandMenuOn$ | async) else defaultMenu">
    <div
      *ngFor="let navigationItem of simplifiedNavigationItems"
      class="navigation-category"
      [style.width]="(100 / simplifiedNavigationItems.length + '%')"
    >
      <div *ngIf="!navigationItem.hidden"
        class="category-title wm-subtitle"
        [class.category-title--wavemaker]="isWmMode">
        <p [translate]="navigationItem.title"></p>
        <p class="wm-xs-400">{{navigationItem.description}}</p>
      </div>
      <ul class="list">
        <ng-container *ngFor="let navigationItem of navigationItem.nav">
          <li *ngIf="navigationItem && !navigationItem.hidden">
            <div
              [class.active]="!navigationItem.disabled"
              [class.disabled]="navigationItem.disabled"
              (click)="handleNavigationItemClick($event, navigationItem, navigationItem.title)"
            >
              <h6 class="wm-m-500 tool-navigation-item active"
              >{{navigationItem.toolName}}</h6>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>

  <ng-template #defaultMenu>
    <div
      *ngFor="let navigationCategory of navigationCategories"
      class="navigation-category"
      [style.width]="(100 / navigationCategories.length + '%')"
    >
      <div *ngIf="!navigationCategory.hidden"
        class="category-title wm-subtitle"
        [class.category-title--wavemaker]="isWmMode">
        <p [translate]="navigationCategory.title"></p>
        <p class="wm-xs-400">{{navigationCategory.description}}</p>
      </div>
      <ul class="list">
        <ng-container *ngFor="let navigationItem of navigationCategory.nav">
          <li *ngIf="!navigationItem.hidden"
            class="navigation-items-wrapper"
            [class.navigation-items-wrapper--wavemaker]="isWmMode">
            <div
              class="navigationItem-title"
              [class.active]="!navigationCategory.disabled && !navigationItem.disabled"
              [class.disabled]="navigationCategory.disabled || navigationItem.disabled"
              (click)="handleNavigationItemClick($event, navigationItem, navigationCategory.title)"
            >
              <h2 class="wm-h4 navigationItem-title__title"
                [class.navigationItem-title__title--wavemaker]="isWmMode"
                [translate]="navigationItem.title"></h2>
              <wm-arrow
                *ngIf="navigationItem.nav && !navigationItem.disabled"
                [size]="arrowSize"
                [isExpanded]="navigationItem.opened"
                color="primary-on-contrast-background"
              ></wm-arrow>
            </div>
            <ul
              class="list subnavigation-list nested-level-1"
              *ngIf="navigationItem.opened"
            >
            <ng-container *ngFor="let subnavigationItem of navigationItem.nav">
              <ng-container
              [ngTemplateOutlet]="subnavigation"
              [ngTemplateOutletContext]="{subnavigationItem:subnavigationItem, navigationItem: navigationItem, nestedLevel: 2}">
              </ng-container>
            </ng-container>
          </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>
</nav>


<ng-template
  #subnavigation
  let-subnavigationItem="subnavigationItem"
  let-navigationItem="navigationItem"
  let-nestedLevel="nestedLevel"
>
  <li
    (click)="handleNavigationItemClick($event, subnavigationItem)"
    class="subnavigation-item"
    [class.subnavigation-item--wavemaker]="isWmMode"
    [class.disabled]="subnavigationItem.disabled"
    *ngIf="!subnavigationItem.hidden"
  >
    <div class="subnavigation-texts">
      <div class="link-and-icon">
        <a [translate]="subnavigationItem.title"
          class="subnavigation-link wm-h4"
          [class.subnavigation-link--wavemaker]="isWmMode"></a>
        <img [src]="subnavigationItem.iconPath" alt="" />
        <span class="wm-chip wm-uppercase" *ngIf="subnavigationItem.isNew">{{'WM_HOME.HEADER.NEW' | translate}}</span>
      </div>
      <p class="subnavigation-description wm-xs-400">
        {{subnavigationItem.description}}
      </p>
    </div>
    <wm-arrow
      *ngIf="subnavigationItem.nav && !subnavigationItem.disabled"
      [size]="arrowSize"
      [isExpanded]="subnavigationItem.opened"
      color="primary-on-contrast-background"
    ></wm-arrow>
  </li>
  <ul
    [className]="'nested-level-' + nestedLevel + ' list subnavigation-list'"
    *ngIf="subnavigationItem.opened"
  >
    <ng-container *ngFor="let nestedItem of subnavigationItem.nav">
      <ng-container
        [ngTemplateOutlet]="subnavigation"
        [ngTemplateOutletContext]="{
          subnavigationItem: nestedItem,
          navigationItem: subnavigationItem,
          nestedLevel: nestedLevel + 1
        }"
      >
      </ng-container>
    </ng-container>
  </ul>
</ng-template>
