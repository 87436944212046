import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { GuideManagerService, GuideService } from '@mads/wm-ng-components';

import { MatIconService } from './mat-icon.service';
import { ModeService } from './mode.service';
import { SnackbarService } from './snackbar.service';
import { NavigationService } from './navigation.service';
import { HttpTranslationsService } from './http-translations.service';
import { MicroAppWrapperService } from './micro-app-wrapper.service';

import {
  AuthGuard,
  CanEditGuard,
  ClientGuard,
  ClientAndBrandGuard,
  LivePanelGuard,
  MarketManagementGuard,
  ModuleGuard,
  PermissionsGuard,
  MaintenanceGuard,
  YouGuard,
  TitleGuard,
  ErrorGuard,
  ResetCurrentProjectGuard,
  ResetBrandGuard,
  ResetClientGuard,
  BrandRouteMatcherGuard,
} from './guards';

import { HttpErrorInterceptor } from './http/http-error.interceptor';

import { BrandsHttpService } from './http/brands-http.service';
import { ClientsHttpService } from './http/clients-http.service';
import { UsersHttpService } from './http/users-http.service';
import { NewsHttpService } from './http/news-http.service';
import { PersonalHttpService } from './http/personal-http.service';
import { ApplicationsHttpService } from './http/applications-http.service';
import { NoMaintenanceGuard } from './guards/no-maintenance.guard';
import { TrackerService } from './tracker.service';
import { TrackerHttpService } from './http/tracker-http.service';
import { ChildNavigationService } from './child-navigation-service';
import { GraphqlModule } from './graphql/graphql.module';
import { ProjectsHttpService } from './http/projects-http.service';
import { ProjectBuilderService } from './project-builder-service';
import { WppOpenThemeHttpService } from './http/wpp-open-theme-http.service';
import { WppOpenThemeService } from './wpp-open-theme.service';
import { WppOpenThemeAppenderService } from './wpp-open-theme-appender.service';
@NgModule({
  // declarations are forbidden in the CoreModule, use feature modules or SharedModule instead
  imports: [CommonModule, HttpClientModule, GraphqlModule],
  providers: [
    BrandsHttpService,
    ClientsHttpService,
    NewsHttpService,
    PersonalHttpService,
    UsersHttpService,
    ApplicationsHttpService,
    ProjectsHttpService,
    TrackerHttpService,
    AuthGuard,
    CanEditGuard,
    ClientGuard,
    ClientAndBrandGuard,
    LivePanelGuard,
    MarketManagementGuard,
    ModuleGuard,
    PermissionsGuard,
    MaintenanceGuard,
    ErrorGuard,
    YouGuard,
    ResetCurrentProjectGuard,
    ResetBrandGuard,
    ResetClientGuard,
    BrandRouteMatcherGuard,
    NoMaintenanceGuard,
    TitleGuard,
    GuideService,
    GuideManagerService,
    MatIconService,
    ModeService,
    ChildNavigationService,
    SnackbarService,
    HttpTranslationsService,
    NavigationService,
    MicroAppWrapperService,
    TrackerService,
    ProjectBuilderService,
    WppOpenThemeHttpService,
    WppOpenThemeAppenderService,
    WppOpenThemeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
