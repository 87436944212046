<wm-guide-tooltip
  class="product-tour-reminder"
  [arrowPosition]="closeReminderTour.arrowPosition"
  [id]="closeReminderTour.id"
  [title]="closeReminderTour.title | translate"
>
  <span [translate]="closeReminderTour.description"></span>
  <div
    class="product-tour-reminder__dont-show-again"
    (click)="dontShowTourReminderAgain()"
    translate="WM_HOME.GUIDE_TOUR.GUIDE_CLOSE_TOUR_REMINDER_BUTTON"
  ></div>
</wm-guide-tooltip>
