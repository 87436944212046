import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'mp-uploader',
  windowLibraryName: 'mpUploaderApp',
  assetsPath: environment.mediaPlanUploaderAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/campaigns/custom',
  appUrl: environment.mediaPlanUploaderAppUrl,
  appLocation: environment.mediaPlanUploaderAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['campaigns/custom']),
};

export const registerMediaPlansUploaderApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
