import { Component, OnInit } from '@angular/core';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { config } from '@config/index';

// uncomment for @questionnaire
import { QuestionnaireComponent } from 'app/shared/components/questionnaire-popup/questionnaire-popup.component';
import { get, isNull } from 'lodash-es';
import { UsersFacade } from 'app/state/users';
import { environment } from '@environment';
import { checkSingleChoicePermission } from 'app/shared/utils';
import { constants, TEST_ENVIRONMENT } from '@config/constants';
import { PersonalFacade } from 'app/state/personal';
import { combineLatest } from 'rxjs';
import { QuestionnaireFacade } from 'app/state/questionnaire/questionnaire.facade';
import { MatDialog } from '@angular/material/dialog';
// ----------------------------------

const maximumRecursiveCalls = 15;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends OnDestroyMixin implements OnInit {
  public headerType: string;
  public headerTypes = config.constants.HEADER_TYPES;
  private safetyCounter = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersFacade: UsersFacade,
    private personalFacade: PersonalFacade,
    private questionnaireFacade: QuestionnaireFacade,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.headerType = this.extractHeaderType();

    this.router.events
      .pipe(
        untilComponentDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.headerType = this.extractHeaderType();
      });

    // uncomment for @questionnaire
    setTimeout(() => this.openQuestionnairePopup(), 800); // timeout needed for unknown reasons in contacts, provoke the brief and who knows where
    // -----------------------------------
  }

  private extractHeaderType(middlePartOfRoutePath = ''): string {
    this.safetyCounter = this.safetyCounter + 1;
    if (this.safetyCounter > maximumRecursiveCalls) {
      console.error(
        "Developer's mistake - someone forgot to add headerType to this route. It causes infinite loop."
      );
      return this.headerTypes.HOME;
    }

    const path = `snapshot.firstChild.${middlePartOfRoutePath}data.headerType`;
    this.headerType = get(this.route, path);
    if (this.headerType) {
      this.safetyCounter = 0;
    }

    return this.headerType || this.extractHeaderType(`${middlePartOfRoutePath}firstChild.`);
  }

  // uncomment for @questionnaire
  private openQuestionnairePopup(): void {
    const isE2E = localStorage.getItem(TEST_ENVIRONMENT);
    if (isE2E) { return; }
    const isBefore5thDecember = new Date() < new Date('2022-12-05');
    if (!isBefore5thDecember
      || this.router.url.includes('scorecards')
      || this.router.url.includes('growth-opportunities')) { return; }
    
    this.usersFacade.myself$.pipe(
      filter(user => !!user),
      take(1),
      filter(user => checkSingleChoicePermission(
        user,
        constants.USER_PERMISSION_KEYS.SHOULD_SEE_QUESTIONNAIRE,
        environment.appsIds.appId
      )),
      tap(user => { this.personalFacade.checkPresentationMode(); }),
      switchMap(user => this.personalFacade.isInPresentationMode$),
      filter(isInPresentationMode => !isNull(isInPresentationMode)), // wait for http answer
      filter(isInPresentationMode => !isInPresentationMode), // stop stream if is in presentation mode
      take(1),
      switchMap(isInPresentationMode => combineLatest([
        this.questionnaireFacade.dismissed$,
        this.questionnaireFacade.opened$,
      ])),
      take(1)
    ).subscribe(([dismissed, opened]: [boolean, boolean]) => {
      if (dismissed || opened) { return; }
      this.dialog.open(QuestionnaireComponent, {
        panelClass: 'questionnaire-modal',
        width: '32vw',
        height: '27vw',
        maxHeight: '610px',
        maxWidth: '778px',
      });
    });
  }
  // -----------------------------------
}
