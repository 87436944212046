import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Application } from 'app/state/applications/applications.model';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable()
export class ApplicationsHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getApplication(payload: { id: string }): Observable<Application> {
    const request = this.http.get<Application>(
      `${ApplicationsHttpService.AUTH_API_URL}/applications/${payload.id}`,
      ApplicationsHttpService.httpOptions
    );

    return this.handleRequest<Application>(request);
  }
}
