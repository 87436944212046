import { environment } from 'src/environments/environment';

import { matchingGlobalPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'login-box-app-container',
  windowLibraryName: 'loginBoxApp',
  assetsPath: environment.loginAppUrl.replace('main.js', 'assets/'),
  baseHref: '/login',
  appUrl: environment.loginAppUrl,
  appLocation: environment.loginAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/login']),
};

export const registerLoginApp = (customParams?: any) => registerApp({ ...config, customParams });
