<wm-wrapper>
  <nav mat-tab-nav-bar class="wm-main-navigation client-creation__navigation">
    <a
      *ngFor="let navItem of nav"
      mat-tab-link
      disableRipple
      [active]="navItem.isActive"
      [routerLink]="navItem.path"
      [disabled]="navItem.disabled"
    >
      <span translate> {{ navItem.title }}</span></a
    >
  </nav>
  <router-outlet></router-outlet>
</wm-wrapper>
