import { matchingGlobalPathname, registerApp, MicrofrontendAppConfig } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'micro-news-app',
  windowLibraryName: 'microNews',
  baseHref: '/news',
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  assetsPath: environment.newsSettingsAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.newsSettingsAppUrl,
  appLocation: environment.newsSettingsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/news']),
};

export const registerNewsApp = (customParams?: Object) =>
  registerApp({ ...config, customParams });
