import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { QuestionnaireFacade } from 'app/state/questionnaire/questionnaire.facade';
import { UsersFacade } from 'app/state/users';

@Component({
  selector: 'app-questionnaire-popup',
  templateUrl: './questionnaire-popup.component.html',
  styleUrls: ['./questionnaire-popup.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal;

  public userName$ = this.usersFacade.myself$.pipe(map(user => user.firstname));

  private clicksOutsideOfModal = fromEvent(document, 'click').pipe(
    filter(event => !this.modal?.nativeElement.contains(event.target))
  );
  private outsideModalClicksSub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<QuestionnaireComponent>,
    private questionnaireFacade: QuestionnaireFacade,
    private usersFacade: UsersFacade
  ) {}

  ngOnInit(): void {
    this.outsideModalClicksSub = this.clicksOutsideOfModal.subscribe(click => this.close(click as MouseEvent));
  }

  ngOnDestroy(): void {
    this.outsideModalClicksSub.unsubscribe();
    this.questionnaireFacade.opened$.pipe(take(1)).subscribe(wasOpened => {
      if (wasOpened) { return; }
      this.questionnaireFacade.displayNotification();
    });
  }

  public close(event: MouseEvent): void {
    const { x, y } = event;
    this.questionnaireFacade.showAnimation({ x, y });
    this.dialogRef.close();
  }

  public goToQuestionnaire(): void {
    this.questionnaireFacade.open();
    this.dialogRef.close();
  }

  public dismiss(event: MouseEvent): void {
    this.questionnaireFacade.dismiss();
    const { x, y } = event;
    this.questionnaireFacade.showAnimation({ x, y });
    this.dialogRef.close();
  }
}
