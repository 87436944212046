import { Component, ElementRef } from '@angular/core';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppParams, MicroAppWrapper } from 'app/modules-wrappers/micro-app-wrapper';
import { registerGeographApp } from '../../../single-spa/geograph';

@Component({
  selector: 'app-geograph-wrapper',
  templateUrl: './geograph-wrapper.component.html',
  styleUrls: ['./geograph-wrapper.component.scss'],
})
export class GeographWrapperComponent extends MicroAppWrapper {
  protected appName = 'Geograph';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerGeographApp(params);
  }
}
