import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from 'app/state';

import * as fromNewsActions from './news-sidebar.actions';
import * as fromNewsSelectors from './news-sidebar.selectors';

@Injectable()
export class NewsSidebarFacade {
  public opened$ = this.store.select(fromNewsSelectors.selectOpened);
  public myNews$ = this.store.select(fromNewsSelectors.selectMyNews);
  public loading$ = this.store.select(fromNewsSelectors.selectLoading);
  public loadingError$ = this.store.select(fromNewsSelectors.selectLoadingError);
  public personalMainVisited$ = this.store.select(fromNewsSelectors.selectPersonalMainVisited);

  constructor(private store: Store<AppState>) {}

  public openNewsSidebar(): void {
    this.store.dispatch(fromNewsActions.openNewsSidebar());
  }

  public closeNewsSidebar(): void {
    this.store.dispatch(fromNewsActions.closeNewsSidebar());
  }

  public getMyNews(limit: number): void {
    this.store.dispatch(fromNewsActions.getMyNews({ limit }));
  }

  public setPersonalMainVisited(): void {
    this.store.dispatch(fromNewsActions.setPersonalMainVisited());
  }
}
