export const TRACKER_VISIT_ID_LS_KEY = 'trackerVisitId';
export const TRACKER_VISIT_TIMESTAMP_LS_KEY = 'trackerVisitTimestamp';
export const VISIT_MAX_DURATION = 1800000; // 30min

export interface TrackerUser {
  Name?: string;
  Surname?: string;
  Email?: string;
  UserCountry?: string;
}

export interface TrackerEvent extends TrackerUser {
  Resolution: string;
  Url: string;
  UrlRef: string;
  SiteId: string;
  VisitId: string;
  ClientName: string;
  MarketName: string;
  BrandName: string;
  PageName: string;
}

export enum MatomoEventType {
  setCustomVariable = 'setCustomVariable',
  setReferrerUrl = 'setReferrerUrl',
  setCustomUrl = 'setCustomUrl',
  deleteCustomVariables = 'deleteCustomVariables',
  trackPageView = 'trackPageView',
  enableLinkTracking = 'enableLinkTracking',
  setDocumentTitle = 'setDocumentTitle',
  setUserId = 'setUserId',
  setSiteId = 'setSiteId',
  setTrackerUrl = 'setTrackerUrl',
  resetUserId = 'resetUserId',
  trackEvent = 'trackEvent',
}

export const customVariables = [
  {
    index: 1,
    name: 'Email',
    key: 'email',
    type: 'visit',
  },
  {
    index: 2,
    name: 'Name',
    key: 'firstname',
    type: 'visit',
  },
  {
    index: 3,
    name: 'Surname',
    key: 'lastname',
    type: 'visit',
  },
  {
    index: 4,
    name: 'Country',
    key: 'country.name',
    type: 'visit',
  },
];

export const VIEW_ACTION_TYPE = 'View';
