import { Component, ElementRef } from '@angular/core';

import { registerAppsApp } from 'src/single-spa/apps';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-apps-wrapper',
  templateUrl: './apps-wrapper.component.html',
  styleUrls: ['./apps-wrapper.component.scss'],
})
export class AppsWrapperComponent extends MicroAppWrapper {
  protected appName = 'Apps';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerAppsApp(params);
  }
}
