import { Component, Input } from '@angular/core';

import { Client, Market } from 'app/state/clients';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-market-header',
  templateUrl: './market-header.component.html',
  styleUrls: ['./market-header.component.scss'],
})
export class MarketHeaderComponent {
  @Input() market: Market;
  @Input() clientId: Client['id'];

  readonly profilePictureApiUrl = environment.profilePicturesApiUrl;
  readonly displayedUsersNumber = 7;
}
