import { every } from 'lodash-es';

import { User } from 'app/state/users';
import { Client } from 'app/state/clients';
import { countries } from '@config/countries';
import { constants } from '@config/constants';
import { checkFeatureFlagAccess, checkSingleChoicePermission } from 'app/shared/utils';
import { environment } from '@environment';
import { TrackerActionType } from '@mads/wm-ng-components';

export const checkAccessToMaximize = (countryCode: string): boolean =>
  countryCode &&
  (countryCode === `IS` || // TODO: Temporary access for testing livepanel data. Contact Operations team before delete
    !countries[countryCode].isWithoutLivePanel);

export const checkAccessToClientEdit = (user: User): boolean =>
  !user || checkSingleChoicePermission(user, constants.USER_PERMISSION_KEYS.CAN_ADD_CLIENT);

export const checkCampaignsListAccess = (user: User) =>
  checkFeatureFlagAccess(user, constants.FEATURE_FLAG_KEYS.CAN_ENTER_CAMPAIGNS_LIST);

export const checkB2BScorecardVisibility = (user: User) =>
  checkFeatureFlagAccess(user, constants.FEATURE_FLAG_KEYS.CAN_SEE_B2B_SCORECARD);

export const checkExpressScenariosAccess = (user: User) =>
  checkSingleChoicePermission(
    user,
    constants.USER_PERMISSION_KEYS.CAN_ENTER_EXPRESS_SCENARIOS,
    environment.appsIds.expressScenariosAppId
  );

export enum UserSectionNavigationName {
  news = 'news',
  help = 'help',
  helpTrainingPortal = 'help-training-portal',
  helpProductTour = 'help-product-tour',
  helpSitemap = 'help-sitemap',
  helpContextualHelp = 'help-contextual-help',
}

export interface UserSectionNavigationItem {
  name: string;
  disabled: boolean;
  hidden: boolean;
  children?: UserSectionNavigationItem[];
}

export enum NavigationItemSource {
  MONDELEZ = 'MONDELEZ',
  LOREAL = 'LOREAL',
  WAVEMAKER = 'WAVEMAKER',
}

// TODO: use interface from library
export interface NavigationItem {
  id: string;
  title: string;
  description?: string;
  toolName?: string; // for 'tools only' view in default brand menu
  iconPath?: string;
  headerColor?: string;
  textColor?: string;
  source?: NavigationItemSource;
  backgroundColor?: string;
  breadcrumbTitle?: string;
  path?: string;
  disabled?: boolean;
  hidden?: boolean;
  external?: boolean;
  isActive?: boolean;
  opened?: boolean;
  nav?: NavigationItem[];
  reloadOnClick?: boolean;
  isNew?: boolean;
  trackerPayload?: NavigationTrackerPayload;
}

export interface NavigationTrackerPayload {
  category: string;
  details: string;
  type: TrackerActionType;
}

export interface CurrentActiveMainNav {
  id: string;
  deactivate?: boolean;
}

export type UserNavigationSection = UserSectionNavigationItem[];
export type NavigationSection = NavigationItem[];

export interface NavigationConfigFunctions {
  dashboard: (user: User) => NavigationSection;
  main: (countryCode: string, user: User) => NavigationSection;
  avatarNavigation: (user: User) => NavigationSection;
  clientCreation: (client: Client, user: User) => NavigationSection;
  userSection: () => UserNavigationSection;
  userSettings: (user: User) => NavigationSection;
}

export interface NavigationConfig {
  dashboard: NavigationSection;
  main: NavigationSection;
  avatarNavigation: NavigationSection;
  clientCreation: NavigationSection;
  userSection: UserNavigationSection;
  userSettings: NavigationSection;
}

export const hideChildlessItems = (navigation: NavigationItem): NavigationItem => {
  if (navigation.hidden || navigation.disabled || !navigation.nav) {
    return navigation;
  }
  const nav = navigation.nav.map(hideChildlessItems);
  const allChildrenHidden = every(nav, (item) => item.hidden);
  return {
    ...navigation,
    hidden: allChildrenHidden,
    nav,
  };
};
