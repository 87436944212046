<wm-wrapper>
  <div class="request-reject-dialog">
    <div class="request-reject-dialog__close-button">
      <i-feather name="x" [mat-dialog-close]="false"></i-feather>
    </div>


    <div class="request-reject-dialog__content">
      <div class="wm-m-500 request-reject-dialog__header">
        <p><span>{{ data?.name }}</span><span> rejected</span></p>
      </div>

      <div class="wm-s-400 request-reject-dialog__body">
       <p>
         <span>You are about to decline the advertiser approval request made by a </span>
         <span class="wm-s-500 request-reject-dialog__client-name">{{ data?.requester }}</span>
       </p>
        <p>
          <span>
            Please take a moment to provide an explanation for your decision in the input field below.
            This explanation will be sent to the user, so please be clear and concise in your response.
          </span>
        </p>
      </div>

      <div class="wm-m-500 request-reject-dialog__input">
        <p class="request-reject-dialog__input-label"><span>Explanation message:</span></p>
        <wm-textarea
          placeholder="Enter your message here"
          [formControl]="reasonControl"
          [maxLength]="REASON_MAX_LENGTH">
        </wm-textarea>
      </div>
    </div>

    <wm-navigation-footer
      backText="Cancel"
      continueText="Send"
      continueDisabledTooltip="Please provide a rejection reason"
      [continueDisabled]="!reasonControl.valid"
      [isBack]="true"
      [containerPosition]="ContainerPosition.ABSOLUTE"
      (back)="onReject()"
      (continue)="onAccept()"
    ></wm-navigation-footer>
  </div>
</wm-wrapper>
