import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOneOfFields = (isEditingMode: boolean, ...fields: string[]): ValidatorFn => {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    return fields.some((fieldName) => {
      const field = formGroup.get(fieldName).value;

      return isEditingMode || !!field;
    })
      ? null
      : ({ atLeastOneOfFields: 'At least one field has to be provided.' } as ValidationErrors);
  };
};
