<ng-container
  *ngIf="{
    hasCanAddBrand: hasCanAddBrand$ | async
  } as viewData"
>
  <div class="wrapper" [ngClass]="mode">
    <div class="header">
      <div class="logo-and-title">
        <div class="logo">
          <img [src]="entity.logo" [alt]="entity.name" />
        </div>
        <div class="title">
          <span *ngIf="entity.country">{{ entity.country.name }}</span>
          <h2 class="wm-h2">{{ entity.name }}</h2>
          <div *ngIf="!entity.markets" (click)="toggleInfo()" class="info-button">
            <span
              class="wm-m-400"
              [translate]="
                entity.country ? 'WM_HOME.HEADER.BRAND_INFO' : 'WM_HOME.HEADER.CLIENT_INFO'
              "
            ></span>
            <wm-arrow
              [size]="15"
              [isExpanded]="infoExpanded"
              color="primary-on-contrast-background"
            ></wm-arrow>
          </div>
        </div>
        <div class="button">
          <ng-content select="[addButton]"></ng-content>
        </div>
        <div *ngIf="viewData?.hasCanAddBrand || !!entity?.markets" class="button button-edit">
          <ng-content select="[editButton]"></ng-content>
        </div>
      </div>
      <div class="right-things-wrapper">
        <div *ngIf="shouldDisplayProjectModeToggle$ | async" class="project-mode-wrapper">
          <i-feather
            name="info"
            class="info-icon"
            [wmTooltip]="'WM_HOME.PROJECT_BUILDER.INFOTIP' | translate"
          ></i-feather>
          <p
            class="wm-s-500 project-mode-label"
            translate="WM_HOME.PROJECT_BUILDER.MODE_SWITCH"
          ></p>
          <mat-slide-toggle
            class="wm-button-toggle"
            [ngModel]="isProjectModeOn$ | async"
            (change)="setProjectMode($event)"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="!!entity.markets || infoExpanded">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>
