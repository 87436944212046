import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { NavigationItem } from '@config';

import { ModeCodes } from '@mads/wm-ng-components';

import { Brand } from 'app/state/brands';
import { ClientManagementDrawerMode } from 'app/state/clients';

@Component({
  selector: 'app-mondelez-brand-menu',
  templateUrl: './mondelez-brand-menu.component.html',
  styleUrls: ['./mondelez-brand-menu.component.scss'],
})
export class MondelezBrandMenuComponent {
  @Input() brand: Brand;
  @Input() navigationCategories: NavigationItem[];

  @Output() goToCart = new EventEmitter<void>();
  @Output() clickNagivationItem = new EventEmitter<{ item: NavigationItem, categoryTitle: string }>();

  public DrawerMode = ClientManagementDrawerMode;
  public modeCode = ModeCodes.MONDELEZ;

  constructor(public renderer2: Renderer2) {}

  public toolSourceIcons = {
    WAVEMAKER: 'assets/icons/wm-icon.svg',
    MONDELEZ: 'assets/icons/mondelez-icon.svg',
  };

  public handleGoToCart(): void {
    this.goToCart.emit();
  }

  public onLinkHover(event, color: string): void {
    this.renderer2.setStyle(event.path[0], 'color', color);
  }

  public onLinkStopHover(event): void {
    this.renderer2.setStyle(event.path[0], 'color', 'inherit');
  }

  public handleNavigationItemClick(
    event: MouseEvent,
    item: NavigationItem,
    categoryTitle?: string
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.clickNagivationItem.emit({ item, categoryTitle });
  }
}
