import { registerApp, MicrofrontendAppConfig, matchingGlobalPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const config: MicrofrontendAppConfig = {
  name: 'ai-center-app',
  windowLibraryName: 'aiCenterApp',
  baseHref: '/ai-center/',
  assetsPath: environment.aiCenterAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.aiCenterAppUrl,
  appLocation: environment.aiCenterAppUrl.replace('main.js', ''),
  // below - values to be used in local development
  // assetsPath: 'http://localhost:5500/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:5500/main.js',
  // appLocation: 'http://localhost:5500/main.js'.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/ai-center']),
};

export const registerAiCenterApp = (
  customParams?: MicroAppParams
): MicrofrontendAppConfig => registerApp({ ...config, customParams });
