import {  registerApp, MicrofrontendAppConfig, matchingGlobalPathname } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'sitemap-app',
  windowLibraryName: 'sitemapApp',
  assetsPath: environment.sitemapAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.sitemapAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  baseHref: '/sitemap',
  appLocation: environment.sitemapAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/sitemap']),
};

export const registerSitemapApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
