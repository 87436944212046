import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerConsumerExperienceInsightsApp } from 'src/single-spa/consumer-experience-insights';

@Component({
  selector: 'app-consumer-experience-insights-wrapper',
  templateUrl: './consumer-experience-insights-wrapper.component.html',
  styleUrls: ['./consumer-experience-insights-wrapper.component.scss'],
})
export class ConsumerExperienceInsightsWrapperComponent extends MicroAppWrapper {
  protected appName = 'Consumer Experience Insights';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerConsumerExperienceInsightsApp(params);
  }
}
