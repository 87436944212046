import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ClientsFacade } from 'app/state/clients';
import { CanDeactivate } from '@angular/router';

@Injectable()
export class ResetClientGuard implements CanDeactivate<void> {
  constructor(private readonly clientsFacade: ClientsFacade) {}

  canDeactivate(): Observable<boolean> {
    this.clientsFacade.resetClient();

    return this.clientsFacade.selectedClient$.pipe(
      filter((brand) => !brand),
      map(() => true)
    );
  }
}
