import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';

import { Project } from 'app/interfaces';
import { getProjectsFail, getProjectsSuccess, ProjectsFacade } from 'app/state/projects';
import { BrandsFacade } from 'app/state/brands';

@Injectable({ providedIn: 'root' })
export class ProjectsResolver implements Resolve<Project[]> {

  constructor(
    private projectsFacade: ProjectsFacade,
    private brandsFacade: BrandsFacade,
    private actions$: Actions,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Project[]> {
    const {brandId} = route.params;
    return this.brandsFacade.selectedBrand$.pipe(
      tap(brand => {
        if (brand.id === brandId) { return; }
        console.error('Something is terribly wrong. After brand guard brand id doesn\'t match brandId from url');
      }),
      switchMap(brand => this.projectsFacade.projects$.pipe(
        switchMap(projects => {
          if (projects[0]?.workspace === brand.clientId) { return of(projects); }
          this.projectsFacade.getProjects(brand.clientId);
          return this.actions$.pipe(
            ofType(getProjectsSuccess, getProjectsFail),
            switchMap(() => this.projectsFacade.projects$),
          );
        }),
      )),
      take(1),
    );
  }
}
