import { Component, ElementRef, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { registerYouPageApp } from 'src/single-spa/you-page';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { NavigationService } from 'app/core/navigation.service';
import { NavigationConfigFunctions } from '@config';
import { ProjectsFacade } from 'app/state/projects';

@Component({
  selector: 'app-you-page-wrapper',
  templateUrl: './you-page-wrapper.component.html',
  styleUrls: ['./you-page-wrapper.component.scss'],
})
export class YouPageWrapperComponent extends MicroAppWrapper implements OnInit {
  protected appName = 'You Page';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected navigationService: NavigationService,
    protected projectsFacade: ProjectsFacade,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.projectsFacade.propagateSettingsToMicrofrontends();
  }

  protected registerFn(
    params: MicroAppParams<{
      navConfigFunction$: BehaviorSubject<NavigationConfigFunctions['main']>;
    }>
  ): void {
    params.customParams.navConfigFunction$ = this.navigationService.mainConfigFunction$;

    this.config = registerYouPageApp(params);
  }
}
