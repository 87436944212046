import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { tap } from 'rxjs/operators';

import * as fromRouter from './router.actions';
import { ChangeRoutePayload } from './router.model';

import { config as scorecardsConfig, SCORECARDS_ROUTE_MATCHER } from 'src/single-spa/scorecards';
import * as singleSpa from 'single-spa';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router) {}

  changeRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.changeRoute),
        tap((route: ChangeRoutePayload) => {
          const extras = { ...route.extras };

          if (this.scorecardsRouteAndLoaded(route)) {
            return singleSpa.unloadApplication(scorecardsConfig.name).then(() =>
              this.router.navigate(route.linkParams, {
                ...extras,
              }));
          }

          return this.router.navigate(route.linkParams, {
            ...extras,
          });
        })
      ),
    { dispatch: false }
  );

  private scorecardsRouteAndLoaded(route: ChangeRoutePayload) {
    return (
      route.linkParams
        && route.linkParams[0]?.indexOf(SCORECARDS_ROUTE_MATCHER) > -1
        && singleSpa.getAppNames().find((app) => app === scorecardsConfig.name)
    );
  }
}
