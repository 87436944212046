import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromContextualHelp from './contextual-help.actions';
import { LocalAndGlobalPagesMatchingTagQuery } from 'app/core/graphql/queries';
import { UsersFacade } from '../users';
import { PersonalFacade } from '../personal';
import { WikiHttpService } from 'app/core/http/wiki-http.service';
import { ApolloService } from 'app/core/apollo.service';

@Injectable()
export class ContextualHelpEffects {
  constructor(
    private actions$: Actions,
    private usersFacade: UsersFacade,
    private personalFacade: PersonalFacade,
    private localAndGlobalPagesMatchingTagQuery: LocalAndGlobalPagesMatchingTagQuery,
    private http: WikiHttpService,
    private apolloService: ApolloService
  ) {}

  setTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextualHelp.setCurrentTag), // this action is deprecated since 4.06.2021
      // there are legit wmos users without permission to see wiki or to download token AND
      // choreo is still using this deprecated action in campaigns creator so:
      filter(() => !!this.apolloService.token),
      map((action) => action.currentTag),
      filter((tag) => !!tag),
      withLatestFrom(this.usersFacade.myself$, this.personalFacade.languageCode$),
      map(([tag, myself, languageCode]) =>
        fromContextualHelp.getPages({
          tag,
          localContentSymbol: myself.country.symbol,
          userLanguage: languageCode.substring(0, 2), // language comes with strange suffixes,
          userId: myself.id,
        })
      )
    )
  );

  setContextualHelpMenuInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextualHelp.setContextualHelpMenuInfo),
      // there are legit wmos users without permission to see wiki or to download token, so:
      filter(() => !!this.apolloService.token),
      map((action) => action.data.contextualHelpArticleTag),
      filter((tag) => !!tag),
      withLatestFrom(this.usersFacade.myself$, this.personalFacade.languageCode$),
      map(([tag, myself, languageCode]) =>
        fromContextualHelp.getPages({
          tag,
          localContentSymbol: myself.country.symbol,
          userLanguage: languageCode.substring(0, 2), // language comes with strange suffixes,
          userId: myself.id,
        })
      )
    )
  );

  getPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextualHelp.getPages),
      switchMap((payload) => this.getPages(payload))
    )
  );

  markPageAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextualHelp.markPageAsRead),
      switchMap(({ pageId, userId }) =>
        this.http.markPageAsRead(pageId, userId).pipe(
          map((res) => fromContextualHelp.markPageAsReadSuccess({ res })),
          catchError((error) => of(fromContextualHelp.markPageAsReadFailure({ error })))
        )
      )
    )
  );

  private getPages({ tag, localContentSymbol, userLanguage, userId }) {
    return this.localAndGlobalPagesMatchingTagQuery
      .watch({
        tag,
        userCountry: localContentSymbol,
        locale: userLanguage,
        userId,
      })
      .valueChanges.pipe(
        take(1),
        map((res) => fromContextualHelp.getPagesSuccess({ res, userLanguage })),
        catchError((error) => of(fromContextualHelp.getPagesFailure({ error })))
      );
  }
}
