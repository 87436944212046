import { Component, ElementRef } from '@angular/core';

import { registerVelocityApp } from 'src/single-spa/velocity';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-velocity-wrapper',
  templateUrl: './velocity-wrapper.component.html',
  styleUrls: ['./velocity-wrapper.component.scss'],
})
export class VelocityWrapperComponent extends MicroAppWrapper {
  protected appName = 'Long-term Growth Simulator';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerVelocityApp(params);
  }
}
