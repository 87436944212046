import {
  Component,
  OnInit,
    OnChanges,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

import { NgxFlickingComponent } from '@egjs/ngx-flicking';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { defaults } from 'app/config/defaults';
import { Client, ClientsFacade } from 'app/state/clients';

@Component({
  selector: 'app-clients-slider',
  templateUrl: './clients-slider.component.html',
  styleUrls: ['./clients-slider.component.scss'],
})
export class ClientsSliderComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @ViewChild('sliderContainer') sliderContainer: ElementRef;
  @ViewChild('flicking') flicking: NgxFlickingComponent;
  @Input() widthOfAppContainer = defaults.defaultAppContainerWidth;
  public clients: Client[];
  public flickerConfig = defaults.flickerDefaults;
  public showSlider = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sliderNavigationVisible();
  }

  constructor(private clientsFacade: ClientsFacade) {
    super();
  }

  ngOnInit() {
    this.clientsFacade.getClients();
    this.clientsFacade.clients$
      .pipe(
        untilComponentDestroyed(this),
      )
      .subscribe((clients) => {
        this.clients = clients.filter((client) => client.mine === true);
        this.sliderNavigationVisible();
      });
  }

  ngOnChanges() {
    this.sliderNavigationVisible();
  }

  sliderNavigationVisible(attempt = 1): void {
    setTimeout(() => {
      if (!this.sliderContainer) {
        this.sliderNavigationVisible(attempt + 1);
        return;
      }
      const mainContainerWidth = this.sliderContainer.nativeElement.offsetWidth;
      this.showSlider = this.widthOfAppContainer * (this.clients && this.clients.length || 0) >= mainContainerWidth;
    }, 100);
  }

  public nextImage(): void {
    this.flicking.next();
  }

  public holdDragStart(e): void {
    e.stop();
  }

  public trackByFn(index, item): number {
    return index;
  }
}
