import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @Input() backDisabled = false;
  @Input() continueDisabled = false;
  @Input() continueDisabledTooltipText: string;
  @Input() backText: string;
  @Input() continueText: string;

  @Output() back = new EventEmitter<void>();
  @Output() continue = new EventEmitter<void>();

  onGoBack(): void {
    this.back.emit();
  }

  onContinue(): void {
    this.continue.emit();
  }
}
