import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { orderBy } from 'lodash-es';
import { GuideService, WmConfirmationPopupService } from '@mads/wm-ng-components';
import { TranslateService } from '@ngx-translate/core';
import { first, filter, map, tap, take } from 'rxjs/operators';

import { Brand, BrandsFacade } from 'app/state/brands';
import { Client, ClientManagementDrawerMode, ClientsFacade, Market } from 'app/state/clients';
import { RouterFacade } from 'app/state/router';
import { UsersFacade } from 'app/state/users';

import { countries } from '@config/countries';
import { constants } from '@config/constants';
import {
  GuideTourStep,
  GUIDE_STEP_ACTIONS,
  MARKET_MANAGEMENT_GUIDE_TOUR_NAMES,
  MARKET_MANAGEMENT_GUIDE_TOURS,
} from '@config/project-guide';
import { checkSingleChoicePermission } from 'app/shared/utils';

export interface MarketOption {
  code: string;
  name: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-markets-management',
  templateUrl: './markets-management.component.html',
  styleUrls: ['./markets-management.component.scss'],
})
export class MarketsManagementComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild('removePopup') removePopupTemplate: TemplateRef<HTMLElement>;
  @ViewChild('createPopup') createPopupTemplate: TemplateRef<HTMLElement>;

  public isClientLoading$ = this.clientsFacade.loading$;
  public isBrandLoading$ = this.brandsFacade.loading$;
  public isPollingActive$ = this.clientsFacade.isPollingActive;
  public brands$ = this.brandsFacade.brands$.pipe(
    tap((brands) => {
      this.areBrandsAvailable = !!brands?.length;
      this.editBrandFromRouting(brands);
    })
  );
  public categories$ = this.clientsFacade.categories$.pipe(
    map((categories) => {
      const availableCategories = categories.filter((category) => category.isBrandCategory);
      return orderBy(availableCategories, ['name']);
    })
  );
  public hasMarketPermissions$ = this.usersFacade.myself$.pipe(
    map((user) => checkSingleChoicePermission(user, constants.USER_PERMISSION_KEYS.CAN_ADD_MARKET))
  );
  public hasRecreateMarketPermission$ = this.usersFacade.myself$.pipe(
    map((user) =>
      checkSingleChoicePermission(user, constants.USER_PERMISSION_KEYS.CAN_RECREATE_MARKET)
    )
  );

  public client: Client;
  public activeMarket: Market;
  private activeMarketCodeFromUrl: string;
  public editedMarket: Market;
  public editedBrand: Brand;
  public availableMarkets: MarketOption[];
  public isDrawerOpen = false;
  public drawerMode: ClientManagementDrawerMode;
  public DrawerMode = ClientManagementDrawerMode;
  private brandEditedFromRoutingId: string;
  public isGuideTourActive = false;
  private areBrandsAvailable = false;
  private activeGuideTours: string[] = [];
  public guideTourSteps: GuideTourStep[] = [];
  readonly marketEmptyGuide = MARKET_MANAGEMENT_GUIDE_TOURS.MARKET_MANAGEMENT_TOUR_EMPTY;
  readonly marketEditGuide = MARKET_MANAGEMENT_GUIDE_TOURS.MARKET_MANAGEMENT_TOUR_EDIT;
  readonly brandEmptyGuide = MARKET_MANAGEMENT_GUIDE_TOURS.BRAND_MANAGEMENT_TOUR_EMPTY;
  readonly brandEditGuide = MARKET_MANAGEMENT_GUIDE_TOURS.BRAND_MANAGEMENT_TOUR_EDIT;

  constructor(
    private activatedRoute: ActivatedRoute,
    private brandsFacade: BrandsFacade,
    private clientsFacade: ClientsFacade,
    private confirmationPopupService: WmConfirmationPopupService,
    private guideService: GuideService,
    private routerFacade: RouterFacade,
    private translateService: TranslateService,
    private usersFacade: UsersFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    const { clientId } = this.activatedRoute.snapshot.params;

    this.getRouterConfiguration();
    this.getClient(clientId);
    this.clientsFacade.getCategories();

    // TODO: Temporarily unavailable due to a change in design and the need to adapt
    // this.startGuideTour();
  }

  public ngOnDestroy(): void {
    this.endGuideTour();
    super.ngOnDestroy();
  }

  public getRouterConfiguration(): void {
    const { activeMarket, brandId, open, drawerMode } = this.activatedRoute.snapshot.queryParams;

    this.activeMarketCodeFromUrl = activeMarket;
    this.drawerMode = drawerMode ?? this.drawerMode;

    if (brandId) {
      this.brandEditedFromRoutingId = brandId;
      return;
    }

    this.isDrawerOpen = open;
  }

  private getClient(clientId: Client['id']): void {
    this.clientsFacade.getClientWithDetails(clientId);

    this.clientsFacade.selectedClient$
      .pipe(
        untilComponentDestroyed(this),
        filter((client) => !!client)
      )
      .subscribe((client) => {
        this.client = {
          ...client,
          markets: orderBy(client.markets, ['isClientAdmin', 'name'], ['desc', 'asc']),
        };
        this.selectActiveMarket();
        this.prepareAvailableMarkets();
      });
  }

  private selectRecreatedMarket(): void {
    if (this.drawerMode !== this.DrawerMode.RECREATE_MARKET || !this.activeMarket) {
      return;
    }

    this.usersFacade.myself$.pipe(take(1)).subscribe((myself) => {
      this.editedMarket = {
        ...this.activeMarket,
        owners: this.activeMarket.owners.filter((owner) => owner.email !== myself.email),
      };
    });
  }

  private selectActiveMarket(): void {
    const markets = this.client.markets;

    if (!markets.length) {
      return;
    }

    if (this.activeMarketCodeFromUrl) {
      const activeMarketFromUrl = markets.find(
        (market) => market.code === this.activeMarketCodeFromUrl
      );
      this.activateMarket(activeMarketFromUrl);
      this.selectRecreatedMarket();
      return;
    }

    if (this.activeMarket) {
      return;
    }

    const activeMarket = markets.find((market) => market.isClientAdmin);
    this.activateMarket(activeMarket);
  }

  private prepareAvailableMarkets(): void {
    const marketCodes = Object.keys(countries);

    const marketsOptions = marketCodes.map((marketCode) => ({
      code: marketCode,
      name: countries[marketCode].name,
      disabled: this.client.markets.some((market) => market.code === marketCode),
    }));

    this.availableMarkets = orderBy(marketsOptions, ['name']);
  }

  public activateMarket(market: Market): void {
    if (!market?.name) {
      return;
    }

    this.activeMarket = this.separateMarketMembers(market);
    this.brandsFacade.getBrands(this.client.id, market.code);
  }

  private separateMarketMembers(market: Market): Market {
    const separatedMembers = market.members.filter((member) =>
      market.owners.every((owner) => owner.id !== member.id)
    );

    return { ...market, members: separatedMembers };
  }

  public openMarketDrawer(mode: ClientManagementDrawerMode, editedMarket?: Market): void {
    this.drawerMode = mode;
    this.editedMarket = editedMarket && this.separateMarketMembers(editedMarket);
    this.toggleDrawer();
    this.skipToNextGuidStep([this.marketEmptyGuide.selectMarket.id]);
  }

  public toggleDrawer(): void {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  public onSaveMarket(market: Market): void {
    if (market.useExistingTeam) {
      this.assignTeamToMarket(market);
      return;
    }

    this.saveMarket(market);
    this.toggleDrawer();
    this.skipToNextGuidStep([this.brandEmptyGuide.brandInfo.id]);
  }

  public assignTeamToMarket(market: Market): void {
    const dialogRef = this.confirmationPopupService.open({
      positiveButtonLabel: this.translateService.instant(
        'WM_HOME.MARKET_MANAGEMENT.POPUP_CONTINUE'
      ),
      negativeButtonLabel: this.translateService.instant('WM_HOME.CLIENT_MANAGEMENT.BUTTON_CANCEL'),
      contentTemplate: this.createPopupTemplate,
      templateContext: {
        teamName: market.existingTeamName,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter((result) => !!result)
      )
      .subscribe(() => {
        const { existingTeamName, ...newMarket } = market;
        this.saveMarket(newMarket);
        this.toggleDrawer();
        this.skipToNextGuidStep([this.brandEmptyGuide.brandInfo.id]);
      });
  }

  private saveMarket(market: Market): void {
    switch (this.drawerMode) {
      case this.DrawerMode.NEW_MARKET:
        this.clientsFacade.createMarket({ clientId: this.client.id, newMarket: market });
        return;

      case this.DrawerMode.EDIT_MARKET:
        this.clientsFacade.updateMarket(this.client.id, { ...this.editedMarket, ...market });
        return;

      case this.DrawerMode.RECREATE_MARKET:
        this.clientsFacade.recreateMarket(this.client.id, market);
        return;
    }
  }

  public openBrandDrawer(editedBrand?: Brand): void {
    this.editedBrand = editedBrand;
    this.drawerMode = ClientManagementDrawerMode.BRAND;
    this.toggleDrawer();
    this.skipToNextGuidStep([this.brandEmptyGuide.brandInfo.id]);
  }

  private editBrandFromRouting(brands: Brand[]): void {
    if (!this.brandEditedFromRoutingId || !brands?.length) {
      return;
    }

    const brandToEdit = brands.find((brand) => brand.id === this.brandEditedFromRoutingId);
    this.brandEditedFromRoutingId = null;
    this.openBrandDrawer(brandToEdit);
  }

  public saveBrand(brand: Brand): void {
    this.editedBrand
      ? this.brandsFacade.updateBrand(brand)
      : this.brandsFacade.createBrand({
          clientId: this.client.id,
          countryCode: this.activeMarket.code,
          brand: brand,
        });

    this.toggleDrawer();
    this.skipToNextGuidStep([this.brandEditGuide.brandDetails.id]);
  }

  public deleteBrand(brand: Brand): void {
    const dialogRef = this.confirmationPopupService.open({
      positiveButtonLabel: this.translateService.instant('WM_HOME.MARKET_MANAGEMENT.POPUP_REMOVE'),
      negativeButtonLabel: this.translateService.instant('WM_HOME.CLIENT_MANAGEMENT.BUTTON_CANCEL'),
      contentTemplate: this.removePopupTemplate,
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter((result) => !!result)
      )
      .subscribe(() => this.brandsFacade.deleteBrand(brand));
  }

  public onContinue(): void {
    this.routerFacade.changeRoute({
      linkParams: [`clients/${this.client.id}/markets`],
    });
  }

  public onBack(): void {
    this.routerFacade.changeRoute({
      linkParams: [`clients/client-creation/${this.client.id}/client-management`],
    });
  }

  // @ts-ignore
  private startGuideTour(): void {
    let availableTours = Object.keys(MARKET_MANAGEMENT_GUIDE_TOUR_NAMES).filter(
      (step) => !localStorage.getItem(step)
    );

    if (!availableTours.length) {
      return;
    }

    // INFO: The time delay is needed to determine the full length of the guide
    setTimeout(() => {
      if (!this.activeMarket) {
        availableTours = availableTours.filter(
          (step) =>
            step !== MARKET_MANAGEMENT_GUIDE_TOUR_NAMES.MARKET_MANAGEMENT_TOUR_EDIT &&
            step !== MARKET_MANAGEMENT_GUIDE_TOUR_NAMES.BRAND_MANAGEMENT_TOUR_EMPTY
        );
      }

      if (!this.areBrandsAvailable) {
        availableTours = availableTours.filter(
          (step) => step !== MARKET_MANAGEMENT_GUIDE_TOUR_NAMES.BRAND_MANAGEMENT_TOUR_EDIT
        );
      }

      this.prepareGuideTourSteps(availableTours);
    }, 2000);
  }

  private prepareGuideTourSteps(guideTours: string[]): void {
    if (!guideTours.length) {
      return;
    }

    this.isGuideTourActive = true;
    this.activeGuideTours = guideTours;

    let guideSteps = [];
    guideTours.forEach((tour) => {
      const tourSteps = Object.values(MARKET_MANAGEMENT_GUIDE_TOURS[tour]) as GuideTourStep[];

      guideSteps = [...guideSteps, ...tourSteps];
    });

    this.guideTourSteps = guideSteps;
    this.guideService.startTour(guideSteps.map((step) => step.id));

    this.activateDynamicGuide();
  }

  private activateDynamicGuide(): void {
    this.guideService.visibleTooltipId$
      .pipe(
        filter((stepId) => !!stepId),
        untilComponentDestroyed(this)
      )
      .subscribe((stepId) => {
        const currentStep = this.guideTourSteps.find((step) => step.id === stepId);

        if (!currentStep || !currentStep.actionType) {
          return;
        }

        this.isDrawerOpen = currentStep.actionType !== GUIDE_STEP_ACTIONS.CLOSE;
        this.drawerMode =
          currentStep.actionType === GUIDE_STEP_ACTIONS.OPEN_MARKET
            ? ClientManagementDrawerMode.NEW_MARKET
            : ClientManagementDrawerMode.BRAND;
      });
  }

  public onDrawerClose(): void {
    this.drawerMode === ClientManagementDrawerMode.BRAND
      ? this.skipToNextGuidStep(
          [this.brandEmptyGuide.addBrand.id, this.brandEditGuide.brandDetails.id],
          true
        )
      : this.skipToNextGuidStep(
          [this.marketEditGuide.marketList.id, this.marketEmptyGuide.addMarket.id],
          true
        );
  }

  private skipToNextGuidStep(stepIds: string[], selectLastAvailableStep?: boolean): void {
    if (!this.isGuideTourActive) {
      return;
    }

    const steps = selectLastAvailableStep ? this.guideTourSteps.reverse() : this.guideTourSteps;
    const nextStep = steps.find((step) => stepIds.includes(step.id));

    nextStep ? this.guideService.setVisibleTooltipId$(nextStep.id) : this.endGuideTour();
  }

  public endGuideTour(): void {
    if (!this.isGuideTourActive) {
      return;
    }

    this.isGuideTourActive = false;
    this.guideService.endTour();
    this.activeGuideTours.forEach((tour) => localStorage.setItem(tour, 'true'));
  }
}
