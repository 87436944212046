import { every } from 'lodash-es';
import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { HelpMenuCustomItem, TrackAnalyticsActionType } from '@mads/wm-ng-components';
import { NavigationExtras, Router } from '@angular/router';
import { combineLatest } from 'rxjs';

import { environment } from 'src/environments/environment';
import { checkAccessToModule, checkSingleChoicePermission } from 'app/shared/utils';
import {
  config,
  UserNavigationSection,
  UserSectionNavigationItem,
  UserSectionNavigationName,
  NavigationItem,
} from '@config';

import { RouterFacade } from 'app/state/router';
import { User, UsersFacade } from 'app/state/users';
import { ApplicationsFacade } from 'app/state/applications';
import { NewsSidebarFacade } from 'app/state/news-sidebar';
import { constants } from '@config/constants';
import { NavigationService } from 'app/core/navigation.service';
import { AnalyticsTrackingService } from 'app/core/analystics-tracking.service';
import { TrackedActions } from '@config/tracked-actions';

const { MODULE_KEYS, NEWS_LIMIT } = config.constants;

@Component({
  selector: 'app-header-news-and-avatar',
  templateUrl: './header-news-and-avatar.component.html',
  styleUrls: ['./header-news-and-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNewsAndAvatarComponent extends OnDestroyMixin implements OnInit {
  @Input() displayNews = false;
  public hasCurrentProductTours$ = this.applicationsFacade.hasCurrentProductTours$;
  public myself$ = this.usersFacade.myself$;
  public helpMenuCustomItems$ = this.applicationsFacade.helpMenuCustomItems$;
  public userNavigation: UserNavigationSection;
  public avatarNavigation$ = this.navigationService.avatarNavigation$;
  public sections = UserSectionNavigationName;
  public sharepointHelpUrl = 'https://insidemedia.sharepoint.com/sites/wmgostraining';
  public hasAccessToNews: boolean;
  public hasAccessToStatistics: boolean = false;

  constructor(
    private routerFacade: RouterFacade,
    private usersFacade: UsersFacade,
    private applicationsFacade: ApplicationsFacade,
    private newsSidebarFacade: NewsSidebarFacade,
    private navigationService: NavigationService,
    private router: Router,
    private analyticsTrackingService: AnalyticsTrackingService
  ) {
    super();
  }

  public ngOnInit(): void {
    combineLatest([this.myself$, this.navigationService.userSectionNavigation$])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([user, navigationConfig]) => {
        this.userNavigation = navigationConfig;
        this.checkUserAccess(user);
      });
  }

  public routeTo(link: string, extras?: NavigationExtras): void {
    if (link === `${environment.authAppUrl}/logout`) {
      window.location.href = link;
      return;
    }

    this.routerFacade.changeRoute({
      linkParams: [link],
      extras: extras,
    });
  }

  public navigateToHelp(): void {
    this.routerFacade.changeRoute({ linkParams: ['/documentation/guide'] });
  }

  public openNews(): void {
    this.newsSidebarFacade.getMyNews(NEWS_LIMIT);
    this.newsSidebarFacade.openNewsSidebar();
  }

  public runProductTours(): void {
    this.applicationsFacade.runProductTours();
  }

  public checkUserAccess(user: User): void {
    this.hasAccessToNews =
      checkAccessToModule(user, MODULE_KEYS.NEWS) &&
      this.shouldEnableSection([UserSectionNavigationName.news]);

    this.hasAccessToStatistics = checkSingleChoicePermission(
      user,
      constants.USER_PERMISSION_KEYS.CAN_ACCESS,
      environment.appsIds.productsStatsAppId
    );
  }

  public shouldDisplaySection(sectionsNames: string[]): boolean {
    const section = this.getSection(this.userNavigation, sectionsNames);

    if (
      section?.name === UserSectionNavigationName.news &&
      this.router.url === constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE
    ) {
      return false;
    }

    return !section?.hidden;
  }

  public shouldEnableSection(sectionsNames: string[]): boolean {
    const section = this.getSection(this.userNavigation, sectionsNames);
    return !section?.disabled;
  }

  public getSection(
    sections: UserNavigationSection,
    sectionsNames: string[]
  ): UserSectionNavigationItem {
    const section = sections?.find(({ name }) => name === sectionsNames[0]);

    if (sectionsNames?.length === 1) {
      return section;
    }

    const childSections = section?.children;
    const [, ...childNames] = sectionsNames;

    return this.getSection(childSections, childNames);
  }

  public allNavigationIsHidden(section: NavigationItem[]): boolean {
    return every(section, 'hidden');
  }

  public onHelpMenuCustomItemClick(id: HelpMenuCustomItem['id']): void {
    this.applicationsFacade.onHelpMenuCustomItemClick(id);
  }

  public trackOpeningHelp(): void {
    this.analyticsTrackingService.track({
      type: TrackAnalyticsActionType.action,
      payload: TrackedActions.HELP_CLICKED,
    });
  }
}
