import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Page } from 'src/app/interfaces';
import { UsersFacade, User } from 'src/app/state/users';
import { ContextualHelpFacade } from 'src/app/state/contextual-help';
import { environment } from 'src/environments/environment';
import { CONTEXTUAL_HELP_TITLE_PREFIX } from '@config/titles';
import { TrackerService } from 'app/core/tracker.service';

@Component({
  selector: 'app-contextual-help-drawer',
  templateUrl: './contextual-help-drawer.component.html',
  styleUrls: ['./contextual-help-drawer.component.scss'],
})
export class ContextualHelpDrawerComponent implements OnInit, OnDestroy {
  public tag$ = this.contextualHelpFacade.currentTag$;
  public globalPage$ = this.contextualHelpFacade.globalPage$;
  public localPage$ = this.contextualHelpFacade.localPage$;
  public pathsToUnclearPages$ = this.contextualHelpFacade.pathsToUnclearPages$;
  public isEditor$ = this.usersFacade.myself$.pipe(map(myself => myself.isContextualHelpEditor));

  public showLocalContent$ = new BehaviorSubject(false);
  public page$ = combineLatest([this.globalPage$, this.localPage$, this.showLocalContent$])
    .pipe(
      map(data => {
        const [globalPage, localPage, showLocalContent] = data;
        if (!globalPage && !localPage) { return null; }
        if (!localPage) { return globalPage; }
        if (!globalPage) { return localPage; }
        return showLocalContent
          ? localPage
          : globalPage;
      })
    );
  private pageAndUserSubscription: Subscription;

  public loading$ = this.contextualHelpFacade.loading$;

  constructor(
    private contextualHelpFacade: ContextualHelpFacade,
    private usersFacade: UsersFacade,
    public dialogRef: MatDialogRef<ContextualHelpDrawerComponent>,
    private trackerService: TrackerService
  ) { }

  public ngOnInit(): void {
    combineLatest([
      this.contextualHelpFacade.contextualHelpTrackingSuffix$,
      this.page$,
    ]).pipe(take(1)).subscribe(props => {
      const [trackingSuffix, page] = props;
      if (!page) { return; } // don't track editors looking for tags
      this.trackContextualHelpMenuClick(trackingSuffix);
    });

    this.pageAndUserSubscription = combineLatest([
      this.page$,
      this.usersFacade.myself$,
    ]).subscribe(([page, myself]: [Page, User]) => {
      if (!myself || !page?.isNewContent) { return; }
      this.contextualHelpFacade.markPageAsRead({ pageId: page.id, userId: myself.id });
    });
  }

  public ngOnDestroy(): void {
    this.pageAndUserSubscription.unsubscribe();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public goToWiki(path: string): void {
    window.open(`${environment.wikiDomain}/${path}`, '_blank');
  }

  public switchContent(): void {
    this.showLocalContent$.next(!this.showLocalContent$.value);
  }

  private trackContextualHelpMenuClick(trackingSuffix: string | null): void {
    // TODO: uncomment when ALL page numbers will be ready
    // if (!pageNumber) {
    // eslint-disable-next-line no-console
    //   console.info('Clicked contextual help on unmarked page. It cannot be tracked in matomo.');
    //   return;
    // }

    const actionName = `${CONTEXTUAL_HELP_TITLE_PREFIX}${trackingSuffix || ''}`;
    this.trackerService.setDocumentTitleForDrawers(actionName);
  }
}
