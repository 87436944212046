import { environment } from 'src/environments/environment';

import { matchingGlobalPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'apps-app',
  windowLibraryName: 'apps',
  assetsPath: environment.appsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/apps',
  appUrl: environment.appsAppUrl,
  matchingStrategy: matchingGlobalPathname(['/apps']),
};

export const registerAppsApp = (customParams?: any) => registerApp({ ...config, customParams });
