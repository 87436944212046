import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import {
  Client,
  ClientsState,
  CreateMarketPayload,
  Market,
  ClientCreationRejectPayload,
  ClientCreationAcceptPayload,
} from './clients.model';

export const getClients = createAction('[Clients] Get Clients');
export const resetError = createAction('[Clients] Reset Error');

export const getClientsSuccess = createAction(
  '[Clients] Get Clients Success',
  props<{ clients: ClientsState['clients'] }>()
);
export const getClientsFail = createAction(
  '[Clients] Get Clients Fail',
  props<{ error: HttpErrorResponse }>()
);

export const getFilteredClients = createAction(
  '[Clients] Get Filtered Clients',
  props<{ clientName: Client['name'] }>()
);
export const getFilteredClientsSuccess = createAction(
  '[Clients] Get Filtered Clients Success',
  props<{ clients: ClientsState['clients'] }>()
);
export const getFilteredClientsFail = createAction(
  '[Clients] Get Filtered Clients Fail',
  props<{ error: HttpErrorResponse }>()
);

export const resetClients = createAction('[Clients] Reset Clients');

export const getClient = createAction('[Clients] Get Client', props<{ id: Client['id'] }>());
export const getClientSuccess = createAction(
  '[Clients] Get Client Success',
  props<{ client: ClientsState['selectedClient'] }>()
);
export const getClientFail = createAction(
  '[Clients] Get Client Fail',
  props<{ error: HttpErrorResponse }>()
);

export const getClientWithDetails = createAction(
  '[Clients] Get Client With Details',
  props<{ id: Client['id'] }>()
);
export const getClientWithDetailsSuccess = createAction(
  '[Clients] Get Client With Details Success',
  props<{ client: ClientsState['selectedClient'] }>()
);
export const getClientWithDetailsFail = createAction(
  '[Clients] Get Client With Details Fail',
  props<{ error: HttpErrorResponse }>()
);

export const createClient = createAction('[Clients] Create Client', props<{ client: Client }>());
export const createClientSuccess = createAction(
  '[Clients] Create Client Success',
  props<{ client: Client }>()
);
export const createClientFail = createAction(
  '[Clients] Create Client Fail',
  props<{ error: HttpErrorResponse }>()
);

export const updateClient = createAction(
  '[Clients] Update Client',
  props<{ client: Client; id: Client['id'] }>()
);
export const updateClientSuccess = createAction(
  '[Clients] Update Client Success',
  props<{ client: Client }>()
);
export const updateClientFail = createAction(
  '[Clients] Update Client Fail',
  props<{ error: HttpErrorResponse }>()
);

export const resetClient = createAction('[Clients] Reset Client');

// Markets
export const createMarket = createAction(
  '[Clients] Create Market',
  props<{ createMarketPayload: CreateMarketPayload }>()
);
export const createMarketFail = createAction(
  '[Clients] Create Market Fail',
  props<{ error: HttpErrorResponse }>()
);

export const pollingMarket = createAction(
  '[Clients] Polling Market',
  props<{ clientId: Client['id']; marketCode: Market['code'] }>()
);
export const pollingMarketSuccess = createAction(
  '[Clients] Polling Market Success',
  props<{ market: Market }>()
);

export const updateMarket = createAction(
  '[Clients] Update Market',
  props<{ clientId: Client['id']; market: Market }>()
);
export const updateMarketSuccess = createAction(
  '[Clients] Update Market Success',
  props<{ market: Market }>()
);
export const updateMarketFail = createAction(
  '[Clients] Update Market Fail',
  props<{ error: HttpErrorResponse }>()
);

export const recreateMarket = createAction(
  '[Clients] Recreate Market',
  props<{ clientId: Client['id']; market: Market }>()
);
export const recreateMarketFail = createAction(
  '[Clients] Recreate Market Fail',
  props<{ error: HttpErrorResponse }>()
);

export const sendMarketRequest = createAction(
  '[Clients] Send Market Request',
  props<{ clientId: Client['id']; countryCode: Market['code'] }>()
);
export const sendMarketRequestSuccess = createAction('[Clients] Send Market Request Success');
export const sendMarketRequestFail = createAction(
  '[Clients] Send Market Request Fail',
  props<{ error: HttpErrorResponse }>()
);

// Categories
export const getCategories = createAction('[Clients] Get Categories');
export const getCategoriesSuccess = createAction(
  '[Clients] Get Categories Success',
  props<{ categories: ClientsState['categories'] }>()
);
export const getCategoriesFail = createAction(
  '[Clients] Get Categories Fail',
  props<{ error: HttpErrorResponse }>()
);

export const acceptClientCreationRequest = createAction(
  '[Clients] Accept Client Creation Request',
  props<ClientCreationAcceptPayload>()
);

export const acceptClientCreationRequestSuccess = createAction(
  '[Clients] Accept Client Creation Request Success',
  props<Client>()
);

export const acceptClientCreationRequestFailure = createAction(
  '[Clients] Accept Client Creation Request Failure',
  props<{ error: HttpErrorResponse }>()
);

export const rejectClientCreationRequest = createAction(
  '[Clients] Reject Client Creation Request',
  props<ClientCreationRejectPayload>()
);

export const rejectClientCreationRequestSuccess = createAction(
  '[Clients] Reject Client Creation Request Success',
);

export const rejectClientCreationRequestFailure = createAction(
  '[Clients] Reject Client Creation Request Failure',
  props<{ error: HttpErrorResponse }>()
);
