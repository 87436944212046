<div class="brand-drawer__container">
  <div class="brand-drawer__content">
    <div class="brand-drawer__close">
      <i-feather name="x" (click)="handleClose()" class="brand-drawer__icon"></i-feather>
    </div>
    <h3
      class="wm-h3 brand-drawer__header"
      [translate]="
        isEdited ? 'WM_HOME.MARKET_MANAGEMENT.EDIT_BRAND' : 'WM_HOME.MARKET_MANAGEMENT.ADD_BRAND'
      "
    ></h3>
    <form [formGroup]="brandForm" class="brand-drawer__form">
      <wm-input
        formControlName="name"
        [label]="'WM_HOME.BRAND_EDIT.LABEL_NAME' | translate"
        [placeholder]="'WM_HOME.BRAND_EDIT.PLACEHOLDER_BRAND_NAME' | translate"
        [invalidate]="brandName.errors?.required && brandName.touched"
        [errorText]="'WM_HOME.MARKET_MANAGEMENT.ERROR_REQUIRED' | translate"
        class="brand-drawer__form-element"
        data-test="WM_HOME.BRAND_DRAWER.NAME_INPUT"
      ></wm-input>
      <div class="dropdown-module-wrapper brand-drawer__form-element">
        <span
          class="wm-overline brand-drawer__label"
          translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_CATEGORY"
        ></span>
        <ng-select
          formControlName="category"
          [items]="categories"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'WM_HOME.MARKET_MANAGEMENT.CATEGORY_PLACEHOLDER' | translate"
          bindLabel="name"
          class="wm-select"
          data-test="WM_HOME.BRAND_DRAWER.CATEGORY_SELECT"
        >
          <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
      <app-add-logo
        [title]="'WM_HOME.BRAND_EDIT.BRAND_LOGO'"
        [description]="'WM_HOME.BRAND_EDIT.BRAND_LOGO_DESCRIPTION'"
        [logo]="editedBrand?.logo"
        [selectedLogoUrl]="selectedLogoUrl$ | async"
        (loadLogo)="loadBrandLogo($event)"
        (loadLogoUrl)="loadBrandLogoUrl($event)"
        (removeLogoUrl)="removeBrandLogoUrl()"
        (getLogos)="getLogos()"
        class="brand-drawer__form-element"
      >
      </app-add-logo>
      <wm-input
        formControlName="websiteUrl"
        [label]="'WM_HOME.BRAND_EDIT.LABEL_WEBSITE_OPTIONAL' | translate"
        [placeholder]="'WM_HOME.BRAND_EDIT.PLACEHOLDER_WEBSITE_OPTIONAL' | translate"
        [invalidate]="website.errors?.pattern"
        [errorText]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.URL_ERROR' | translate"
        class="brand-drawer__form-element"
        data-test="WM_HOME.BRAND_DRAWER.WEBSITE_INPUT"
      ></wm-input>
      <div class="brand-drawer__optional" data-test="WM_HOME.BRAND_DRAWER.CONTACT_INDICATOR">
        <div (click)="toggleContact()" class="brand-drawer__optional-header">
          <wm-arrow [isExpanded]="isContactExpand" [size]="20"></wm-arrow>
          <h6
            class="wm-h6 brand-drawer__optional-title"
            translate="WM_HOME.MARKET_MANAGEMENT.CONTACT_TITLE"
          ></h6>
        </div>
        <ng-container *ngIf="isContactExpand">
          <wm-input
            formControlName="contactName"
            [label]="'WM_HOME.MARKET_MANAGEMENT.CONTACT_NAME_LABEL' | translate"
            [placeholder]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.PLACEHOLDER_CONTACT_NAME' | translate"
            [invalidate]="contactName.errors?.pattern"
            [errorText]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.NAME_ERROR' | translate"
            class="brand-drawer__form-element"
            data-test="WM_HOME.BRAND_DRAWER.CONTACT_NAME_INPUT"
          ></wm-input>
          <wm-input
            formControlName="contactPhone"
            [label]="'WM_HOME.MARKET_MANAGEMENT.CONTACT_PHONE_LABEL' | translate"
            [placeholder]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.PLACEHOLDER_PHONE_NUMBER' | translate"
            [invalidate]="contactPhone.errors?.pattern"
            [errorText]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.PHONE_ERROR' | translate"
            class="brand-drawer__form-element"
            data-test="WM_HOME.BRAND_DRAWER.CONTACT_PHONE_INPUT"
          ></wm-input>
          <wm-input
            formControlName="contactEmail"
            [label]="'WM_HOME.MARKET_MANAGEMENT.CONTACT_EMAIL_LABEL' | translate"
            [placeholder]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.PLACEHOLDER_EMAIL' | translate"
            [invalidate]="contactEmail.errors?.email"
            [errorText]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.EMAIL_ERROR' | translate"
            class="brand-drawer__form-element"
            data-test="WM_HOME.BRAND_DRAWER.CONTACT_EMAIL_INPUT"
          ></wm-input>
        </ng-container>
      </div>
      <div
        formArrayName="socialMediaLinks"
        class="brand-drawer__optional"
        data-test="WM_HOME.BRAND_DRAWER.SOCIAL_INDICATOR"
      >
        <div (click)="toggleMedia()" class="brand-drawer__optional-header">
          <wm-arrow [isExpanded]="isMediaExpand" [size]="20"></wm-arrow>
          <h6
            class="wm-h6 brand-drawer__optional-title"
            translate="WM_HOME.BRAND_EDIT.LABEL_SOCIAL_MEDIA_LINKS"
          ></h6>
        </div>
        <ng-container *ngIf="isMediaExpand">
          <ng-container
            *ngFor="let media of socialMedia.controls; let i = index"
            [formGroupName]="i"
          >
            <wm-input
              formControlName="name"
              [label]="'WM_HOME.MARKET_MANAGEMENT.MEDIA_PLATFORM_LABEL' | translate"
              [placeholder]="
                'WM_HOME.BRAND_MANAGEMENT_DRAWER.PLACEHOLDER_SOCIAL_MEDIA_NAME' | translate
              "
              [invalidate]="media.controls.name.errors?.required"
              [errorText]="'WM_HOME.MARKET_MANAGEMENT.ERROR_REQUIRED' | translate"
              class="brand-drawer__form-element"
              data-test="WM_HOME.BRAND_DRAWER.SOCIAL_PLATFORM_INPUT"
            ></wm-input>
            <wm-input
              formControlName="url"
              [label]="'WM_HOME.BRAND_MANAGEMENT_DRAWER.PLACEHOLDER_WEB_ADDRESS' | translate"
              [placeholder]="'WM_HOME.MARKET_MANAGEMENT.MEDIA_URL_PLACEHOLDER' | translate"
              [invalidate]="
                media.controls.url.errors?.pattern || media.controls.url.errors?.required
              "
              [errorText]="
                (media.controls.url.errors?.required
                  ? 'WM_HOME.MARKET_MANAGEMENT.ERROR_REQUIRED'
                  : 'WM_HOME.BRAND_MANAGEMENT_DRAWER.URL_ERROR'
                ) | translate
              "
              class="brand-drawer__form-element"
              data-test="WM_HOME.BRAND_DRAWER.SOCIAL_URL_INPUT"
            ></wm-input>
          </ng-container>
          <button
            mat-stroked-button
            (click)="addSocialMedia()"
            class="wm-button-tertiary brand-drawer__optional-button"
            translate="WM_HOME.MARKET_MANAGEMENT.ADD_MEDIA_BUTTON"
          ></button>
        </ng-container>
      </div>
      <div
        formArrayName="competitors"
        class="brand-drawer__optional"
        data-test="WM_HOME.BRAND_DRAWER.COMPETITOR_INDICATOR"
      >
        <div (click)="toggleCompetitors()" class="brand-drawer__optional-header">
          <wm-arrow [isExpanded]="isCompetitorExpand" [size]="20"></wm-arrow>
          <h6
            class="wm-h6 brand-drawer__optional-title"
            translate="WM_HOME.BRAND_EDIT.LABEL_COMPETITORS_OPTIONAL"
          ></h6>
        </div>
        <ng-container *ngIf="isCompetitorExpand">
          <wm-input
            *ngFor="let competitor of competitors.controls; let i = index"
            [formControlName]="i"
            [label]="'WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_COMPETITORS' | translate"
            [placeholder]="'WM_HOME.MARKET_MANAGEMENT.COMPETITOR_PLACEHOLDER' | translate"
            class="brand-drawer__form-element"
            data-test="WM_HOME.BRAND_DRAWER.COMPETITOR_NAME_INPUT"
          ></wm-input>
          <button
            mat-stroked-button
            (click)="addCompetitor()"
            class="wm-button-tertiary brand-drawer__optional-button"
            translate="WM_HOME.MARKET_MANAGEMENT.ADD_COMPETITOR_BUTTON"
          ></button>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="brand-drawer__footer">
    <app-action-buttons
      [continueDisabled]="!brandForm.valid"
      [continueDisabledTooltipText]="'WM_HOME.BRAND_MANAGEMENT.BUTTON_TOOLTIP'"
      backText="WM_HOME.CLIENT_MANAGEMENT.BUTTON_CANCEL"
      continueText="WM_HOME.CLIENT_MANAGEMENT.BUTTON_SAVE"
      (back)="handleClose()"
      (continue)="handleSave()"
    ></app-action-buttons>
  </div>
</div>
