import { environment } from '@environment';
import { TOUR_REMINDER_TOOLTIP_ID } from '@mads/wm-ng-components';

export interface GuideTourStep {
  title: string;
  description: string;
  id: string;
  arrowPosition: string;
  class: string;
  actionType?: string;
}

export const GUIDE_STEP_ACTIONS = {
  CLOSE: 'CLOSE',
  OPEN_MARKET: 'OPEN_MARKET',
  OPEN_BRAND: 'OPEN_BRAND',
};

export const CLIENT_MANAGEMENT_TOUR = {
  clientName: {
    title: 'WM_HOME.GUIDE_TOUR.CLIENT_NAME_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.CLIENT_NAME_DESCRIPTION',
    id: 'GUIDE_CLIENT_NAME',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--name',
  },
  clientLogo: {
    title: 'WM_HOME.GUIDE_TOUR.CLIENT_LOGO_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.CLIENT_LOGO_DESCRIPTION',
    id: 'GUIDE_CLIENT_LOGO',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--logo',
  },
};

export const CLIENT_MANAGEMENT_TOUR_NAME = 'CLIENT_MANAGEMENT_TOUR';

const MARKET_MANAGEMENT_TOUR_EMPTY = {
  addMarket: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_ADD_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_ADD_DESCRIPTION',
    id: 'GUIDE_MARKET_ADD',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--market-add',
    actionType: GUIDE_STEP_ACTIONS.CLOSE,
  },
  selectMarket: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_SELECT_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_SELECT_DESCRIPTION',
    id: 'GUIDE_MARKET_SELECT',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--market-select',
    actionType: GUIDE_STEP_ACTIONS.OPEN_MARKET,
  },
  selectStatus: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_STATUS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_STATUS_DESCRIPTION',
    id: 'GUIDE_MARKET_STATUS',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--market-status',
  },
  selectOwners: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_OWNERS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_OWNERS_DESCRIPTION',
    id: 'GUIDE_MARKET_OWNERS',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--market-owners',
  },
  selectMembers: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_MEMBERS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_MEMBERS_DESCRIPTION',
    id: 'GUIDE_MARKET_MEMBERS',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--market-members',
    actionType: GUIDE_STEP_ACTIONS.OPEN_MARKET,
  },
};

const MARKET_MANAGEMENT_TOUR_EDIT = {
  marketList: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_LIST_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_LIST_DESCRIPTION',
    id: 'GUIDE_MARKET_LIST',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--market-list',
    actionType: GUIDE_STEP_ACTIONS.CLOSE,
  },
  addAnother: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_ADD_ANOTHER_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_ADD_ANOTHER_DESCRIPTION',
    id: 'GUIDE_MARKET_ADD_ANOTHER',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--market-add',
  },
  editMarket: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_EDIT_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_EDIT_DESCRIPTION',
    id: 'GUIDE_MARKET_EDIT',
    arrowPosition: 'left',
    class: 'guide-tooltip guide-tooltip--market-edit',
  },
  marketDetails: {
    title: 'WM_HOME.GUIDE_TOUR.MARKET_DETAILS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.MARKET_DETAILS_DESCROPTION',
    id: 'GUIDE_MARKET_DETAILS',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--market-details',
  },
};

const BRAND_MANAGEMENT_TOUR_EMPTY = {
  addBrand: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_ADD_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_ADD_DESCRIPTION',
    id: 'GUIDE_BRAND_ADD',
    arrowPosition: 'bottom',
    class: 'guide-tooltip guide-tooltip--brand-add',
    actionType: GUIDE_STEP_ACTIONS.CLOSE,
  },
  brandInfo: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_INFO_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_INFO_DESCRIPTION',
    id: 'GUIDE_BRAND_INFO',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--brand-info',
    actionType: GUIDE_STEP_ACTIONS.OPEN_BRAND,
  },
  brandCategory: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_CATEGORY_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_CATEGORY_DESCRIPTION',
    id: 'GUIDE_BRAND_CATEGORY',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--brand-category',
  },
  brandOptional: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_OPTIONAL_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_OPTIONAL_DESCRIPTION',
    id: 'GUIDE_BRAND_OPTIONAL',
    arrowPosition: 'right',
    class: 'guide-tooltip guide-tooltip--brand-optional',
    actionType: GUIDE_STEP_ACTIONS.OPEN_BRAND,
  },
};

const BRAND_MANAGEMENT_TOUR_EDIT = {
  brandDetails: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_DETAILS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_DETAILS_DESCRIPTION',
    id: 'GUIDE_BRAND_DETAILS',
    arrowPosition: 'top',
    class: 'guide-tooltip guide-tooltip--brand-details',
    actionType: GUIDE_STEP_ACTIONS.CLOSE,
  },
  brandEdit: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_EDIT_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_EDIT_DESCRIPTION',
    id: 'GUIDE_BRAND_EDIT',
    arrowPosition: 'top',
    class: 'guide-tooltip guide-tooltip--brand-edit',
  },
  deleteBrand: {
    title: 'WM_HOME.GUIDE_TOUR.BRAND_DELETE_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.BRAND_DELETE_DESCRIPTION',
    id: 'GUIDE_BRAND_DELETE',
    arrowPosition: 'top',
    class: 'guide-tooltip guide-tooltip--brand-delete',
  },
};

export const CLOSE_TOUR_REMINDER = {
    title: 'WM_HOME.GUIDE_TOUR.GUIDE_CLOSE_TOUR_REMINDER_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.GUIDE_CLOSE_TOUR_REMINDER_DESCRIPTION',
    id: TOUR_REMINDER_TOOLTIP_ID,
    arrowPosition: environment.appName === 'WMOS' ? 'right' : 'top',
    class: 'guide-tooltip guide-tooltip--close',
};

export const MARKET_MANAGEMENT_GUIDE_TOUR_NAMES = {
  MARKET_MANAGEMENT_TOUR_EMPTY: 'MARKET_MANAGEMENT_TOUR_EMPTY',
  MARKET_MANAGEMENT_TOUR_EDIT: 'MARKET_MANAGEMENT_TOUR_EDIT',
  BRAND_MANAGEMENT_TOUR_EMPTY: 'BRAND_MANAGEMENT_TOUR_EMPTY',
  BRAND_MANAGEMENT_TOUR_EDIT: 'BRAND_MANAGEMENT_TOUR_EDIT',
};

export const MARKET_MANAGEMENT_GUIDE_TOURS = {
  MARKET_MANAGEMENT_TOUR_EMPTY,
  MARKET_MANAGEMENT_TOUR_EDIT,
  BRAND_MANAGEMENT_TOUR_EMPTY,
  BRAND_MANAGEMENT_TOUR_EDIT,
};
