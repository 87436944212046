<div *ngIf="!!config" class="promo">
  <div class="promo__lottie">
    <ng-lottie [options]="{ path: config.lottiePath }"></ng-lottie>
  </div>

  <div class="promo__texts">
    <span class="promo__section-name">
      {{ config.texts.sectionName | translate }}
    </span>

    <p>
      <span class="promo__title">
        {{ config.texts.title | translate }}
      </span>
      <span class="promo__app-name">
        {{ config.texts.appName | translate }}
      </span>
    </p>
    <p class="promo__description">
      {{ config.texts.description | translate }}
    </p>
  </div>
</div>
