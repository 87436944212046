import { createSelector, createFeatureSelector } from '@ngrx/store';
import { QuestionnaireState, questionnaireFeatureKey } from './questionnaire.model';

export const selectQuestionnaireState = createFeatureSelector<QuestionnaireState>(questionnaireFeatureKey);

export const selectOpened = createSelector(
  selectQuestionnaireState,
  (state) => state.opened
);

export const selectDismissed = createSelector(
  selectQuestionnaireState,
  (state) => state.dismissed
);
export const selectDisplayNotification = createSelector(
  selectQuestionnaireState,
  (state) => state.displayNotification
);
export const selectRunAnimationPosition = createSelector(
  selectQuestionnaireState,
  (state) => state.runAnimationPosition
);
