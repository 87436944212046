import { Component, OnInit } from '@angular/core';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Mode } from '@mads/wm-ng-components';
import { filter, map as rMap } from 'rxjs/operators';
import { map, compact } from 'lodash-es';

import { ClientsFacade, Client, ClientManagementDrawerMode } from 'app/state/clients';
import { ModeService } from 'app/core/mode.service';
import { constants } from '@config/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.scss'],
})
export class ClientMenuComponent extends OnDestroyMixin implements OnInit {
  public mode: Mode;

  // ResetClentGuard would remove selectedClient from store while routing from client menu to brand menu,
  // therefore we filter truthy values. TODO: wmo-3802 remove this behavior while keeping reset in needed cases
  public client$ = this.clientsFacade.selectedClient$.pipe(
    filter(client => !!client),
    rMap((client) => this.filterMarkets(client))
  );
  public isClientAdmin$ = this.client$.pipe(
    rMap((client) => client.markets.some((market) => market.isClientAdmin))
  );

  public readonly clientManagementAppId = environment.appsIds.clientManagementAppId;
  public readonly canAddClientPermissionKey = constants.USER_PERMISSION_KEYS.CAN_ADD_CLIENT;
  public readonly canAddMarketPermissionKey = constants.USER_PERMISSION_KEYS.CAN_ADD_MARKET;
  public readonly DrawerMode = ClientManagementDrawerMode;

  constructor(private clientsFacade: ClientsFacade, private modeService: ModeService) {
    super();
  }

  public ngOnInit(): void {
    this.modeService.mode$
      .pipe(untilComponentDestroyed(this))
      .subscribe((mode) => (this.mode = mode));
  }

  private filterMarkets(client: Client): Client {
    const markets = map(client.markets, (market) => {
      if (market.status === constants.MARKET_STATUS.INACTIVE) {
        return null;
      }

      return market;
    });

    return {
      ...client,
      markets: compact(markets),
    };
  }
}
