import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Mode, ModeCodes } from '@mads/wm-ng-components';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { filter, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { includes } from 'lodash-es';

import { ModeService } from 'app/core/mode.service';

@Component({
  selector: 'app-fullsize-menu',
  templateUrl: './fullsize-menu.component.html',
  styleUrls: ['./fullsize-menu.component.scss'],
})
export class FullsizeMenuComponent extends OnDestroyMixin implements OnInit {
  public shouldRenderClientMenu: boolean;
  public checkIfSameBackgroundOnBothSides: boolean;
  public mode: Mode;
  public ModeCodes = ModeCodes;

  constructor(private modeService: ModeService, private router: Router) {
    super();
  }

  public ngOnInit(): void {
    const routerEvents = this.router.events.pipe(
      startWith(new NavigationEnd(0, '', '')) // mock first reroute to run stream below
    );

    combineLatest(this.modeService.mode$, routerEvents)
      .pipe(
        untilComponentDestroyed(this),
        filter(([mode, event]) => event instanceof NavigationEnd)
      )
      .subscribe(([mode, event]) => {
        this.shouldRenderClientMenu = this.checkIfClientMenu(this.router.url);
        this.checkIfSameBackgroundOnBothSides =
          !this.shouldRenderClientMenu || mode.code === ModeCodes.LOREAL;
        this.mode = mode;
      });
  }

  private checkIfClientMenu(url: string): boolean {
    return includes(url, 'client');
  }
}
