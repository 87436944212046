import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { isNil } from 'lodash-es';

import { AddLogoDialogComponent } from '../add-logo-dialog/add-logo-dialog.component';
import {
  ADD_LOGO_DIALOG_PANEL_CLASS,
  DialogOutputData,
} from '../add-logo-dialog/add-logo-dialog.model';

@Component({
  selector: 'app-add-logo',
  templateUrl: './add-logo.component.html',
  styleUrls: ['./add-logo.component.scss'],
})
export class AddLogoComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() logo: string;
  @Input() alignLogo: boolean;
  @Input() selectedLogoUrl: string;

  @Output() loadLogo = new EventEmitter<File>();
  @Output() loadLogoUrl = new EventEmitter<string>();
  @Output() removeLogoUrl = new EventEmitter<string>();
  @Output() getLogos = new EventEmitter<any>();

  public newLogo: string | ArrayBuffer;
  public showLogoError = false;
  public showLogoSizeError = false;
  private maxAllowedSize = 0.5;
  private MbConvertor = 1000000;

  constructor(public dialog: MatDialog) {}

  public handleLoadLogo(logo: File, logoUrl?: string): void {
    if (logoUrl) {
      this.loadLogoUrl.emit(logoUrl);
      this.showLogoError = false;

      return;
    }

    if (!logo) {
      return;
    }

    const fileSizeMB = logo.size / this.MbConvertor;

    if (fileSizeMB > this.maxAllowedSize) {
      this.showLogoSizeError = true;
      return;
    }

    this.showLogoSizeError = false;
    const reader = new FileReader();
    reader.readAsDataURL(logo);
    reader.onload = () => {
      this.newLogo = reader.result;
    };

    this.showLogoError = false;
    this.loadLogo.emit(logo);
    this.removeLogoUrl.emit(logoUrl);
  }

  public openModal(): void {
    this.getLogos.emit();

    const dialogRef = this.dialog.open(AddLogoDialogComponent, {
      panelClass: ADD_LOGO_DIALOG_PANEL_CLASS,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((dialogOutputData: DialogOutputData) => {
        const isLogoExist = !isNil(this.selectedLogoUrl || this.logo || this.newLogo);

        if (!dialogOutputData?.logoUrl && !dialogOutputData?.logo && !isLogoExist) {
          this.showLogoError = true;
        }

        if (!dialogOutputData?.logoUrl && !dialogOutputData?.logo) {
          return;
        }

        this.handleLoadLogo(dialogOutputData?.logo, dialogOutputData?.logoUrl);
      });
  }
}
