import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { App } from 'app/state/personal/personal.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PersonalHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getPersonalApps(language: string): Observable<App[]> {
    const request = this.http.get<App[]>(
      `${PersonalHttpService.APPS_API_URL}/v1/apps?lang=${language}&sort=name`,
      PersonalHttpService.httpOptions
    );
    return this.handleRequest<App[]>(request);
  }

  public updateAppLastUseDate({ app }: { app: App }): Observable<App> {
    const request = this.http.put<App>(
      `${PersonalHttpService.APPS_API_URL}/v1/used/${app.id}/`,
      app.id,
      PersonalHttpService.httpOptions
    );
    return this.handleRequest<App>(request);
  }

  public checkPresentationMode(): Observable<boolean> {
    const url = `${PersonalHttpService.YOU_API_URL}/presentationmode`;
    const request = this.http.get<{ advertiserId: string }>(url);
    return this.handleRequest<{ advertiserId: string }>(request)
      .pipe(map((res: { advertiserId: string }) => !!res.advertiserId));
  }

}
