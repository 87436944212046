<div class="loader">
  <div class="loader__content">
    <div class="loader__circle">
      <wm-loader mode="indeterminate"></wm-loader>
    </div>

    <h5 class="loader__title" translate="WM_HOME.LOADER"></h5>
    <p
      *ngFor="let description of descriptions"
      class="loader__description"
      translate="{{ description }}"
    ></p>
  </div>
</div>
