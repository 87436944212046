<div class="snackbar__container">
  <i-feather
    *ngIf="data.isErrorType; else infoIcon"
    class="snackbar__icon snackbar__icon--mark"
    name="alert-triangle"
  ></i-feather>
  <ng-template #infoIcon>
    <i-feather class="snackbar__icon snackbar__icon--mark" name="check-circle"></i-feather>
  </ng-template>
  <div class="snackbar__message">
    <span *ngIf="data.message" [translateParams]="data.messageParams" translate>
      {{ data.message }}
    </span>
    <span
      *ngFor="let message of data.messageWithStyles"
      translate="{{ message.part }}"
      class="snackbar__sentence {{ message.class }}"
    ></span>
  </div>
  <button mat-stroked-button class="snackbar__action" (click)="close()">
    <span translate="{{ data.actionText }}"></span>
    <i-feather class="snackbar__icon snackbar__icon--cancel" name="x"></i-feather>
  </button>
</div>
