export interface DialogOutputData {
  logo: File;
  logoUrl: string;
}

export const ACCEPTED_FILE_TYPES = ['.png', '.jpeg', '.jpg', '.bmp', '.tga'];

export const LOGOS_LIMIT = 10;

export const ADD_LOGO_DIALOG_PANEL_CLASS = 'add-logo-dialog-panel';
