import { registerApp, MicrofrontendAppConfig, matchingGlobalPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const SCORECARDS_QUESTIONS_MANAGEMENT_ROUTE_MATCHER = '/sqm';

export const config: MicrofrontendAppConfig = {
  name: 'scorecards-questions-management-app',
  windowLibraryName: 'scorecardsQuestionsManagementApp',
  assetsPath: environment.scorecardsQuestionsManagementAppUrl.replace('main.js', '').substr(1),
  baseHref: '/sqm',
  appUrl: environment.scorecardsQuestionsManagementAppUrl,
  appLocation: environment.scorecardsQuestionsManagementAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname([SCORECARDS_QUESTIONS_MANAGEMENT_ROUTE_MATCHER]),
};

export const registerScorecardsQuestionsManagementApp = (customParams?: MicroAppParams): MicrofrontendAppConfig =>
  registerApp({ ...config, customParams });
