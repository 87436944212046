import { Component, ElementRef } from '@angular/core';

import { registerCampaignsUiApp } from 'src/single-spa/campaigns-ui';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface CampaignsUiAppState {
  pristine: boolean;
}

const appState: CampaignsUiAppState = { pristine: true };

@Component({
  selector: 'app-campaigns-ui-wrapper',
  templateUrl: './campaigns-ui-wrapper.component.html',
  styleUrls: ['./campaigns-ui-wrapper.component.scss'],
})
export class CampaignsUiWrapperComponent extends MicroAppWrapper {
  protected appName = 'CampaignsUi';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: CampaignsUiAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerCampaignsUiApp(params);
  }
}
