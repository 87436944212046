<div class="wrapper" *ngIf="{ breadcrumbs: breadcrumbs$ | async } as viewData">
  <div class="logo-and-breadcrumbs">
    <div [routerLink]="['/']" class="app-logo" [ngClass]="currentMode?.code">
      <img [src]="currentSmallLogoPath$ | async" alt="agency logo" />
    </div>
    <ul class="breadcrumbs-list">
      <li *ngIf="selectedClient$ | async as selectedClient">
        <span class="breadcrumbs-list__arrow"></span>
        <a
          (click)="goToClient($event)"
          class="client-breadcrumb"
          [class.active-breadcrumb]="!brandIsInUrl && viewData?.breadcrumbs?.text === null"
        >
          {{ selectedClient?.name }}
        </a>
      </li>
      <li *ngIf="brandIsInUrl && (selectedBrand$ | async) as brand">
        <span class="breadcrumbs-list__arrow"></span>
        <a
          (click)="goToBrand($event)"
          class="brand-breadcrumb"
          [class.active-breadcrumb]="viewData?.breadcrumbs === null"
          >{{ brand.country.code }} - {{ brand.name }}</a
        >
      </li>
      <ng-container *ngIf="viewData?.breadcrumbs as breadcrumbs">
        <li *ngIf="breadcrumbs.text">
          <span class="breadcrumbs-list__arrow"></span>
          <div class="app-breadcrumb active-breadcrumb">
            <span [translate]="breadcrumbs.text"></span>
          </div>
        </li>
        <li *ngIf="breadcrumbs.category && breadcrumbs.app">
          <span class="breadcrumbs-list__arrow"></span>
          <a
            (click)="goToApp($event, breadcrumbs.category, breadcrumbs.app, breadcrumbs.section)"
            class="app-breadcrumb"
            [class.active-breadcrumb]="!breadcrumbs.section && !breadcrumbs.details"
          >
            <span [translate]="breadcrumbs.category"></span>
            <span> - </span>
            <span [translate]="breadcrumbs.app"></span>
          </a>
        </li>
        <li *ngIf="breadcrumbs.section && !breadcrumbs.hideSection">
          <span class="breadcrumbs-list__arrow"></span>
          <a class="section-breadcrumb" [class.active-breadcrumb]="!breadcrumbs.details">
            <span [translate]="breadcrumbs.section"></span>
          </a>
        </li>
        <ng-container *ngIf="breadcrumbs.details">
          <li *ngFor="let detail of breadcrumbs.details; let last = last">
            <span class="breadcrumbs-list__arrow"></span>
            <a
              (click)="goToDetail($event, detail)"
              [class.active-breadcrumb]="last"
              [class.active-breadcrumb--cursor]="!!detail?.path"
            >
              <span>{{ detail.name }}</span>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <app-header-news-and-avatar [displayNews]="true"></app-header-news-and-avatar>
</div>
