import { Component, ElementRef } from '@angular/core';

import { registerTouchpointsUIApp } from 'src/single-spa/touchpoints-ui';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface TouchpointsUIAppState {
  pristine: boolean;
}

const appState: TouchpointsUIAppState = { pristine: true };

@Component({
  selector: 'app-touchpoints-ui-wrapper',
  templateUrl: './touchpoints-ui-wrapper.component.html',
  styleUrls: ['./touchpoints-ui-wrapper.component.scss'],
})
export class TouchpointsUIWrapperComponent extends MicroAppWrapper {
  protected appName = 'TouchpointsUI';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: TouchpointsUIAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerTouchpointsUIApp(params);
  }
}
