import { Component, OnDestroy, OnInit } from '@angular/core';
import { contextualHelpTags } from '@config/contextual-help-tags';
import { NavigationService } from 'app/core/navigation.service';
import { BreadcrumbsFacade } from 'app/state/breadcrumbs';
import { ContextualHelpFacade } from 'app/state/contextual-help';

@Component({
  selector: 'app-personal-layout',
  templateUrl: './personal-layout.component.html',
  styleUrls: ['./personal-layout.component.scss'],
})
export class PersonalLayoutComponent implements OnInit, OnDestroy {
  constructor(
    private navigationService: NavigationService,
    private breadcrumbsFacade: BreadcrumbsFacade,
    private contextualHelpFacade: ContextualHelpFacade
  ) {}

  public ngOnInit(): void {
    this.navigationService.closeAllNavigationItems();
    this.breadcrumbsFacade.resetBreadcrumbs();
    this.contextualHelpFacade.setContextualHelpMenuInfo({
      contextualHelpArticleTag: contextualHelpTags.personalLayout,
      contextualHelpTrackingSuffix: 'You',
    });
  }

  public ngOnDestroy(): void {
    this.contextualHelpFacade.resetContextualHelpMenuInfo();
  }
}
