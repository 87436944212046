import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { find } from 'lodash-es';
import { registerLocales, TrackerActionType } from '@mads/wm-ng-components';

import { config } from '@config/index';
import { PersonalFacade } from 'app/state/personal';
import { contextualHelpTags } from '@config/contextual-help-tags';
import { ContextualHelpFacade } from 'app/state/contextual-help';
import { matomoSections } from '@config/matomo-sections';
import { TrackerService } from 'app/core/tracker.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  public languages = config.defaults.languages;
  public dateFormats = config.defaults.dateFormats;
  public numberFormats = config.defaults.numberFormats;

  public selectedDateFormat$ = this.personalFacade.dateFormat$;
  public selectedNumberType$ = this.personalFacade.numberFormat$;
  public userOpenForChallenges$ = this.personalFacade.userOpenForChallenges$;
  public loading$ = this.personalFacade.loading$;

  constructor(
    private personalFacade: PersonalFacade,
    private contextualHelpFacade: ContextualHelpFacade,
    private trackerService: TrackerService
  ) {}

  public ngOnInit(): void {
    this.contextualHelpFacade.setContextualHelpMenuInfo({
      contextualHelpArticleTag: contextualHelpTags.userSettings,
      contextualHelpTrackingSuffix: 'UserProfile',
    });
    this.contextualHelpFacade.setMatomoSection(matomoSections.userSettings);
    this.personalFacade.getOpenForChallenges();
    registerLocales();
  }

  public ngOnDestroy(): void {
    this.contextualHelpFacade.resetContextualHelpMenuInfo();
    this.contextualHelpFacade.setMatomoSection(null);
  }

  public getDateFormat(code: string): { label: string; code: string; } | undefined {
    return find(this.dateFormats, { code });
  }

  public getNumberFormat(code: string): { label: string; code: string; } | undefined {
    return find(this.numberFormats, { code });
  }

  public changeDateFormat(dateFormat: { label: string; code: string }): void {
    this.personalFacade.setDateFormatLocale(dateFormat.code);
    
    this.trackerService.trackAction({
      category: 'I think its not used anymore',
      type: TrackerActionType.click,
      details: `dateFormat: ${dateFormat.label}`,
    });
  }

  public changeNumberFormat(numberFormat: { label: string; code: string }): void {
    this.personalFacade.setNumberFormatLocale(numberFormat.code);
    
    this.trackerService.trackAction({
      category: 'I think its not used anymore',
      type: TrackerActionType.click,
      details: `numberFormat: ${numberFormat.code}`,
    });
  }

  public changeOpenForChallenges(change: MatSlideToggleChange): void {
    this.personalFacade.setOpenForChallenges(change.checked);
  }
}
