import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonalState, personalFeatureKey } from './personal.model';

export const selectPersonalState = createFeatureSelector<PersonalState>(personalFeatureKey);

export const selectRecentWork = createSelector(
  selectPersonalState,
  state => state.recentWork
);

export const selectLoading = createSelector(
  selectPersonalState,
  state => state.loading
);

export const selectLanguageCode = createSelector(
  selectPersonalState,
  state => state.language
);

export const selectDateFormatLocale = createSelector(
  selectPersonalState,
  state => state.dateFormatLocale
);

export const selectNumberFormatLocale = createSelector(
  selectPersonalState,
  state => state.numberFormatLocale
);

export const selectFavuriteApps = createSelector(
  selectPersonalState,
  state => state.favouriteApps
);

export const selectUserOpenForWmChallenge = createSelector(
  selectPersonalState,
  state => state.userOpenForWmChallenges
);

export const selectIsInPresentationMode = createSelector(
  selectPersonalState,
  state => state.isInPresentationMode
);
