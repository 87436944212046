import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerGrowthPathApp } from 'src/single-spa/growth-path';

@Component({
  selector: 'app-growth-path-wrapper',
  templateUrl: './growth-path-wrapper.component.html',
  styleUrls: ['./growth-path-wrapper.component.scss'],
})
export class GrowthPathWrapperComponent extends MicroAppWrapper {
  protected appName = 'Growth Path';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerGrowthPathApp(params);
  }
}
