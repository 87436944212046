import { createReducer, on, Action } from '@ngrx/store';

import * as fromBreadcrumbs from './breadcrumbs.actions';
import { BreadcrumbsState, initialState } from './breadcrumbs.model';

const reducer = createReducer(
  initialState,
  on(
    fromBreadcrumbs.updateHeaderBreadcrumbs,
    (state, { updatedBreadcrumbs }) => ({
      ...state,
      breadcrumbs: { ...state.breadcrumbs, ...updatedBreadcrumbs },
    })
  ),
  on(
    fromBreadcrumbs.resetBreadcrumbsDetails,
    (state) => ({
      ...state,
      breadcrumbs: { ...state.breadcrumbs, details: null },
    })
  ),
  on(
    fromBreadcrumbs.resetBreadcrumbs,
    (state) => ({
      ...state,
      breadcrumbs: null,
    })
  )
);

export function breadcrumbsReducer(state: BreadcrumbsState | undefined, action: Action): BreadcrumbsState {
  return reducer(state, action);
}
