import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreadcrumbsDetail } from '@mads/wm-ng-components';
import { filter, take } from 'rxjs/operators';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { RouterFacade } from 'app/state/router';
import { ClientsFacade, Client } from 'app/state/clients';
import { BrandsFacade, Brand } from 'app/state/brands';
import { BreadcrumbsFacade } from 'app/state/breadcrumbs';
import { ModeService } from 'app/core/mode.service';
import { NavigationService } from 'app/core/navigation.service';
import { ChildNavigationService } from 'app/core/child-navigation-service';

import { includes } from 'lodash-es';

@Component({
  selector: 'app-header-breadcrumbs',
  templateUrl: './header-breadcrumbs.component.html',
  styleUrls: ['./header-breadcrumbs.component.scss'],
})
export class HeaderBreadcrumbsComponent extends OnDestroyMixin implements OnInit {
  public brandIsInUrl = false;
  public selectedClient$ = this.clientsFacade.selectedClient$;
  public selectedBrand$ = this.brandsFacade.selectedBrand$;
  public breadcrumbs$ = this.breadcrumbsFacade.breadcrumbs$;
  public currentSmallLogoPath$ = this.modeService.currentSmallLogoPath$;
  public currentMode = this.modeService.currentMode;

  private clientId: Client['id'];
  private brandId: Brand['id'];
  private appPath: string;

  constructor(
    private clientsFacade: ClientsFacade,
    private brandsFacade: BrandsFacade,
    private breadcrumbsFacade: BreadcrumbsFacade,
    private modeService: ModeService,
    private childNavigationService: ChildNavigationService,
    private navigationService: NavigationService,
    private routerFacade: RouterFacade,
    private router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getClientId();
    this.getBrandData();
    this.checkUrlForBrand();

    this.brandIsInUrl = this.isBrandInUrl(this.router.url);
  }

  public isBrandInUrl(url: string): boolean {
    // TODO: how to recognize BrandManagementComponent?
    return includes(url, 'brand');
  }

  public goToClient(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.routerFacade.changeRoute({ linkParams: [`/clients/${this.clientId}/markets`] });
    this.navigationService.closeAllNavigationItems();
  }

  public goToBrand(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.routerFacade.changeRoute({ linkParams: [`/brands/${this.brandId}`] });
  }

  public goToApp(e: MouseEvent, category: string, appName: string, section: string): void {
    e.preventDefault();
    e.stopPropagation();

    // for situations when you navigate to main app view from within child app and want no details lagging:
    this.breadcrumbsFacade.resetBreadcrumbsDetails();

    if (section) {
      this.navigationService.openMainNavigationItem(appName);
      return this.routerFacade.changeRoute({ linkParams: [`/brands/${this.brandId}`] });
    }

    this.updateAppPath(category, appName);
    this.routerFacade.changeRoute({ linkParams: [`/brands/${this.brandId}/${this.appPath}`] });
  }

  public goToDetail(e: MouseEvent, detail: BreadcrumbsDetail): void {
    e.preventDefault();
    e.stopPropagation();
    const { path } = detail;
    if (!path) {
      return;
    }

    this.childNavigationService.broadcastBreadcrumbDetailClick(path);
  }

  private getClientId(): void {
    this.selectedClient$
      .pipe(
        filter((client) => !!client),
        untilComponentDestroyed(this)
      )
      .subscribe((client) => {
        this.clientId = client.id;
      });
  }

  private getBrandData(): void {
    this.selectedBrand$
      .pipe(
        filter((brand) => !!brand),
        untilComponentDestroyed(this)
      )
      .subscribe((brand) => {
        this.clientId = brand.clientId;
        this.brandId = brand.id;
      });
  }

  private checkUrlForBrand(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        this.brandIsInUrl = this.isBrandInUrl(this.router.url);
      });
  }

  private updateAppPath(category: string, appName: string): void {
    this.navigationService.mainNavigation$
      .pipe(
        filter((data) => !!data),
        take(1)
      )
      .subscribe((data) => {
        const navs = data.find(
          (item) => item.title === category || item.breadcrumbTitle === category
        )?.nav;
        const matchedItem = navs
          ? navs.find((item) => item.title === appName || item.breadcrumbTitle === appName)
          : null;
        this.appPath = matchedItem
          ? matchedItem.path
          : this.getNestedAppPath(data, category, appName);
      });
  }

  private getNestedAppPath(obj, category, appName): string {
    return (
      this.iterateForAppPathUnderCategory(obj, category, appName) ??
      this.iterateForAppPath(obj, appName) ??
      ''
    );
  }

  private iterateForAppPathUnderCategory = (obj, category?, appName?) => {
    let appPath;
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (typeof value === 'object') {
        this.iterateForAppPathUnderCategory(value, category, appName);
      }
      if (value === category && !!obj.nav && (key === 'title' || key === 'breadcrumbTitle')) {
        appPath = this.iterateForAppPath(obj, appName);
        break;
      }
    }
    if (appPath) {
      return appPath;
    }
  };

  private iterateForAppPath = (obj, appName?) => {
    let appPath;
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (typeof value === 'object') {
        appPath = this.iterateForAppPath(value, appName);
        if (appPath) {
          return appPath;
        }
      }
      if (value === appName && !!obj.path && (key === 'title' || key === 'breadcrumbTitle')) {
        appPath = obj.path;
        break;
      }
    }
    if (appPath) {
      return appPath;
    }
  };
}
