import { environment } from 'src/environments/environment';

import {
  matchingBrandPathname,
  MicrofrontendAppConfig,
  registerApp,
} from './utils';

const config: MicrofrontendAppConfig = {
  name: 'media-marketing-mix-app',
  windowLibraryName: 'mediaMarketingMixApp',
  baseHref: '/brands/:brandId/media-marketing-mix',
  assetsPath: environment.mediaMarketingMixAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.mediaMarketingMixAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.mediaMarketingMixAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['media-marketing-mix']),
};

export const registerMediaMarketingMixApp = (customParams?: any) => registerApp({ ...config, customParams });
