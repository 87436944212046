import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { environment } from '@environment';
import { Brand, BrandsFacade } from 'app/state/brands';
import { UsersFacade } from 'app/state/users';
import { isNil } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  defaultIfEmpty,
  filter,
  first,
  map,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class BrandRouteMatcherGuard implements CanActivate {
  private myselfRequested$ = new BehaviorSubject(false);

  private brands$ = this.brandsFacade.brands$;
  private myself$ = this.usersFacade.myself$.pipe(
    withLatestFrom(this.myselfRequested$),
    tap(([myself, myselfRequested]) => {
      if (isNil(myself?.country?.symbol) && !myselfRequested) {
        this.usersFacade.getMyself();
        this.myselfRequested$.next(true);
      }
    }),
    map(([myself]) => myself),
    filter((myself) => !isNil(myself?.country?.symbol))
  );

  constructor(
    private router: Router,
    private brandsFacade: BrandsFacade,
    private usersFacade: UsersFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const brandsSegment = 'brands';
    const defaultBrandSegment = 'defaultbrand';

    const urlSegments = route.url;
    const unconsumedSegments = route.paramMap.get('unconsumedSegments');
    const path = urlSegments[0].path;
    const isDefaultBrand = path === defaultBrandSegment;

    if (!isDefaultBrand) {
      return of(true);
    }

    const returnUrlTree = this.router.createUrlTree(['/you/dashboard']);

    const brandsUrlTreeSegments = [brandsSegment];

    return combineLatest([this.brands$, this.myself$]).pipe(
      tap(([brands, myself]) => {
        if (!brands || brands.length === 0) {
          this.brandsFacade.getBrands(environment.defaultClient, myself.country.symbol);
        }
      }),
      map(([brands]) => brands),
      first((brands) => !!brands && brands.length > 0, null),
      defaultIfEmpty(returnUrlTree),
      map((brands: Brand[]) => {
        if (!brands || brands.length === 0) {
          return returnUrlTree;
        }

        brandsUrlTreeSegments[1] = brands[0].id;
        brandsUrlTreeSegments.push(...(unconsumedSegments?.split('/') ?? null));

        return this.router.createUrlTree(brandsUrlTreeSegments);
      })
    );
  }
}
