import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from 'app/state';

import * as fromPersonalActions from './personal.actions';
import * as fromPersonalSelectors from './personal.selectors';
import { App } from './personal.model';

@Injectable()
export class PersonalFacade {
  public recentWork$ = this.store.select(fromPersonalSelectors.selectRecentWork);
  public loading$ = this.store.select(fromPersonalSelectors.selectLoading);
  public languageCode$ = this.store.select(fromPersonalSelectors.selectLanguageCode);
  public dateFormat$ = this.store.select(fromPersonalSelectors.selectDateFormatLocale);
  public numberFormat$ = this.store.select(fromPersonalSelectors.selectNumberFormatLocale);
  public favouriteApps$ = this.store.select(fromPersonalSelectors.selectFavuriteApps);
  public userOpenForChallenges$ = this.store.select(fromPersonalSelectors.selectUserOpenForWmChallenge);
  public isInPresentationMode$ = this.store.select(fromPersonalSelectors.selectIsInPresentationMode);

  constructor(private store: Store<AppState>) {}

  public getRecentWork(): void {
    this.store.dispatch(fromPersonalActions.getRecentWork());
  }

  public getFavouriteApps(): void {
    this.store.dispatch(fromPersonalActions.getFavouriteApps());
  }

  public goToFavouriteApp(app: App): void {
    this.store.dispatch(fromPersonalActions.goToFavouriteApp({app}));
  }

  public initLanguage(): void {
    this.store.dispatch(fromPersonalActions.initLanguage());
  }

  public setLanguageCode(language: string): void {
    this.store.dispatch(fromPersonalActions.setLanguageCode({language}));
  }

  public setDateFormatLocale(dateFormatLocale: string): void {
    this.store.dispatch(fromPersonalActions.setDateFormatLocale({dateFormatLocale}));
  }

  public setNumberFormatLocale(numberFormatLocale: string): void {
    this.store.dispatch(fromPersonalActions.setNumberFormatLocale({numberFormatLocale}));
  }

  public setOpenForChallenges(consentToParticipate: boolean): void {
    this.store.dispatch(fromPersonalActions.setOpenForChallenges({ consentToParticipate }));
  }

  public getOpenForChallenges(): void {
    this.store.dispatch(fromPersonalActions.getOpenForChallenges());
  }

  public checkPresentationMode(): void {
    this.store.dispatch(fromPersonalActions.checkPresentationMode());
  }
}
