import { Page } from 'src/app/interfaces';

export interface ContextualHelpState {
  loading: boolean;
  currentTag: string | null;
  currentGlobalPage: Page | null;
  currentLocalPage: Page | null;
  pathsToUnclearPages: Page['path'][] | null;
  matomoSection: string | null;
  contextualHelpTrackingSuffix: string | null;
  error?: any;
}

export const initialState: ContextualHelpState = {
  loading: false,
  currentTag: null,
  pathsToUnclearPages: null,
  currentGlobalPage: null,
  currentLocalPage: null,
  matomoSection: null,
  contextualHelpTrackingSuffix: null,
};
