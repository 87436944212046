<div class="content-wrapper" #wikiContentWrapper>
  <div
    class="title"
    (mouseover)="imgSrc = 'assets/icons/go-to-hover.svg'"
    (mouseout)="imgSrc = 'assets/icons/go-to-default.svg'"
    (click)="goToWiki(page.path)">
    <h3 class="wm-h3">{{page.title}}</h3>
    <img [src]="imgSrc">
  </div>
  <p class="wm-subtitle subtitle">{{page.description}}</p>
  <div
    #articleContainer
    [innerHtml]="sanitizedContent"
    appMutationObserver
    (innerHtmlRendered)="addScrollableAnchors()"
    class="wiki-content">
  </div>
</div>
