import {
  ApolloLink,
  ApolloClientOptions,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client/core';

import { HttpLink } from 'apollo-angular/http';
import { environment } from '@environment';
import { HttpHeaders } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloService } from '../apollo.service';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export function createApollo(httpLink: HttpLink, service: ApolloService): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: `${environment.wikiDomain}/graphql`,
  });

  const middleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: new HttpHeaders().set('Authorization', `Bearer ${service.token}`),
    });

    return forward(operation);
  });

  const link = middleware.concat(http);

  return {
    link,
    cache: new InMemoryCache(),
    defaultOptions,
  };
}

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: ApolloService) => () => service.fetchToken(),
      deps: [ApolloService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ApolloService],
    },
  ],
})
export class GraphqlModule {}
