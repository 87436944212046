<app-entity-menu-header [entity]="brand" [mode]="modeCode">
  <div class="info">
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.CATEGORIES"></h5>
      <ul *ngIf="brand.categories" class="list list--categories">
        <li *ngFor="let category of brand.categories" class="label">
          {{ category.name }}
        </li>
      </ul>
    </div>
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.WEBSITE"></h5>
      <a *ngIf="brand.websiteUrl" [href]="brand.websiteUrl" data-test="WM_HOME.HEADER.WEBSITE">
        {{ brand.websiteUrl }}
      </a>
    </div>
    <div class="section">
      <h5 class="title" translate="WM_HOME.HEADER.SOCIAL_MEDIA"></h5>
      <ul *ngIf="brand.socialMediaLinks" class="list" data-test="WM_HOME.HEADER.SOCIAL_MEDIA">
        <li *ngFor="let media of brand.socialMediaLinks">
          <a [href]="media.url">{{ media.name }}</a>
        </li>
      </ul>
    </div>
  </div>

  <i-feather
    *ngIf="brand.isClientAdmin"
    name="edit-3"
    class="edit-button"
    editButton
    [routerLink]="['/clients/client-creation', brand.clientId, 'markets-management']"
    [queryParams]="{
      open: true,
      activeMarket: brand.country.code,
      brandId: brand.id,
      drawerMode: DrawerMode.BRAND
    }"
  ></i-feather>
</app-entity-menu-header>

<nav class="menu">
  <div
    *ngFor="let navigationCategory of navigationCategories"
    class="navigation-category"
  >
    <div *ngIf="!navigationCategory.hidden"
      class="category-title wm-h5">
      <span [translate]="navigationCategory.title"></span>
    </div>
    <ul class="list">
      <ng-container *ngFor="let navigationItem of navigationCategory.nav">
        <li *ngIf="!navigationItem.hidden"
          class="navigation-items-wrapper">
          <div
            class="navigationItem-title"
            [class.active]="!navigationCategory.disabled && !navigationItem.disabled"
            [class.disabled]="navigationCategory.disabled || navigationItem.disabled"
            (click)="handleNavigationItemClick($event, navigationItem, navigationCategory.title)"
          >
            <a class="navigationItem-title__title wm-m-400"
              [translate]="navigationItem.title"></a>
            <wm-arrow
              *ngIf="navigationItem.nav && !navigationItem.disabled"
              [size]="24"
              [isExpanded]="navigationItem.opened"
              color="primary-on-contrast-background"
            ></wm-arrow>
          </div>
          <ul
            class="list subnavigation-list nested-level-1"
            *ngIf="navigationItem.opened"
          >
          <ng-container *ngFor="let subnavigationItem of navigationItem.nav">
            <ng-container 
            [ngTemplateOutlet]="subnavigation" 
            [ngTemplateOutletContext]="{subnavigationItem:subnavigationItem, navigationItem: navigationItem, nestedLevel: 2}">
            </ng-container>
          </ng-container>
        </ul>
        </li>
    
      </ng-container>
    </ul>
  </div>
</nav>

<img class="loreal-drawer-logo" src="assets/modes/loreal/loreal_drawer_logo.svg" />

<ng-template
  #subnavigation
  let-subnavigationItem="subnavigationItem"
  let-navigationItem="navigationItem"
  let-nestedLevel="nestedLevel"
>
  <li
    (click)="handleNavigationItemClick($event, subnavigationItem)"
    class="subnavigation-item active"
    [class.disabled]="subnavigationItem.disabled"
    *ngIf="!subnavigationItem.hidden"
  >
    <a [translate]="subnavigationItem.title"
      class="subnavigation-link wm-s-500"></a>
    <wm-arrow
      *ngIf="subnavigationItem.nav && !subnavigationItem.disabled"
      [size]="24"
      [isExpanded]="subnavigationItem.opened"
      color="primary-on-contrast-background"
    ></wm-arrow>
  </li>
  <ul
    [className]="'nested-level-' + nestedLevel + ' list subnavigation-list'"
    *ngIf="subnavigationItem.opened"
  >
    <ng-container *ngFor="let nestedItem of subnavigationItem.nav">
      <ng-container
        [ngTemplateOutlet]="subnavigation"
        [ngTemplateOutletContext]="{
          subnavigationItem: nestedItem,
          navigationItem: subnavigationItem,
          nestedLevel: nestedLevel + 1
        }"
      >
      </ng-container>
    </ng-container>
  </ul>
</ng-template>