import { Component, ElementRef } from '@angular/core';

import { registerExpressScenariosApp } from 'src/single-spa/express-scenarios';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface ExpressScenariosAppState {
  pristine: boolean;
}

const appState: ExpressScenariosAppState = { pristine: true };

@Component({
  selector: 'app-express-scenarios-wrapper',
  templateUrl: './express-scenarios-wrapper.component.html',
  styleUrls: ['./express-scenarios-wrapper.component.scss'],
})
export class ExpressScenariosWrapperComponent extends MicroAppWrapper {
  protected appName = 'ArchitectJunior';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: ExpressScenariosAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerExpressScenariosApp(params);
  }
}
