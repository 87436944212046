<wm-wrapper>
  <div
    data-test="SHARED.ADD-LOGO-DIALOG.CONTAINER"
    *ngIf="{ isLogosLoading: isLogosLoading$ | async, logos: logos$ | async } as viewData"
  >
    <div class="add-logo-dialog">
      <i-feather class="add-logo-dialog__close" name="x" [mat-dialog-close]="false"></i-feather>
      <div class="add-logo-dialog__header">
        <h4
          class="wm-h4 add-logo-dialog__header__title"
          [translate]="'WM_HOME.BRAND_MANAGEMENT.DIALOG_TITLE'"
        ></h4>
        <p
          class="wm-caption add-logo-dialog__header__description"
          [translate]="'WM_HOME.BRAND_MANAGEMENT.DIALOG_DESCRIPTION'"
        ></p>
      </div>
      <ng-container *ngIf="!viewData?.isLogosLoading; else loader">
        <div class="add-logo-dialog__icons" *ngIf="viewData?.logos?.length; else noicons">
          <div
            class="add-logo-dialog__icons__icon"
            [class.add-logo-dialog__icons__icon--selected]="selectedLogoUrl === logo"
            *ngFor="let logo of viewData?.logos"
            (click)="selectLogo(logo)"
          >
            <div class="add-logo-dialog__icons__icon__selected-circle-container">
              <img [src]="logo" />
              <div
                class="add-logo-dialog__icons__icon__selected-circle"
                *ngIf="selectedLogoUrl === logo"
              >
                <i-feather name="check"></i-feather>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="add-logo-dialog__upload-icon">
        <wm-file-upload
          #wmFileUpload
          [initialPrimaryText]="'WM_HOME.BRAND_MANAGEMENT.FILE_INITIAL_PRIMARY_TEXT' | translate"
          [initialSecondaryText]="'WM_HOME.BRAND_MANAGEMENT.FILE_NITIAL_SECONDARY_TEXT' | translate"
          [currentState]="fileUploadState"
          [acceptedFileTypes]="acceptedFileTypes"
          [completedText]="'WM_HOME.BRAND_MANAGEMENT.FILE_COMPLETED_TEXT' | translate"
          (fileChange)="loadLogo($event)"
          (removeFile)="removeLogo()"
          (fileError)="uploadError()"
        ></wm-file-upload>
        <div class="wm-caption add-logo-dialog__upload-error" *ngIf="error">
          {{ 'WM_HOME.BRAND_MANAGEMENT.UPLOAD_LOGO_ERROR' | translate:{ formats: acceptedFileTypes } }}
        </div>
      </div>
      <ng-template #noicons>
        <div class="add-logo-dialog__no-icons">
          <p
            class="wm-caption add-logo-dialog__no-icons__text"
            [translate]="'WM_HOME.BRAND_MANAGEMENT.NO_LOGO_DESCRIPTION'"
          ></p>
        </div>
      </ng-template>
      <ng-template #loader>
        <div class="add-logo-dialog__loader">
          <wm-loader mode="indeterminate" diameter="50" strokeWidth="2"> </wm-loader>
        </div>
      </ng-template>
    </div>
    <wm-navigation-footer
      class="subsegment-manage__footer"
      [backText]="'WM_HOME.BRAND_MANAGEMENT.CANCEL' | translate"
      [continueText]="'WM_HOME.BRAND_MANAGEMENT.SAVE' | translate"
      containerPosition="static"
      (continue)="saveDialog()"
      (back)="closeDialog()"
    ></wm-navigation-footer>
  </div>
</wm-wrapper>
