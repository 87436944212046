import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent, SnackbarData } from 'app/shared/components/snackbar/snackbar.component';
import { notifications, snackbarConfig } from 'app/config/notifications';

@Injectable()
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) { }

  public show(payload: SnackbarData): void {
    const {
      actionText = notifications.NOTIFICATION_DEFAULT_ACTION,
      panelClass = snackbarConfig.types.info,
      message,
      messageParams,
      messageWithStyles,
    } = payload;

    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        isErrorType: panelClass === snackbarConfig.types.error,
        actionText,
        message,
        messageParams,
        messageWithStyles,
      },
      duration: snackbarConfig.duration,
      panelClass,
    });
  }

  public showError(message: string): void {
    this.show({
      message,
      isErrorType: true,
      panelClass: snackbarConfig.types.error,
      messageParams: null,
      messageWithStyles: null,
    });
  }
}
