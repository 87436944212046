import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationsFacade } from 'app/state/applications/applications.facade';

@Injectable()
export class NoMaintenanceGuard implements CanActivate {
  constructor(private applicationsFacade: ApplicationsFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const appId: string = route.queryParams.appId;

    return this.applicationsFacade.getApplicationFromStateById({ id: appId }).pipe(
      map((application) => {
        if (application?.maintenance) {
          return true;
        }

        return this.router.createUrlTree(['']);
      })
    );
  }
}
