<div *ngIf="myself$ | async as myself" class="settings-section">
  <ul class="settings-section__list">
    <li *ngIf="displayNews">
      <a
        data-test="WM_HOME.HEADER.SETTINGS.NEWS"
        translate="WM_HOME.HEADER.NEWS"
        class="settings-section__list__item"
        [class.settings-section__list__item--disabled]="!hasAccessToNews"
        (click)="openNews()"
      >
      </a>
    </li>
    <li *ngIf="hasAccessToStatistics">
      <a
        data-test="WM_HOME.HEADER.STATISTICS"
        translate="WM_HOME.HEADER.STATISTICS"
        class="settings-section__list__item"
        [class.settings-section__list__item--disabled]="!hasAccessToStatistics"
        [routerLink]="['/products-stats']"
      >
      </a>
    </li>
    <li *ngIf="shouldDisplaySection([sections.help])">
      <!--TODO: WMS-5819 Temporarily inactive
      <div class="standard-button" (click)="navigateToHelp()">
        <span translate="WM_HOME.HEADER.HELP"></span>
      </div>
      -->
      <a
        data-test="WM_HOME.HEADER.SETTINGS.HELP"
        translate="WM_HOME.HEADER.HELP"
        class="settings-section__list__item"
        [class.settings-section__list__item--disabled]="!shouldEnableSection([sections.help])"
        [matMenuTriggerFor]="helpMenu"
        (menuOpened)="trackOpeningHelp()"
      >
      </a>
    </li>
    <li>
      <div
        data-test="WM_HOME.HEADER.SETTINGS.AVATAR"
        [matMenuTriggerFor]="userMenu"
        [class.user-button--active]="trigger.menuOpen"
        #trigger="matMenuTrigger"
        class="user-button"
      >
        <img [src]="myself.avatar" class="user-button__avatar" alt="User avatar" />
      </div>
    </li>
  </ul>
  <!-- Avatar menu -->
  <mat-menu #userMenu="matMenu" class="user-menu">
    <!-- Section -->
    <ng-container *ngFor="let section of avatarNavigation$ | async">
      <div *ngIf="!allNavigationIsHidden(section.nav)" class="user-menu__section">
        <span class="user-menu__section-header" [translate]="section.title"></span>
        <!-- Link -->
        <ng-container *ngFor="let link of section.nav">
          <a
            [attr.data-test]="link.title"
            *ngIf="!link.hidden"
            class="user-menu__section-element"
            [class.disabled]="link.disabled"
            [translate]="link.title"
            (click)="routeTo(link.path)"
          ></a>
        </ng-container>
      </div>
    </ng-container>
  </mat-menu>

  <mat-menu #helpMenu="matMenu" class="user-menu" xPosition="before">
    <div class="user-menu__section">
      <span
        class="user-menu__section-element"
        [class.disabled]="!shouldEnableSection([sections.help, sections.helpTrainingPortal])"
        *ngIf="shouldDisplaySection([sections.help, sections.helpTrainingPortal])"
      >
        <a
          class="settings-section__list__item"
          [class.disabled]="!shouldEnableSection([sections.help, sections.helpTrainingPortal])"
          [href]="sharepointHelpUrl"
          target="_blank"
          translate="WM_HOME.HEADER.TRAINING_PORTAL"
        >
        </a>
      </span>
      <button
        *ngIf="shouldDisplaySection([sections.help, sections.helpProductTour])"
        [disabled]="
          !(hasCurrentProductTours$ | async) ||
          !shouldEnableSection([sections.help, sections.helpProductTour])
        "
        class="user-menu__section-element user-menu__section-button"
        translate="WM_HOME.HEADER.PRODUCT_TOUR"
        (click)="runProductTours()"
      ></button>
      <button
        *ngFor="let customItem of helpMenuCustomItems$ | async"
        class="user-menu__section-element user-menu__section-button"
        (click)="onHelpMenuCustomItemClick(customItem?.id)"
      >
        {{ customItem?.text }}
      </button>
    </div>
  </mat-menu>
</div>

