import { Injectable } from '@angular/core';

import { DONT_SHOW_TOUR_REMINDER_LS_KEY, GuideService, GuideVisibility, TOUR_REMINDER_TOOLTIP_ID } from '@mads/wm-ng-components';
import { ApplicationsFacade } from 'app/state/applications';

@Injectable({
  providedIn: 'root',
})
export class GuideMasterService {
  private guideVisibility: GuideVisibility = {};
  private lockedGuideNameList: Array<string> = [];
  private currentGuideName = '';

  constructor(
    private applicationsFacade: ApplicationsFacade,
    private guideService: GuideService,
  ) {
    this.loadLocked();
  }

  updateVisibility(guideVisibility: GuideVisibility) {
    this.guideVisibility = {...this.guideVisibility, ...guideVisibility};

    this.selectAndStartGuide();
  }

  consumeGuideClosed(guideName: string): void {
    this.lockGuide(guideName);
    this.selectAndStartGuide();
  }

  unlockAndStartGuide(): void {
    const visibleGuideNames = this.getVisibleGuideNames();
    this.lockedGuideNameList = this.lockedGuideNameList.filter((guideName) => !visibleGuideNames.includes(guideName));
    this.saveLocked();

    this.selectAndStartGuide();
  }

  private selectAndStartGuide(): void {
    const visibleGuideNames = this.getVisibleGuideNames();
    const visibleAndUnlocked = visibleGuideNames.filter((guideName) => !this.lockedGuideNameList.includes(guideName));

    this.checkAndPopulateGuideStarter();

    const candidateGuideName = visibleAndUnlocked.length ? visibleAndUnlocked[0] : '';
    this.startGuide(candidateGuideName);

    try {
      const dontShowTourReminderLS: boolean = JSON.parse(localStorage.getItem(DONT_SHOW_TOUR_REMINDER_LS_KEY)) || false;
      if (candidateGuideName === '' && !dontShowTourReminderLS) {
        this.guideService.visibleTooltipId$.next(TOUR_REMINDER_TOOLTIP_ID);
      }
    } catch (e) {
      console.log('WM - Error: Can not parse DONT_SHOW_TOUR_REMINDER_LS_KEY from localStorage', e);
    }
  }

  private startGuide(guideName: string): void {
    if (guideName !== '') {
      this.lockGuide(guideName); // show ones - TODO should bee confirmed with business
      this.applicationsFacade.setHasCurrentProductTours({ hasCurrentProductTours: false });
    }

    if (guideName === this.currentGuideName) { return; }

    this.currentGuideName = guideName;
    this.applicationsFacade.runProductTours(this.currentGuideName);
  }

  private checkAndPopulateGuideStarter(): void {
    const visibleGuideNames = this.getVisibleGuideNames();
    const visibleAndLocked = this.lockedGuideNameList.filter((guideName) => visibleGuideNames.includes(guideName));
    if (!visibleAndLocked.length) { return; }

    this.applicationsFacade.setHasCurrentProductTours({ hasCurrentProductTours: true });
  }

  private loadLocked(): void {
    try {
      this.lockedGuideNameList = JSON.parse(localStorage.getItem('WM_GUIDE_LOCKED')) || [];
    } catch (e) {
      console.log('WM - Error: Can not parse WM_GUIDE_LOCKED from localStorage', e);
    }
  }

  private saveLocked(): void {
    localStorage.setItem('WM_GUIDE_LOCKED', JSON.stringify(this.lockedGuideNameList));
  }

  private getVisibleGuideNames(): Array<string> {
   return Object.keys(this.guideVisibility).filter((guideName) => this.guideVisibility[guideName]); 
  }

  private lockGuide(guideName: string): void {
    if (this.lockedGuideNameList.includes(guideName)) { return; }

    this.lockedGuideNameList.push(guideName);
    this.saveLocked();
  }
}
