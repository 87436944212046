import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { MyNewsResponse, NewsImage } from 'app/state/news-sidebar';


@Injectable()
export class NewsHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getMyNews(payload: { limit: number, market: string }): Observable<MyNewsResponse> {
    const request = this.http.get<MyNewsResponse>(
      `${NewsHttpService.NEWS_API_URL}/news?format=json&market=${payload.market}&limit=${payload.limit}&pinnedFirst=true`,
      NewsHttpService.httpOptions
    );
    return this.handleRequest<MyNewsResponse>(request);
  }

  public getNewsImages(ids: string): Observable<NewsImage[]> {
    const request = this.http.get<NewsImage[]>(
      `${NewsHttpService.NEWS_API_URL}/news/images?ids=${ids}`,
      NewsHttpService.httpOptions
    );
    return this.handleRequest<NewsImage[]>(request);
  }
}
