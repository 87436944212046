import { registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const DATA_STRATEGY_DECK_ROUTE_MATCHER = 'data-strategy-deck';

export const config: MicrofrontendAppConfig = {
  name: 'data-strategy-deck-app',
  windowLibraryName: 'dataStrategyDeckApp',
  assetsPath: environment.dataStrategyDeckAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/data-strategy-deck/',
  appUrl: environment.dataStrategyDeckAppUrl,
  appLocation: environment.dataStrategyDeckAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname([DATA_STRATEGY_DECK_ROUTE_MATCHER]),
};

export const registerDataStrategyDeckApp = (
  customParams?: MicroAppParams
): MicrofrontendAppConfig => registerApp({ ...config, customParams });
