import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerAppsSliderApp } from 'src/single-spa/apps-slider';

@Component({
  selector: 'app-apps-slider-wrapper',
  templateUrl: './apps-slider-wrapper.component.html',
  styleUrls: ['./apps-slider-wrapper.component.scss'],
})
export class AppsSliderWrapperComponent extends MicroAppWrapper {
  protected appName = 'Apps';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerAppsSliderApp(params);
  }
}
