import { Component, ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';

import { registerLoginApp } from 'src/single-spa/login';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { UsersFacade } from 'app/state/users';
import { MicroAppParams, MicroAppWrapper } from '../micro-app-wrapper';

@Component({
  selector: 'app-login-wrapper',
  templateUrl: './login-wrapper.component.html',
})
export class LoginWrapperComponent extends MicroAppWrapper {
  protected appName = 'Login';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef,
    private usersFacade: UsersFacade
  ) {
    super(microAppWrapperService, elementRef);
  }

  private getTargetPath(): Promise<string> {
    return this.usersFacade.targetPath$.pipe(take(1)).toPromise();
  }

  protected async registerFn(params: MicroAppParams): Promise<void> {
    params.customParams['targetPath'] = await this.getTargetPath();
    this.config = registerLoginApp(params);
  }
}
