import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { GeographWrapperComponent } from 'app/modules-wrappers/geograph-wrapper/geograph-wrapper.component';

import { GuideManagerService, WmNgComponentsModule } from '@mads/wm-ng-components';
import { localStorageSync } from 'ngrx-store-localstorage';

import { filter, switchMap, take } from 'rxjs';
import { ClickOutsideModule } from 'ng4-click-outside';
import { LottieModule } from 'ngx-lottie';

// @TODO: do we use Sentry anywhere?
// import * as Sentry from '@sentry/angular';

import { environment } from 'src/environments/environment';
import { reducers, effects, facades } from './state';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { SafeHtmlPipe, AppDatePipe, ErrorImagePipe, GetImageSrcPipe } from './pipes';
import { WelcomePopupModule } from './welcome-popup/welcome-popup.module';

import {
  AddLogoComponent,
  AddLogoDialogComponent,
  WmosLoginComponent,
  BrandsListComponent,
  ClientCreationComponent,
  ClientManagementComponent,
  BrandDrawerComponent,
  PersonalMainComponent,
  PersonalLayoutComponent,
  AppsSliderComponent,
  AppCardComponent,
  SingleTileComponent,
  WorkspaceWidgetComponent,
  ClientsSliderComponent,
  HeaderWrapperComponent,
  HeaderHomeComponent,
  HeaderHomeGuideProjectBuilderComponent,
  HeaderNewsAndAvatarComponent,
  HeaderBreadcrumbsComponent,
  ManagementMarketListComponent,
  MarketDrawerComponent,
  MarketHeaderComponent,
  MarketStatusComponent,
  MarketsManagementComponent,
  NewsSidebarComponent,
  LayoutComponent,
  FullsizeMenuComponent,
  MarketListComponent,
  ClientMenuComponent,
  NoAccessComponent,
  UsersSelectionComponent,
  UserSpaceComponent,
  UserSettingsComponent,
  MaintenanceComponent,
  LoginComponent,
  ErrorComponent,
  ContextualHelpDrawerComponent,
  ContextualHelpPageComponent,
  AddMarketFailurePopupComponent,
} from './components';

import {
  AppsWrapperComponent,
  AppsSliderWrapperComponent,
  AudiencesWrapperComponent,
  BudgetWrapperComponent,
  CampaignsUiWrapperComponent,
  CampaignsSetupWrapperComponent,
  DocumentationWrapperComponent,
  RetailCompassWrapperComponent,
  OsPayWrapperComponent,
  GrowthOpportunitiesWrapperComponent,
  LoginWrapperComponent,
  MarketAdminWrapperComponent,
  NewsWrapperComponent,
  ScorecardsWrapperComponent,
  YouPageWrapperComponent,
  FourMoresWrapperComponent,
  MediaMarketingMixWrapperComponent,
  MediaPlansComparisonWrapperComponent,
  MediaPlansResultsWrapperComponent,
  MediaPlansUploaderWrapperComponent,
  GrowthPathWrapperComponent,
  SitemapWrapperComponent,
  InfluencerViewerWrapperComponent,
  AiCenterWrapperComponent,
} from './modules-wrappers';

import { ProductsStatsWrapperComponent } from './modules-wrappers/products-stats-wrapper/products-stats-wrapper.component';
import { guideTours } from '@config/guide-tour.config';
import { GuideMasterService } from './core/guide-master.service';
import { questionnaireFeatureKey } from './state/questionnaire/questionnaire.model';
import { ClientRequestComponent } from 'app/components/client-request/client-request.component';
import { RequestRejectDialogComponent } from './components/client-request/request-reject-dialog/request-reject-dialog.component';
import { PendingClientComponent } from './components/client-creation/components/pending-client/pending-client.component';
import { WelcomePopupService } from './welcome-popup/welcome-popup.service';
import { WmosLoginPromoComponent } from './components/wmos-login/wmos-login-promo/wmos-login-promo.component';
import { WppOpenThemeService } from './core/wpp-open-theme.service';
import { WppOpenThemeAppenderService } from './core/wpp-open-theme-appender.service';
import { ProductStatsJuly24Component } from './welcome-popup/welcome-popup-components/product-stats-july24/product-stats-july24.component';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { personal: ['dateFormatLocale', 'numberFormatLocale'] },
      { [questionnaireFeatureKey]: ['opened', 'dismissed', 'displayNotification'] },
      { projects: ['projectModeSettings', 'selections'] },
      { users: ['simplifiedBrandMenuOn'] },
    ],
    rehydrate: true,
  })(reducer);
}

export function playerFactory() {
  return import('lottie-web');
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

const devImports = [
  StoreDevtoolsModule.instrument({
    maxAge: 25,
  }),
];

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

function initializeGuideFactory(
  guideManagerService: GuideManagerService,
  guideMasterService: GuideMasterService
): () => void {
  return (): void => {
    guideManagerService.setGuideTours(guideTours);
    guideManagerService.guideVisibility$.subscribe((guideVisibility) => {
      guideMasterService.updateVisibility(guideVisibility);
    });
    guideManagerService.closedGuideName$.subscribe((closedGuideName) => {
      guideMasterService.consumeGuideClosed(closedGuideName);
    });
  };
}

const guideProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeGuideFactory,
  deps: [GuideManagerService, GuideMasterService],
  multi: true,
};

@NgModule({
    declarations: [
        AddLogoComponent,
        AddLogoDialogComponent,
        AppComponent,
        BrandDrawerComponent,
        BrandsListComponent,
        ClientCreationComponent,
        ClientManagementComponent,
        LayoutComponent,
        HeaderWrapperComponent,
        HeaderHomeComponent,
        HeaderHomeGuideProjectBuilderComponent,
        HeaderBreadcrumbsComponent,
        HeaderNewsAndAvatarComponent,
        WmosLoginComponent,
        WmosLoginPromoComponent,
        AudiencesWrapperComponent,
        YouPageWrapperComponent,
        AppsWrapperComponent,
        AppsSliderWrapperComponent,
        BudgetWrapperComponent,
        CampaignsUiWrapperComponent,
        CampaignsSetupWrapperComponent,
        DocumentationWrapperComponent,
        RetailCompassWrapperComponent,
        OsPayWrapperComponent,
        MediaMarketingMixWrapperComponent,
        GrowthOpportunitiesWrapperComponent,
        MarketAdminWrapperComponent,
        LoginWrapperComponent,
        ManagementMarketListComponent,
        MarketDrawerComponent,
        MarketHeaderComponent,
        MarketStatusComponent,
        MarketsManagementComponent,
        ScorecardsWrapperComponent,
        NewsWrapperComponent,
        SafeHtmlPipe,
        AppDatePipe,
        ErrorImagePipe,
        AppsSliderComponent,
        AppCardComponent,
        PersonalMainComponent,
        PersonalLayoutComponent,
        SingleTileComponent,
        WorkspaceWidgetComponent,
        ClientsSliderComponent,
        FullsizeMenuComponent,
        ClientMenuComponent,
        MarketListComponent,
        NewsSidebarComponent,
        NoAccessComponent,
        UsersSelectionComponent,
        UserSpaceComponent,
        UserSettingsComponent,
        MaintenanceComponent,
        LoginComponent,
        ErrorComponent,
        ContextualHelpPageComponent,
        ContextualHelpDrawerComponent,
        FourMoresWrapperComponent,
        AddMarketFailurePopupComponent,
        MediaPlansComparisonWrapperComponent,
        MediaPlansResultsWrapperComponent,
        MediaPlansUploaderWrapperComponent,
        GetImageSrcPipe,
        GrowthPathWrapperComponent,
        SitemapWrapperComponent,
        ProductsStatsWrapperComponent,
        GeographWrapperComponent,
        InfluencerViewerWrapperComponent,
        ClientRequestComponent,
        RequestRejectDialogComponent,
        PendingClientComponent,
        AiCenterWrapperComponent,
        ProductStatsJuly24Component
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        EffectsModule.forRoot(effects),
        ClickOutsideModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        ...(environment.production ? [] : devImports),
        WmNgComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        HttpClientModule,
        WelcomePopupModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [WmNgComponentsModule],
    providers: [
        ...facades,
        guideProvider,
        WelcomePopupService,
        // TODO: do we use Sentry anywhere?
        // {
        //   provide: ErrorHandler,
        //   useValue: Sentry.createErrorHandler({
        //     showDialog: false,
        //   }),
        // },
        // {
        //   provide: Sentry.TraceService,
        //   deps: [Router],
        //   useValue: undefined
        // },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: () => () => {}, // TODO: ??
        //   deps: [Sentry.TraceService],
        //   multi: true,
        // },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly wppOpenThemeService: WppOpenThemeService,
    private readonly wppOpenThemeAppenderService: WppOpenThemeAppenderService
  ) {
    this.wppOpenThemeService
      .loadTheme()
      .pipe(
        switchMap(() =>
          this.wppOpenThemeService.getFlatTheme().pipe(
            filter((theme) => !!theme),
            take(1)
          )
        )
      )
      .subscribe((flatTheme) => {
        this.wppOpenThemeAppenderService.appendTheme(flatTheme);
      });
  }
}
