import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { constants } from '@config/constants';

import { ModeService } from 'app/core/mode.service';
import { NavigationService } from 'app/core/navigation.service';
import { TrackerService } from 'app/core/tracker.service';
import { NavigationTrackerPayload } from '@config';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent extends OnDestroyMixin implements OnInit {
  public currentRoute: string;
  public dashboardNav$ = this.navigationService.dashboardNavigation$;
  public currentLogoPath$ = this.modeService.currentLogoPath$;
  public currentMode = this.modeService.currentMode;
  public constants = constants;

  // variables for Keycloak authorization - will be removed
  public isAuthenticated: boolean;
  public userData$: Observable<any>;
  public hasAccessToInfo = false;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private trackerService: TrackerService,
    private modeService: ModeService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        this.currentRoute = this.router.url;
      });
  }

  public isPathActive(path: string): boolean {
    if (path === '/') {
      return this.currentRoute === path;
    }

    const activeModule = path.split('/')[1];

    return this.currentRoute.includes(activeModule);
  }

  public redirectWithChildAppReload(uri:string): void {
    /* when user starts inside single-spa child and clicks nav link leading to the same app -
    url changes but router does not work. Mother app thinks that only child route changed. Child
    doesn't know that something happened, routing happened in mother. Possibly related to
    https://single-spa.js.org/docs/ecosystem-angular/#configure-routes
    anyway, this works, its good enough:
    */
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
  }

  public callTracker(tracker: NavigationTrackerPayload): void {
    if(!tracker) return;

    this.trackerService.trackAction(tracker);
  }
}
