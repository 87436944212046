<div #modal class="wrapper">
  <wm-wrapper>
    <div class="content" [style.background-image]="'url(/assets/img/content-survey-background.png)'">
      <div class="close-button-wrapper">
        <img src="assets/icons/x-white.svg" (click)="close($event)">
      </div>
      <!-- <p class="wm-m-500 side-text">Content</p> commented out on design team request -->
      <p class="wm-l-500 greeting-text">Hi {{userName$ | async}},</p>
      <p class="wm-l-400 explanation-text">We would be delighted if you could help us to create a new tool by answering a couple of questions.</p>
      <div class="buttons">
        <button
          (click)="goToQuestionnaire()"
          mat-button
          class="wm-button-primary">
          Yes I'll help!
        </button>
        <p class="wm-s-500 dismiss-button" (click)="dismiss($event)">
          I can't right now
        </p>
      </div>
    </div>
  </wm-wrapper>
</div>


<!-- this was provoke the brief questionnaire (left as example):
<div class="close-button-wrapper">
  <img src="assets/icons/x.svg" (click)="close($event)">
</div>
<img
  src="assets/img/begging-wavy.svg"
  class="wavy" />
<p class="wm-cta greeting-text">Hey {{userName$ | async}},</p>
<p class="wm-caption explanation-text">You have been selected to answer some questions about an important new application. Help us by completing a quick survey. We will use the feedback to make the new product as great as we can, thanks for your support.</p>
<p class="wm-caption signing-text">Wavemaker Development Team</p>
<button
  (click)="goToQuestionnaire()"
  mat-button
  class="wm-button-primary">
  Take a survey
</button>
<p class="wm-caption dismiss-button" (click)="dismiss($event)">
  Do not show again
</p>
-->