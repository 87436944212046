import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'touchpoints-ui-app',
  windowLibraryName: 'touchpointsUIApp',
  assetsPath: environment.touchpointsUIAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/touchpoints-ui',
  appUrl: environment.touchpointsUIAppUrl,
  appLocation: environment.touchpointsUIAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['touchpoints-ui']),
};

export const registerTouchpointsUIApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
