import { BreadcrumbsDetail } from '@mads/wm-ng-components';

export interface Breadcrumbs {
  category?: string;
  app?: string;
  section?: string;
  details?: BreadcrumbsDetail[];
  text?: string;
  hideSection?: boolean;
}

export interface BreadcrumbsState {
  breadcrumbs: Breadcrumbs;
}

export const initialState: BreadcrumbsState = {
  breadcrumbs: null,
};

export const breadcrumbsFeatureKey = 'breadcrumbs';
