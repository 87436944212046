import { matchingGlobalPathname, MicrofrontendAppConfig, registerApp } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'market-admin-app',
  windowLibraryName: 'marketAdminApp',
  assetsPath: environment.marketAdminAppUrl.replace('main.js', '').substr(1),
  baseHref: '/market-admin',
  appUrl: environment.marketAdminAppUrl,
  matchingStrategy: matchingGlobalPathname(['/market-admin']),
};

export const registerMarketAdminApp = (customParams: Object) => registerApp({ ...config, customParams });
