<div class="wrapper">
  <div class="content">
    <app-entity-menu-header [entity]="brand" [mode]="'project-builder'">
      <div class="info">
        <div class="section">
          <h5 class="title" translate="WM_HOME.HEADER.CATEGORIES"></h5>
          <ul *ngIf="brand.categories" class="list list--categories">
            <li *ngFor="let category of brand.categories" class="label">
              {{ category.name }}
            </li>
          </ul>
        </div>
        <div class="section">
          <h5 class="title" translate="WM_HOME.HEADER.WEBSITE"></h5>
          <a *ngIf="brand.websiteUrl" [href]="brand.websiteUrl" data-test="WM_HOME.HEADER.WEBSITE">
            {{ brand.websiteUrl }}
          </a>
        </div>
        <div class="section">
          <h5 class="title" translate="WM_HOME.HEADER.SOCIAL_MEDIA"></h5>
          <ul *ngIf="brand.socialMediaLinks" class="list" data-test="WM_HOME.HEADER.SOCIAL_MEDIA">
            <li *ngFor="let media of brand.socialMediaLinks">
              <a [href]="media.url">{{ media.name }}</a>
            </li>
          </ul>
        </div>
      </div>

      <i-feather
        *ngIf="brand.isClientAdmin"
        name="edit-3"
        class="edit-button"
        editButton
        [routerLink]="['/clients/client-creation', brand.clientId, 'markets-management']"
        [queryParams]="{ open: true, activeMarket: brand.country.code, brandId: brand.id, drawerMode: DrawerMode.BRAND }"
      ></i-feather>
    </app-entity-menu-header>
    <div class="breakline-header" *ngIf="(projects$ | async) as projects">
      <div class="project-name-or-select-and-button">
        <p class="wm-500-l breakline-header__title" *ngIf="projects.length === 1 else selectProject">
          {{projects[0].name}}
        </p>
        <ng-template #selectProject>
          <div>
            <label
            class="wm-m-400"
            translate="WM_HOME.PROJECT_BUILDER.NAME"
            ></label>
            <ng-select
              class="wm-select wm-home-project-builder-select"
              [(ngModel)]="selectedProject"
              [items]="projects$ | async"
              [clearable]="false"
              (change)="onSelectProject($event)"
              bindLabel="name"
              >
            </ng-select>
          </div>
        </ng-template>
        <button
          mat-stroked-button
          class="wm-button-secondary wm-button-secondary--big goddamn-button"
          (click)="goToProjectBuiler()"
          translate="WM_HOME.NAVIGATION_MAIN.GO_TO_PROJECT_BUILDER"
        ></button>
      </div>
      <button
        mat-stroked-button
        class="wm-button-secondary wm-button-secondary--big goddamn-button"
        (click)="handleGoToCart()"
        translate="WM_HOME.NAVIGATION_MAIN.CART"
      ></button>
    </div>

    <div class="guide-pb-welcome" *ngIf="selectedProject?.columns.length">
      <wm-guide-step
        [guideName]="'PROJECT_BUILDER_MODE_DRAWER'"
        [stepName]="'welcome'"
        >
      </wm-guide-step>
    </div>
    <div class="guide-pb-tasks">
      <wm-guide-step
        [guideName]="'PROJECT_BUILDER_MODE_DRAWER'"
        [stepName]="'tasks'"
        >
        <div class="guide-pb-tasks--section" *ngFor="let section of guideTasksFrameCfg">
          <div class="guide-pb-tasks--frame">
            <div style="position: relative">
              <span *ngIf="section.comingUp" class="exclamation-mark deadline-approaching">!</span>
              <span *ngIf="section.passed" class="exclamation-mark deadline-passed">!!</span>
            </div>
            <span translate="WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_FRAME"></span>
            <div class="your-task-marker" [style.backgroundColor]="getColor(0)">
              <span class="wm-xs-400" translate="WM_HOME.NAVIGATION_MAIN.YOUR_TASK"></span>
              <img *ngIf="section.done" src="assets/icons/done.svg" alt="" />
            </div>
          </div>
          <div class="guide-pb-tasks--description">
            <span class="guide-pb-tasks--dot"></span>
            <span>{{ section.description | translate }}</span>
          </div>
        </div>
      </wm-guide-step>
    </div>
    <div class="columns" [style.grid-template-columns]="'repeat(' + columnsHorizontalAmount + ', auto)'">
      <div class="column" *ngFor="let column of selectedProject.columns; let idx = index">
        <ng-container *ngIf="getColor(idx) as color">
          <p class="wm-m-400 column-title underlined" [style.color]="color" [style.border-color]="color">{{column.name}}</p>
          <p class="wm-xs-300 column-title">{{column.description}}</p>
          <p *ngFor="let task of column.tasks"><a
            class="wm-m-400 task"
            [href]="task.link || (projectLink + task.id)"
            [class.my-task]="myTasks?.[task.id]"
            target="_blank">
            <span
              class="exclamation-mark deadline-approaching"
              *ngIf="deadlinesApproachingOfTasks?.[task.id] && (myTasks?.[task.id] || isMyProject)"
              [wmTooltip]="'WM_HOME.PROJECT_BUILDER.DEADLINE_COMING_UP' | translate">
              !
            </span>
            <span
              class="exclamation-mark deadline-passed"
              *ngIf="deadlinesPassedOfTasks?.[task.id] && (myTasks?.[task.id] || isMyProject)"
              [wmTooltip]="'WM_HOME.PROJECT_BUILDER.DEADLINE_PASSED' | translate">
              !!
            </span>
            {{task.name}}
            <div
              *ngIf="myTasks?.[task.id]"
              class="your-task-marker"
              [style.backgroundColor]="color">
              <span class="wm-xs-400" translate="WM_HOME.NAVIGATION_MAIN.YOUR_TASK"></span>
              <img *ngIf="task.status === STATUS_DONE" src="assets/icons/done.svg" alt="" />
            </div>
          </a></p>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="mode-marking">
    <p class="mirrored-font" translate="WM_HOME.PROJECT_BUILDER.MODE_NAME"></p>
    <p translate="WM_HOME.PROJECT_BUILDER.MODE_NAME"></p>
  </div>
</div>
