import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as singleSpa from 'single-spa';
import { getSingleSpaExtraProviders } from 'single-spa-angular';

/* TODO: Temporary unavailable until move sentry to separate pod
Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.sentryEnvironment,
  release: environment.sentryRelease,
  autoSessionTracking: true,
  enabled: environment.production,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://wmos.hygienetools.com/_apps', 'https://os.wmglobal.com/_apps', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
});*/

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(getSingleSpaExtraProviders())
  .bootstrapModule(AppModule)
  .then(() => singleSpa.start({ urlRerouteOnly: true }))
  .catch((err) => console.error(err));
