import { Component, Input } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { map } from 'rxjs';
import { ModeCodes } from '@mads/wm-ng-components';

import { ProjectsFacade } from 'app/state/projects';
import { UsersFacade } from 'app/state/users';
import { getHasCanAddBrand } from 'app/components/client-creation/components/brands-list/utils';

@Component({
  selector: 'app-entity-menu-header',
  templateUrl: './entity-menu-header.component.html',
  styleUrls: ['./entity-menu-header.component.scss'],
})
export class EntityMenuHeaderComponent {
  @Input() entity: any; // this is Brand | Client but conditional type confuses angular template and causes error
  @Input() mode: ModeCodes | 'project-builder';

  hasCanAddBrand$ = this.usersFacade.myself$.pipe(map(getHasCanAddBrand));
  public infoExpanded = false;
  public isProjectModeOn$ = this.projectsFacade.projectModeOn$;
  public shouldDisplayProjectModeToggle$ =
    this.projectsFacade.areThereprojectsForCurrentBrand$.pipe(
      map((projectsExist) => this.entity.clientId && projectsExist) // check if not on client view
    );

  constructor(private projectsFacade: ProjectsFacade, private usersFacade: UsersFacade) {}

  public toggleInfo(): void {
    this.infoExpanded = !this.infoExpanded;
  }

  public setProjectMode(event: MatSlideToggleChange): void {
    this.projectsFacade.setProjectMode({ brandId: this.entity.id, modeOn: event.checked });
  }
}
