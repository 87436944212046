import { Pipe, PipeTransform } from '@angular/core';
import { NewsArticle } from 'app/state/news-sidebar';

@Pipe({
  name: 'getImageSrc',
})
export class GetImageSrcPipe implements PipeTransform {
  public transform(news: NewsArticle): string | null {
    const image =  news?.imageObject?.image;
    return image ? `url('${image}')` : null;
  }
}

