import { Component, ElementRef } from '@angular/core';

import { registerMediaPlansUploaderApp } from 'src/single-spa/mp-uploader';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface MediaPlansUploaderAppState {
  pristine: boolean;
}

const appState: MediaPlansUploaderAppState = { pristine: true };

@Component({
  selector: 'app-mp-uploader-wrapper',
  templateUrl: './mp-uploader-wrapper.component.html',
  styleUrls: ['./mp-uploader-wrapper.component.scss'],
})
export class MediaPlansUploaderWrapperComponent extends MicroAppWrapper {
  protected appName = 'MediaPlansUploader';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: MediaPlansUploaderAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerMediaPlansUploaderApp(params);
  }
}
