<div class="content">
  <div class="wm-7xl-900 title">
    <span class="primary">Live Audiences</span> is now widely available!
  </div>
  <div class="wm-l-400">
    Get a holistic view of potential audiences using real-time behavioural data from
    <span class="strong">Google Audience Insights.</span>
  </div>
  <div  class="wm-l-400">
    More partner data coming soon.
  </div>
</div>
