import { Component, ElementRef } from '@angular/core';

import { registerFourMoresApp } from 'src/single-spa/4mores';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-four-mores-wrapper',
  templateUrl: './4mores-wrapper.component.html',
  styleUrls: ['./4mores-wrapper.component.scss'],
})
export class FourMoresWrapperComponent extends MicroAppWrapper {
  protected appName = 'Source of growth';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerFourMoresApp(params);
  }
}
