import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User, UsersFacade } from 'app/state/users';
import { constants } from '@config/constants';
import { ModeService } from 'app/core/mode.service';
import { AnimationOptions } from 'ngx-lottie';
import { wmosLoginPromoConfig } from '@config/wmos-login-promo.config';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-wmos-login',
  templateUrl: './wmos-login.component.html',
  styleUrls: ['./wmos-login.component.scss'],
})
export class WmosLoginComponent implements OnInit, AfterViewInit {
  @ViewChild('loginContainer') loginContainer: ElementRef<HTMLDivElement>;
  @ViewChild('popup') popup: ElementRef<HTMLElement>;
  @ViewChild('wmLogo') wmLogo: ElementRef<HTMLImageElement>;
  @ViewChild('waveAnimation', { static: true }) waveAnimation: ElementRef<HTMLVideoElement>;

  public showPromo = this.getShowPromo();
  public currentLogoPath$ = this.modeService.currentLogoPath$;
  public loading$: Observable<boolean>;
  public myself$: Observable<User>;
  public animationOptions: AnimationOptions = {};
  private targetPath: string;

  constructor(
    private usersFacade: UsersFacade,
    private modeService: ModeService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {}

  ngAfterViewInit(): void {
    const loginContainer = this.loginContainer.nativeElement;
    const formElement = this.popup.nativeElement;
    const wmLogo = this.wmLogo.nativeElement;
    this.waveAnimation.nativeElement.oncanplaythrough = () => {
      this.waveAnimation.nativeElement.muted = true;
      this.waveAnimation.nativeElement.play();
    };
    setTimeout(() => loginContainer.classList.add('on'), 1);
    setTimeout(() => wmLogo.classList.add('on'), 2100);
    setTimeout(() => formElement.classList.add('on'), 5700);
  }

  ngOnInit() {
    this.loading$ = this.usersFacade.loading$;
    this.myself$ = this.usersFacade.myself$;
    this.usersFacade.targetPath$.pipe(take(1)).subscribe((path) => {
      this.targetPath = path;
    });
  }

  login(): void {
    if (!environment.production) {
      // on localhost we just load HTUser mock into state to log in
      return this.usersFacade.getMyself();
    }
    const targetPathSuffix = this.targetPath ? `&targetPath=${this.targetPath}` : '';

    if (
      (targetPathSuffix === '' || this.targetPath === '/') &&
      !localStorage.getItem(constants.YOU_V2_CONSTANTS.LOCAL_STORAGE_ALLOW_YOU_V2_ANIMATION)
    ) {
      localStorage.setItem(constants.YOU_V2_CONSTANTS.LOCAL_STORAGE_ALLOW_YOU_V2_ANIMATION, 'true');
    }

    const url = `${environment.authAppUrl}/login/${environment.appName}?instantRedirect=true${targetPathSuffix}`;
    this.document.location.href = url;
  }

  private getShowPromo(): boolean {
    if (isNil(wmosLoginPromoConfig)) {
      return false;
    }

    const now = new Date();
    const start = new Date(wmosLoginPromoConfig.dates.start);
    const end = new Date(wmosLoginPromoConfig.dates.end);

    return now >= start && now <= end;
  }
}
