import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import * as fromQuestionnaireActions from './questionnaire.actions';
import * as fromQuestionnaireSelectors from './questionnaire.selectors';
@Injectable()
export class QuestionnaireFacade {
  public opened$ = this.store.select(fromQuestionnaireSelectors.selectOpened);
  public dismissed$ = this.store.select(fromQuestionnaireSelectors.selectDismissed);
  public displayNotification$ = this.store.select(fromQuestionnaireSelectors.selectDisplayNotification);
  public runAnimationPosition$ = this.store.select(fromQuestionnaireSelectors.selectRunAnimationPosition);

  constructor(private store: Store<AppState>) { }

  public open(): void {
    this.store.dispatch(fromQuestionnaireActions.open());
  }

  public dismiss(): void {
    this.store.dispatch(fromQuestionnaireActions.dismiss());
  }

  public displayNotification(): void {
    this.store.dispatch(fromQuestionnaireActions.displayNotification());
  }

  public showAnimation(position: { x: number, y: number }): void {
    this.store.dispatch(fromQuestionnaireActions.showAnimation({ position }));
  }

}
