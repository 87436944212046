import { Component, Input } from '@angular/core';

import { Market } from 'app/state/clients';

@Component({
  selector: 'app-market-status',
  templateUrl: './market-status.component.html',
  styleUrls: ['./market-status.component.scss'],
})
export class MarketStatusComponent {
  @Input() status: Market['status'];
  @Input() text?: string;
}
