import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AudiencesWrapperComponent } from './audiences-wrapper.component';
import { ConfirmationPopupComponent } from 'app/shared/components';
import { ConfirmationPopupConfig } from 'app/shared/components/confirmation-popup/confirmation-popup.config';

@Injectable({ providedIn: 'root' })
export class CanDeactivateAudiences implements CanDeactivate<AudiencesWrapperComponent> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(
    component: AudiencesWrapperComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (nextState.url.includes('audiences') || component.appState.pristine) {
      return true;
    }

    const config: MatDialogConfig<ConfirmationPopupConfig> = {
      width: '500px',
      data: {
        body: [
          'WM_HOME.MICROFRONTENDS.GO_BACK_CONFIRMATION_TOP',
          'WM_HOME.MICROFRONTENDS.GO_BACK_CONFIRMATION_BOTTOM',
        ],
        confirmation: 'WM_HOME.MICROFRONTENDS.GO_BACK_YES',
        cancellation: 'WM_HOME.MICROFRONTENDS.GO_BACK_NO',
        extraInfo: 'WM_HOME.MICROFRONTENDS.GO_BACK_HINT',
      },
    };

    this.dialog.closeAll();

    const dialogRef = this.dialog
      .open<ConfirmationPopupComponent, ConfirmationPopupConfig, boolean>(
        ConfirmationPopupComponent,
        config
      );

    return dialogRef.afterClosed()
      .pipe(tap(result => component.appState.pristine = !!result));
  }
}
