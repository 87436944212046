import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LottieModule } from 'ngx-lottie';

import { WelcomePopupComponent } from './welcome-popup.component';
import { WelcomePopupService } from './welcome-popup.service';
import { WmNgComponentsModule } from '@mads/wm-ng-components';

function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [WelcomePopupComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    WmNgComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
})
export class WelcomePopupModule {
  constructor(private welcomePopupService: WelcomePopupService) {
    this.welcomePopupService.init().subscribe();
  }
}
