import { Component, ElementRef } from '@angular/core';

import { registerNewsApp } from 'src/single-spa/news';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface NewsAppState {
  pristine: boolean;
}

const appState: NewsAppState = { pristine: true };

@Component({
  selector: 'app-news--wrapper',
  templateUrl: './news-wrapper.component.html',
  styleUrls: ['./news-wrapper.component.scss'],
})
export class NewsWrapperComponent extends MicroAppWrapper {
  protected appName = 'News';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: NewsAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerNewsApp(params);
  }
}
