import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { SnackbarService } from 'app/core/snackbar.service';
import { ClientsHttpService } from 'app/core/http/clients-http.service';

import { changeRoute } from 'app/state/router';
import * as fromClients from './clients.actions';

import { defaults } from '@config/defaults';
import { notifications, snackbarConfig } from 'app/config/notifications';
import { EMPTY, interval, of, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// needs to be deep import because of circular references
import { AddMarketFailurePopupComponent } from '../../components/client-creation/components/add-market-failure-popup/add-market-failure-popup.component';
import { ClientsFacade } from './clients.facade';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ClientsEffects {
  public defaults = defaults;
  private pollingDestroy$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private http: ClientsHttpService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private clientsFacade: ClientsFacade
  ) {}

  getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.getClients),
      switchMap(() =>
        this.http.getClients().pipe(
          map((clients) => fromClients.getClientsSuccess({ clients })),
          catchError((err) => [fromClients.getClientsFail(err)])
        )
      )
    )
  );

  getFilteredClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.getFilteredClients),
      switchMap(({ clientName }) =>
        (clientName ? this.http.getFilteredClients(clientName) : of([])).pipe(
          map((clients) => fromClients.getFilteredClientsSuccess({ clients })),
          catchError((err) => [fromClients.getFilteredClientsFail(err)])
        )
      )
    )
  );

  getClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.getClient),
      withLatestFrom(this.clientsFacade.clients$),
      switchMap(([{ id }, clients]) => {
        const cachedClient = clients.find((client) => client.id === id);
        if (cachedClient) {
          return of(fromClients.getClientSuccess({ client: cachedClient }));
        }
        return this.http.getClient({ id }).pipe(
          map((client) => fromClients.getClientSuccess({ client })),
          catchError((error) => of(fromClients.getClientFail({ error })))
        );
      })
    )
  );

  getClientWithDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.getClientWithDetails),
      switchMap(({ id }) =>
        this.http.getClientWithDetails({ id }).pipe(
          mergeMap((client) => {
            const pendingMarket = client.markets.find(
              (market) =>
                market.isClientAdmin && market.creationStatus === defaults.creationStatus.PENDING
            );

            return !pendingMarket
              ? [fromClients.getClientSuccess({ client })]
              : [
                  fromClients.getClientSuccess({ client }),
                  fromClients.pollingMarket({
                    clientId: client.id,
                    marketCode: pendingMarket.code,
                  }),
                ];
          }),
          catchError((err) => of(fromClients.getClientFail(err)))
        )
      )
    )
  );

  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.createClient),
      switchMap(({ client }) =>
        this.http.createClient(client).pipe(
          mergeMap((resClient) => [
            fromClients.createClientSuccess({ client: resClient }),
            changeRoute({
              linkParams: ['clients', 'client-creation', resClient.id, 'pending'],
            }),
          ]),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 409) {
              this.snackbarService.showError('A client with that name already exists.');

              return of(fromClients.createClientFail(err));
            }

            this.snackbarService.showError('A client creation request failed');

            return [fromClients.createClientFail(err), changeRoute({ linkParams: ['/'] })];
          })
        )
      )
    )
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.updateClient),
      switchMap(({ client, id }) =>
        this.http.updateClient({ client, id }).pipe(
          mergeMap((resClient) => [
            fromClients.updateClientSuccess({ client: resClient }),
            changeRoute({
              linkParams: ['clients', 'client-creation', resClient.id, 'markets-management'],
            }),
          ]),
          catchError((err) => [fromClients.updateClientFail(err)])
        )
      )
    )
  );

  createMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.createMarket),
      switchMap(({ createMarketPayload }) => {
        const { clientId, newMarket } = createMarketPayload;
        return this.http.createMarket(clientId, newMarket).pipe(
          map(() =>
            fromClients.pollingMarket({
              clientId,
              marketCode: newMarket.code,
            })
          ),
          catchError((err) => {
            if (err.status !== 409) {
              return of(fromClients.createMarketFail(err));
            }

            const dialogRef = this.dialog.open(AddMarketFailurePopupComponent);

            return dialogRef.afterClosed().pipe(map(() => fromClients.createMarketFail(err)));
          })
        );
      })
    )
  );

  updateMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.updateMarket),
      switchMap(({ clientId, market }) =>
        this.http.updateMarket(clientId, market).pipe(
          map(() => fromClients.updateMarketSuccess({ market })),
          catchError((err) => [fromClients.updateMarketFail(err)])
        )
      )
    )
  );

  recreateMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.recreateMarket),
      switchMap(({ clientId, market }) =>
        this.http.recreateMarket(clientId, market).pipe(
          map(() =>
            fromClients.pollingMarket({
              clientId,
              marketCode: market.code,
            })
          ),
          catchError((err) => of(fromClients.recreateMarketFail(err)))
        )
      )
    )
  );

  pollingMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.pollingMarket),
      switchMap(({ clientId, marketCode }) =>
        interval(10000).pipe(
          takeUntil(this.pollingDestroy$),
          switchMap(() =>
            this.http.getMarket(clientId, marketCode).pipe(
              switchMap((market) => {
                if (market.creationStatus === defaults.creationStatus.PENDING) {
                  return EMPTY;
                }

                this.pollingDestroy$.next(true);
                return [
                  fromClients.pollingMarketSuccess({ market }),
                  fromClients.getClientWithDetails({ id: clientId }),
                ];
              }),
              catchError(() => EMPTY)
            )
          )
        )
      )
    )
  );

  sendMarketRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.sendMarketRequest),
      switchMap(({ clientId, countryCode }) =>
        this.http.sendRequestForMarket({ clientId, countryCode }).pipe(
          map(() => {
            this.snackbarService.show({
              message: notifications.sendRequest.success,
            });

            return fromClients.sendMarketRequestSuccess();
          }),
          catchError((err) => {
            this.snackbarService.show({
              message: notifications.sendRequest.error,
              panelClass: snackbarConfig.types.error,
            });

            return [fromClients.sendMarketRequestFail(err)];
          })
        )
      )
    )
  );

  getCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.getCategories),
      switchMap(() =>
        this.http.getCategories().pipe(
          map((categories) => fromClients.getCategoriesSuccess({ categories })),
          catchError((err) => [fromClients.getCategoriesFail(err)])
        )
      )
    )
  );

  acceptClientCreationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.acceptClientCreationRequest),
      switchMap(({ id }) =>
        this.http.acceptClientCreationRequest({ id }).pipe(
          map(() => {
            return fromClients.acceptClientCreationRequestSuccess(null);
          }),
          catchError((err) => {
            return of(fromClients.acceptClientCreationRequestFailure(err));
          })
        )
      )
    )
  );

  rejectClientCreationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromClients.rejectClientCreationRequest),
      switchMap(({ id, rejectReason }) =>
        this.http.rejectClientCreationRequest({ id, rejectReason }).pipe(
          map(() => fromClients.rejectClientCreationRequestSuccess()),
          catchError((err) => {
            return of(fromClients.rejectClientCreationRequestFailure(err));
          })
        )
      )
    )
  );
}
