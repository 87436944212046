import { Action, createReducer, on } from '@ngrx/store';

import { map, uniqBy } from 'lodash-es';

import { countries } from '@config/countries';

import { initialState, ClientsState, CLIENT_CREATION_RESPONSE_TYPE } from './clients.model';
import * as fromClients from './clients.actions';

const reducer = createReducer(
  initialState,
  on(
    fromClients.getClients,
    fromClients.getFilteredClients,
    fromClients.getClient,
    fromClients.getClientWithDetails,
    fromClients.createMarket,
    fromClients.updateMarket,
    fromClients.recreateMarket,
    (state) => ({
      ...state,
      loading: true,
      error: initialState.error,
    })
  ),
  on(
    fromClients.getClientsFail,
    fromClients.getFilteredClientsFail,
    fromClients.getClientFail,
    fromClients.getClientWithDetailsFail,
    fromClients.createMarketFail,
    fromClients.updateMarketFail,
    fromClients.recreateMarketFail,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    fromClients.getClientsSuccess,
    fromClients.getFilteredClientsSuccess,
    (state, { clients }) => ({
      ...state,
      clients,
      loading: false,
    })
  ),
  on(fromClients.resetClients, (state) => ({ ...state, clients: initialState.clients })),
  on(fromClients.resetError, (state) => ({ ...state, error: initialState.error })),
  on(
    fromClients.getClientSuccess,
    fromClients.getClientWithDetailsSuccess,
    fromClients.createClientSuccess,
    fromClients.updateClientSuccess,
    (state, { client }) => {
      const selectedClient = {
        ...client,
        markets: map(client.markets, (country) => {
          if (countries[country.code]) {
            return { ...country, region: countries[country.code].region };
          }

          return country;
        }),
      };

      // we want to add singularily downloaded client to clients for caching purposes:
      const clients = uniqBy([
        ...state.clients,
        selectedClient,
      ], 'id');

      return {
        ...state,
        selectedClient,
        clients,
        loading: false,
      };
    }
  ),
  on(fromClients.pollingMarket, (state) => ({ ...state, loading: false, isPollingActive: true })),
  on(
    fromClients.pollingMarketSuccess,
    fromClients.updateMarketSuccess,
    (state, { market }) => {
      const clientMarkets = state.selectedClient.markets;
      const isUpdate = clientMarkets.some((clientMarket) => clientMarket.code === market.code);
      const markets = isUpdate
        ? clientMarkets.map((clientMarket) =>
          clientMarket.code === market.code ? market : clientMarket
        )
        : [...clientMarkets, market];

      return {
        ...state,
        selectedClient: { ...state.selectedClient, markets },
        loading: false,
        isPollingActive: false,
      };
    }
  ),
  on(fromClients.resetClient, (state) => ({ ...state, loading: false, selectedClient: null })),
  on(fromClients.getCategoriesSuccess, (state, { categories }) => ({ ...state, categories })),
  on(fromClients.acceptClientCreationRequest, (state) => ({
    ...state,
    clientCreationRequestState: {
      loading: true,
      error: null,
      type: CLIENT_CREATION_RESPONSE_TYPE.APPROVE,
      success: false,
    }
  })),
  on(fromClients.acceptClientCreationRequestSuccess, (state) => ({
    ...state,
    clientCreationRequestState: {
      ...state.clientCreationRequestState,
      loading: false,
      error: null,
      success: true,
    }
  })),
  on(fromClients.acceptClientCreationRequestFailure, (state, { error }) => ({
    ...state,
    clientCreationRequestState: {
      ...state.clientCreationRequestState,
      loading: false,
      success: false,
      error,
    }
  })),
  on(fromClients.rejectClientCreationRequest, (state) => ({
    ...state,
    clientCreationRequestState: {
      loading: true,
      error: null,
      type: CLIENT_CREATION_RESPONSE_TYPE.REJECT,
      success: false,
    }
  })),
  on(fromClients.rejectClientCreationRequestSuccess, (state) => ({
    ...state,
    clientCreationRequestState: {
      ...state.clientCreationRequestState,
      loading: false,
      error: null,
      success: true,
    }
  })),
  on(fromClients.rejectClientCreationRequestFailure, (state, { error }) => ({
    ...state,
    clientCreationRequestState: {
      ...state.clientCreationRequestState,
      loading: false,
      success: false,
      error,
    }
  })),
);

export function clientsReducer(state: ClientsState | undefined, action: Action): ClientsState {
  return reducer(state, action);
}
