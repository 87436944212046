import { createAction, props } from '@ngrx/store';
import { HelpMenuCustomItem } from '@mads/wm-ng-components';
import { Application } from './applications.model';

export const getApplication = createAction(
  '[Applications] get application',
  props<{ id: string }>()
);
export const getApplicationSuccess = createAction(
  '[Applications] get application success',
  props<{ application: Application }>()
);
export const getApplicationFailure = createAction(
  '[Applications] get application failure',
  props<{ error: any; id: string }>()
);

export const setHasCurrentProductTours = createAction(
  '[Applications] set has current product tour',
  props<{ hasCurrentProductTours: boolean }>()
);

export const runProductTours = createAction(
  '[Applications] run product tours',
  props<{ guideName: string | void }>()
);

export const addHelpMenuCustomItem = createAction(
  '[Applications] add help menu custom item',
  props<{ helpMenuCustomItem: HelpMenuCustomItem }>()
);

export const removeHelpMenuCustomItem = createAction(
  '[Applications] remove help menu custom item',
  props<{ id: HelpMenuCustomItem['id'] }>()
);

export const onHelpMenuCustomItemClick = createAction(
  '[Applications] on help menu custom item click',
  props<{ id: HelpMenuCustomItem['id'] }>()
);

export const removeAllHelpMenuCustomItems = createAction(
  '[Applications] remove all help menu custom item'
);
