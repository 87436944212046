import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TrackAnalyticsAction, TrackAnalyticsActionType } from '@mads/wm-ng-components';

import { constants } from '@config/constants';
import { environment } from '@environment';

import { User } from 'app/state/users';
import { hasPermissionInApp } from 'app/shared/utils';
import { ModeService } from './mode.service';
import { Router } from '@angular/router';

declare global {
  interface ExtendedWindow extends Window {
    analytics?: SegmentAnalytics.AnalyticsJS;
  }
}

interface SegmentEventInterface {
  action: string;
  params: SegmentEventParam[];
}

interface SegmentEventParam {
  key: string;
  value: string;
}

const routeToNameMapper = [
  { displayName: 'Audiences', route: 'audiences' },
  { displayName: 'Campaigns', route: 'campaigns/list' },
  { displayName: 'Media Plans Comparison', route: 'campaigns/comparison' },
  { displayName: 'Media Plans Results', route: 'campaigns/results' },
  { displayName: 'Media Plans Uploader', route: 'campaigns/custom' },
  { displayName: 'Touchpoints', route: 'touchpoints' },
];

@Injectable({ providedIn: 'root' })
export class AnalyticsTrackingService {
  private readonly window: ExtendedWindow;
  private readonly renderer2: Renderer2;
  private currentAgency: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private modeService: ModeService,
    private router: Router
  ) {
    this.window = this.document.defaultView;
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
    this.currentAgency = this.modeService.currentMode.name;
  }

  public initTrackAnalytics(user: User): void {
    if (
      environment.excludeDevelopersFromSegment &&
      hasPermissionInApp(
        user,
        environment.appsIds.appId,
        constants.USER_PERMISSION_KEYS.OMIT_USER_EVENTS
      )
    ) {
      return;
    }

    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/scripts/segment-analytics.js';
    script.onload = () => {
      this.window.analytics.load(environment.segmentAnalyticsKey);
      this.identifyUser(user);
    };
    this.renderer2.appendChild(this.document.head, script);
  }

  public track(data: TrackAnalyticsAction): void {
    if (!this.window.analytics) {
      return;
    }

    switch (data.type) {
      case TrackAnalyticsActionType.identify:
        this.identifyUser(data.payload);
        break;

      case TrackAnalyticsActionType.page:
        this.trackPage(data.payload);
        break;

      case TrackAnalyticsActionType.action:
        this.trackAction(data.payload);
        break;

      case TrackAnalyticsActionType.reset:
        this.reset();
        break;

      default:
        break;
    }
  }

  public trackAction(payload: string | SegmentEventInterface): void {
    if (typeof payload === 'string') {
      this.window.analytics.track(payload, {
        agency: this.currentAgency,
        category: this.getCurrentAppName(),
      });
      return;
    }
    const paramsMap = Object.assign(
      {},
      ...payload.params.map((item: SegmentEventParam) => ({ [item.key]: item.value }))
    );

    this.window.analytics.track(payload.action, {
      agency: this.currentAgency,
      category: this.getCurrentAppName(),
      ...paramsMap,
    });
  }

  private identifyUser(user: User): void {
    const { agencyId, country, createdAt, firstname, lastname, email, id } = user;
    this.window.analytics.identify(id, {
      firstname,
      lastname,
      email,
      agencyId,
      createdAt,
      country,
    });
  }

  private trackPage(payload: string | SegmentEventInterface): void {
    if (typeof payload === 'string') {
      this.window.analytics.page(payload, {
        agency: this.currentAgency,
      });
      return;
    }
    const paramsMap = Object.fromEntries(
      payload.params.map((item: SegmentEventParam) => [item.key, item.value]),
    );
    this.window.analytics.page(payload.action, {
      agency: this.currentAgency,
      ...paramsMap,
    });
  }

  private reset(): void {
    this.window.analytics.reset();
  }

  private getCurrentAppName(): string {
    const routerSegmentNumberWithAppName = 3;
    const urlSegment = this.router.routerState.snapshot.url.split('/')[routerSegmentNumberWithAppName];
    const application = routeToNameMapper.find((name) => name.route === urlSegment);
    return application ? application.displayName : 'Other WMOS Actions';
  }
}
