<div *ngIf="myself$ | async as myself" class="market-drawer__container">
  <div class="market-drawer__content">
    <div class="market-drawer__close">
      <i-feather name="x" (click)="handleClose()" class="market-drawer__icon"></i-feather>
    </div>
    <h3
      class="wm-h3 market-drawer__header"
      [translate]="
        editMode ? 'WM_HOME.MARKET_MANAGEMENT.EDIT_MARKET' : 'WM_HOME.MARKET_MANAGEMENT.ADD_MARKET'
      "
    ></h3>
    <p 
      class="wm-subtitle market-drawer__description"
      translate="WM_HOME.MARKET_MANAGEMENT.MARKET_DRAWER_DESCRIPTION"
    ></p>
    <form [formGroup]="marketForm" class="market-drawer__form">
      <div class="dropdown-module-wrapper market-drawer__select">
        <span
          class="wm-overline market-drawer__label"
          translate="WM_HOME.MARKET_MANAGEMENT.MARKET_LABEL"
        ></span>
        <ng-select
          formControlName="market"
          [items]="availableMarkets"
          [clearable]="false"
          [searchable]="true"
          [placeholder]="'WM_HOME.MARKET_MANAGEMENT.MARKET_PLACEHOLDER' | translate"
          bindLabel="name"
          class="wm-select"
          data-test="WM_HOME.MARKET_MANAGEMENT.DRAWER_MARKET_SELECT"
        >
        </ng-select>
      </div>
      <div class="dropdown-module-wrapper market-drawer__select">
        <span
          class="wm-overline market-drawer__label"
          translate="WM_HOME.MARKET_MANAGEMENT.STATUS_LABEL"
        ></span>
        <ng-select
          formControlName="status"
          [items]="marketStatusList"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'WM_HOME.MARKET_MANAGEMENT.STATUS_PLACEHOLDER' | translate"
          class="wm-select"
          data-test="WM_HOME.MARKET_MANAGEMENT.DRAWER_STATUS_SELECT"
        >
          <ng-template ng-label-tmp let-item="item"> {{ item.name | titlecase }} </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="market-drawer__status">
              <mat-radio-button
                [checked]="(item.name | lowercase) === (marketStatus.value?.name | lowercase)"
                class="wm-radio-button market-drawer__status-radio"
              ></mat-radio-button>
              <div>
                <p class="wm-text-small market-drawer__status-name">{{ item.name | titlecase }}</p>
                <span
                  class="wm-text-small market-drawer__status-description"
                  [translate]="item.description"
                ></span>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <ng-container *ngIf="allowTeamAssign">
        <h6
          class="wm-h6 market-drawer__assignment-header"
          translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGNED_TEAM"
        ></h6>
        <p
          class="wm-subtitle market-drawer__assignment-description"
          translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGNED_TEAM_INFO_TOP"
          [translateParams]="{ clientName: clientName, marketName: marketName }"
        ></p>
        <p
          class="wm-subtitle market-drawer__assignment-description"
          translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGNED_TEAM_INFO_BOTTOM"
        ></p>
        <div class="market-drawer__assignment-action">
          <p class="wm-overline" translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGNED_SWITCH"></p>
          <mat-slide-toggle formControlName="assignTeam" class="wm-button-toggle"></mat-slide-toggle>
        </div>
      </ng-container>
      <ng-container *ngIf="allowTeamAssign && assignTeam; else users">
        <ng-container *ngIf="selectedTeam">
          <p class="wm-overline" translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGNED_TEAM"></p>
          <p class="wm-text-small market-drawer__assignment-selected">
            {{ selectedTeam.displayName }}
          </p>
        </ng-container>
        <ng-container *ngIf="isLoading$ | async; else teamInput"
          ><wm-loader mode="indeterminate" diameter="32" strokeWidth="1"></wm-loader>
        </ng-container>
        <ng-template #teamInput>
          <wm-input-with-dropdown
            formControlName="teamName"
            [label]="'WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGN_LABEL' | translate"
            [placeholder]="'WM_HOME.MARKET_MANAGEMENT.DRAWER_ASSIGN_PLACEHOLDER' | translate"
            [icon]="'search'"
            class="market-drawer__assignment-input"
          >
            <span
              *ngFor="let team of filteredTeams"
              class="wm-text-small market-drawer__assignment-element"
              [class.market-drawer__assignment-element--disabled]="selectedTeam?.id === team.id"
              (click)="selectedTeam?.id === team.id ? $event.stopPropagation() : selectTeam(team)"
            >
              {{ team.displayName }}
            </span>
          </wm-input-with-dropdown>
        </ng-template>
      </ng-container>
      <ng-template #users>
        <div class="market-drawer__users" data-test="WM_HOME.MARKET_MANAGEMENT.DRAWER_OWNERS">
          <p
            class="wm-overline market-drawer__users-label"
            translate="WM_HOME.MARKET_MANAGEMENT.HEADER_OWNERS"
          ></p>
          <app-users-selection
            [myself]="myselfRequired && myself"
            [users]="owners$ | async"
            [selectedUsers]="selectedOwners"
            [disabledUsers]="selectedMembers"
            [isLoading]="isLoading$ | async"
            [isOwnersSelect]="true"
            (filterUsers)="getUsers($event, true)"
            (selectUser)="addOwner($event)"
            (deleteUser)="deleteOwner($event)"
          ></app-users-selection>
        </div>
        <div class="market-drawer__users" data-test="WM_HOME.MARKET_MANAGEMENT.DRAWER_MEMBERS">
          <p
            class="wm-overline market-drawer__users-label"
            translate="WM_HOME.MARKET_MANAGEMENT.DRAWER_MEMBERS"
          ></p>
          <app-users-selection
            [myself]="!editMode && myself"
            [users]="members$ | async"
            [selectedUsers]="selectedMembers"
            [disabledUsers]="selectedOwners"
            [isLoading]="isLoading$ | async"
            (filterUsers)="getUsers($event)"
            (selectUser)="addMember($event)"
            (deleteUser)="deleteMember($event)"
          ></app-users-selection>
        </div>
      </ng-template>
    </form>
  </div>
  <div class="market-drawer__footer">
    <app-action-buttons
      [continueDisabled]="
        !marketForm.valid ||
        (assignTeam && !selectedTeam) ||
        (!assignTeam &&
          selectedOwners.length <
            (myselfRequired ? requiredOwnersNumber - 1 : requiredOwnersNumber))
      "
      [continueDisabledTooltipText]="'WM_HOME.MARKET_MANAGEMENT.DRAWER_ADD_TOOLTIP'"
      backText="WM_HOME.CLIENT_MANAGEMENT.BUTTON_CANCEL"
      continueText="WM_HOME.CLIENT_MANAGEMENT.BUTTON_SAVE"
      (back)="handleClose()"
      (continue)="handleSave()"
    ></app-action-buttons>
  </div>
</div>
