<wm-wrapper>
  <div class="container">
    <div class="lottie">
      <ng-lottie [options]="animationOptions"></ng-lottie>
    </div>
    <div class="right-panel" #description>
      <div class="close">
        <img src="assets/icons/x.svg" alt="Close" class="close--icon" (click)="closeModal()" />
      </div>

      <div class="content">
        <ng-container *ngComponentOutlet="contentComponent"></ng-container>
      </div>

      <button mat-button class="wm-button-primary" (click)="goToApp()">
        {{
          data.alternativeCallToActionEnabled
            ? data?.alternativeCallToAction?.text
            : data.callToActionText
        }}
      </button>
    </div>
  </div>
</wm-wrapper>
