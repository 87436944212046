import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { filter } from 'rxjs/operators';

import { BreadcrumbsFacade } from 'app/state/breadcrumbs';
import { NavigationItem } from '@config';
import { NavigationService } from 'app/core/navigation.service';

@Component({
  selector: 'app-client-creation',
  templateUrl: './client-creation.component.html',
  styleUrls: ['./client-creation.component.scss'],
})
export class ClientCreationComponent extends OnDestroyMixin implements OnInit {
  public nav: NavigationItem[] = [];
  public navigationConfig: NavigationItem[] = [];
  private currentPath: string;

  constructor(
    private breadcrumbsFacade: BreadcrumbsFacade,
    private router: Router,
    private navigationService: NavigationService
  ) {
    super();

    this.getRouterEvents();
  }

  public ngOnInit(): void {
    this.breadcrumbsFacade.updateHeaderBreadcrumbs({
      text: 'WM_HOME.CLIENT_CREATION.BREADCRUMB',
    });

    this.navigationService.clientCreationNavigation$
      .pipe(untilComponentDestroyed(this))
      .subscribe((navigationConfig) => {
        this.navigationConfig = navigationConfig;

        this.prepareNavigation();
      });
  }

  private getRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilComponentDestroyed(this)
      )
      .subscribe((navigationEvent: NavigationEnd) => {
        this.currentPath = navigationEvent.urlAfterRedirects;
        this.prepareNavigation();
      });
  }

  private prepareNavigation(): void {
    this.nav = this.navigationConfig
      ?.filter((element) => !element.hidden)
      ?.map((element) => {
        const navigationStages = element.path.split('/');

        return {
          ...element,
          isActive: this.currentPath.includes(navigationStages[navigationStages.length - 1]),
        };
      });
  }
}
