import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModeCodes } from '@mads/wm-ng-components';

import { defaults } from '@config/defaults';

@Injectable()
export class HttpTranslationsService {
  constructor(private httpClient: HttpClient) {}

  public getTranslations(lang: string, modeCode: ModeCodes): Observable<object> {
    return combineLatest([
      this.getDefaultTranslations(defaults.defaultLanguageCode),
      this.getDefaultTranslations(lang),
    ]).pipe(map((translations) => merge(...translations)));
  }

  private getDefaultTranslations(lang: string): Observable<object> {
    return this.httpClient.get(`assets/i18n/${lang}.json`);
  }
}
