import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectsFacade } from 'app/state/projects';

@Injectable()
export class ResetCurrentProjectGuard {
  constructor(private readonly projectsFacade: ProjectsFacade) {}

  canDeactivate(): Observable<boolean> {
    this.projectsFacade.resetProjects();
    return this.projectsFacade.selectedProject$.pipe(
      filter(project => !project),
      map(() => true),
    );
  }
}
