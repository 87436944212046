import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerAiCenterApp } from '../../../single-spa/ai-center';

@Component({
  selector: 'app-ai-center-wrapper',
  templateUrl: './ai-center-wrapper.component.html',
  styleUrls: ['./ai-center-wrapper.component.scss'],
})
export class AiCenterWrapperComponent extends MicroAppWrapper {
  protected appName = 'AI Center';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerAiCenterApp(params);
  }
}
