import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Myself } from '@mads/wm-ng-components';

import { Page } from 'src/app/interfaces';
import { AppState } from '..';
import * as contextualHelpActions from './contextual-help.actions';
import * as fromContextualHelp from './contextual-help.selectors';
import { LegacyHelpMenuInfo } from './contextual-help.utils';

@Injectable()
export class ContextualHelpFacade {
  currentTag$ = this.store.select(fromContextualHelp.selectCurrentTag);
  globalPage$ = this.store.select(fromContextualHelp.selectGlobalPage);
  localPage$ = this.store.select(fromContextualHelp.selectLocalPage);
  pathsToUnclearPages$ = this.store.select(fromContextualHelp.selectPathsToUnclearPages);
  loading$ = this.store.select(fromContextualHelp.selectLoading);
  matomoSection$ = this.store.select(fromContextualHelp.selectMatomoSection);
  contextualHelpTrackingSuffix$ = this.store.select(fromContextualHelp.selectContextualHelpTrackingSuffix);

  constructor(private store: Store<AppState>, protected actions$: Actions) {}

  setTag(currentTag: string): void { // deprecated since 4.06.2021
    this.store.dispatch(contextualHelpActions.setCurrentTag({ currentTag }));
  }

  setContextualHelpMenuInfo(data: LegacyHelpMenuInfo): void {
    this.store.dispatch(contextualHelpActions.setContextualHelpMenuInfo({ data }));
  }

  resetContextualHelpMenuInfo(): void {
    this.store.dispatch(contextualHelpActions.resetContextualHelpMenuInfo());
  }

  setMatomoSection(section: string | null): void {
    this.store.dispatch(contextualHelpActions.setMatomoSection({ section }));
  }

  markPageAsRead(payload: { pageId: Page['id'], userId: Myself['id'] }): void {
    this.store.dispatch(contextualHelpActions.markPageAsRead(payload));
  }
}
