import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationItem } from '@config';

import { ModeCodes } from '@mads/wm-ng-components';

import { Brand } from 'app/state/brands';
import { ClientManagementDrawerMode } from 'app/state/clients';

@Component({
  selector: 'app-loreal-brand-menu',
  templateUrl: './loreal-brand-menu.component.html',
  styleUrls: ['./loreal-brand-menu.component.scss'],
})
export class LorealBrandMenuComponent {
  @Input() brand: Brand;
  @Input() navigationCategories: NavigationItem[];

  @Output() clickNagivationItem = new EventEmitter<{ item: NavigationItem, categoryTitle: string }>();

  public DrawerMode = ClientManagementDrawerMode;
  public modeCode = ModeCodes.LOREAL;

  constructor() {}

  public handleNavigationItemClick(
    event: MouseEvent,
    item: NavigationItem,
    categoryTitle?: string
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.clickNagivationItem.emit({ item, categoryTitle });
  }
}
