import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'touchpoints-app',
  windowLibraryName: 'touchpointsApp',
  assetsPath: environment.touchpointsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/touchpoints',
  appUrl: environment.touchpointsAppUrl,
  appLocation: environment.touchpointsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['touchpoints']),
};

export const registerTouchpointsApp = (customParams?: any) => registerApp({ ...config, customParams });
