import { OnInit, OnDestroy, EventEmitter, Directive, ElementRef } from '@angular/core';
import { Subscription, combineLatest, Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  Breadcrumbs,
  CustomParams,
  HelpMenuCustomItem,
  MicroAppAction,
  MicroAppActionType,
  TrackerActionType,
} from '@mads/wm-ng-components';

import { Action } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { cloneDeep, isString } from 'lodash-es';

import * as applicationsActions from 'app/state/applications/applications.actions';
import { microFrontendActions } from '@config/micro-frontend-actions';
import { environment } from 'src/environments/environment';

import { MicrofrontendAppConfig } from 'src/single-spa/utils';
import * as singleSpa from 'single-spa';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { ThemeStyles } from '@wppopen/components-library';

export interface MicroAppParams<T = {}> {
  eventEmitter: EventEmitter<Action>;
  overlayContainer: HTMLElement;
  domElementGetter: () => HTMLElement;
  theme: Observable<ThemeStyles>;
  customParams: CustomParams<T>;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class MicroAppWrapper implements OnInit, OnDestroy {
  protected eventEmitter = new EventEmitter<Action>();
  protected eventEmitterSub: Subscription;
  protected onRunProductTours$: Subject<string | void> = new Subject();
  protected onHelpMenuCustomItemClick$: Subject<HelpMenuCustomItem['id']> = new Subject();
  protected appName = 'Micro Frontend';
  protected config: MicrofrontendAppConfig = null;
  protected unsubscribe$ = new Subject<void>();

  constructor(protected wrapperService: MicroAppWrapperService, protected elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.subscribeRunProductToursAction();
    this.subscribeOnHelpMenuCustomItemClick();
    this.subscribeMicroAppActions();

    /** @deprecated - to be removed. Applications should migrate to Choreograph OS to get token */
    const accessTokenOS$ = new BehaviorSubject('');
    const onRunProductTours$ = this.onRunProductTours$;
    const onHelpMenuCustomItemClick$ = this.onHelpMenuCustomItemClick$;
    const brand$ = this.wrapperService.brandsFacade.selectedBrand$;
    const languageCode$ = this.wrapperService.personalFacade.languageCode$;
    /** @deprecated - to be removed. Applications should use a theme from mode$ instead */
    const theme$ = this.wrapperService.modeService.theme$;
    const theme = this.wrapperService.wppOpenThemeService.getTheme().pipe(
      filter((theme) => !!theme),
      map((theme) => theme.content.light)
    );
    const mode$ = this.wrapperService.modeService.mode$;
    const client$ = this.wrapperService.clientsFacade.selectedClient$;
    const projectBuilderSettings$ =
      this.wrapperService.projectBuilderService.projectBuilderSettings$;
    const breadcrumbs$ = this.wrapperService.breadcrumbsFacade.breadcrumbs$.pipe(
      map((breadcrumbs: Breadcrumbs) =>
        breadcrumbs
          ? {
              ...breadcrumbs,
              category: this.translateBreadcrumb(breadcrumbs.category),
              app: this.translateBreadcrumb(breadcrumbs.app),
              section: this.translateBreadcrumb(breadcrumbs.section),
              details: breadcrumbs.details
                ? breadcrumbs.details.map((detail) => ({
                    name: this.translateBreadcrumb(detail.name),
                  }))
                : null,
              text: this.translateBreadcrumb(breadcrumbs.text),
            }
          : null
      )
    );
    const clickedBreadcrumbDetailPath$ =
      this.wrapperService.childNavigationService.breadCrumbDetailPath$;

    // @TODO remove WMS-2956
    const language$ = languageCode$.pipe(
      map((languageCode) => languageCode && languageCode.split('-')[0])
    );

    const { dateFormat$, numberFormat$ } = this.wrapperService.personalFacade;

    const user$ = combineLatest([
      this.wrapperService.usersFacade.myself$,
      dateFormat$,
      numberFormat$,
    ]).pipe(
      map(([user, dateLocale, numberLocale]) => ({
        ...user,
        dateLocale: dateLocale || user?.dateLocale,
        numberLocale: numberLocale || user?.numberLocale,
      }))
    );

    const overlayContainer = this.wrapperService.overlayContainer.getContainerElement();
    const domElementGetter = () => this.elementRef.nativeElement as HTMLElement;
    this.registerApp({
      eventEmitter: this.eventEmitter,
      overlayContainer,
      domElementGetter,
      theme,
      customParams: {
        language$, // deprecated
        microFrontendActions: cloneDeep(microFrontendActions), // deprecated
        accessTokenOS$, // deprecated
        brand$,
        languageCode$,
        user$,
        theme$, // deprecated
        mode$,
        client$,
        breadcrumbs$,
        clickedBreadcrumbDetailPath$,
        onRunProductTours$,
        appsIds: environment.appsIds,
        onHelpMenuCustomItemClick$,
        projectBuilderSettings$: projectBuilderSettings$,
      },
    });

    this.wrapperService.breadcrumbsFacade.resetBreadcrumbsDetails();

    let route = this.wrapperService.router.routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    this.wrapperService.breadcrumbsFacade.updateHeaderBreadcrumbs(route.snapshot.data.breadcrumbs);
  }

  public ngOnDestroy(): void {
    this.wrapperService.guideService.hideTourReminder();
    this.wrapperService.applicationsFacade.removeAllHelpMenuCustomItems();
    this.wrapperService.applicationsFacade.setHasCurrentProductTours({
      hasCurrentProductTours: false,
    });

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (!this.config) {
      return;
    }

    singleSpa
      .unregisterApplication(this.config.name)
      .then(() => this.wrapperService.applicationRef.tick());
  }

  private subscribeMicroAppActions(): void {
    this.eventEmitter.pipe(takeUntil(this.unsubscribe$)).subscribe((action: MicroAppAction) => {
      switch (action.type) {
        case MicroAppActionType.USER_UNAUTHORZIED:
          this.wrapperService.usersFacade.microfrontendUnauthorized(this.appName);
          break;

        case MicroAppActionType.UPDATE_BREADCRUMBS:
          this.wrapperService.breadcrumbsFacade.updateHeaderBreadcrumbs({ details: action.data });
          break;

        case MicroAppActionType.RESET_BREADCRUMBS:
          this.wrapperService.breadcrumbsFacade.resetBreadcrumbsDetails();
          break;

        case MicroAppActionType.CHANGE_ROUTE:
          const linkParams = action.data.map((data) => data.route);
          this.wrapperService.routerFacade.changeRoute({
            linkParams,
            extras: action.extras,
          });
          break;

        case MicroAppActionType.GUIDE_TOUR_CLOSED:
          this.wrapperService.guideMasterService.consumeGuideClosed(action.data);
          break;

        case MicroAppActionType.PATCH_GUIDE_VISIBILITY:
          this.wrapperService.guideMasterService.updateVisibility(action.data);
          break;

        case MicroAppActionType.SET_HAS_PRODUCT_TOURS:
          this.wrapperService.applicationsFacade.setHasCurrentProductTours({
            hasCurrentProductTours: action.data,
          });
          break;

        case MicroAppActionType.RUN_PRODUCT_TOURS_REMINDER:
          this.wrapperService.guideService.showTourReminder();
          break;

        case MicroAppActionType.HIDE_PRODUCT_TOURS_REMINDER:
          this.wrapperService.guideService.hideTourReminder();
          break;

        case MicroAppActionType.SET_CONTEXTUAL_HELP_PAGE_TAG:
          this.wrapperService.contextualHelpFacade.setTag(action.data);
          break;

        case MicroAppActionType.ADD_ITEM_TO_HELP_MENU:
          this.wrapperService.applicationsFacade.addHelpMenuCustomItem(action.data);
          break;

        case MicroAppActionType.REMOVE_ITEM_FROM_HELP_MENU:
          this.wrapperService.applicationsFacade.removeHelpMenuCustomItem(action.data);
          break;

        case MicroAppActionType.SET_CONTEXTUAL_HELP_MENU_INFO:
          this.wrapperService.contextualHelpFacade.setContextualHelpMenuInfo(action.data);
          break;

        case MicroAppActionType.RESET_CONTEXTUAL_HELP_MENU_INFO:
          this.wrapperService.contextualHelpFacade.resetContextualHelpMenuInfo();
          break;

        case MicroAppActionType.SET_MATOMO_SECTION:
          this.wrapperService.contextualHelpFacade.setMatomoSection(action.data);
          break;

        case MicroAppActionType.TRACK_ACTION:
          this.wrapperService.trackerService.trackAction(action.data);
          break;

        case MicroAppActionType.TRACK_ANALYTICS:
          this.wrapperService.analyticsTrackingService.track(action.data);
          break;

        case MicroAppActionType.UPDATE_PROJECT_BUILDER_SETTINGS:
          this.wrapperService.projectBuilderService.emitProjectBuilderSettingsFromChild(
            action.data
          );
          break;
      }

      this.wrapperService.applicationRef.tick();
    });
  }

  protected subscribeRunProductToursAction(): void {
    this.wrapperService.actions$
      .pipe(ofType(applicationsActions.runProductTours), takeUntil(this.unsubscribe$))
      .subscribe(({ guideName }) => {
        const strictTypedGuideName = isString(guideName) ? guideName : null; // for back compatibility
        this.onRunProductTours$.next(strictTypedGuideName);
        this.wrapperService.applicationRef.tick();

        this.wrapperService.trackerService.trackAction({
          type: TrackerActionType.click,
          details: `Run product tour - ${this.appName}`,
          category: 'Home',
        });
      });
  }

  protected subscribeOnHelpMenuCustomItemClick(): void {
    this.wrapperService.actions$
      .pipe(ofType(applicationsActions.onHelpMenuCustomItemClick), takeUntil(this.unsubscribe$))
      .subscribe(({ id }) => {
        this.onHelpMenuCustomItemClick$.next(id);
        this.wrapperService.applicationRef.tick();
      });
  }

  public registerApp(params: MicroAppParams): void {
    this.registerFn(params);
  }

  protected registerFn(params: MicroAppParams): void {}

  protected translateBreadcrumb(key: string): string {
    return key && this.wrapperService.translateService.instant(key);
  }
}
