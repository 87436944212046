import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChildNavigationService {
  // child-apps should skip the first value emitted as it surely is not a breadcrumb
  // coming from inside the app that is just being activated. E.g. app1 could emit crumb
  // with path: 'list' a while ago and let's say now user enters app2. User would get
  // rerouted to app2/list (if it exist) instantly if not for a first skip
  public breadCrumbDetailPath$: BehaviorSubject<string> = new BehaviorSubject('skip me');

  public broadcastBreadcrumbDetailClick(path: string): void {
    this.breadCrumbDetailPath$.next(path);
  }
}
