<wm-wrapper>
  <div class="close-button-wrapper" [class.with-local-switch]="(localPage$ | async) && (globalPage$ | async)">
    <wm-segmented-controls
      *ngIf="(localPage$ | async) && (globalPage$ | async)"
      [options]="['Global Content', 'Local Content']"
      [selected]="(showLocalContent$ | async) ? 'Local Content' : 'Global Content'"
      [itemWidthPx]="125"
      (selectedChange)="switchContent()">
    </wm-segmented-controls>
    <button
      mat-icon-button
      aria-label="Close modal"
      (click)="close()">
      <img src="assets/icons/x.svg">
    </button>
  </div>
  <p *ngIf="(loading$ | async) else loaded" translate="WM_HOME.CONTEXTUAL_HELP.LOADING"></p>

  <ng-template #loaded>
    <ng-container *ngIf="(tag$ | async) else notTagged">
      <div *ngIf="(isEditor$ | async) && (pathsToUnclearPages$ | async)" class="unclear-pages-info">
        <p class="wm-text" translate="WM_HOME.CONTEXTUAL_HELP.TWO_PAGES_ALARM"></p>
        <p class="wm-text-small" translate="WM_HOME.CONTEXTUAL_HELP.CHILLOUT_INFO"></p>
        <p class="wm-text" translate="WM_HOME.CONTEXTUAL_HELP.CLICK_PATHS_INFO"></p>
        <ul>
          <li
            *ngFor="let path of (pathsToUnclearPages$ | async)"
            (click)="goToWiki(path)"
            class="wm-text-small link">
            {{path}}
          </li>
        </ul>
      </div>
      <app-contextual-help-page
        *ngIf="(page$ | async) as page else taggedWithoutPage"
        [page]="page"
      ></app-contextual-help-page>
    </ng-container>
  </ng-template>

  <ng-template #notTagged>
    <!-- surely user is editor here (otherwise pip wouldnt be visible) -->
    <p class="wm-text" translate="WM_HOME.CONTEXTUAL_HELP.NOT_YET_TAGGED"></p>
  </ng-template>

  <ng-template #taggedWithoutPage>
    <!-- surely user is editor here (otherwise pip wouldnt be visible) -->
    <div class="tagged-without-page-wrapper">
      <p class="wm-text" translate="WM_HOME.CONTEXTUAL_HELP.CREATING_PAGES_INFO"></p>
      <img
        src="assets/img/pencil-wavy.svg"
        class="pencil-wavy" />
      <div [wmTooltip]="'Click to copy to clipboard'" class="tag-wrapper" [cdkCopyToClipboard]="tag$ | async">
        <img src="assets/icons/clipboard-sign.svg" />
        <span class="tag">
          {{tag$ | async}}
        </span>
      </div>
      <button
        (click)="goToWiki('')"
        mat-button
        translate="WM_HOME.CONTEXTUAL_HELP.CREATE_CONTENT"
        class="wm-button-primary">
      </button>
    </div>
  </ng-template>
</wm-wrapper>
