import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { isString } from 'lodash-es';

import { environment } from 'src/environments/environment';
import { constants } from '@config/constants';

import { Client, ClientsFacade } from 'app/state/clients';

@Component({
  selector: 'app-workspace-widget',
  templateUrl: './workspace-widget.component.html',
  styleUrls: ['./workspace-widget.component.scss'],
})
export class WorkspaceWidgetComponent extends OnDestroyMixin implements OnInit {
  @Output() toggleWidget = new EventEmitter<boolean>();
  @ViewChild('workspaceContainer', { static: true }) workspaceContainer: ElementRef;
  @Input()
  set isExpanded(expanded: boolean) {
    this._isExpanded = expanded;

    if (!this._isExpanded) {
      this.clearSearchValue();
    }
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  public loading$ = this.clientsFacade.loading$;
  public searchValue: string;
  public allFilteredClients: Client[];
  public myClients: Client[];
  public allClients: Client[];
  public otherClients: Client[];

  public searchMode = false;
  public yourClientsLabel: string;
  public otherClientsLabel: string;

  private readonly divTagName = 'DIV';
  private readonly tilesClass = 'workspace__clients-tile--large';
  private readonly disabledTile = 'workspace__clients-tile--disabled';
  private readonly navTab = 'mat-tab-label';
  public readonly clientCreationPath = '/clients/client-creation/new';
  public readonly clientManagementAppId = environment.appsIds.clientManagementAppId;
  public readonly canAddClientPermissionKey = constants.USER_PERMISSION_KEYS.CAN_ADD_CLIENT;

  private _isExpanded: boolean;

  constructor(private clientsFacade: ClientsFacade, private translate: TranslateService) {
    super();

    this.yourClientsLabel = this.translate.instant('WM_HOME.WORKSPACE_WIDGET.YOUR_CLIENTS');
    this.otherClientsLabel = this.translate.instant('WM_HOME.WORKSPACE_WIDGET.OTHER_CLIENTS');
  }

  public ngOnInit(): void {
    this.workspaceContainer.nativeElement.addEventListener('click', (e) => {
      const tagClass = isString(e.target.className) ? e.target.className : '';

      if (
        (e.target.tagName !== this.divTagName && !tagClass.includes(this.disabledTile)) ||
        tagClass.includes(this.tilesClass)
      ) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      if (e.target.className.includes(this.navTab)) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      this.onToggleWidget();
    });

    this.getClients();
  }

  private getClients(): void {
    this.clientsFacade.getClients();
    this.clientsFacade.clients$
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe((clients) => {
        this.allClients = clients;
        this.otherClients = clients.filter((val) => !val.mine);
        this.myClients = clients.filter((val) => val.mine);
        this.allFilteredClients = [...this.allClients];
      });
  }

  public onToggleWidget(): void {
    this.isExpanded = !this.isExpanded;
    this.toggleWidget.emit(this.isExpanded);
  }

  public onChangeSearchValue() {
    this.switchSearchMode();
    this.filterClients();
  }

  public clearSearchValue(): void {
    this.searchValue = null;
    this.switchSearchMode();
  }

  public getRoute(clientId: string): string {
    return `/clients/${clientId}/markets`;
  }

  private switchSearchMode(): void {
    this.searchMode = !!this.searchValue;
  }

  private filterClients(): void {
    this.allFilteredClients = this.allClients.filter((item) =>
      item.name.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }
}
