import { environment } from 'src/environments/environment';

import {
  matchingGlobalPathname,
  MicrofrontendAppConfig,
  registerApp,
} from './utils';

const config: MicrofrontendAppConfig = {
  name: 'products-stats-app',
  windowLibraryName: 'productsStatsApp',
  baseHref: '/products-stats',
  assetsPath: environment.productsStatsAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.productsStatsAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.productsStatsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/products-stats']),
};

export const registerProductsStatsApp = (customParams?: any) => registerApp({ ...config, customParams });
