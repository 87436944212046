import { createFeatureSelector, createSelector } from '@ngrx/store';

import { clientsFeatureKey, ClientsState } from './clients.model';

export const selectClientsState = createFeatureSelector<ClientsState>(clientsFeatureKey);

export const selectClients = createSelector(selectClientsState, (state) => state.clients);

export const selectSelectedClient = createSelector(
  selectClientsState,
  (state) => state.selectedClient
);

export const selectCategories = createSelector(selectClientsState, (state) => state.categories);

export const selectLoading = createSelector(selectClientsState, (state) => state.loading);

export const selectIsPollingActive = createSelector(
  selectClientsState,
  (state) => state.isPollingActive
);

export const selectError = createSelector(selectClientsState, (state) => state.error);

export const selectClientCreationRequestState = createSelector(
  selectClientsState,
  (state) => state.clientCreationRequestState);
