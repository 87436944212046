import { props, createAction } from '@ngrx/store';
import { RecentWorkItem, App } from './personal.model';

export const getRecentWork = createAction(
  '[Personal] Get Recent Work'
);

export const getRecentWorkSuccess = createAction(
  '[Personal] Get Recent Work Success',
  props<{ recentWork: RecentWorkItem[] }>()
);

export const getRecentWorkFailure = createAction(
  '[Personal] Get Recent Work Failure',
  props<{ error: string }>()
);

export const getFavouriteApps = createAction(
  '[Personal] Get Favourite Apps'
);

export const getFavouriteAppsSuccess = createAction(
  '[Personal] Get Favourite Apps Success',
  props<{ favouriteApps: App[] }>()
);

export const getFavouriteAppsFailure = createAction(
  '[Personal] Get Favourite Apps Failure',
  props<{ error: string }>()
);

export const initLanguage = createAction(
  '[Personal] Initialize Language'
);

export const setLanguageCode = createAction(
  '[Personal] Set Language Code',
  props<{ language: string }>()
);

export const setDateFormatLocale = createAction(
  '[Personal] Set Date Format Code',
  props<{ dateFormatLocale: string }>()
);

export const setNumberFormatLocale = createAction(
  '[Personal] Set Number Format Code',
  props<{ numberFormatLocale: string }>()
);

export const goToFavouriteApp = createAction(
  '[Personal] Go To Favourite App',
  props<{ app: App }>()
);

export const goToFavouriteAppSuccess = createAction(
  '[Personal] Go To Favourite App Success'
);

export const goToFavouriteAppFailure = createAction(
  '[Personal] Go To Favourite App Failure',
  props<{ error: string }>()
);

export const openFavouriteAppTab = createAction(
  '[Personal] Open Favourite App Tab',
  props<App>()
);

export const setOpenForChallenges = createAction(
  '[Personal] Set Open For WM Challenges',
  props<{ consentToParticipate: boolean }>()
);

export const setOpenForChallengesSuccess = createAction(
  '[Personal] Set Open For WM Challenges Success',
  props<{ consentToParticipate: boolean }>()
);

export const setOpenForChallengesFailure = createAction(
  '[Personal] Set Open For WM Challenges Failure',
  props<{ error: any }>()
);

export const getOpenForChallenges = createAction(
  '[Personal] Get Open for WM Challenges'
);

export const getOpenForChallengesSuccess = createAction(
  '[Personal] Get Open for WM Challenges Success',
  props<{ consentToParticipate: boolean }>()
);

export const getOpenForChallengesFailure = createAction(
  '[Personal] Get Open for WM Challenges Failure',
  props<{ error: any }>()
);

export const checkPresentationMode = createAction(
  '[Personal] Check Presentation Mode'
);

export const checkPresentationModeSuccess = createAction(
  '[Personal] Check Presentation Mode Success',
  props<{ isInPresentationMode: boolean }>()
);
