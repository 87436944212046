import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep, forEach, isEmpty, last, sortBy } from 'lodash-es';
import { Brand } from '@mads/wm-ng-components';

import { ModeSettings, nameOfPropertyByWhichWeSortColumnsAndTask, nameOfPropertyByWhichWeSortProjectsToDecideWhichToChooseByDefault, Project, Selection } from '@interfaces';
import { selectSelectedBrand } from '../brands';
import { projectsFeatureKey, ProjectsState } from './projects.model';

const tidyColumnsAndTask = (project: Project): Project => {
  const copy = cloneDeep(project);
  copy.columns = sortBy(copy.columns.filter(column => column.tasks.length > 0), nameOfPropertyByWhichWeSortColumnsAndTask);
  forEach(copy.columns, column => {
    column.tasks = sortBy(column.tasks, nameOfPropertyByWhichWeSortColumnsAndTask);
  });
  return copy;
};

export const selectProjectsState = createFeatureSelector<ProjectsState>(projectsFeatureKey);

export const selectProjects = createSelector(selectProjectsState, (state) => state.projects);
export const selectLoading = createSelector(selectProjectsState, (state) => state.loading);
export const selectError = createSelector(selectProjectsState, (state) => state.error);
export const selectSelections = createSelector(selectProjectsState, (state) => state.selections);
export const selectModeSettings = createSelector(selectProjectsState, (state) => state.projectModeSettings);
export const selectProjectMode = createSelector(selectProjectsState, (state) => state.projectModeOn);

export const selectProjectsForCurrentBrand = createSelector(
  selectProjects,
  selectSelectedBrand,
  (projects: Project[], brand: Brand) =>
    projects.filter(project => project.brand?.includes(brand?.id)),
);
export const selectAreThereProjectsForCurrentBrand = createSelector(
  selectProjectsForCurrentBrand,
  (projects: Project[]) => !isEmpty(projects),
);

export const selectSelectionForCurrentBrand = createSelector(
  selectSelections,
  selectSelectedBrand,
  (selections: Selection[], brand: Brand) =>
    selections.find(selection => selection.brandId === brand?.id),
);

export const selectSelectedProject = createSelector(
  selectProjectsForCurrentBrand,
  selectSelectionForCurrentBrand,
  (projects: Project[], selection: Selection | undefined) => {
    if (isEmpty(projects)) { return null; }
    const freshSelected = selection
      && projects.find(project => project.id === selection.project.id);
    if (freshSelected) {
      return tidyColumnsAndTask(freshSelected);
    }

    const sorted = sortBy(projects, nameOfPropertyByWhichWeSortProjectsToDecideWhichToChooseByDefault);
    return tidyColumnsAndTask(last(sorted));
  },
);

export const selectIsProjectModeOnForCurrentBrand = createSelector(
  selectModeSettings,
  selectSelectedBrand,
  (settings: ModeSettings[], brand: Brand) => {
    const setting = settings.find(setting => setting.brandId === brand?.id);
    return setting
      ? setting.modeOn
      : false;
  },
);
