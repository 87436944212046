import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Brand, COLORS } from '@mads/wm-ng-components';
import { withLatestFrom } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import moment from 'moment';

import { ClientManagementDrawerMode } from 'app/state/clients';
import { ProjectsFacade } from 'app/state/projects';
import { maximumAmountOfColumnsInARowThatWeCanDisplay, Project } from '@interfaces';
import { UsersFacade } from 'app/state/users';
import { ModeService } from 'app/core/mode.service';

const COLUMN_COLORS = [
  'data3Light',
  'data1Light',
  'data4Light',
  'data2Light',
  'data5Light',
];

const guideTasksFrameCfg = [{
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_SECTION_1'
  }, {
    comingUp: true,
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_SECTION_2'
  }, {
    done: true,
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_SECTION_3'
  }, {
    passed: true,
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_SECTION_4'
  }, 
];

@Component({
  selector: 'app-project-builder-brand-menu',
  templateUrl: './project-builder-brand-menu.component.html',
  styleUrls: ['./project-builder-brand-menu.component.scss']
})
export class ProjectBuilderBrandMenuComponent extends OnDestroyMixin implements OnInit {
  @Input() brand: Brand;
  @Output() goToCart = new EventEmitter<void>();

  public guideTasksFrameCfg = guideTasksFrameCfg;
  public readonly STATUS_DONE = 'done';
  public DrawerMode = ClientManagementDrawerMode;
  public projects$ = this.projectsFacade.projectsForCurrentBrand$;
  public selectedProject: Project;
  public deadlinesApproachingOfTasks: Record<string, boolean> = {}; 
  public deadlinesPassedOfTasks: Record<string, boolean> = {}; 
  public myTasks: Record<string, boolean> = {};
  public isMyProject = false;
  private fallbackColor = 'grey';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly projectsFacade: ProjectsFacade,
    private readonly usersFacade: UsersFacade,
    private readonly modeService: ModeService,
  ) { super(); }

  ngOnInit(): void {
    this.projectsFacade.selectedProject$.pipe(
      withLatestFrom(this.usersFacade.myself$),
      untilComponentDestroyed(this),
    ).subscribe(([project, myself]) => {
      const STATUS_DONE = 'done';
      this.selectedProject = project;
      this.isMyProject = project?.createdBy === myself.id;
      this.selectedProject?.columns?.forEach(column => {
        column.tasks?.forEach((task) => {
          this.deadlinesApproachingOfTasks[task.id] = this.isTaskApproachingDeadline(task.deadline) && task.status !== STATUS_DONE;
          this.deadlinesPassedOfTasks[task.id] = this.isTaskPassedDeadline(task.deadline) && task.status !== STATUS_DONE;
          this.myTasks[task.id] = task.assignees?.includes(myself.email);
        });
      });
    })
  }

  public handleGoToCart(): void {
    this.goToCart.emit();
  }

  public onSelectProject(project: Project): void {
    this.projectsFacade.selectProject({ brandId: this.brand.id, project });
  }

  get columnsHorizontalAmount(): number {
    const columnsAmount = this.selectedProject.columns.length;
    return columnsAmount > maximumAmountOfColumnsInARowThatWeCanDisplay
      ? maximumAmountOfColumnsInARowThatWeCanDisplay
      : columnsAmount;
  }

  get projectLink(): string {
    return `${this.document.defaultView.location.origin}/project-builder/projects/${this.selectedProject.id}/board?openTaskId=`
  }

  public isTaskApproachingDeadline(date: string): boolean {
    const momentDate = moment(date);
    const today = moment();
    const tommorow = moment().add(1, 'day');

    return momentDate.isSame(today, 'day')
    || momentDate.isSame(tommorow, 'day');
  }

  public isTaskPassedDeadline(date: string): boolean {
    const now = new Date();
    now.setDate(now.getDate() - 1); // compare with yesterday because deadlines are at midnight
    return new Date(date) < now;
  }

  public goToProjectBuiler(): void {
    window.open(`${this.document.defaultView.location.origin}/project-builder/projects/${this.selectedProject.id}/board`, '_blank');
  }

  public getColor(index: number): string {
    const matchedColor = COLUMN_COLORS[index % COLUMN_COLORS.length];
    const mode = this.modeService.currentMode;
    if (!mode) { return this.fallbackColor; }
    return COLORS[mode.code]?.[matchedColor] || this.fallbackColor;
  }

}
