<div class="client__container">
  <h4
    class="wm-h4 client__header"
    [translate]="
      isEdited
        ? 'WM_HOME.CLIENT_MANAGEMENT.HEADER_TITLE_EDIT_CLIENT'
        : 'WM_HOME.CLIENT_MANAGEMENT.HEADER_TITLE_NEW_CLIENT'
    "
  ></h4>
  <p
    class="wm-text client__description"
    [translate]="
      isEdited
        ? 'WM_HOME.CLIENT_MANAGEMENT.DESCRIPTION_EDIT'
        : 'WM_HOME.CLIENT_MANAGEMENT.DESCRIPTION_ADD'
    "
  ></p>
  <form [formGroup]="clientForm" class="client__form">
    <div class="client__form__input-name">
      <wm-loader
        class="client__form__input-name__loader"
        mode="indeterminate"
        diameter="20"
        strokeWidth="1"
        *ngIf="isLoading$ | async"
      >
      </wm-loader>

      <wm-input-with-dropdown
        formControlName="name"
        [label]="'WM_HOME.CLIENT_MANAGEMENT.LABEL_NAME' | translate"
        [placeholder]="'WM_HOME.CLIENT_MANAGEMENT.PLACEHOLDER_CLIENT_NAME' | translate"
        [maxLength]="inputsMaxLength"
        [dropdownTitle]="'WM_HOME.CLIENT_MANAGEMENT.LIST_TITLE' | translate"
        [emptyTitle]="
          'WM_HOME.CLIENT_MANAGEMENT.LIST_TITLE_EMPTY' | translate: { search: clientName }
        "
        [emptyDescription]="'WM_HOME.CLIENT_MANAGEMENT.LIST_DESCRIPTION_EMPTY' | translate"
      >
        <div
          *ngFor="let client of clientsList"
          (click)="navigateToClient(client.id)"
          class="client__element"
        >
          <div class="client__element-logo">
            <img [src]="client.logo" class="client__element-image" />
          </div>
          <p class="wm-text-small">{{ client.name }}</p>
        </div>
      </wm-input-with-dropdown>
    </div>

    <wm-guide-tooltip
      [class]="clientGuideTour.clientName.class"
      [arrowPosition]="clientGuideTour.clientName.arrowPosition"
      [id]="clientGuideTour.clientName.id"
      [title]="clientGuideTour.clientName.title | translate"
    >
      <span [translate]="clientGuideTour.clientName.description"> </span>
    </wm-guide-tooltip>
    <wm-textarea
      formControlName="description"
      [label]="'WM_HOME.CLIENT_MANAGEMENT.LABEL_DESCRIPTION' | translate"
      [placeholder]="'WM_HOME.CLIENT_MANAGEMENT.PLACEHOLDER_DESCRIPTION' | translate"
      [maxLength]="textareasMaxLength"
      class="client__form-textarea"
    ></wm-textarea>
    <div class="guide-tooltip__context">
      <app-add-logo
        [title]="'WM_HOME.CLIENT_MANAGEMENT.LABEL_CLIENT_LOGO'"
        [description]="'WM_HOME.CLIENT_MANAGEMENT.DESCRIPTION_CLIENT_LOGO'"
        [logo]="client?.logo"
        [alignLogo]="true"
        [selectedLogoUrl]="selectedLogoUrl$ | async"
        (loadLogo)="loadClientLogo($event)"
        (loadLogoUrl)="loadClientLogoUrl($event)"
        (removeLogoUrl)="removeClientLogoUrl()"
        (getLogos)="getLogos()"
      >
      </app-add-logo>
      <wm-guide-tooltip
        [class]="clientGuideTour.clientLogo.class"
        [arrowPosition]="clientGuideTour.clientLogo.arrowPosition"
        [id]="clientGuideTour.clientLogo.id"
        [title]="clientGuideTour.clientLogo.title | translate"
      >
        <span [translate]="clientGuideTour.clientLogo.description"> </span>
      </wm-guide-tooltip>
    </div>
  </form>
  <div class="client__actions">
    <app-action-buttons
      [continueDisabled]="!clientForm.valid"
      [continueDisabledTooltipText]="'WM_HOME.CLIENT_MANAGEMENT.FORM_TOOLTIP_INFO'"
      [backText]="'WM_HOME.CLIENT_MANAGEMENT.BUTTON_CANCEL'"
      [continueText]="
        isEdited
          ? 'WM_HOME.CLIENT_MANAGEMENT.BUTTON_SAVE'
          : 'WM_HOME.BRAND_MANAGEMENT.BUTTON_CONTINUE'
      "
      (back)="navigateToMainPage()"
      (continue)="saveClient()"
    ></app-action-buttons>
  </div>
</div>
