import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map,  take } from 'rxjs/operators';

import { constants } from '@config/constants';
import { UsersFacade } from 'src/app/state/users';
import { checkAccessToModule } from 'app/shared/utils';

@Injectable()
export class YouGuard implements CanActivate {

  constructor(private router: Router, private usersFacade: UsersFacade) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.usersFacade.myself$.pipe(
      filter((myself) => !!myself),
      take(1),
      map((user) => {
        const youV2Access = checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2);
        const youV1Access = checkAccessToModule(user, constants.MODULE_KEYS.YOU_V1);

        const personalLayoutUrl = state.url === '' || state.url === '/';

        if (!youV2Access && !youV1Access) {
          return personalLayoutUrl ? true : this.router.createUrlTree(['/']);
        }

        if (!this.isPlainYouRoute(state.url)) {
          return true;
        }

        const animationAvailable = localStorage.getItem(
          constants.YOU_V2_CONSTANTS.LOCAL_STORAGE_ALLOW_YOU_V2_ANIMATION
        );

        if (!animationAvailable) {
          return state.url === constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE
            ? true
            : this.router.createUrlTree([constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE]);
        }

        if (state.url === constants.YOU_V2_CONSTANTS.ANIMATION_ROUTE) {
          localStorage.removeItem(constants.YOU_V2_CONSTANTS.LOCAL_STORAGE_ALLOW_YOU_V2_ANIMATION);
          return true;
        }
        return this.router.createUrlTree([constants.YOU_V2_CONSTANTS.ANIMATION_ROUTE]);
      })
    );
  }

  isPlainYouRoute(url: string): boolean {
    return url === '' || url === '/' || url === constants.YOU_V2_CONSTANTS.DEFAULT_ROUTE
      || url === constants.YOU_V2_CONSTANTS.ANIMATION_ROUTE;
  }
}
