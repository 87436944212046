import { registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'budget-app',
  windowLibraryName: 'budgetApp',
  assetsPath: environment.budgetAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/budget/',
  appUrl: environment.budgetAppUrl,
  appLocation: environment.budgetAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['budget']),
};

export const registerBudgetApp = (customParams?: any) => registerApp({ ...config, customParams });
