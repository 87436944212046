import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerInfluencerViewerApp } from '../../../single-spa/influencer-viewer';

@Component({
  selector: 'app-influencer-viewer-wrapper',
  templateUrl: './influencer-viewer-wrapper.component.html',
  styleUrls: ['./influencer-viewer-wrapper.component.scss'],
})
export class InfluencerViewerWrapperComponent extends MicroAppWrapper {
  protected appName = 'Influencers for Architect';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerInfluencerViewerApp(params);
  }
}
