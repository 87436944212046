import { Component, ElementRef } from '@angular/core';

import { registerTouchpointsApp } from 'src/single-spa/touchpoints';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface TouchpointsAppState {
  pristine: boolean;
}

const appState: TouchpointsAppState = { pristine: true };

@Component({
  selector: 'app-touchpoints-wrapper',
  templateUrl: './touchpoints-wrapper.component.html',
  styleUrls: ['./touchpoints-wrapper.component.scss'],
})
export class TouchpointsWrapperComponent extends MicroAppWrapper {
  protected appName = 'Touchpoints';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: TouchpointsAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerTouchpointsApp(params);
  }
}
