import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class ApolloService {
  token: string = null;

  fetchToken(): Promise<void> {
    return fetch(`${environment.contextualHelpApi}/wikijs/token`)
      .then((res) => res.json())
      .then(({ token }) => {
        this.token = token;
      })
      .catch((error) => console.error(error));
  }
}
