import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const getApplicationsState = (state: AppState) => state.applications;

export const selectHasCurrentProductTour = createSelector(
  getApplicationsState,
  (state) => state.hasCurrentProductTours
);

export const selectApplications = createSelector(
  getApplicationsState,
  (state) => state.applications
);

export const selectApplicationById = (id: string) =>
  createSelector(selectApplications, (state) => state[id]);

export const selectHelpMenuCustomItems = createSelector(
  getApplicationsState,
  (state) => state.helpMenuCustomItems
);
