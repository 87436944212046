import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Client, Market } from 'app/state/clients';

@Component({
  selector: 'app-management-market-list',
  templateUrl: './management-market-list.component.html',
  styleUrls: ['./management-market-list.component.scss'],
})
export class ManagementMarketListComponent {
  @Input() client: Client;
  @Input() activeMarketCode: string;
  @Input() hasMarketPermissions: boolean;
  @Input() hasRecreateMarketPermission: boolean;

  @Output() selectMarket = new EventEmitter<Market>();
  @Output() editMarket = new EventEmitter<Market>();
  @Output() recreateMarket = new EventEmitter<Market>();
  @Output() addNewMarket = new EventEmitter<void>();

  public handleSelect(market: Market): void {
    this.selectMarket.emit(market);
  }

  public handleEdit(market: Market): void {
    this.editMarket.emit(market);
  }

  public handleRecreate(market: Market): void {
    this.recreateMarket.emit(market);
  }

  public handleAddMarket(): void {
    this.addNewMarket.emit();
  }
}
