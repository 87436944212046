import { Component, ElementRef } from '@angular/core';

import { registerGrowthOpportunitiesApp } from 'src/single-spa/growth-opportunities';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-growth-opportunities-wrapper',
  styleUrls: ['./growth-opportunities-wrapper.component.scss'],
  templateUrl: './growth-opportunities-wrapper.component.html',
})
export class GrowthOpportunitiesWrapperComponent extends MicroAppWrapper {
  protected appName = 'Opportunities';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerGrowthOpportunitiesApp(params);
  }
}
