<mat-drawer-container (backdropClick)="onDrawerClose()" class="markets-management__container">
  <mat-drawer-content
    *ngIf="client && (isClientLoading$ | async) === false"
    class="markets-management__content"
  >
    <app-management-market-list
      [client]="client"
      [activeMarketCode]="activeMarket?.code"
      [hasMarketPermissions]="hasMarketPermissions$ | async"
      [hasRecreateMarketPermission]="hasRecreateMarketPermission$ | async"
      (selectMarket)="activateMarket($event)"
      (addNewMarket)="openMarketDrawer(DrawerMode.NEW_MARKET)"
      (editMarket)="openMarketDrawer(DrawerMode.EDIT_MARKET, $event)"
      (recreateMarket)="openMarketDrawer(DrawerMode.RECREATE_MARKET, $event)"
      class="markets-management__list"
    ></app-management-market-list>
    <div class="markets-management__market">
      <ng-container *ngIf="activeMarket; else emptyPlaceholder">
        <app-market-header
          [market]="activeMarket"
          [clientId]="client.id"
          class="markets-management__header"
        ></app-market-header>
        <app-brands-list
          [brands]="brands$ | async"
          [isLoading]="isBrandLoading$ | async"
          (addBrand)="openBrandDrawer()"
          (editBrand)="openBrandDrawer($event)"
          (deleteBrand)="deleteBrand($event)"
          class="markets-management__brands"
        ></app-brands-list>
      </ng-container>
      <ng-template #emptyPlaceholder>
        <div class="markets-management__empty">
          <h4
            class="wm-h4 markets-management__empty-title"
            translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_MARKET_TITLE"
          ></h4>
          <div class="markets-management__empty-description">
            <h5
              class="wm-h5 markets-management__empty-part markets-management__empty-part--action"
              translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_ACTION"
              (click)="openMarketDrawer(DrawerMode.NEW_MARKET)"
            ></h5>
            <h5
              class="wm-h5 markets-management__empty-part"
              translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_DESCRIPTION_TOP"
            ></h5>
            <h5
              class="
                wm-h5
                markets-management__empty-part markets-management__empty-part--highlighted
              "
              translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_MARKET_BOLD"
            ></h5>
          </div>
          <h5
            class="wm-h5 markets-management__empty-part"
            translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_MARKET_DESCRIPTION_BOTTOM"
          ></h5>
        </div>
      </ng-template>
      <div class="markets-management__footer">
        <button
          mat-button
          class="wm-button-primary wm-auto-size wm-cancel"
          translate="WM_HOME.BRAND_MANAGEMENT.BUTTON_BACK"
          (click)="onBack()"
        ></button>
        <button
          mat-button
          class="wm-button-primary wm-auto-size"
          translate="WM_HOME.MARKET_MANAGEMENT.BUTTON_SAVE_AND_CONTINUE"
          (click)="onContinue()"
        ></button>
      </div>
    </div>
  </mat-drawer-content>
  <mat-drawer position="end" [(opened)]="isDrawerOpen" class="markets-management__drawer">
    <ng-container *ngIf="isDrawerOpen">
      <app-market-drawer
        *ngIf="drawerMode !== DrawerMode.BRAND; else brandDrawer"
        [editedMarket]="editedMarket"
        [availableMarkets]="availableMarkets"
        [clientName]="client.name"
        (closeDrawer)="toggleDrawer(); onDrawerClose()"
        (saveMarket)="onSaveMarket($event)"
        [drawerMode]="drawerMode"
      ></app-market-drawer>
    </ng-container>
    <ng-template #brandDrawer>
      <app-brand-drawer
        [editedBrand]="editedBrand"
        [categories]="categories$ | async"
        (closeDrawer)="toggleDrawer(); onDrawerClose()"
        (saveBrand)="saveBrand($event)"
      ></app-brand-drawer>
    </ng-template>
  </mat-drawer>
</mat-drawer-container>

<ng-template #removePopup>
  <span translate="WM_HOME.MARKET_MANAGEMENT.POPUP_TITLE"></span>
</ng-template>
<ng-template #createPopup let-context>
  <span
    class="markets-management__popup-info"
    translate="WM_HOME.MARKET_MANAGEMENT.POPUP_CREATE_TITLE"
    [translateParams]="{ teamName: context?.teamName }"
  ></span>
  <span translate="WM_HOME.MARKET_MANAGEMENT.POPUP_CREATE_ACTION"></span>
</ng-template>
<app-loader
  *ngIf="(isClientLoading$ | async) || (isPollingActive$ | async)"
  [descriptions]="
    (isPollingActive$ | async) && [
      'WM_HOME.MARKET_MANAGEMENT.LOADER_DESCRIPTION_TOP',
      'WM_HOME.MARKET_MANAGEMENT.LOADER_DESCRIPTION_BOTTOM'
    ]
  "
  data-test="WM_HOME.MARKET_MANAGEMENT.LOADER"
></app-loader>
<ng-container *ngIf="isGuideTourActive">
  <wm-guide-tooltip
    *ngFor="let step of guideTourSteps"
    [class]="step.class"
    [arrowPosition]="step.arrowPosition"
    [id]="step.id"
    [title]="step.title | translate"
    (closeTooltip)="endGuideTour()"
  >
    <span [translate]="step.description"> </span>
  </wm-guide-tooltip>
</ng-container>
