import { Component, } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { UsersFacade } from 'app/state/users';
import { NavigationService } from 'app/core/navigation.service';

@Component({
  selector: 'app-user-space',
  templateUrl: './user-space.component.html',
  styleUrls: ['./user-space.component.scss'],
})
export class UserSpaceComponent {

  /*
      .pipe(delay(1)) - used as a workaround for 'Expresion changed after it was checked'
      unfortunately data load initiated by one of the children of 'user-space.component'
      is causing usersFacade.loading$ to change multiple times.
      delay(1) helps us move rendering of the loader to the next render cycle and thus
      angular does not complaing about changing value of expression in the same check-cycle.
  */
  public showLoader$: Observable<boolean> = this.usersFacade.loading$.pipe(delay(1));
  public navigationItems$ = this.navigationService.userSettingsNavigation$;

  constructor(
    public usersFacade: UsersFacade,
    public navigationService: NavigationService
  ) { }

}
