import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { filter, first } from 'rxjs/operators';

import { get } from 'lodash-es';

import { WarningSnackbarComponent } from 'app/shared/components/warning-snackbar/warning-snackbar.component';
import { PersonalFacade } from 'src/app/state/personal';
import { UsersFacade } from './state/users';
import { environment } from 'src/environments/environment';
import { MatIconService } from './core/mat-icon.service';
import { constants } from 'app/config/constants';
import { TrackerService } from './core/tracker.service';
import { AnalyticsTrackingService } from './core/analystics-tracking.service';
import { ProjectsFacade } from './state/projects';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private currentPath = constants.MATOMO_START_TRACKING_INFO;
  private trackerCache = {
    timeStamp: 0,
    target: '',
  };

  constructor(
    private router: Router,
    private personalFacade: PersonalFacade,
    private snackBar: MatSnackBar,
    private matIconService: MatIconService,
    private trackerService: TrackerService,
    private usersFacade: UsersFacade,
    private analyticsTrackingService: AnalyticsTrackingService,
    private projectsFacade: ProjectsFacade,
  ) {
    this.personalFacade.initLanguage();
    this.matIconService.init();
  }

  private checkForShadowDomSupport(): void {
    if (!get(document, 'head.createShadowRoot') && !get(document, 'head.attachShadow')) {
      this.snackBar.openFromComponent(WarningSnackbarComponent, {
        data: {
          message: 'WM_HOME.WARNING_SNACKBAR.SHADOW_DOM_ERROR',
        },
        panelClass: 'home-warning-snackbar',
      });
    }
  }

  public ngOnInit(): void {
    this.checkForShadowDomSupport();
    this.trackUser();
    this.initTrackAnalytics();
    this.projectsFacade.propagateSettingsToMicrofrontends();
  }

  private trackUser(): void {
    this.trackerService.init();
    this.trackerService.startTracking();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        first()
      )
      .subscribe((event: NavigationEnd) => this.sendPathToMatomo(event.url));

    window.onpopstate = (event: PopStateEvent) => {
      const { timeStamp } = event;
      const target = (event.target as any).location.pathname; // typing doesnt match reality AGAIN

      // '/target' and '/target/' paths are considered the same, therefore:
      const targetsAreTheSame =
        target === this.trackerCache.target ||
        target.substring(0, target.length - 1) === this.trackerCache.target;
      const shouldDebounce = targetsAreTheSame && timeStamp - this.trackerCache.timeStamp < 1000;

      this.trackerCache = {
        timeStamp,
        target,
      };

      if (shouldDebounce) {
        return;
      }

      return this.sendPathToMatomo(document.location.pathname);
    };
  }

  private sendPathToMatomo(newPath: string): void {
    if (this.sameBasePath(newPath, this.currentPath)) {
      return;
    }

    this.trackerService.sendPageVisit(this.currentPath, newPath);
    this.currentPath = newPath;
  }

  private sameBasePath(newPath: string, currentPath: string): boolean {
    const baseCurrentPath = currentPath.split('#')[0];
    const baseNewPath = newPath.split('#')[0];

    return baseCurrentPath === baseNewPath;
  }

  private initTrackAnalytics(): void {
    if (!environment.segmentAnalyticsKey) {
      return;
    }

    this.usersFacade.myself$
      .pipe(
        filter((user) => !!user),
        first()
      )
      .subscribe((user) => this.analyticsTrackingService.initTrackAnalytics(user));
  }
}
