import { createReducer, on, Action } from '@ngrx/store';

import * as fromApplications from './applications.actions';
import { initialState, ApplicationsState } from './applications.model';

const reducer = createReducer(
  initialState,
  on(fromApplications.getApplication, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromApplications.getApplicationSuccess, (state, { application }) => ({
    ...state,
    applications: {
      [application.id]: application,
    },
    loading: false,
    error: null,
  })),
  on(fromApplications.getApplicationFailure, (state, { error, id }) => ({
    ...state,
    applications: {
      [id]: null,
    },
    loading: false,
    error,
  })),
  on(fromApplications.setHasCurrentProductTours, (state, { hasCurrentProductTours }) => ({
    ...state,
    hasCurrentProductTours,
  })),
  on(fromApplications.addHelpMenuCustomItem, (state, { helpMenuCustomItem }) => ({
    ...state,
    helpMenuCustomItems: [...state.helpMenuCustomItems, helpMenuCustomItem],
  })),
  on(fromApplications.removeHelpMenuCustomItem, (state, { id }) => ({
    ...state,
    helpMenuCustomItems: state.helpMenuCustomItems.filter((item) => item.id !== id),
  })),
  on(fromApplications.removeAllHelpMenuCustomItems, (state) => ({
    ...state,
    helpMenuCustomItems: [],
  }))
);

export function applicationsReducer(
  state: ApplicationsState | undefined,
  action: Action
): ApplicationsState {
  return reducer(state, action);
}
