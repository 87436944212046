import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/state';

import {
  Client,
  CreateMarketPayload,
  Market,
  ClientCreationRejectPayload,
  ClientCreationAcceptPayload,
} from './clients.model';
import * as fromClientsActions from './clients.actions';
import * as fromClientsSelectors from './clients.selectors';
import { combineLatest, filter, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { statusToMessage } from 'app/shared/utils';

@Injectable()
export class ClientsFacade {
  public clients$ = this.store.select(fromClientsSelectors.selectClients);
  public selectedClient$ = this.store.select(fromClientsSelectors.selectSelectedClient);
  public categories$ = this.store.select(fromClientsSelectors.selectCategories);
  public loading$ = this.store.select(fromClientsSelectors.selectLoading);
  public isPollingActive = this.store.select(fromClientsSelectors.selectIsPollingActive);
  public error$ = this.store.select(fromClientsSelectors.selectError);
  public clientCreationRequestState$ = this.store.select(fromClientsSelectors.selectClientCreationRequestState);

  constructor(private store: Store<AppState>) { }

  public getClients(): void {
    this.store.dispatch(fromClientsActions.getClients());
  }

  public getFilteredClients(clientName: Client['name']): void {
    this.store.dispatch(fromClientsActions.getFilteredClients({ clientName }));
  }

  public resetClients(): void {
    this.store.dispatch(fromClientsActions.resetClients());
  }

  public selectClient$(id: Client['id'], shouldGetClientWithDetails = false): Observable<Client> {
    this.store.dispatch(fromClientsActions.resetError()); // reset old errors in store

    const gettingMethod = shouldGetClientWithDetails // pick method, some routes want more details
      ? 'getClientWithDetails'
      : 'getClient';

    return combineLatest([this.selectedClient$, this.error$]).pipe( // take selectedClient and error
      tap(([client]) => {
        // if route requires details, or if wrong client is selected, dispatch actions that gets and selects client
        if (shouldGetClientWithDetails || !client || client.id !== id) { this[gettingMethod](id); }
      }),
      filter(([client, error]) => client?.id === id || !!error), // "wait" until we have correct client or error in store
      switchMap(([client, error]) => {
        if (client) { return of({ ...client }); } // if we have client without errors, return observable
        // otherwise throw error with message:
        const messagePrefix = statusToMessage[error?.status] || 'Error while getting ';
        const message = `${messagePrefix}advertiser or it's wating for approval`;

        return throwError(() => new Error(message));
      }),
    );
  }

  public getClient(id: Client['id']): void {
    this.store.dispatch(fromClientsActions.getClient({ id }));
  }

  public getClientWithDetails(id: Client['id']): void {
    this.store.dispatch(fromClientsActions.getClientWithDetails({ id }));
  }

  public createClient(client: Client): void {
    this.store.dispatch(fromClientsActions.createClient({ client }));
  }

  public updateClient(client: Client, id: Client['id']): void {
    this.store.dispatch(fromClientsActions.updateClient({ client, id }));
  }

  public resetClient(): void {
    this.store.dispatch(fromClientsActions.resetClient());
  }

  public createMarket(createMarketPayload: CreateMarketPayload): void {
    this.store.dispatch(fromClientsActions.createMarket({ createMarketPayload }));
  }

  public updateMarket(clientId: Client['id'], market: Market): void {
    this.store.dispatch(fromClientsActions.updateMarket({ clientId, market }));
  }

  public recreateMarket(clientId: Client['id'], market: Market): void {
    this.store.dispatch(fromClientsActions.recreateMarket({ clientId, market }));
  }

  public sendMarketRequest(clientId: Client['id'], countryCode: Market['code']): void {
    this.store.dispatch(fromClientsActions.sendMarketRequest({ clientId, countryCode }));
  }

  public getCategories(): void {
    this.store.dispatch(fromClientsActions.getCategories());
  }

  public acceptClientCreationRequest(payload: ClientCreationAcceptPayload): void {
    this.store.dispatch(fromClientsActions.acceptClientCreationRequest(payload));
  }

  public rejectClientCreationRequest(payload: ClientCreationRejectPayload): void {
    this.store.dispatch(fromClientsActions.rejectClientCreationRequest(payload));
  }
}
