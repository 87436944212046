import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { EMPTY, interval, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { countries } from '@config/countries';
import { defaults } from '@config/defaults';

import { BrandsHttpService } from 'app/core/http/brands-http.service';

import * as fromBrands from './brands.actions';
import { changeRoute } from '../router';
import { BrandsFacade } from './brands.facade';

@Injectable()
export class BrandsEffects {
  public countries = countries;
  private pollingDestroy$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private http: BrandsHttpService,
    private brandsFacade: BrandsFacade,
  ) {}

  getBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.getBrands),
      switchMap(({ countryCode, id }) =>
        this.http.getBrands({ countryCode, id }).pipe(
          map((brands) => fromBrands.getBrandsSuccess({ brands })),
          catchError((err) => [fromBrands.getBrandsFailure(err)])
        )
      )
    )
  );

  getBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.getBrand),
      withLatestFrom(this.brandsFacade.brands$),
      switchMap(([{ id }, brands]) => {
        const cachedBrand = brands.find(brand => brand.id === id);
        if (cachedBrand) { return of(fromBrands.getBrandSuccess({ brand: cachedBrand })); }
        return this.http.getBrand({ id }).pipe(
          map((brand) => {
            const countryExtraData = this.countries[brand.country.code];
            if (countryExtraData) {
              brand.country.currency = countryExtraData.currency;
            }
            return fromBrands.getBrandSuccess({ brand });
          }),
          catchError((error) => of(fromBrands.getBrandFailure({error})))
        );
      })
    )
  );

  createBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.createBrand),
      switchMap(({ createBrandPayload }) =>
        this.http.createBrand(createBrandPayload).pipe(
          map((brand) =>
            brand.orderStatus === defaults.creationStatus.SUCCESS
              ? fromBrands.createBrandSuccess({ brand })
              : fromBrands.pollingBrand({ brandId: brand.id })
          ),
          catchError((err) => [fromBrands.createBrandFailure(err)])
        )
      )
    )
  );

  updateBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.updateBrand),
      switchMap(({ brand }) =>
        this.http.updateBrand({ brand }).pipe(
          map((updatedBrand) => fromBrands.updateBrandSuccess({ updatedBrand })),
          catchError((err) => [fromBrands.updateBrandFailure(err)])
        )
      )
    )
  );

  deleteBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.deleteBrand),
      switchMap(({ brand, withRedirect }) =>
        this.http.deleteBrand({ brandId: brand.id }).pipe(
          mergeMap(() =>
            withRedirect
              ? [
                  fromBrands.deleteBrandSuccess({ brandId: brand.id }),
                  changeRoute({ linkParams: ['/clients', brand.clientId] }),
                ]
              : [fromBrands.deleteBrandSuccess({ brandId: brand.id })]
          ),

          catchError((err) => [fromBrands.deleteBrandFailure(err)])
        )
      )
    )
  );

  pollingBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.pollingBrand),
      switchMap(({ brandId }) =>
        interval(5000).pipe(
          takeUntil(this.pollingDestroy$),
          switchMap(() =>
            this.http.getBrand({ id: brandId }).pipe(
              mergeMap((brand) => {
                if (brand.orderStatus === defaults.creationStatus.PENDING) {
                  return EMPTY;
                }

                this.pollingDestroy$.next(true);
                return of(fromBrands.createBrandSuccess({ brand }));
              }),
              // TODO: Temporary solution until update getBrand endpoint
              catchError((err) => EMPTY)
            )
          )
        )
      )
    )
  );

  getLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrands.getLogos),
      switchMap(({ name, limit }) =>
        this.http.getLogos({ name, limit }).pipe(
          map((logos) => {
            const logosWithoutDuplicate = Array.from(new Set(logos));
            return fromBrands.getLogosSuccess({ logos: logosWithoutDuplicate });
          }),
          catchError((err) => [fromBrands.getLogosFailure(err)])
        )
      )
    )
  );
}
