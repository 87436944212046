import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-to-wpp-open-popup',
  templateUrl: './redirect-to-wpp-open-popup.component.html',
  styleUrls: ['./redirect-to-wpp-open-popup.component.scss'],
})
export class RedirectToWppOpenPopupComponent {
  constructor(private dialogRef: MatDialogRef<RedirectToWppOpenPopupComponent>) {}

  redirect(): void {
    const wppOpenUrl = 'https://wavemaker.os.wpp.com/';
    window.open(wppOpenUrl, '_blank');
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
