import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map } from 'rxjs/operators';

import * as fromQuestionnaireActions from './questionnaire.actions';
import { environment } from '@environment';

@Injectable()
export class QuestionnaireEffects {

  constructor(
    private actions$: Actions
  ) { }

  open$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestionnaireActions.open),
      map(() => window.open(environment.questionnaireUrl, '_blank'))
    ),
    { dispatch: false }
  );

  runAnimation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestionnaireActions.showAnimation),
      delay(1700), // this is how much animation described in header-wrapper component lasts
      map(() => fromQuestionnaireActions.hideAnimation())
    )
  );

}
