import { Injectable } from '@angular/core';
import { CanActivateChild, UrlTree, Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { get } from 'lodash-es';

import { ClientsFacade } from 'app/state/clients';
import { SnackbarService } from '../snackbar.service';

@Injectable()
export class ClientGuard implements CanActivateChild, CanActivate {
  constructor(
    private router: Router,
    private clientsFacade: ClientsFacade,
    private snackbarService: SnackbarService,
  ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const clientId = get(route.parent.params, 'clientId');
    const mainUrlTree = this.router.createUrlTree([ '/' ]);

    if (!clientId) {
      return of(mainUrlTree);
    }

    return this.clientsFacade.selectClient$(clientId, route.data.withDetails).pipe(
      take(1),
      map((client) => {
        // New client creation flow
        // throw error if client is not in pending status and route requires it
        if (route.data.limitToNotAccepted && client.isAccepted) {
          throw new Error('Advertiser is not waiting for approval');
        }

        return true;
      }), // clientsfacade returns correct client here or throws an error
      catchError(err => {
        this.snackbarService.showError(err);
        return of(mainUrlTree);
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateChild(route);
  }
}
