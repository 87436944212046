import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerDataStrategyDeckApp } from 'src/single-spa/data-strategy-deck';

@Component({
  selector: 'app-data-strategy-deck-wrapper',
  templateUrl: './data-strategy-deck-wrapper.component.html',
  styleUrls: ['./data-strategy-deck-wrapper.component.scss'],
})
export class DataStrategyDeckWrapperComponent extends MicroAppWrapper {
  protected appName = 'Data Strategy';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerDataStrategyDeckApp(params);
  }
}
