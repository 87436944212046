import { environment } from 'src/environments/environment';

import { matchingGlobalPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'you-page',
  windowLibraryName: 'youPageApp',
  baseHref: '/you',
  assetsPath: environment.youPageAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.youPageAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.youPageAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/you']),
};

export const registerYouPageApp = (customParams?: any) => registerApp({ ...config, customParams });
