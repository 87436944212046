import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ContextualHelpDrawerComponent } from './contextual-help-drawer.component';

@Injectable({
  providedIn: 'root',
})
export class ContextualHelpDrawerService {

  constructor(
    private dialog: MatDialog
  ) { }

  public open(): void {
    this.dialog.open(ContextualHelpDrawerComponent, {
      // 637px - width of slide-in-side-panels according to the designs
      width: '637px',
      // 65px - height of main WMOS header which we don't want to overlap
      height: 'calc(100vh - 65px)',
      // contextual-help-side-panel - class responsible for animation (and other)
      panelClass: 'contextual-help-side-panel',
      // contextual-help-side-panel-backdrop - class responsible for moving backdrop down by 65px (top menu) and other
      backdropClass: 'contextual-help-side-panel-backdrop',
    });
  }
}
