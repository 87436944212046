import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationItem } from '@config';
import { flatten, sortBy } from 'lodash-es';

import { Brand } from 'app/state/brands';
import { ClientManagementDrawerMode } from 'app/state/clients';
import { UsersFacade } from 'app/state/users';

@Component({
  selector: 'app-default-brand-menu',
  templateUrl: './default-brand-menu.component.html',
  styleUrls: ['./default-brand-menu.component.scss'],
})
export class DefaultBrandMenuComponent implements OnInit {
  @Input() brand: Brand;
  @Input() isWmMode: boolean;
  @Input() navigationCategories: NavigationItem[];

  @Output() goToCart = new EventEmitter<void>();
  @Output() clickNagivationItem = new EventEmitter<{ item: NavigationItem, categoryTitle: string }>();

  public DrawerMode = ClientManagementDrawerMode;
  public hasAccessToSimplifiedBrandMenu$ = this.usersFacade.hasAccessToSimplifiedBrandMenu$;
  public simplifiedBrandMenuOn$ = this.usersFacade.simplifiedBrandMenuOn$;
  public simplifiedNavigationItems: NavigationItem[] = [];
  public arrowSize = 15;

  constructor(
    private usersFacade: UsersFacade,
  ) {}

  public ngOnInit(): void {
    this.simplifiedNavigationItems = this.navigationCategories.map(firstLevelNavigationItem => {
      const secondLevelNavigationItemsNavs = firstLevelNavigationItem.nav
        .map(secondLevelNavigationItem => secondLevelNavigationItem.nav);
      const unsorted = flatten(secondLevelNavigationItemsNavs);
      const nav = sortBy(unsorted, 'toolName');
      return {
        ...firstLevelNavigationItem,
        nav,
      };
    });
  }

  public handleGoToCart(): void {
    this.goToCart.emit();
  }

  public handleNavigationItemClick(
    event: MouseEvent,
    item: NavigationItem,
    categoryTitle?: string
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.clickNagivationItem.emit({ item, categoryTitle });
  }

  public toggleBrandMenuMode(): void {
    this.usersFacade.toggleBrandMenuMode();
  }
}
