import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { TrackerService } from '../tracker.service';

@Injectable()
export class TitleGuard implements CanActivate {
  constructor(private titleService: Title, private trackerService: TrackerService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { titles } = route.data;

    this.titleService.setTitle(titles?.PAGE_TITLE);

    if (environment.matomoId) {
      this.trackerService.setDocumentTitle(titles?.MATOMO_TITLE);
    }

    return of(true);
  }
}
