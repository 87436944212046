<div class="add-logo__container" [class.add-logo__container--align]="alignLogo">
  <div class="add-logo__details">
    <h6 class="wm-h6 add-logo__header" [translate]="title"></h6>
    <p class="wm-text add-logo__description" [translate]="description"></p>
    <div class="add-logo__input">
      <div class="add-logo_errors">
        <mat-error
          *ngIf="showLogoSizeError"
          class="add-logo__error"
          translate="WM_HOME.CLIENT_MANAGEMENT.LOGO_SIZE_ERROR"
        ></mat-error>
        <mat-error
          *ngIf="showLogoError && !showLogoSizeError"
          class="add-logo__error"
          translate="WM_HOME.CLIENT_MANAGEMENT.LOGO_ERROR"
        ></mat-error>
      </div>
    </div>
  </div>
  <img
    *ngIf="selectedLogoUrl || newLogo || logo; else logoPlaceholder"
    data-test="SHARED.BUTTONS.LOGO-CIRCLE-BUTTON"
    (click)="openModal()"
    [src]="selectedLogoUrl || newLogo || logo"
    [alt]="'WM_HOME.MARKET_MANAGEMENT.LOGO_ALT' | translate"
    class="add-logo__image"
    [wmTooltip]="'WM_HOME.BRAND_MANAGEMENT.EXISTING_LOGO_TOOLTIP' | translate"
  />
  <ng-template #logoPlaceholder>
    <wm-empty-state-circle-button
      data-test="SHARED.BUTTONS.EMPTY-STATE-CIRCLE-BUTTON"
      class="add-logo__placeholder"
      (click)="openModal()"
    ></wm-empty-state-circle-button>
  </ng-template>
</div>
