import { matchingGlobalPathname, MicrofrontendAppConfig, registerApp } from 'src/single-spa/utils';
import { environment } from '@environment';

const config: MicrofrontendAppConfig = {
  name: 'apps-slider-app',
  windowLibraryName: 'apps',
  assetsPath: environment.appsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/',
  appUrl: environment.appsAppUrl,
  matchingStrategy: matchingGlobalPathname(['/']),
};

export const registerAppsSliderApp = (params?: any) =>
  registerApp({ ...config, customParams: { customParams: { ...params.customParams, appsSlider: true } } });

