import { createAction, props } from '@ngrx/store';

export const open = createAction('[Questionnaire] Set Opened');
export const dismiss = createAction('[Questionnaire] Set Dismissed');
export const displayNotification = createAction('[Questionnaire] Set Display Notifications');
export const showAnimation = createAction(
  '[Questionnaire] Show Questionnaire Animation',
  props<{position: { x: number, y: number }}>()
);
export const hideAnimation = createAction('[Questionnaire] Hide Questionnaire Animation');
