import { environment } from 'src/environments/environment';

import {
  matchingGlobalPathname,
  MicrofrontendAppConfig,
  registerApp,
} from './utils';

const config: MicrofrontendAppConfig = {
  name: 'os-pay',
  windowLibraryName: 'osPayApp',
  baseHref: '/os-pay',
  assetsPath: environment.osPayAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.osPayAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.osPayAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/os-pay']),
};

export const registerOsPayApp = (customParams?: any) => registerApp({ ...config, customParams });
