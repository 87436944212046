import { Component, ElementRef } from '@angular/core';

import { registerMarketAdminApp } from 'src/single-spa/market-admin';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface MarketAdminAppState {
  pristine: boolean;
}

const appState: MarketAdminAppState = { pristine: true };

@Component({
  selector: 'app-market-admin-wrapper',
  templateUrl: './market-admin-wrapper.component.html',
})
export class MarketAdminWrapperComponent extends MicroAppWrapper {
  protected appName = 'Market Admin';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: MarketAdminAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerMarketAdminApp(params);
  }
}
