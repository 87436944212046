import { createFeatureSelector, createSelector } from '@ngrx/store';

import { brandsFeatureKey, BrandsState } from './brands.model';

export const selectBrandsState = createFeatureSelector<BrandsState>(brandsFeatureKey);

export const selectBrands = createSelector(selectBrandsState, (state) => state.brands);

export const selectSelectedBrand = createSelector(
  selectBrandsState,
  (state) => state.selectedBrand
);

export const selectLoading = createSelector(selectBrandsState, (state) => state.loading);

export const selectError = createSelector(selectBrandsState, (state) => state.error);

export const selectLogos = createSelector(selectBrandsState, (state) => state.logos);

export const selectSelectedLogoUrl = createSelector(
  selectBrandsState,
  (state) => state.selectedLogoUrl
);

export const selectIsLogosLoading = createSelector(
  selectBrandsState,
  (state) => state.isLogosLoading
);
