import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { constants } from '@config/constants';
import { environment } from '@environment';
import { RouterFacade } from 'app/state/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['error.component.scss'],
})
export class ErrorComponent {
  public supportMail = constants.SUPPORT_MAIL;
  public appName = environment.appName;
  public appNameOptions = constants.APP_NAME;

  constructor(private routerFacade: RouterFacade, private location: Location) {}

  public goBack(): void {
    this.location.back();
  }

  public goToYouPage(): void {
    this.routerFacade.changeRoute({ linkParams: ['you/dashboard'] });
  }
}
