import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ConfirmationPopupConfig,
  defaultConfirmationPopupConfig,
} from './confirmation-popup.config';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {
  public config: ConfirmationPopupConfig;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupConfig
  ) {}

  public cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.config = {
      ...defaultConfirmationPopupConfig,
      ...this.data,
    };
  }
}
