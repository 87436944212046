import { Injectable, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { Actions } from '@ngrx/effects';

import { GuideManagerService, GuideService } from '@mads/wm-ng-components';

import { PersonalFacade } from 'app/state/personal';
import { UsersFacade } from 'app/state/users';
import { BrandsFacade } from 'app/state/brands';
import { BreadcrumbsFacade } from 'app/state/breadcrumbs';
import { RouterFacade } from 'app/state/router';
import { ClientsFacade } from 'app/state/clients';
import { ApplicationsFacade } from 'app/state/applications';

import { ModeService } from 'app/core/mode.service';
import { ContextualHelpFacade } from 'app/state/contextual-help';
import { TrackerService } from './tracker.service';
import { ChildNavigationService } from './child-navigation-service';
import { AnalyticsTrackingService } from './analystics-tracking.service';
import { ProjectBuilderService } from './project-builder-service';
import { GuideMasterService } from './guide-master.service';
import { WppOpenThemeService } from './wpp-open-theme.service';

@Injectable()
export class MicroAppWrapperService {
  constructor(
    public readonly brandsFacade: BrandsFacade,
    public readonly personalFacade: PersonalFacade,
    public readonly usersFacade: UsersFacade,
    public readonly overlayContainer: OverlayContainer,
    public readonly router: Router,
    public readonly breadcrumbsFacade: BreadcrumbsFacade,
    public readonly routerFacade: RouterFacade,
    public readonly clientsFacade: ClientsFacade,
    public readonly translateService: TranslateService,
    public readonly actions$: Actions,
    public readonly applicationsFacade: ApplicationsFacade,
    public readonly contextualHelpFacade: ContextualHelpFacade,
    public readonly applicationRef: ApplicationRef,
    public readonly modeService: ModeService,
    public readonly guideService: GuideService,
    public readonly trackerService: TrackerService,
    public readonly childNavigationService: ChildNavigationService,
    public readonly analyticsTrackingService: AnalyticsTrackingService,
    public readonly projectBuilderService: ProjectBuilderService,
    public readonly guideMasterService: GuideMasterService,
    public readonly guideManagerService: GuideManagerService,
    public readonly wppOpenThemeService: WppOpenThemeService
  ) {}
}
