<div #loginContainer class="login">
  <img src="assets/img/white-logo.svg" alt="WM logo" #wmLogo class="main-logo" />
  <video autoplay muted="true" #waveAnimation>
    <source src="assets/wave_login.webm" type='video/webm;codecs="vp8, vorbis"' />
  </video>

  <div *ngIf="!(myself$ | async)" class="popup" #popup>
    <ng-container *ngIf="showPromo">
      <app-wmos-login-promo></app-wmos-login-promo>

      <div class="vertical-line"></div>
    </ng-container>

    <div class="login-form">
      <img [src]="currentLogoPath$ | async" alt="agency logo" class="small-logo" />
      <div class="login-form__input" *ngIf="!(loading$ | async)">
        <button (click)="login()">
          {{ 'WM_HOME.LOGIN_PAGE.LOGIN' | translate }}
        </button>
      </div>
      <mat-spinner *ngIf="loading$ | async" diameter="50" strokeWidth="3"> </mat-spinner>
      <div class="login-form__info">
        <a href="https://login.microsoftonline.com" target="_blank" class="login-problems-info">
          {{ 'WM_HOME.LOGIN_PAGE.PROBLEMS' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
