import { HelpMenuCustomItem } from '@mads/wm-ng-components';

export interface Application {
  active: boolean;
  createdAt: string;
  createdSecretAt: string;
  id: string;
  maintenance: boolean;
  name: string;
  public: boolean;
  redirectUrl: string;
  updatedAt: string;
}

export interface ApplicationsState {
  applications: {
    [key: string]: Application;
  };
  loading: boolean;
  error: any;
  hasCurrentProductTours: boolean;
  helpMenuCustomItems: HelpMenuCustomItem[];
}

export const initialState: ApplicationsState = {
  applications: {},
  loading: false,
  error: null,
  hasCurrentProductTours: false,
  helpMenuCustomItems: [],
};
