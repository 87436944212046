import { GuideTour, GuideTours, TooltipPosition } from '@mads/wm-ng-components';

export const PROJECT_BUILDER_TOUR: GuideTour = {
  headerNav: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_ABOUT_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_ABOUT_DESCTIPTION',
    id: 'GUIDE_PROJECT_BUILDER_ABOUT',
    arrowPosition: TooltipPosition.TOP,
    class: 'wm-guide-step wm-guide-step--top',
  },
  sidebar1: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_NAV_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_NAV1_DESCTIPTION',
    id: 'GUIDE_PROJECT_BUILDER_NAV_1',
    arrowPosition: TooltipPosition.LEFT,
    class: 'wm-guide-step wm-guide-step--left',
  },
  sidebar2: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_NAV_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_NAV2_DESCTIPTION',
    id: 'GUIDE_PROJECT_BUILDER_NAV_2',
    arrowPosition: TooltipPosition.LEFT,
    class: 'wm-guide-step wm-guide-step--left',
  },
};

export const PROJECT_BUILDER_TASKS_BALL_TOUR = {
  tasksBall: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_TASKS_BALL.TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_TASKS_BALL.DESCTIPTION',
    id: 'PROJECT_BUILDER_TASKS_BALL',
    arrowPosition: TooltipPosition.RIGHT,
    class: 'wm-guide-step wm-guide-step--right',
  },
}

export const PROJECT_BUILDER_MODE_DRAWER: GuideTour = {
  welcome: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.WELCOME_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.WELCOME_DESCTIPTION',
    id: 'PROJECT_BUILDER_MODE_DRAWER_WELCOME',
    arrowPosition: TooltipPosition.BOTTOM,
    class: 'wm-guide-step wm-guide-step--bottom',
  },
  tasks: {
    title: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_TITLE',
    description: 'WM_HOME.GUIDE_TOUR.PROJECT_BUILDER_MODE_DRAWER.TASKS_DESCTIPTION',
    id: 'PROJECT_BUILDER_MODE_DRAWER_TASKS',
    arrowPosition: TooltipPosition.LEFT,
    class: 'wm-guide-step wm-guide-step--left guide-tooltip--w700',
  },
};

export const guideTours: GuideTours = {
  PROJECT_BUILDER_TOUR,
  PROJECT_BUILDER_TASKS_BALL_TOUR,
  PROJECT_BUILDER_MODE_DRAWER,
}

