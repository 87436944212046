import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

// This directive adds event innerHtmlRendered to an element, which is usefull if
// you need to know when inner html was rendered to safely do something.
// It uses native MutationObserver to watch for changes in DOM Tree.
@Directive({
  selector: '[appMutationObserver]',
})
export class AppMutationObserverDirective implements OnDestroy {
  _observer: MutationObserver;
  @Output() innerHtmlRendered = new EventEmitter();

  constructor(private el: ElementRef) {
    this._observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation, index) => {
        if (mutation.type === 'childList') {
          this.innerHtmlRendered.emit();
        }
      });
    });
    this._observer.observe(
      this.el.nativeElement,
      { attributes: true, childList: true, characterData: true }
    );
  }

  ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
    }
  }

}
