import { Component, ElementRef } from '@angular/core';
import { of } from 'rxjs';

import { registerAudiencesApp } from 'src/single-spa/audiences';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

import { environment } from '@environment';
import { constants } from 'app/config/constants';

interface AudiencesAppState {
  pristine: boolean;
}

const appState: AudiencesAppState = { pristine: true };

@Component({
  selector: 'app-audiences-wrapper',
  templateUrl: './audiences-wrapper.component.html',
  styleUrls: ['./audiences-wrapper.component.scss'],
})
export class AudiencesWrapperComponent extends MicroAppWrapper {
  protected appName = 'Audiences';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: AudiencesAppState }>): void {
    params.customParams.state = this.appState;

    if (environment.appName === constants.APP_NAME.WMOS) {
      params.customParams.mode$ = of(null);
    }

    this.config = registerAudiencesApp(params);
  }
}
