<div #sliderContainer>
  <div *ngIf="!clients || clients.length === 0" class="empty-container"></div>
  <span *ngIf="clients && clients.length > 0">
    <div *ngIf="!showSlider" class="apps-slider__hidden">
      <app-single-tile
        class="apps-slider__hidden__tile"
        *ngFor="let client of clients; trackBy: trackByFn"
        [client]="client"
      >
      </app-single-tile>
    </div>

    <div *ngIf="showSlider" class="apps-slider__content">
      <span class="apps-slider__content--nav right" (click)="nextImage()">
        <i-feather name="chevron-right" class="apps-slider__content--next"> </i-feather>
      </span>

      <ngx-flicking #flicking [options]="flickerConfig" (holdStart)="holdDragStart($event)">
        <ng-template>
          <div *ngFor="let client of clients; trackBy: trackByFn" class="apps-slider__content__tile">
            <app-single-tile [client]="client"> </app-single-tile>
          </div>
        </ng-template>
      </ngx-flicking>
    </div>
  </span>
</div>
