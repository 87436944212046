import {  registerApp, MicrofrontendAppConfig, matchingGlobalPathname } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'growth-path-app',
  windowLibraryName: 'growthPathApp',
  assetsPath: environment.growthPathAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.growthPathAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  baseHref: '/project-builder', // business name - "Project builder", "Growth path" is a name for developers 
  appLocation: environment.growthPathAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/project-builder']),
};

export const registerGrowthPathApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
