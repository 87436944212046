import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { APPS_NAMES_MAP } from 'app/config/microfrontends';
import { environment } from 'src/environments/environment.master';
import { ModeService } from 'app/core/mode.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  public maintenancePageAppName$ = this.getAppName();
  public currentLogoPath$ = this.modeService.currentLogoPath$;
  public gearsUrl = 'url(/assets/modes/wavemaker/gears.svg)';

  constructor(private modeService: ModeService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.setGearsUrl();
  }

  public setGearsUrl(): void {
    this.modeService.mode$
      .pipe(take(1))
      .subscribe((mode) => (this.gearsUrl = `url(/assets/modes/${mode?.code || 'wavemaker'}/gears.svg)`));
  }

  public getAppName(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => APPS_NAMES_MAP[params.appId] || APPS_NAMES_MAP[environment.appsIds.appId])
    );
  }
}
