import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { constants } from '@config/constants';
import { ModeService } from 'app/core/mode.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements  AfterViewInit {
  @ViewChild('noAccessContainer') noAccessContainer: ElementRef<HTMLDivElement>;
  @ViewChild('waveAnimation', { static: true }) waveAnimation: ElementRef<HTMLVideoElement>;

  public currentLogoPath$ = this.modeService.currentLogoPath$;
  public supportMail = constants.SUPPORT_MAIL;

  constructor(private modeService: ModeService) {}

  public ngAfterViewInit(): void {
    this.waveAnimation.nativeElement.oncanplaythrough = () => {
      this.waveAnimation.nativeElement.muted = true;
      this.waveAnimation.nativeElement.play();
    };

    setTimeout(() => this.noAccessContainer.nativeElement.classList.add('on'), 1);
  }
}
