import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { BehaviorSubject, } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MODE_DATA, Mode } from '@mads/wm-ng-components';

import { environment } from '@environment';
import { externalAgenciesModeCodes } from '@config/constants';

export const MODES_ASSETS_PATH = 'assets/modes';

@Injectable()
export class ModeService {
  public currentMode = MODE_DATA.find((mode) => mode.code === environment.mode) || MODE_DATA[0];
  public isExternalAgencyModeOn = externalAgenciesModeCodes.includes(environment.mode);
  private renderer: Renderer2;

  public mode$ = new BehaviorSubject<Mode>({ ...this.currentMode }).pipe(
    tap((mode) => this.setTheme(mode.theme))
  );
  /** @deprecated - to be removed. Applications should use theme from mode$ instead */
  public theme$ = this.mode$.pipe(map((mode) => mode.theme));

  public currentLogoPath$ = this.mode$.pipe(
    map((mode) => `${MODES_ASSETS_PATH}/${mode.code}/logo.svg`)
  );

  public currentSmallLogoPath$ = this.mode$.pipe(
    map((mode) => `${MODES_ASSETS_PATH}/${mode.code}/logo-small.svg`)
  );

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setTheme(theme: string): void {
    this.renderer.addClass(document.body, theme);
  }
}
