
import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';
import { LocalAndGlobalPagesByTagsResponse } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocalAndGlobalPagesMatchingTagQuery extends Query<LocalAndGlobalPagesByTagsResponse> {
  document = gql`
  query GetLocalAndGlobalPagesMatchingTag($tag: String!, $userCountry: String!, $locale: String!, $userId: String!) {
    pages {
      pipSearch (tag: $tag, userCountry: $userCountry, locale: $locale, userId: $userId) {
        local {
          id
          path
          title
          description
          tags { tag }
          render
          locale
          isNewContent
        }
        global {
          id
          path
          title
          description
          tags { tag }
          render
          locale
          isNewContent
        }
      }
    }
  }
`;
}
