import { registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const INFLUENCER_VIEWER_ROUTE_MATCHER = 'influencer-viewer';

export const config: MicrofrontendAppConfig = {
  name: 'influencer-viewer-app',
  windowLibraryName: 'influencerViewerApp',
  baseHref: '/brands/:brandId/influencer-viewer/',
  assetsPath: environment.influencerViewerAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.influencerViewerAppUrl,
  appLocation: environment.influencerViewerAppUrl.replace('main.js', ''),
  // below - values to be used in local development
  // assetsPath: 'http://localhost:5500/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:5500/main.js',
  // appLocation: 'http://localhost:5500/main.js'.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname([INFLUENCER_VIEWER_ROUTE_MATCHER]),
};

export const registerInfluencerViewerApp = (
  customParams?: MicroAppParams
): MicrofrontendAppConfig => registerApp({ ...config, customParams });
