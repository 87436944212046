import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { filter, map } from 'rxjs/operators';
import { Mode, TrackerActionType } from '@mads/wm-ng-components';
import { sortBy } from 'lodash-es';

import { BrandsFacade } from 'app/state/brands';
import {
  Client,
  ClientManagementDrawerMode,
  ClientsFacade,
  Market,
  Region,
} from 'app/state/clients';
import { BreadcrumbsFacade } from 'app/state/breadcrumbs';

import { constants } from '@config/constants';
import { defaults } from '@config/defaults';
import { TrackerService } from 'app/core/tracker.service';
import { ProjectsFacade } from 'app/state/projects';
import { UsersFacade } from 'app/state/users';
import { getHasCanAddBrand } from 'app/components/client-creation/components/brands-list/utils';
import { environment } from '@environment';

interface RegionToDisplay extends Region {
  columnsWithMarkets: Market[][];
}

const MIN_MARKETS_IN_COLUMN_NUMBER = 3;
const MAX_MARKETS_COLUMN_NUMBER = 3;

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
})
export class MarketListComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() public client: Client;
  @Input() mode: Mode;

  hasCanAddBrand$ = this.usersFacade.myself$.pipe(map(getHasCanAddBrand));
  public brandsLoading$ = this.brandsFacade.loading$;
  public regionsToDisplay: RegionToDisplay[];
  public brands = [];
  public isShowedOnlyMineMarkets = true;
  public pitchStatus = constants.MARKET_STATUS.PITCH;
  public testStatus = constants.MARKET_STATUS.TEST;
  public expandedCountryCode: string;
  public DrawerMode = ClientManagementDrawerMode;
  public areAnyMarkets = false;
  public loadingProjectBuilderProjects$ = this.projectsFacade.loading$;

  private regionsList: Region[];

  constructor(
    private brandsFacade: BrandsFacade,
    private breadcrumbsFacade: BreadcrumbsFacade,
    private clientsFacade: ClientsFacade,
    private trackerService: TrackerService,
    private projectsFacade: ProjectsFacade,
    private usersFacade: UsersFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.breadcrumbsFacade.resetBreadcrumbs();
    this.getBrands();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.client?.currentValue) {
      this.createMarketsList();
    }
  }

  public handleRequestNewBrand(hasCanAddBrand: boolean): void {
    if (hasCanAddBrand) {
      return;
    }

    window.open(environment.formsUrl, '_blank');
  }

  private getBrands(): void {
    this.brandsFacade.brands$
      .pipe(
        untilComponentDestroyed(this),
        filter((brands) => !!brands)
      )
      .subscribe((brands) => {
        const completedBrands = brands.filter(
          (brand) => brand.orderStatus === defaults.creationStatus.SUCCESS
        );
        this.brands = sortBy(completedBrands, ['name']);
      });
  }

  private createMarketsList(): void {
    if (!this.client.markets) {
      return;
    }

    const allClientRegions = defaults.regions.map((region) => {
      const markets = this.client.markets.filter(
        (market) => market.region === region.id && !market.isArchived
      );
      return { ...region, markets: sortBy(markets, ['name']) };
    });
    this.regionsList = allClientRegions;

    this.isShowedOnlyMineMarkets
      ? this.filterRegionsList()
      : this.prepareMarketsToDisplay(this.regionsList);
  }

  private prepareMarketsToDisplay(regions: Region[]): void {
    this.regionsToDisplay = regions.map((region) => {
      const allMarkets = [...region.markets];
      const columnsWithMarkets = [];
      const columnsNumber = this.getMarketColumnsNumber(allMarkets.length);
      const marketsInColumnNumber = Math.ceil(allMarkets.length / columnsNumber);

      for (let i = 0; i < columnsNumber; i++) {
        const marketsInColumn = allMarkets.splice(0, marketsInColumnNumber);
        columnsWithMarkets.push(marketsInColumn);
      }

      return { ...region, columnsWithMarkets };
    });

    this.areAnyMarkets = this.regionsToDisplay.some((region) => region.markets.length > 0);
  }

  private getMarketColumnsNumber(marketsLenght: number): number {
    return marketsLenght > (MAX_MARKETS_COLUMN_NUMBER - 1) * MIN_MARKETS_IN_COLUMN_NUMBER
      ? MAX_MARKETS_COLUMN_NUMBER
      : Math.ceil(marketsLenght / MIN_MARKETS_IN_COLUMN_NUMBER);
  }

  public handleSectionClick(countryCode: string) {
    this.brands = [];
    this.brandsFacade.getBrands(this.client.id, countryCode);
    this.toggleCountryExpanded(countryCode);
    this.trackerService.trackAction({
      category: 'Markets list',
      type: TrackerActionType.click,
      details: `Market ${countryCode}`,
    });
  }

  private toggleCountryExpanded(countryCode: string): void {
    this.expandedCountryCode = this.expandedCountryCode === countryCode ? '' : countryCode;
  }

  public toggleMarketsFilter(): void {
    this.isShowedOnlyMineMarkets
      ? this.filterRegionsList()
      : this.prepareMarketsToDisplay(this.regionsList);
  }

  private filterRegionsList(): void {
    const regionsWithFilteredMarkets = this.regionsList.map((region) => {
      const markets = region.markets.filter((market) => market.mine);
      return { ...region, markets };
    });

    this.prepareMarketsToDisplay(regionsWithFilteredMarkets);
  }

  public makeRequestForMarket(countryCode: string): void {
    this.clientsFacade.sendMarketRequest(this.client.id, countryCode);

    const regionsWithPendingRequest = this.regionsList.map((region) => {
      const markets = region.markets.map((market) => ({
        ...market,
        pending: market.code === countryCode,
      }));
      return { ...region, markets };
    });
    this.prepareMarketsToDisplay(regionsWithPendingRequest);
  }
}
