import { Action, createReducer, on } from '@ngrx/store';

import * as fromPersonal from './personal.actions';
import { PersonalState, initialState } from './personal.model';

import { filter } from 'lodash-es';

const reducer = createReducer(
  initialState,
  on(fromPersonal.getRecentWork, (state: PersonalState) => ({
    ...state,
    loading: true,
  })),
  on(fromPersonal.getRecentWorkSuccess, (state: PersonalState, { recentWork }) => ({
    ...state,
    recentWork,
    loading: false,
  })),
  on(fromPersonal.getRecentWorkFailure, (state: PersonalState) => ({
    ...state,
    loading: false,
  })),
  on(fromPersonal.setLanguageCode, (state: PersonalState, { language }) => ({
    ...state,
    language,
  })),
  on(fromPersonal.setDateFormatLocale, (state: PersonalState, { dateFormatLocale }) => ({
    ...state,
    dateFormatLocale,
  })),
  on(fromPersonal.setNumberFormatLocale, (state: PersonalState, { numberFormatLocale }) => ({
    ...state,
    numberFormatLocale,
  })),
  on(fromPersonal.getFavouriteAppsSuccess, (state: PersonalState, { favouriteApps }) => {
    return {
      ...state,
      favouriteApps: filter(favouriteApps, (app) => app.favourite || app.pinned),
    };
  }),
  on(fromPersonal.getFavouriteAppsFailure, (state: PersonalState) => ({
    ...state,
  })),
  on(fromPersonal.setOpenForChallenges, (state: PersonalState) => ({
    ...state,
    loading: true,
  })),
  on(
    fromPersonal.setOpenForChallengesSuccess,
    (state: PersonalState, { consentToParticipate }) => ({
      ...state,
      loading: false,
      userOpenForWmChallenges: consentToParticipate,
    })
  ),
  on(fromPersonal.setOpenForChallengesFailure, (state: PersonalState, { error }) => ({
    ...state,
    loading: false,
  })),
  on(fromPersonal.getOpenForChallenges, (state: PersonalState) => ({
    ...state,
    loading: true,
  })),
  on(
    fromPersonal.getOpenForChallengesSuccess,
    (state: PersonalState, { consentToParticipate }) => ({
      ...state,
      loading: false,
      userOpenForWmChallenges: consentToParticipate,
    })
  ),
  on(fromPersonal.getOpenForChallengesFailure, (state: PersonalState, { error }) => ({
    ...state,
    loading: false,
  })),
  on(
    fromPersonal.checkPresentationModeSuccess,
    (state: PersonalState, { isInPresentationMode }) => ({
      ...state,
      isInPresentationMode,
    })
  )
);

export function personalReducer(state: PersonalState = initialState, action: Action) {
  return reducer(state, action);
}
