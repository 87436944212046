<div class="markets-list__container">
  <div class="markets-list__client markets-list__element">
    <div class="markets-list__client-header">
      <img [src]="client.logo" class="markets-list__client-logo" />
      <h4 class="wm-h4 markets-list__client-name">{{ client.name }}</h4>
    </div>
    <div *ngIf="client.description" class="markets-list__client-description">
      <p class="wm-text-small" translate="WM_HOME.MARKET_MANAGEMENT.CLIENT_DESCRIPTION"></p>
      <i-feather
        name="eye"
        class="markets-list__client-icon"
        [wmTooltip]="client.description"
      ></i-feather>
    </div>
  </div>
  <div *ngIf="hasMarketPermissions" class="markets-list__add markets-list__element">
    <button mat-fab (click)="handleAddMarket()" class="wm-button-add markets-list__add-button">
      <i-feather name="plus" class="markets-list__add-icon"></i-feather>
    </button>
    <div class="markets-list__add-info">
      <h6
        (click)="handleAddMarket()"
        class="wm-h6 markets-list__add-title"
        translate="WM_HOME.MARKET_MANAGEMENT.ADD_MARKET"
      ></h6>
      <p
        class="wm-caption markets-list__add-description"
        translate="WM_HOME.MARKET_MANAGEMENT.ADD_MARKET_FOR"
        [translateParams]="{ clientName: client.name }"
      ></p>
    </div>
  </div>
  <ul *ngIf="client.markets?.length" class="markets-list__list">
    <li
      *ngFor="let market of client.markets"
      (click)="handleSelect(market)"
      class="markets-list__element markets-list__element--market"
      [ngClass]="{
        'markets-list__element--active': market.code === activeMarketCode,
        'markets-list__element--disabled':
          !(market.isTeamDeleted && hasRecreateMarketPermission) && !market.isClientAdmin
      }"
    >
      <div class="markets-list__element-info">
        <h6 class="wm-h6 markets-list__element-name">{{ market.name }}</h6>
        <div class="markets-list__element-statuses">
          <app-market-status [status]="market.status"></app-market-status>
          <app-market-status
            status="inactive"
            text="{{ 'WM_HOME.MARKET_MANAGEMENT.DELETED' | translate }}"
            *ngIf="market.isTeamDeleted"
          ></app-market-status>
        </div>
      </div>
      <div (click)="$event.stopPropagation()" class="markets-list__element-actions">
        <button mat-icon-button [matMenuTriggerFor]="listActionsMenu">
          <i-feather class="markets-list__element-icon" name="more-vertical"></i-feather>
        </button>
        <mat-menu #listActionsMenu xPosition="after">
          <button
            mat-menu-item
            (click)="handleEdit(market)"
            *ngIf="!market.isTeamDeleted && market.isClientAdmin"
          >
            <span translate="WM_HOME.MARKET_MANAGEMENT.EDIT"></span>
          </button>
          <button
            mat-menu-item
            (click)="handleRecreate(market)"
            *ngIf="market.isTeamDeleted && hasRecreateMarketPermission"
          >
            <span translate="WM_HOME.MARKET_MANAGEMENT.RECREATE"></span>
          </button>
        </mat-menu>
      </div>
    </li>
    <li class="markets-list__element--empty"></li>
  </ul>
</div>
