import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HelpMenuCustomItem } from '@mads/wm-ng-components';

import { AppState } from '..';
import * as applicationsActions from './applications.actions';
import { Application } from './applications.model';
import * as fromApplications from './applications.selectors';

@Injectable()
export class ApplicationsFacade {
  hasCurrentProductTours$ = this.store.select(fromApplications.selectHasCurrentProductTour);
  applications$ = this.store.select(fromApplications.selectApplications);
  helpMenuCustomItems$ = this.store.select(fromApplications.selectHelpMenuCustomItems);

  constructor(private store: Store<AppState>, protected actions$: Actions) {}

  getApplicationFromStateById({ id }: { id: string }): Observable<Application> {
    return this.store.select(fromApplications.selectApplicationById(id));
  }

  getApplication({ id }: { id: string }): void {
    this.store.dispatch(applicationsActions.getApplication({ id }));
  }

  setHasCurrentProductTours({ hasCurrentProductTours }: { hasCurrentProductTours: boolean }): void {
    this.store.dispatch(applicationsActions.setHasCurrentProductTours({ hasCurrentProductTours }));
  }

  runProductTours(guideName: string | void): void {
    this.store.dispatch(applicationsActions.runProductTours({ guideName }));
  }

  addHelpMenuCustomItem(helpMenuCustomItem: HelpMenuCustomItem): void {
    this.store.dispatch(applicationsActions.addHelpMenuCustomItem({ helpMenuCustomItem }));
  }

  removeHelpMenuCustomItem(id: string): void {
    this.store.dispatch(applicationsActions.removeHelpMenuCustomItem({ id }));
  }

  onHelpMenuCustomItemClick(id: string): void {
    this.store.dispatch(applicationsActions.onHelpMenuCustomItemClick({ id }));
  }

  removeAllHelpMenuCustomItems(): void {
    this.store.dispatch(applicationsActions.removeAllHelpMenuCustomItems());
  }
}
