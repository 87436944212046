import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BrandsFacade } from 'app/state/brands';

@Injectable()
export class ResetBrandGuard {
  constructor(private readonly brandsFacade: BrandsFacade) {}

  canDeactivate(): Observable<boolean> {
    this.brandsFacade.resetBrand();
    return this.brandsFacade.selectedBrand$.pipe(
      filter(brand => !brand),
      map(() => true),
    );
  }
}
