import { HttpErrorResponse } from '@angular/common/http';
import { Client, Category, Market } from '../clients/clients.model';

export interface Country {
  name: string;
  code: string;
  currency?: string;
}

export interface Brand {
  id: string;
  tempId?: string;
  name: string;
  description?: string;
  orderStatus: string;
  contactPhone?: string;
  contactName?: string;
  contactEmail?: string;
  logo: string | File;
  logoUrl?: string;
  websiteUrl?: string;
  clientId: Client['id'];
  country: Country;
  categories: Category[];
  categoryIds?: string[];
  keywords?: string[];
  competitors?: string[];
  socialMediaLinks?: SocialMedia[];
  isClientAdmin?: boolean;
  market: Market;
}

export interface CreateBrandPayload {
  countryCode: string;
  clientId: string;
  brand: Partial<Brand>;
}

export enum BrandFormDataValidationKeys {
  CATEGORY_IDS = 'categoryIds',
  LOGO = 'logo',
  LOGO_URL = 'logoUrl',
}

export interface SocialMedia {
  name: string;
  url: string;
}

export interface BrandsState {
  brands: Brand[];
  selectedBrand: Brand;
  loading: boolean;
  isLogosLoading: boolean;
  logos: string[];
  selectedLogoUrl: string;
  error: HttpErrorResponse;
}

export const initialState: BrandsState = {
  brands: [],
  selectedBrand: null,
  loading: false,
  isLogosLoading: false,
  logos: [],
  selectedLogoUrl: null,
  error: null,
};

export const brandsFeatureKey = 'brands';

export const NEW_API_CATEGORIES_KEY = 'categoryGuids';
