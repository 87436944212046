<div class="content">
  <div class="wm-7xl-900 title"><span class="primary">Product Statistics</span> is live!</div>

  <div class="wm-l-400 middle-description">
    Explore OS performance to gather actionable insights! Find the new app on the top menu bar.
  </div>

  <div class="wm-l-400 strong">
    <span class="primary">PP Leaderboard</span> is now a part of Product Statistics and can be
    accessed from there.
  </div>
</div>
