<div [ngClass]="{ 'line': mode === ModeCodes.LOREAL }"></div>
<wm-wrapper class="wrapper" *ngIf="brand" [ngSwitch]="mode">
  <app-mondelez-brand-menu
    *ngSwitchCase="ModeCodes.MONDELEZ"
    [brand]="brand"
    [navigationCategories]="navigationCategories$ | async"
    (goToCart)="goToCart()"
    (clickNagivationItem)="onNavigationItemClick($event)"
  >
  </app-mondelez-brand-menu>

  <app-loreal-brand-menu
    *ngSwitchCase="ModeCodes.LOREAL"
    [brand]="brand"
    [navigationCategories]="navigationCategories$ | async"
    (goToCart)="goToCart()"
    (clickNagivationItem)="onNavigationItemClick($event)"
  >
  </app-loreal-brand-menu>

  <app-project-builder-brand-menu
    *ngSwitchCase="brandMenuModeCodeForProjectBuilder"
    [brand]="brand"
    (goToCart)="goToCart()"
  >
  </app-project-builder-brand-menu>

  <app-default-brand-menu
    *ngSwitchDefault
    [brand]="brand"
    [isWmMode]="isWmMode"
    [navigationCategories]="navigationCategories$ | async"
    (goToCart)="goToCart()"
    (clickNagivationItem)="onNavigationItemClick($event)"
  >
  </app-default-brand-menu>
</wm-wrapper>
