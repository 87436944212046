import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { debounceTime } from 'rxjs/operators';

import { User, WmMember } from 'app/state/users';

import { defaults } from 'app/config/defaults';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-selection',
  templateUrl: './users-selection.component.html',
  styleUrls: ['./users-selection.component.scss'],
})
export class UsersSelectionComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() myself: User;
  @Input() users: WmMember[];
  @Input() selectedUsers: WmMember[];
  @Input() disabledUsers: WmMember[];
  @Input() isLoading: boolean;
  @Input() isOwnersSelect: boolean;

  @Output() filterUsers = new EventEmitter<string>();
  @Output() selectUser = new EventEmitter<WmMember>();
  @Output() deleteUser = new EventEmitter<WmMember['id']>();

  public nameControl = new UntypedFormControl('');
  public usersList: WmMember[];

  readonly profilePictureApiUrl = environment.profilePicturesApiUrl;
  readonly requiredOwnersNumber = defaults.requiredTeamOwnersNumber;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.filterList();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.users?.currentValue ||
      changes.selectedUsers?.currentValue ||
      changes.disabledUsers?.currentValue
    ) {
      this.prepareUsersList();
    }
  }

  private filterList(): void {
    this.nameControl.valueChanges
      .pipe(debounceTime(500), untilComponentDestroyed(this))
      .subscribe((value) => {
        const userName = value?.trim();

        userName ? this.filterUsers.emit(userName) : (this.usersList = []);
      });
  }

  private prepareUsersList(): void {
    this.usersList = this.users.map((user) => {
      const isMyself = user.userPrincipalName === this.myself?.userPrincipalName;

      return {
        ...user,
        selected:
          this.selectedUsers?.some((selectedUser) => selectedUser.id === user.id) ||
          (this.isOwnersSelect && isMyself),
        disabled:
          this.disabledUsers?.some((disabledUser) => disabledUser.id === user.id) ||
          (!this.isOwnersSelect && isMyself),
      };
    });
  }

  public handleSelectUser(selectedUser: WmMember): void {
    const { selected, disabled, ...user } = selectedUser;
    this.selectUser.emit(user);
    this.nameControl.reset();
    this.usersList = [];
  }

  public handleDeleteUser(userId: WmMember['id']): void {
    this.deleteUser.emit(userId);
  }
}
