import { Component, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  isWMAgency = environment.isWMAgency;
}
