import { Injectable } from '@angular/core';
import { CanActivateChild, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, map, take, catchError } from 'rxjs/operators';
import { get } from 'lodash-es';

import { ClientsFacade } from 'app/state/clients';
import { BrandsFacade } from 'app/state/brands';
import { SnackbarService } from '../snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { RedirectToWppOpenPopupComponent } from 'app/shared/components/redirect-to-wpp-open-popup/redirect-to-wpp-open-popup.component';

@Injectable()
export class ClientAndBrandGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private brandsFacade: BrandsFacade,
    private clientsFacade: ClientsFacade,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const mainUrlTree = this.router.createUrlTree(['/']);
    const brandId = get(route.parent.params, 'brandId');
    if (!brandId) {
      return of(mainUrlTree);
    } // (I consider this impossible - but who knows :) )

    return this.brandsFacade.selectBrand$(brandId).pipe(
      take(1),
      switchMap((brand) => {
        // see brandsFacade - there will always be correct brand returned here, or error thrown
        if (!brand || brand.id !== brandId) {
          return of(mainUrlTree);
        } // therefore this is, again, impossible

        return this.clientsFacade.selectClient$(brand.clientId).pipe(
          take(1),
          switchMap(() => {
            const {
              market: { redirectToWppOpen },
            } = brand;

            if (redirectToWppOpen) {
              return this.dialog
                .open(RedirectToWppOpenPopupComponent, { disableClose: true })
                .afterClosed()
                .pipe(map((redirect) => (redirect ? mainUrlTree : false)));
            }

            return of(true);
          }) // clientsFacade returns correct client here or throws an error
        );
      }),
      catchError((err) => {
        this.snackbarService.showError(err);
        return of(mainUrlTree);
      })
    );
  }
}
