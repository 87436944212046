import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, combineLatest, filter, map, tap } from 'rxjs';

import { environment } from '@environment';
import { Role } from '@mads/wm-ng-components';
import { UsersFacade } from 'app/state/users';
import { TrackerService } from 'app/core/tracker.service';

import { DialogData, WelcomePopupConfigItem } from './welcome-popup.types';
import { WelcomePopupComponent } from './welcome-popup.component';

import { welcomePopupConfig } from '@config/welcome-popup.config';

@Injectable({
  providedIn: 'root',
})
export class WelcomePopupService {
  private currentConfig!: WelcomePopupConfigItem;
  private dialogRef!: MatDialogRef<unknown>;
  private alternativeCallToActionEnabled!: boolean;

  private myself$ = this.usersFacade.myself$.pipe(filter((myself) => !!myself));

  private navigationEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd)
  );

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private usersFacade: UsersFacade,
    private trackerService: TrackerService
  ) {}

  init(): Observable<WelcomePopupConfigItem> {
    return combineLatest([this.myself$, this.navigationEnd$]).pipe(
      map(([myself]) => {
        this.currentConfig = this.getSelectedConfig(welcomePopupConfig, myself.userRoles);

        this.alternativeCallToActionEnabled =
          this.currentConfig?.alternativeCallToAction?.enable(myself) ?? false;
      }),
      map(() => this.currentConfig),
      filter((configItem) => !!configItem),
      tap(() => {
        this.show();
      })
    );
  }

  private getSelectedConfig(
    config: Array<WelcomePopupConfigItem>,
    userRoles: Array<Role>
  ): WelcomePopupConfigItem {
    const lockedPopupList = this.getLockedPopupList();

    const filtered = config.filter((configItem) => {
      const isLocked = lockedPopupList.includes(configItem.lockKey);
      if (isLocked) {
        return false;
      }

      const isRouteValid =
        !configItem.filters.routes || configItem.filters.routes.includes(this.router.url);
      if (!isRouteValid) {
        return false;
      }

      const isStartDateValid =
        !configItem.filters.startDate || new Date(configItem.filters.startDate) < new Date();
      if (!isStartDateValid) {
        return false;
      }

      const isEndDateValid =
        !configItem.filters.endDate || new Date(configItem.filters.endDate) > new Date();
      if (!isEndDateValid) {
        return false;
      }

      const isPermissionValid =
        !configItem.filters.permissionKey ||
        userRoles.some(
          (role) =>
            role.applicationId === (configItem.filters.appId ?? environment.appsIds.appId) &&
            role.permissions.some(
              (permission) => permission.key === configItem.filters.permissionKey
            )
        );
      if (!isPermissionValid) {
        return false;
      }

      return true;
    });

    filtered.sort((itemA, itemB) => (itemA.priority > itemB.priority ? 1 : -1));
    return filtered[0];
  }

  private show() {
    const lockedPopupList = this.getLockedPopupList();
    lockedPopupList.push(this.currentConfig.lockKey);
    localStorage.setItem('WM_WELCOME_POPUP_LOCKED', JSON.stringify(lockedPopupList));

    this.trackerService.trackAction(this.currentConfig.trafficTrackerActions.show);

    const data: DialogData = {
      lottieAsset: this.currentConfig.lottieAsset,
      callToActionText: this.currentConfig?.callToActionText ?? 'Go to the app',
      contentComponent: this.currentConfig.contentComponent,
      alternativeCallToActionEnabled: this.alternativeCallToActionEnabled,
      alternativeCallToAction: this.currentConfig?.alternativeCallToAction ?? null,
    };
    this.dialogRef = this.dialog.open(WelcomePopupComponent, { data });
    this.dialogRef.addPanelClass('welcome-popup-panel');

    this.dialogRef.afterClosed().subscribe((skipTracking) => {
      if (skipTracking) {
        return;
      }

      this.trackerService.trackAction(this.currentConfig.trafficTrackerActions.close);
    });
  }

  private getLockedPopupList(): Array<string> {
    try {
      return JSON.parse(localStorage.getItem('WM_WELCOME_POPUP_LOCKED')) || [];
    } catch (e) {
      console.error('WM - Error: Can not parse WM_WELCOME_POPUP_LOCKED from localStorage', e);
      return [];
    }
  }

  consumeGoToApp(): void {
    this.dialogRef.close(true);
    this.trackerService.trackAction(this.currentConfig.trafficTrackerActions.goToApp);
  }

  consumeAlternativeCallToAction(): void {
    this.dialogRef.close(true);
    this.trackerService.trackAction(
      this.currentConfig.trafficTrackerActions.alternativeCallToAction ??
        this.currentConfig.trafficTrackerActions.goToApp
    );
  }

  targetPage(): string {
    if (this.currentConfig.isTargetPageDefaultBrand) {
      return `defaultbrand${this.currentConfig.targetPage}`;
    }

    return this.currentConfig.targetPage;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}
