<div #noAccessContainer class="no-access">
  <video autoplay muted="true" #waveAnimation>
    <source src="assets/wave_login.webm"
            type='video/webm;codecs="vp8, vorbis"'/>
  </video>
  <div class="no-access__content">
    <img [src]="currentLogoPath$ | async" alt="agency logo" class="small-logo"/>

    <h3 class="no-access__content__title">It seems that you don't have access.</h3>
    <p class="no-access__content__info">
      Currently, access to Wavemaker OS is restricted. Your Champion will let you know once your access has been granted! Stay tuned!
      <br>If you have any problems logging in please contact:
      <a [href]="'mailto:' + supportMail">{{ supportMail }}</a>
    </p>
  </div>
</div>
