import { maxBy } from "lodash-es"
import { Brand, Myself } from "@mads/wm-ng-components";

import { Project } from "@interfaces"

export const getNewestProjectForBrand = (projects: Project[], brandId: Brand['id']): Project => {
  const filtered = projects.filter(project => project.brand?.includes(brandId));
  return maxBy(filtered, project => new Date(project.createdAt).getTime());
}

export const checkIfUserIsInvolvedInProject = (project: Project, user: Myself): boolean =>
  project.createdBy === user.id
  || project.columns.some(column =>
      column.tasks.some(task =>
        task.assignees?.includes(user.userPrincipalName)
      )
    );
