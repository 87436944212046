<div mat-dialog-content class="popup__content">
  <p
    *ngFor="let description of config?.body"
    [translateParams]="config?.bodyParams"
    class="popup__content__description"
    [translate]="description"
  ></p>
</div>
<div mat-dialog-actions class="popup__actions">
  <button
    mat-button
    [mat-dialog-close]="true"
    data-test="WM_HOME.CONFIRMATION_POPUP.CONFIRM_BUTTON"
  >
    <span [translate]="config?.confirmation"></span>
  </button>
  <button mat-button (click)="cancel()">
    <span [translate]="config?.cancellation"></span>
  </button>
  <div *ngIf="config?.extraInfo">
    <p class="popup__extra-info" [translate]="config.extraInfo"></p>
  </div>
</div>
