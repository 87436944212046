<div class="personal-container">
  <h3 class="personal-container__title" translate="WM_HOME.MAIN_PAGE.RECENT_WORK_TITLE"></h3>
  <div class="slider-container">
    <div class="slider-container__tile slider-container__widget"></div>
    <app-workspace-widget
      [@resizeWidget]="isClientWidgetExpanded"
      [isExpanded]="isClientWidgetExpanded"
      (toggleWidget)="toggleWidget($event)"
      [class.slider-container__widget-expanded]="isClientWidgetExpanded"
      class="slider-container__tile slider-container__widget expanded-index"
    ></app-workspace-widget>
    <div class="clients-container">
      <app-clients-slider></app-clients-slider>
    </div>
  </div>

  <app-apps-slider-wrapper *ngIf="isAppsDeployed"></app-apps-slider-wrapper>
</div>
<div *ngIf="isClientWidgetExpanded" class="blur-background" (click)="toggleWidget(false)"></div>
