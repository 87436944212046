import { registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const CONSUMER_EXPERIENCE_INSIGHTS_ROUTE_MATCHER = 'consumer-experience-insights';

export const config: MicrofrontendAppConfig = {
  name: 'consumer-experience-insights-app',
  windowLibraryName: 'consumerExperienceInsightsApp',
  assetsPath: environment.consumerExperienceInsightsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/consumer-experience-insights/',
  appUrl: environment.consumerExperienceInsightsAppUrl,
  appLocation: environment.consumerExperienceInsightsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname([CONSUMER_EXPERIENCE_INSIGHTS_ROUTE_MATCHER]),
};

export const registerConsumerExperienceInsightsApp = (
  customParams?: MicroAppParams
): MicrofrontendAppConfig => registerApp({ ...config, customParams });
