import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'mp-results',
  windowLibraryName: 'mpResultsApp',
  assetsPath: environment.mediaPlanResultsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/campaigns/results',
  appUrl: environment.mediaPlanResultsAppUrl,
  appLocation: environment.mediaPlanResultsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['campaigns/results']),
};

export const registerMediaPlansResultsApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
