import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromApplications from './applications.actions';
import { ApplicationsHttpService } from 'src/app/core/http/applications-http.service';
import { GuideManagerService } from '@mads/wm-ng-components';
import { isString } from 'lodash-es';

@Injectable()
export class ApplicationsEffects {
  constructor(
    private actions$: Actions,
    private httpSerivce: ApplicationsHttpService,
    private guideManagerService: GuideManagerService,
  ) {}

  getApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromApplications.getApplication),
      switchMap(({ id }) =>
        this.httpSerivce.getApplication({ id }).pipe(
          map((application) => fromApplications.getApplicationSuccess({ application })),
          catchError((error) => of(fromApplications.getApplicationFailure({ error, id })))
        )
      )
    )
  );

  runProductTours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromApplications.runProductTours),
      map(({ guideName }) => {
        if (!isString(guideName)) { return; }
        this.guideManagerService.startGuide(guideName);
      }),
    ), {dispatch: false});

}
