import { HttpParams } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

import { environment } from 'app/../environments/environment';
import { defaults } from '@config/defaults';

import { assign, isNil, forIn, toString } from 'lodash-es';

export class BaseHttpService {
  static CLIENT_DATA_API_URL = environment.clientDataApiUrl;
  static APPS_API_URL = environment.appsDataApiUrl;
  static AUTH_API_URL = environment.authAppUrl;
  static NEWS_API_URL = environment.newsApiUrl;
  static YOU_API_URL = environment.youApi;
  static TRACKER_API_URL = environment.trackerApi;
  static PROJECT_BUILDER_API_URL = environment.projectBuilderApi;
  static WPP_OPEN_API_URL = environment.wppOpenApiUrl;
  static WPP_OPEN_TENANT_PREFIX = environment.wppOpenTenantPrefix;
  static ERROR_TYPE = 'HTTP_ERROR';

  static httpOptions = {
    withCredentials: true,
  };

  public handleError(err: Error): Observable<never> {
    assign(err, { type: BaseHttpService.ERROR_TYPE });
    return throwError(err);
  }

  public handleRequest<T>(requestObservable: Observable<T>): Observable<T> {
    return requestObservable.pipe(catchError((err) => this.handleError(err)));
  }

  public parseQueryParams(params): HttpParams {
    let queryParams = new HttpParams();
    forIn(params, (value, key) => {
      if (isNil(value)) {
        return;
      }

      queryParams = queryParams.append(key, toString(value));
    });
    return queryParams;
  }

  public useMockup(mockupData: any): Observable<any> {
    const delayRange = defaults.maxMockupAPICallDelayMs + 1;
    const delayMs = Math.floor(Math.random() * delayRange);

    return of(mockupData).pipe(delay(delayMs));
  }
}
