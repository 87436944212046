import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { Client, ClientsState, Market, ClientCreationRejectPayload } from 'app/state/clients';
import { prepareClientFormData } from 'app/state/clients/clients.utils';

@Injectable()
export class ClientsHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getClients(): Observable<ClientsState['clients']> {
    const request = this.http.get<ClientsState['clients']>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients`,
      {
        ...ClientsHttpService.httpOptions,
      }
    );

    return this.handleRequest<ClientsState['clients']>(request);
  }

  public getFilteredClients(clientName: Client['name']): Observable<ClientsState['clients']> {
    const request = this.http.get<ClientsState['clients']>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/search?clientName=${clientName}`,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<ClientsState['clients']>(request);
  }

  public getClient(payload: { id: string }): Observable<Client> {
    const request = this.http.get<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${payload.id}`,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }

  public getClientWithDetails(payload: { id: string }): Observable<Client> {
    const request = this.http.get<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/details/${payload.id}`,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }

  public sendRequestForMarket({
    clientId,
    countryCode,
  }: {
    clientId: string;
    countryCode: string;
  }): Observable<Client> {
    const request = this.http.post<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/request-access`,
      { countryCode },
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }

  public getMarket(clientId: Client['id'], marketCode: Market['code']): Observable<Market> {
    const request = this.http.get<Market>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/markets/${marketCode}`,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Market>(request);
  }

  public createMarket(clientId: Client['id'], market: Partial<Market>): Observable<void> {
    const request = this.http.post<void>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/markets`,
      market,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<void>(request);
  }

  public updateMarket(clientId: Client['id'], market: Market): Observable<void> {
    const request = this.http.put<void>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/markets/${market.code}`,
      market,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<void>(request);
  }

  public recreateMarket(clientId: Client['id'], market: Market): Observable<Market> {
    const request = this.http.put<Market>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/markets/${market.code}/recreate-team`,
      market,
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Market>(request);
  }

  public createClient(client: Client): Observable<Client> {
    const request = this.http.post<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients`,
      prepareClientFormData(client),
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }

  public updateClient(payload: { client: Client; id: string }): Observable<Client> {
    const request = this.http.put<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${payload.id}`,
      prepareClientFormData(payload.client),
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }

  public getCategories(): Observable<ClientsState['categories']> {
    const request = this.http.get<ClientsState['categories']>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/categories`,
      ClientsHttpService.httpOptions
    );
    return this.handleRequest<ClientsState['categories']>(request);
  }

  public rejectClientCreationRequest({
    id,
    rejectReason,
  }: ClientCreationRejectPayload): Observable<void> {
    const request = this.http.delete<void>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${id}?rejectReason=${rejectReason}`,
      {
        ...ClientsHttpService.httpOptions,
      }
    );

    return this.handleRequest<void>(request);
  }

  public acceptClientCreationRequest(payload: { id: string }): Observable<Client> {
    const request = this.http.put<Client>(
      `${ClientsHttpService.CLIENT_DATA_API_URL}/clients/${payload.id}`,
      {},
      ClientsHttpService.httpOptions
    );

    return this.handleRequest<Client>(request);
  }
}
