<wm-wrapper *ngIf="client$ | async as client" class="wrapper">
  <div class="container" [ngClass]="mode.code">
    <app-entity-menu-header class="menu-header-container" [entity]="client" [mode]="mode.code">
      <p class="wm-text-small description">{{ client.description }}</p>
      <i-feather
        *ngIf="isClientAdmin$ | async"
        appHasPermissionInApp
        [permissions]="canAddClientPermissionKey"
        [applicationId]="clientManagementAppId"
        [routerLink]="['/clients/client-creation', client.id, 'client-management']"
        name="edit-3"
        class="edit-button"
        editButton
      ></i-feather>
      <button
        appHasPermissionInApp
        [permissions]="canAddMarketPermissionKey"
        [applicationId]="clientManagementAppId"
        mat-stroked-button
        addButton
        [routerLink]="['/clients/client-creation', client.id, 'markets-management']"
        [queryParams]="{ open: true, drawerMode: DrawerMode.NEW_MARKET }"
        class="wm-button-tertiary wm-auto-size"
        translate="WM_HOME.HEADER.ADD_MARKET_BUTTON"
      ></button>
    </app-entity-menu-header>
    <app-market-list class="markets-container" [client]="client" [mode]="mode"></app-market-list>
  </div>
</wm-wrapper>
