import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { PersonalHttpService } from 'app/core/http/personal-http.service';
import { AppState } from 'app/state/index';

import * as fromPersonal from './personal.actions';
import { defaults } from '@config/defaults';

@Injectable()
export class PersonalEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private http: PersonalHttpService,
    private translate: TranslateService
  ) { }

  getFavouriteApps$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.getFavouriteApps),
    withLatestFrom(this.store$),
    switchMap(([payload, store]: [Action, AppState]) =>
      this.http.getPersonalApps(store.personal.language).pipe(
        map((favouriteApps => fromPersonal.getFavouriteAppsSuccess({ favouriteApps })),
          catchError((error) => of(fromPersonal.getFavouriteAppsFailure(error))))
      )
    )
  ));

  goToFavouriteApp$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.goToFavouriteApp),
    switchMap(({ app }) =>
      this.http.updateAppLastUseDate({ app }).pipe(
        switchMap(() => [
          fromPersonal.getFavouriteApps(),
          fromPersonal.openFavouriteAppTab(app),
        ]),
        catchError((error) => of(fromPersonal.goToFavouriteAppFailure(error)))
      )
    )
  ));

  initLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.initLanguage),
    withLatestFrom(this.store$),
    map(([action, store]) => {
      const language = store.personal.language;

      if (language.length === 2) {
        // temporary fallback from two letters code to four letter code
        const foundLang = defaults.languages.find(lang => lang.code.split('-')[0] === language);

        this.translate.setDefaultLang(defaults.defaultLanguageCode);
        return fromPersonal.setLanguageCode({ language: foundLang ? foundLang.code : defaults.defaultLanguageCode });
      }

      if (language) {
        return fromPersonal.setLanguageCode({ language });
      }

      this.translate.setDefaultLang(defaults.defaultLanguageCode);
      return fromPersonal.setLanguageCode({ language: `${this.translate.getDefaultLang()}` });
    })
  ));

  setLanguageCode$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.setLanguageCode),
    tap(({ language }) => this.translate.use(language))
  ), { dispatch: false });

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.openFavouriteAppTab),
    switchMap(({ url }) => {
      window.open(url, '_blank');
      return of({ type: 'noop' });
    })
  ));

  checkPresentationMode$ = createEffect(() => this.actions$.pipe(
    ofType(fromPersonal.checkPresentationMode),
    switchMap(() =>
      this.http.checkPresentationMode().pipe(
        map((isInPresentationMode => fromPersonal.checkPresentationModeSuccess({ isInPresentationMode })),
        // swallowing errors as this serves only as a block to a temprary survey modal:
        catchError((error) => of({ type: 'noop' })))
      )
    )
  ));
}
