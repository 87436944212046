import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

@Injectable()
export class ErrorGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): UrlTree | boolean {
    const currentNav = this.router.getCurrentNavigation();
    const error = currentNav.extras.state?.error;

    return !!error || this.router.createUrlTree(['']);
  }
}
