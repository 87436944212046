import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';

import { RouterFacade } from 'app/state/router';

import { DialogData } from './welcome-popup.types';
import { WelcomePopupService } from './welcome-popup.service';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePopupComponent {
  animationOptions!: AnimationOptions;
  contentComponent!: ComponentType<unknown>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private routerFacade: RouterFacade,
    private welcomePopupService: WelcomePopupService
  ) {
    this.animationOptions = {
      path: data.lottieAsset,
    };
    this.contentComponent = data.contentComponent;
  }

  goToApp(): void {
    if (this.data.alternativeCallToActionEnabled) {
      // opens link in new tab
      window.open(this.data.alternativeCallToAction.url, '_blank');
      this.welcomePopupService.consumeAlternativeCallToAction();
      return;
    }

    const link = this.welcomePopupService.targetPage();
    this.routerFacade.changeRoute({
      linkParams: [link],
    });
    this.welcomePopupService.consumeGoToApp();
  }

  closeModal(): void {
    this.welcomePopupService.closeDialog();
  }
}
