import { Component, OnInit } from '@angular/core';

import { filter } from 'rxjs/operators';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { TrackerActionType } from '@mads/wm-ng-components';

import { environment } from '@environment';
import { NEWS_DRAWER_MATOMO_TITLE } from '@config/titles';

import { NewsSidebarFacade, NewsArticle } from 'app/state/news-sidebar';
import { RouterFacade } from 'app/state/router';
import { TrackerService } from 'app/core/tracker.service';
import { constants } from '@config/constants';

@Component({
  selector: 'app-news-sidebar',
  templateUrl: './news-sidebar.component.html',
  styleUrls: ['./news-sidebar.component.scss'],
})
export class NewsSidebarComponent extends OnDestroyMixin implements OnInit {
  public newsList: NewsArticle[];
  public newsListWithFailsafeImages: any[];
  public newsSidebarOpened: boolean;
  public loading$ = this.newsSidebarFacade.loading$;
  public loadingError$ = this.newsSidebarFacade.loadingError$;
  public imageError = false;
  public requestPending: boolean;
  public restRowsOfTiles: number[];
  public firstRowTileCount = 3;
  public maxTilesInGridRow = 2;
  public failsafeImagesMaxIndex = 9;
  public currentFailsafeIndex = 1;
  public minimalNewsTilesToDisplay = 5;

  constructor(
    private routerFacade: RouterFacade,
    private newsSidebarFacade: NewsSidebarFacade,
    private trackerService: TrackerService
  ) {
    super();
  }

  public trackClick(details: string): void {
    this.trackerService.trackAction({
      category: 'News',
      type: TrackerActionType.click,
      details,
    });
  }

  public ngOnInit(): void {
    this.getNews();
    this.getNewsSidebarOpened();
  }

  public closeDrawer(): void {
    this.newsSidebarFacade.closeNewsSidebar();
  }

  public hideImage(): void {
    this.imageError = true;
  }

  public navigateToLatestNews(): void {
    this.navigateTo('news/latest');
  }

  public navigateTo(path: string): void {
    this.newsSidebarFacade.closeNewsSidebar();
    this.routerFacade.changeRoute({
      linkParams: [path],
    });
  }

  private getNews(): void {
    this.loading$.pipe(untilComponentDestroyed(this)).subscribe((request) => (this.requestPending = request));
    this.newsSidebarFacade.myNews$
      .pipe(
        untilComponentDestroyed(this),
        filter((news) => !!news)
      )
      .subscribe((news) => {
        if (!this.requestPending && news && news.length > 0 ) {
          this.currentFailsafeIndex = 1;
          this.newsList = news.map(
            obj => !obj.imageObject?.image ? {...obj, newsFailsafeImage: this.imageIndex()} : obj
          );
          this.newsList.length = (this.newsList.length > this.minimalNewsTilesToDisplay)
            ? 2 * Math.floor(this.newsList.length / 2) - 1
            : this.newsList.length;
          this.generateLoopForPatchyGrid();
        }
      });
  }

  private getNewsSidebarOpened(): void {
    this.newsSidebarFacade.opened$
      .pipe(untilComponentDestroyed(this))
      .subscribe((newsSidebarOpened) => {
        this.newsSidebarOpened = newsSidebarOpened;
        if (!this.newsSidebarOpened || !environment.matomoId) {
          return;
        }
        this.trackerService.setDocumentTitleForDrawers(NEWS_DRAWER_MATOMO_TITLE);
      });
  }

  goToNews(news: NewsArticle): void {
    this.trackerService.setDocumentTitle('WMOS>News>Drawer');
    const isPinnedLabel = news.isPinned ? 'PinnedNewsArticleOpen' : 'NotPinnedNewsArticleOpen';
    this.trackClick(`${isPinnedLabel}: ${news.title}`);
    window.open(news.url, '_blank');
  }

  onClickedOutside() {
    if (this.newsList?.length && this.newsSidebarOpened && !this.requestPending) {
      this.closeDrawer();
    }
  }

  generateLoopForPatchyGrid(): void {
    const count = Math.ceil((this.newsList.length - this.firstRowTileCount) / this.maxTilesInGridRow);
    this.restRowsOfTiles = Array(count).fill(0).map((x, i) => i);
  }

  calculateIndexForPatchyGrid(rowIndex: number): number {
    return rowIndex * this.maxTilesInGridRow + this.firstRowTileCount;
  }

  imageIndex(): string {
    if (this.currentFailsafeIndex > this.failsafeImagesMaxIndex) {
      this.currentFailsafeIndex = 1;
    }
    return `url(${constants.NEWS_FAILSAFE_IMAGES_FOLDER}${this.currentFailsafeIndex++}.png)`;
  }
}
