import { Action, createReducer, on } from '@ngrx/store';
import { omit } from 'lodash-es';

import { initialState, ProjectsState } from './projects.model';
import * as fromProjects from './projects.actions';
import { getNewestProjectForBrand } from './projects.utils';

const reducer = createReducer(
  initialState,
  on(
    fromProjects.getProjects,
    (state) => ({ ...state, loading: true })
  ),
  on(
    fromProjects.getProjectsFail,
    (state) => ({ ...state, loading: false })
  ),
  on(
    fromProjects.getProjectsSuccess,
    (state, { projects }) => ({
      ...state,
      projects,
      loading: false,
    })
  ),
  on(
    fromProjects.selectProject,
    (state, payload) => ({
      ...state,
      selections: [
        ...state.selections.filter(selection => selection.brandId !== payload.brandId),
        omit(payload, 'type'),
      ],
    }),
  ),
  on(
    fromProjects.setProjectMode,
    (state, {newSetting}) => ({
      ...state,
      projectModeSettings: [
        ...state.projectModeSettings.filter(setting => setting.brandId !== newSetting.brandId),
        {
          ...newSetting,
          newestProjectCreation: getNewestProjectForBrand(state.projects, newSetting.brandId).createdAt,
        },
      ],
    }),
  ),
  on(
    fromProjects.setProjectModeBulk,
    (state, {newSettings}) => ({
      ...state,
      projectModeSettings: [
        ...state.projectModeSettings.filter(setting => newSettings.every(newSetting => newSetting.brandId !== setting.brandId)),
        ...newSettings,
      ],
    }),
  ),
  on(
    fromProjects.resetProjects,
    (state) => ({
      ...state,
      projects: initialState.projects,
    }),
  ),
  on(
    fromProjects.setSettingsFromChild,
    (state, { selections, modeSettings }) => {
      const removeDuplicates = (arr, map = new Map()) => {
        arr.forEach(o => map.set(o.brandId, o));
      
        return [...map.values()];
      };

      // removes duplicates by keeping the last occurence 
      const newSelections = removeDuplicates([...state.selections, ...selections])
      const newModeSettings = removeDuplicates([...state.projectModeSettings, ...modeSettings]);

      return {
        ...state,
        selections: newSelections,
        projectModeSettings: newModeSettings,
      };
    },
  ),
);

export function projectsReducer(state: ProjectsState | undefined, action: Action): ProjectsState {
  return reducer(state, action);
}
