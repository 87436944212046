import { defaults } from './defaults';
import { colors } from './colors';
import { countries } from './countries';
import { constants } from './constants';
import { notifications } from './notifications';
import { contextualHelpTags } from './contextual-help-tags';
import { matomoSections } from './matomo-sections';
import { titles } from './titles';

export const config = {
  defaults,
  colors,
  countries,
  constants,
  notifications,
  contextualHelpTags,
  matomoSections,
  titles,
};

export * from './box/navigation/common';
