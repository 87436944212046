import { matchingBrandPathname, registerApp, MicrofrontendAppConfig } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'cart-app',
  windowLibraryName: 'wmCart',
  assetsPath: environment.cartAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/cart/',
  appUrl: environment.cartAppUrl,
  appLocation: environment.cartAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['cart']),
};

export const registerCartApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
