import { createAction, props } from '@ngrx/store';
import { NewsArticle, NewsImage } from './news-sidebar.model';

export const openNewsSidebar = createAction('[News] Open News Sidebar');
export const closeNewsSidebar = createAction('[News] Close News Sidebar');

export const getMyNews = createAction('[News] Get My News', props<{ limit: number }>());
export const getMyNewsSuccess = createAction(
  '[News] Get My News Success',
  props<{ myNews: NewsArticle[] }>()
);
export const getMyNewsFailure = createAction(
  '[News] Get My News Failure',
  props<{ error: string }>()
);

export const getNewsImages = createAction(
  '[News] Get News Images',
  props<{ newsIds: number[] }>()
);

export const getNewsImagesSuccess = createAction(
  '[News] Get News Images Success',
  props<{ newsImages: NewsImage[] }>()
);

export const getNewsImagesFail = createAction(
  '[News] Get News Images Fail',
  props<{ error: string }>()
);

export const setPersonalMainVisited = createAction('[News] Set Personal Main Visited');
