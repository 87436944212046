import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { Breadcrumbs } from './breadcrumbs.model';
import * as fromBreadcrumbsActions from './breadcrumbs.actions';
import * as fromBreadcrumbsSelectors from './breadcrumbs.selectors';

@Injectable()
export class BreadcrumbsFacade {
  public breadcrumbs$ = this.store.select(fromBreadcrumbsSelectors.selectBreadcrumbs);

  constructor(private store: Store<AppState>) {}

  public updateHeaderBreadcrumbs(updatedBreadcrumbs: Breadcrumbs): void {
    this.store.dispatch(fromBreadcrumbsActions.updateHeaderBreadcrumbs({ updatedBreadcrumbs }));
  }

  public resetBreadcrumbsDetails(): void {
    this.store.dispatch(fromBreadcrumbsActions.resetBreadcrumbsDetails());
  }

  public resetBreadcrumbs(): void {
    this.store.dispatch(fromBreadcrumbsActions.resetBreadcrumbs());
  }
}
