<div class="header-nav guide-text-style">
  <wm-guide-step
    [guideName]="'PROJECT_BUILDER_TOUR'"
    [stepName]="'headerNav'"
    >
  </wm-guide-step>
</div>

<!-- DREAM The following section will be moved to the You app, some day. -->
<div class="you-page-simulate guide-text-style" *ngIf="isOnYouPage">
  <div class="you-page-drawer-simulate"></div>
  <div class="you-page-dashboard-simulate">
    <div class="positioning">

      <div class="nav-1">
        <wm-guide-step
          [guideName]="'PROJECT_BUILDER_TOUR'"
          [stepName]="'sidebar1'"
          >
        </wm-guide-step>
      </div>

      <div class="nav-2">
        <wm-guide-step
          [guideName]="'PROJECT_BUILDER_TOUR'"
          [stepName]="'sidebar2'"
          >
        </wm-guide-step>
      </div>

    </div>
  </div>
</div>
