import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'growth-opportunities-app',
  windowLibraryName: 'growthOpportunitiesApp',
  assetsPath: environment.growthOpportunitiesAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/growth-opportunities',
  appUrl: environment.growthOpportunitiesAppUrl,
  matchingStrategy: matchingBrandPathname(['growth-opportunities']),
  appLocation: environment.growthOpportunitiesAppUrl.replace('main.js', ''),
};

export const registerGrowthOpportunitiesApp = (customParams?: any) =>
registerApp({ ...config, customParams });

