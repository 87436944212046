import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Brand } from 'app/state/brands';
import { prepareBrandFormData } from 'app/state/brands/brands.utils';
import { BaseHttpService } from './base-http.service';

@Injectable()
export class BrandsHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getBrands(payload: { id: string; countryCode: string }): Observable<Brand[]> {
    const request = this.http.get<Brand[]>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/clients/${payload.id}/${payload.countryCode}/brands`,
      BrandsHttpService.httpOptions
    );

    return this.handleRequest<Brand[]>(request);
  }

  public getBrand(payload: { id: string }): Observable<Brand> {
    const request = this.http.get<Brand>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/brands/${payload.id}`,
      BrandsHttpService.httpOptions
    );

    return this.handleRequest<Brand>(request);
  }

  public createBrand(payload: {
    countryCode: string;
    clientId: string;
    brand: Partial<Brand>;
  }): Observable<Brand> {
    const { countryCode, clientId, brand } = payload;

    const request = this.http.post<Brand>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/clients/${clientId}/${countryCode}/brands`,
      prepareBrandFormData(brand),
      BrandsHttpService.httpOptions
    );

    return this.handleRequest<Brand>(request);
  }

  public updateBrand(payload: { brand: Partial<Brand> }): Observable<Brand> {
    const brand = payload.brand;

    const request = this.http.put<Brand>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/brands/${brand.id}`,
      prepareBrandFormData(brand),
      BrandsHttpService.httpOptions
    );

    return this.handleRequest<Brand>(request);
  }

  public deleteBrand(payload: { brandId: Brand['id'] }): Observable<void> {
    const request = this.http.delete<void>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/brands/${payload.brandId}`
    );

    return this.handleRequest<void>(request);
  }

  public getLogos(payload: { name: string; limit: number }): Observable<string[]> {
    const request = this.http.get<string[]>(
      `${BrandsHttpService.CLIENT_DATA_API_URL}/brands/logos?brandName=${payload.name}&limit=${payload.limit}&page=1`,
      BrandsHttpService.httpOptions
    );

    return this.handleRequest<string[]>(request);
  }
}
