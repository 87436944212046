import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'architect-junior-app',
  windowLibraryName: 'architect-junior-app',
  assetsPath: environment.expressScenariosAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/express-scenarios',
  appUrl: environment.expressScenariosAppUrl,
  appLocation: environment.expressScenariosAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['express-scenarios']),
};

export const registerExpressScenariosApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
