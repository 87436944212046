import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'campaigns-setup',
  windowLibraryName: 'campaignsSetupApp',
  assetsPath: environment.campaignsSetupAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/campaigns/setup',
  appUrl: environment.campaignsSetupAppUrl,
  appLocation: environment.campaignsSetupAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['campaigns/setup']),
};

export const registerCampaignsSetupApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
