<wm-wrapper>
  <div class="add-market-failure-popup">
    <div class="add-market-failure-popup__content">
      <p
        class="wm-cta add-market-failure-popup__text"
        [translate]="'WM_HOME.MARKET_MANAGEMENT.CREATE_MARKET_EXISTING_MESSAGE_1'"
      ></p>
      <p
        class="wm-cta add-market-failure-popup__text"
        [translate]="'WM_HOME.MARKET_MANAGEMENT.CREATE_MARKET_EXISTING_MESSAGE_2'"
      ></p>
    </div>
    <button
      mat-button
      mat-dialog-close
      class="wm-button-primary add-market-failure-popup__close-button"
    >
      {{ 'WM_HOME.MARKET_MANAGEMENT.CLOSE' | translate }}
    </button>
  </div>
</wm-wrapper>
