import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Myself } from '@mads/wm-ng-components';

import { BaseHttpService } from './base-http.service';
import { Page, PagesReadersRelation } from '@interfaces';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class WikiHttpService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  public markPageAsRead(pageId: Page['id'], userId: Myself['id']): Observable<PagesReadersRelation> {
    const request = this.http.post<PagesReadersRelation>(
      `${environment.wikiDomain}/pages/${pageId}/readers/${userId}`,
      {}
    );
    return this.handleRequest(request);
  }
}
