import { Component, ElementRef } from '@angular/core';

import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';
import { registerMediaMarketingMixApp } from '../../../single-spa/media-marketing-mix';

@Component({
  selector: 'app-media-marketing-mix-wrapper',
  templateUrl: './media-marketing-mix-wrapper.component.html',
  styleUrls: ['./media-marketing-mix-wrapper.component.scss'],
})
export class MediaMarketingMixWrapperComponent extends MicroAppWrapper {
  protected appName = 'MMM On Demand';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerMediaMarketingMixApp(params);
  }
}
