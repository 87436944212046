import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/state';

import * as fromProjectsActions from './projects.actions';
import * as fromProjectsSelectors from './projects.selectors';
import { Brand, Client, ProjectBuilderSettings } from '@mads/wm-ng-components';
import { ModeSettings, Project } from '@interfaces';

@Injectable()
export class ProjectsFacade {
  public projects$ = this.store.select(fromProjectsSelectors.selectProjects);
  public projectsForCurrentBrand$ = this.store.select(fromProjectsSelectors.selectProjectsForCurrentBrand);
  public areThereprojectsForCurrentBrand$ = this.store.select(fromProjectsSelectors.selectAreThereProjectsForCurrentBrand);

  public loading$ = this.store.select(fromProjectsSelectors.selectLoading);
  public error$ = this.store.select(fromProjectsSelectors.selectError);
  public selections$ = this.store.select(fromProjectsSelectors.selectSelections);
  public selectedProject$ = this.store.select(fromProjectsSelectors.selectSelectedProject);
  public modeSettings$ = this.store.select(fromProjectsSelectors.selectModeSettings);
  public projectModeOn$ = this.store.select(fromProjectsSelectors.selectIsProjectModeOnForCurrentBrand);

  constructor(private store: Store<AppState>) {}

  public getProjects(clientId: Client['id']): void {
    this.store.dispatch(fromProjectsActions.getProjects({clientId}));
  }

  public selectProject(data: {brandId: Brand['id'], project: Project}): void {
    this.store.dispatch(fromProjectsActions.selectProject(data));
  }

  public setProjectMode(newSetting: ModeSettings): void {
    this.store.dispatch(fromProjectsActions.setProjectMode({newSetting}));
  }

  public resetProjects(): void {
    this.store.dispatch(fromProjectsActions.resetProjects());
  }

  public propagateSettingsToMicrofrontends(): void {
    this.store.dispatch(fromProjectsActions.propagateSettingsToMicrofrontends());
  }

  public setSettingsFromChild(payload: ProjectBuilderSettings): void {
    this.store.dispatch(fromProjectsActions.setSettingsFromChild(payload));
  }
}
