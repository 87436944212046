export interface QuestionnaireState {
  opened: boolean;
  dismissed: boolean;
  displayNotification: boolean;
  runAnimationPosition: { x: number, y: number } | null;
}
export const questionnaireFeatureKey = 'questionnaire2';
export const initialState: QuestionnaireState = {
  opened: false,
  dismissed: false,
  displayNotification: false,
  runAnimationPosition: null,
};
