<wm-wrapper>
  <div
    class="client-request"
    *ngIf="{
      selectedClient: selectedClient$ | async,
      clientCreationRequestState: clientCreationRequestState$ | async
    } as viewData"
  >
    <div class="client-request__content">
      <p class="wm-3xl-500 client-request__header">Client creation request</p>
      <div class="client-request__grid">
        <div class="client-request__client-info">
          <div class="client-request__info-box">
            <div class="client-request__client-header">
              <div class="client-request__client-logo">
                <img
                  *ngIf="viewData.selectedClient?.logo; else logoPlaceholder"
                  [src]="viewData.selectedClient?.logo"
                  alt="Client logo"
                />
                <ng-template #logoPlaceholder>
                  <img src="assets/icons/avatar-mock.svg" alt="Avatar mock" />
                </ng-template>
              </div>
              <div class="client-request__client-name">
                <span class="wm-m-500">{{ viewData.selectedClient?.name }}</span>
              </div>
            </div>

            <div class="client-request__client-description">
              <p class="wm-m-400 client-request__client-description-label">Description</p>
              <p class="wm-m-400 client-request__client-description-text">
                <span>{{ viewData.selectedClient?.description }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="client-request__admin-panel">
          <div class="client-request__admin-panel-header">
            <p class="wm-xl-500 client-request__admin-panel-title">
              Please accept or reject the client creation request.
            </p>
          </div>

          <div class="client-request__admin-panel-requester">
            <!-- @TODO: add requester info when BE will be ready-->
            <p>
              <span class="wm-m-500">Requested by: </span
              ><span class="wm-m-400">{{ viewData.selectedClient?.createdBy }}</span>
            </p>
            <p>
              <span class="wm-m-500">Request date: </span
              ><span class="wm-m-400">{{ viewData.selectedClient?.createdAt | appDate }}</span>
            </p>
          </div>

          <div class="client-request__validation-options">
            <p class="client-request__validation-options-text">
              <span>Validation options:</span>
            </p>
            <div class="client-request__validation-options-buttons">
              <div
                *ngIf="
                  !viewData.clientCreationRequestState?.success &&
                    !viewData.clientCreationRequestState?.error;
                  else result
                "
                class="client-request__buttons-action"
              >
                <div class="client-request__validation-options-button">
                  <button
                    mat-button
                    (click)="onRejectButtonClick()"
                    [disabled]="viewData.clientCreationRequestState?.loading"
                    class="wm-button-primary wm-alt"
                  >
                    <span *ngIf="!viewData.clientCreationRequestState?.loading">Reject</span>
                  </button>

                  <wm-loader
                    *ngIf="
                      viewData.clientCreationRequestState?.loading &&
                      viewData.clientCreationRequestState?.type ===
                        CLIENT_CREATION_RESPONSE_TYPE.REJECT
                    "
                    [diameter]="loaderDiameter"
                    [strokeWidth]="loaderStrokeWidth"
                    mode="indeterminate"
                  ></wm-loader>
                </div>

                <div class="client-request__validation-options-button">
                  <button
                    mat-button
                    (click)="onAcceptButtonClick()"
                    [disabled]="viewData.clientCreationRequestState?.loading"
                    class="wm-button-primary"
                  >
                    <span *ngIf="!viewData.clientCreationRequestState?.loading">Accept</span>
                  </button>

                  <wm-loader
                    *ngIf="
                      viewData.clientCreationRequestState?.loading &&
                      viewData.clientCreationRequestState?.type ===
                        CLIENT_CREATION_RESPONSE_TYPE.APPROVE
                    "
                    [diameter]="loaderDiameter"
                    [strokeWidth]="loaderStrokeWidth"
                    mode="indeterminate"
                  >
                  </wm-loader>
                </div>
              </div>

              <ng-template #result>
                <ng-container *ngIf="viewData.clientCreationRequestState?.success; else error">
                  <ng-container
                    *ngIf="
                      viewData.clientCreationRequestState?.type ===
                      CLIENT_CREATION_RESPONSE_TYPE.APPROVE
                    "
                  >
                    <div class="client-request__button client-request__button--approved">
                      <div class="client-request__button__content">
                        <i-feather name="check"></i-feather>
                        <span class="wm-m-500">Client validated</span>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      viewData.clientCreationRequestState?.type ===
                      CLIENT_CREATION_RESPONSE_TYPE.REJECT
                    "
                  >
                    <div class="client-request__button client-request__button--rejected">
                      <div class="client-request__button__content">
                        <i-feather name="x"></i-feather>
                        <span class="wm-m-500">Client rejected</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-template #error>
                <div class="client-request__error">
                  <i-feather name="alert-triangle"></i-feather>
                  <span class="wm-m-500">Error</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</wm-wrapper>
