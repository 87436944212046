import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Brand, BrandsFacade } from 'app/state/brands';

import { countries } from 'app/config/countries';

@Injectable()
export class LivePanelGuard implements CanActivate {
  constructor(private router: Router, private brandsFacade: BrandsFacade) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.brandsFacade.selectedBrand$.pipe(
      take(1),
      map((brand) => {
        if (this.checkAvailabilityOfLivePanel(brand)) {
          return true;
        }

        return this.router.createUrlTree(['/brands/', brand.id]);
      })
    );
  }

  private checkAvailabilityOfLivePanel(brand: Brand): boolean {
    return (
      brand?.country &&
      // TODO: Temporary access for testing livepanel data. Contact Operations team before delete
      (brand.country.code === `IS` || !countries[brand.country.code].isWithoutLivePanel)
    );
  }
}
