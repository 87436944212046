import {  registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'provoke-brief-app',
  windowLibraryName: 'provokeBriefApp',
  assetsPath: environment.provokeBriefAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.provokeBriefAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  baseHref: '/brands/:brandId/provoke-brief',
  appLocation: environment.provokeBriefAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['provoke-brief']),
};

export const registerProvokeBriefApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
