import { createAction, props } from '@ngrx/store';

import { Team, User, WmMember } from './users.model';
import { HttpErrorResponse } from "@angular/common/http";

export const getMyself = createAction('[Users] Get Myself');
export const getMyselfSuccess = createAction('[Users] Get Myself Success', props<{ user: User }>());
export const getMyselfFailure = createAction(
  '[Users] Get Myself Failure',
  props<{ error: string }>()
);

export const getUsers = createAction(
  '[Users] Get Users',
  props<{ name: string; getOwners: boolean }>()
);
export const getUsersSuccess = createAction(
  '[Users] Get Users Success',
  props<{ users: WmMember[]; getOwners: boolean }>()
);
export const getUsersFailure = createAction('[Users] Get Users Failure', props<{ error: string }>());

export const resetUsers = createAction('[Users] Reset Users');

export const logout = createAction('[Users] Logout');

export const microfrontendUnauthorized = createAction(
  '[Users] Microfrontend Unauthorized',
  props<{ appName: string }>()
);

export const getUserTeams = createAction('[Users] Get User Teams');
export const getUserTeamsSuccess = createAction(
  '[Users] Get User Teams Success',
  props<{ userTeams: Team[] }>()
);
export const getUserTeamsFailure = createAction(
  '[Users] Get User Teams Failure',
  props<{ error: any }>()
);

export const saveTargetPath = createAction(
  '[Users] Save Targeted Path',
  props<{ path: string }>()
);

export const toggleBrandMenuMode = createAction(
  '[Users] Toggle Brand Menu Mode'
);

export const getDefaultMarket = createAction(
  '[Users] Get Default Market',
  props<{ countryCode: string }>()
);
export const getDefaultMarketSuccess = createAction(
  '[Users] Get Default Market Success',
  props<{ defaultMarketId: string }>()
);
export const getDefaultMarketFailure = createAction(
  '[Users] Get Default Market Failure',
  props<{ error: HttpErrorResponse }>()
);
