import { constants } from '@config/constants';
import { environment } from '@environment';
import { isNil } from 'lodash-es';
import { Myself, SimplifiedPermission } from '@mads/wm-ng-components';

export const getHasCanAddBrand = (myself: Myself) => {
  const userAppRoles = myself.userRoles.filter(
    ({ applicationId }) => applicationId === environment.appsIds.clientManagementAppId
  );

  const hasCanAddBrand = !isNil(
    userAppRoles
      .reduce<SimplifiedPermission[]>((acc, { permissions }) => [...acc, ...permissions], [])
      .find(({ key }) => key === constants.USER_PERMISSION_KEYS.CAN_ADD_BRAND)
  );

  return hasCanAddBrand;
};
