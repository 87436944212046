<ng-container
  *ngIf="{
    hasCanAddBrand: hasCanAddBrand$ | async
  } as viewData"
>
  <div class="brands-list__container">
    <div class="brands-list__header">
      <h4 class="wm-h4" translate="WM_HOME.CLIENT_MANAGEMENT.BRANDS"></h4>
      <button
        mat-button
        (click)="handleAddBrand(viewData?.hasCanAddBrand)"
        class="wm-button-primary wm-button-primary--small wm-auto-size market-header__button"
        data-test="WM_HOME.MARKETS_MANAGEMENT.ADD_BRAND"
      >
        {{
          (viewData?.hasCanAddBrand
            ? 'WM_HOME.MARKET_MANAGEMENT.ADD_BRAND'
            : 'WM_HOME.MARKET_MANAGEMENT.REQUEST_BRAND'
          ) | translate
        }}
      </button>
    </div>
    <div class="brands-list__content">
      <ng-container *ngIf="!isLoading; else loader">
        <ng-container *ngIf="brands?.length; else emptyList">
          <div class="brands-list__labels">
            <p
              class="wm-overline column-name brands-list__label"
              translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_NAME"
            ></p>
            <p
              class="wm-overline column-category brands-list__label"
              translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_CATEGORY"
            ></p>
            <p
              class="wm-overline column-contact brands-list__label"
              translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_CONTACT"
            ></p>
            <p
              class="wm-overline column-website brands-list__label"
              translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_WEBSITE"
            ></p>
            <span class="column-actions"></span>
          </div>
          <div class="brands-list__elements">
            <ng-container *ngFor="let brand of brands">
              <div
                class="brands-list__element"
                [class.brands-list__element--disabled]="
                  brand.orderStatus !== creationStatus.SUCCESS
                "
              >
                <div
                  class="column-name brands-list__element-part brands-list__element-part--clickable"
                  (click)="activateBrand(brand.id)"
                >
                  <wm-arrow [isExpanded]="brand.id === activeBrandId" [size]="20"></wm-arrow>
                  <img
                    [src]="brand.logo"
                    [alt]="'WM_HOME.MARKET_MANAGEMENT.BRAND_ALT' | translate"
                    class="brands-list__element-logo"
                  />
                  <p class="wm-caption">{{ brand.name }}</p>
                </div>
                <p class="wm-subtitle-small column-category">{{ brand.categories[0].name }}</p>
                <div class="column-contact brands-list__element-part">
                  <p class="wm-subtitle-small brands-list__element-contact">
                    {{ brand.contactName }}
                  </p>
                  <p class="wm-subtitle-small" data-test="WM_HOME.MARKETS_MANAGEMENT.CONTACT_PHONE">
                    {{ brand.contactPhone }}
                  </p>
                </div>
                <p class="wm-subtitle-small column-website brands-list__element-website">
                  {{ brand.websiteUrl }}
                </p>
                <div
                  *ngIf="viewData?.hasCanAddBrand"
                  class="column-actions brands-list__element-part brands-list__element-part--actions"
                >
                  <i-feather
                    name="edit-3"
                    class="brands-list__element-icon"
                    (click)="handleEditBrand(brand, viewData?.hasCanAddBrand)"
                    data-test="WM_HOME.MARKETS_MANAGEMENT.EDIT_BRAND"
                  ></i-feather>
                  <i-feather
                    name="trash-2"
                    class="brands-list__element-icon"
                    (click)="handleDeleteBrand(brand, viewData?.hasCanAddBrand)"
                    data-test="WM_HOME.MARKETS_MANAGEMENT.DELETE_BRAND"
                  ></i-feather>
                </div>
              </div>
              <div *ngIf="brand.id === activeBrandId" class="brands-list__details">
                <div class="brands-list__details-info">
                  <p
                    class="wm-subtitle-small brands-list__details-label"
                    translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_SOCIAL"
                  ></p>
                  <div
                    *ngIf="brand.socialMediaLinks.length; else emptyDetails"
                    class="brands-list__details-elements"
                  >
                    <a
                      *ngFor="let link of brand.socialMediaLinks"
                      [href]="link.url?.startsWith('http') ? link.url : '//' + link.url"
                      target="_blank"
                      class="wm-subtitle-small brands-list__details-text"
                      data-test="WM_HOME.MARKETS_MANAGEMENT.SOCIAL_PLATFORM"
                    >
                      {{ link.name }}</a
                    >
                  </div>
                </div>
                <div class="brands-list__details-info">
                  <p
                    class="wm-subtitle-small"
                    translate="WM_HOME.MARKET_MANAGEMENT.BRAND_LIST_COMPETITORS"
                  ></p>
                  <div
                    *ngIf="brand.competitors.length; else emptyDetails"
                    class="brands-list__details-elements"
                  >
                    <p
                      *ngFor="let competitor of brand.competitors"
                      class="wm-subtitle-small brands-list__details-text"
                      data-test="WM_HOME.MARKETS_MANAGEMENT.COMPETITOR_NAME"
                    >
                      {{ competitor }}
                    </p>
                  </div>
                </div>
                <ng-template #emptyDetails> - </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #emptyList>
          <ng-container *ngIf="viewData?.hasCanAddBrand; else emptyListCantAddBrand">
            <div class="brands-list__empty">
              <h4
                class="wm-h4 brands-list__empty-header"
                translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_HEADER"
              ></h4>
              <div class="brands-list__description">
                <h5
                  class="wm-h5 brands-list__description-part brands-list__description-part--action"
                  translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_ACTION"
                  (click)="handleAddBrand(viewData?.hasCanAddBrand)"
                ></h5>
                <h5
                  class="wm-h5 brands-list__description-part"
                  translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_DESCRIPTION_TOP"
                ></h5>
                <h5
                  class="wm-h5 brands-list__description-part brands-list__description-part--highlighted"
                  translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_DESCRIPTION_BOLD"
                ></h5>
              </div>
              <h5
                class="wm-h5 brands-list__description-part"
                translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_DESCRIPTION_BOTTOM"
              ></h5>
            </div>
          </ng-container>

          <ng-template #emptyListCantAddBrand>
            <div class="brands-list__empty">
              <h4
                class="wm-h4 brands-list__empty-header"
                translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_REQUEST_HEADER"
              ></h4>

              <div class="brands-list__description">
                <h5
                  class="wm-h5 brands-list__description-part"
                  translate="WM_HOME.MARKET_MANAGEMENT.EMPTY_BRANDS_REQUEST_DESCRIPTION"
                ></h5>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
      <ng-template #loader>
        <div class="brands-list__loader">
          <wm-loader mode="indeterminate" diameter="50" strokeWidth="2"> </wm-loader>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
