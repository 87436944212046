import { MicrofrontendAppConfig, registerApp, matchingGlobalPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'documentation-app',
  windowLibraryName: 'mxmz-documentation',
  assetsPath: environment.documentationAppUrl.replace('main.js', '').substr(1),
  baseHref: '/documentation',
  appUrl: environment.documentationAppUrl,
  appLocation: environment.documentationAppUrl.replace('main.js', ''),
  matchingStrategy: matchingGlobalPathname(['/documentation']),
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4300/main.js'.replace('main.js', '').substr(0),
  // appUrl: 'http://localhost:4300/main.js',
};

export const registerDocumentationApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
