import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TrackAnalyticsActionType } from '@mads/wm-ng-components';

import { UsersHttpService } from 'app/core/http/users-http.service';
import { environment } from 'src/environments/environment';

import * as fromUsers from './users.actions';
import { changeRoute } from '../router';
import { AnalyticsTrackingService } from 'app/core/analystics-tracking.service';
import { TrackerService } from 'app/core/tracker.service';
import { customVariables } from 'app/core/tracker.model';

import { get, each } from 'lodash-es';
@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private analyticsTrackingService: AnalyticsTrackingService,
    private http: UsersHttpService,
    private trackerService: TrackerService
  ) {}

  getMyself$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.getMyself),
      switchMap(() =>
        this.http.getMyself().pipe(
          map((user) => fromUsers.getMyselfSuccess({ user })),
          catchError((err) => of(fromUsers.getMyselfFailure(err)))
        )
      )
    )
  );

  getMyselfSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUsers.getMyselfSuccess),
        map(({ user }) => {
          if (environment.matomoId) {
            each(customVariables, (variable) => {
              this.trackerService.setCustomVariable(
                variable.index,
                variable.name,
                get(user, variable.key),
                variable.type
              );
            });
          }
        })
      ),
    { dispatch: false }
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.getUsers),
      switchMap(({ name, getOwners }) =>
        this.http.getUsers(name).pipe(
          map((users) => fromUsers.getUsersSuccess({ users, getOwners })),
          catchError((err) => of(fromUsers.getUsersFailure(err)))
        )
      )
    )
  );

  getUserTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.getUserTeams),
      switchMap(() =>
        this.http.getUserTeams().pipe(
          map(
            (userTeams) => fromUsers.getUserTeamsSuccess({ userTeams }),
            catchError((err) => of(fromUsers.getUserTeamsFailure(err)))
          )
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.logout),
      tap(() =>
        this.analyticsTrackingService.track({
          type: TrackAnalyticsActionType.reset,
        })
      ),
      map(() => changeRoute({ linkParams: ['/login'] }))
    )
  );

  microfrontendUnauthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.microfrontendUnauthorized),
      map(() => fromUsers.getMyself())
    )
  );

  getGreenDayMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.getDefaultMarket),
      switchMap(({ countryCode }) =>
        this.http.getGreenDayMarket(countryCode).pipe(
          map((defaultMarketId) => fromUsers.getDefaultMarketSuccess({ defaultMarketId })),
          catchError((err) => of(fromUsers.getDefaultMarketFailure(err)))
        )
      )
    )
  );
}
