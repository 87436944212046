import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'campaigns-ui',
  windowLibraryName: 'campaignsUiApp',
  assetsPath: environment.campaignsUiAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/campaigns/list',
  appUrl: environment.campaignsUiAppUrl,
  appLocation: environment.campaignsUiAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['campaigns/list']),
};

export const registerCampaignsUiApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
