import { Component } from '@angular/core';
import { wmosLoginPromoConfig } from '@config/wmos-login-promo.config';

@Component({
  selector: 'app-wmos-login-promo',
  templateUrl: './wmos-login-promo.component.html',
  styleUrls: ['./wmos-login-promo.component.scss'],
})
export class WmosLoginPromoComponent {
  public config = wmosLoginPromoConfig;
}
