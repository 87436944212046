import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';

import { ClientManagementDrawerMode, ClientsFacade } from 'app/state/clients';
import { UsersFacade } from 'app/state/users';

import { checkSingleChoicePermission } from 'app/shared/utils';
import { constants } from '@config/constants';

@Injectable()
export class MarketManagementGuard implements CanActivate {
  constructor(
    private clientsFacade: ClientsFacade,
    private router: Router,
    private usersFacade: UsersFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.usersFacade.myself$.pipe(
      withLatestFrom(this.clientsFacade.selectedClient$),
      first(),
      map(([user, client]) => {
        const activeMarket = client?.markets.find(
          (market) => market.code === route.queryParams.activeMarket
        );

        const canRecreate =
          checkSingleChoicePermission(user, constants.USER_PERMISSION_KEYS.CAN_RECREATE_MARKET) &&
          activeMarket?.isTeamDeleted;

        if (
          route.queryParams.drawerMode === ClientManagementDrawerMode.RECREATE_MARKET &&
          !activeMarket.isTeamDeleted
        ) {
          return this.router.createUrlTree([
            `/clients/client-creation/${client.id}/markets-management`,
          ]);
        }

        const isAdminOfAny = client?.markets.some((market) => market.isClientAdmin);
        const canAdd = checkSingleChoicePermission(
          user,
          constants.USER_PERMISSION_KEYS.CAN_ADD_MARKET
        );

        return canRecreate || isAdminOfAny || canAdd || this.router.createUrlTree(['/']);
      })
    );
  }
}
