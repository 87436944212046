<div class="page-wrapper">
  <h3 class="wm-h3" translate="WM_HOME.USER.PERSONAL_SETTINGS"></h3>
  <div class="row main-section-row">
    <div class="col personal-settings-col">
      <div class="row data-row">
        <span class="wm-subtitle-small" translate="WM_HOME.USER.DATE_FORMAT"></span>
        <ng-select
          (change)="changeDateFormat($event)"
          class="wm-select"
          dropdownPosition="bottom"
          [searchable]="false"
          [clearable]="false"
          [ngModel]="getDateFormat(selectedDateFormat$ | async)"
        >
          <ng-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat">
            <span>{{ dateFormat.label }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="row data-row">
        <span class="wm-subtitle-small" translate="WM_HOME.USER.NUMBER_DISPLAY"></span>
        <ng-select
          (change)="changeNumberFormat($event)"
          class="wm-select"
          dropdownPosition="bottom"
          [searchable]="false"
          [clearable]="false"
          [ngModel]="getNumberFormat(selectedNumberType$ | async)"
        >
          <ng-option *ngFor="let numberFormat of numberFormats" [value]="numberFormat">
            <span>{{numberFormat.label}}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="row data-row">
        <span class="wm-text-small" translate="WM_HOME.USER.EXAMPLE_CURRENCY"></span>
        <span class="wm-subtitle-small">{{0 | currency:'EUR':'symbol':'12.2-3' :(selectedNumberType$ | async)}}</span>
      </div>
    </div>
  </div>
</div>
