export interface ConfirmationPopupConfig {
  body?: string[];
  bodyParams?: any;
  confirmation?: string;
  cancellation?: string;
  extraInfo?: string;
}

export const defaultConfirmationPopupConfig: ConfirmationPopupConfig = {
  confirmation: 'WM_HOME.YES',
  cancellation: 'WM_HOME.NO',
};
