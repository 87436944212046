<div class="pending-client" *ngIf="{ client: client$ | async } as viewData">
  <div class="pending-client__content">
    <div class="pending-client__text">
      <p class="pending-client__header">
        <span class="wm-3xl-500">Your request was sent to OS Support team.</span>
        <span class="wm-xl-400">It should be processed within 1 day.</span>
      </p>

      <p class="pending-client__description">
        <span class="wm-s-400"
          >If you have any questions write to OS Support
          <a href="mailto:support@wmglobal.com">support@wmglobal.com</a>
        </span>
      </p>
    </div>

    <div class="pending-client__info">
      <div class="pending-client__info-content">
        <div class="pending-client__info-header">
          <div class="pending-client__info-logo">
            <img [src]="viewData.client?.logo" />
          </div>
          <div class="pending-client__info-name">
            <span class="wm-m-500">{{ viewData.client?.name }}</span>
          </div>
        </div>

        <div class="pending-client__info-description">
          <p class="pending-client__info-description-label">
            <span class="wm-s-400">Description</span>
          </p>
          <p class="pending-client__info-description-text">
            <span>{{ viewData.client?.description }}</span>
          </p>
        </div>
      </div>

      <div class="pending-client__info-background">
        <img src="/assets/img/pending_client_background.svg" />
      </div>
    </div>
  </div>
</div>
