import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { CreateBrandPayload, Brand, BrandsState } from './brands.model';
import { Client, Market } from '../clients';

export const getBrands = createAction(
  '[Brands] Get Brands',
  props<{ id: Client['id']; countryCode: Market['code'] }>()
);
export const getBrandsSuccess = createAction(
  '[Brands] Get Brands Success',
  props<{ brands: BrandsState['brands'] }>()
);
export const getBrandsFailure = createAction(
  '[Brands] Get Brands Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetError = createAction('[Brands] Reset Error');
export const getBrand = createAction('[Brands] Get Brand', props<{ id: Brand['id'] }>());
export const getBrandSuccess = createAction(
  '[Brands] Get Brand Success',
  props<{ brand: BrandsState['selectedBrand'] }>()
);
export const getBrandFailure = createAction(
  '[Brands] Get Brand Failure',
  props<{ error: HttpErrorResponse }>()
);

export const createBrand = createAction(
  '[Brands] Create Brand',
  props<{ createBrandPayload: CreateBrandPayload }>()
);
export const createBrandSuccess = createAction(
  '[Brands] Create Brand Success',
  props<{ brand: Brand }>()
);
export const createBrandFailure = createAction(
  '[Brands] Create Brand Failure',
  props<{ error: HttpErrorResponse }>()
);

export const updateBrand = createAction(
  '[Brands] Update Brand',
  props<{ brand: Partial<Brand> }>()
);
export const updateBrandSuccess = createAction(
  '[Brands] Update Brand Success',
  props<{ updatedBrand: BrandsState['selectedBrand'] }>()
);
export const updateBrandFailure = createAction(
  '[Brands] Update Brand Failure',
  props<{ error: HttpErrorResponse }>()
);

export const deleteBrand = createAction(
  '[Brands] Delete Brand',
  props<{ brand: Brand; withRedirect: boolean }>()
);
export const deleteBrandSuccess = createAction(
  '[Brands] Delete Brand Success',
  props<{ brandId: Brand['id'] }>()
);
export const deleteBrandFailure = createAction(
  '[Brands] Delete Brand Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetBrand = createAction('[Brands] Reset Brand');

export const pollingBrand = createAction(
  '[Brands] Polling Brand',
  props<{ brandId: Brand['id'] }>()
);

export const getLogos = createAction('[Brands] Get Logos', props<{ name: string, limit: number }>());
export const getLogosSuccess = createAction(
  '[Brands] Get Logos Success',
  props<{ logos: string[] }>()
);
export const getLogosFailure = createAction(
  '[Brands] Get Logos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setSelectedLogoUrl = createAction(
  '[Brands] Set selected logo url',
  props<{ selectedLogoUrl: string }>()
);

export const resetLogosSettings = createAction('[Brands] Reset logos settings');
