<ng-container
  *ngIf="{
    hasCanAddBrand: hasCanAddBrand$ | async
  } as viewData"
>
  <div class="market-list" [ngClass]="mode.code">
    <ng-container *ngIf="areAnyMarkets; else noMarkets">
      <div class="market-list__header">
        <div class="market-list__toggle">
          <mat-slide-toggle
            [(ngModel)]="isShowedOnlyMineMarkets"
            (change)="toggleMarketsFilter()"
            class="wm-button-toggle"
          >
          </mat-slide-toggle>
          <p
            class="wm-text-small market-list__toggle-label"
            translate="WM_HOME.HEADER.TOGGLE_LABEL"
          ></p>
        </div>
      </div>

      <div *ngFor="let region of regionsToDisplay" class="market-list__region">
        <ng-container *ngIf="region.columnsWithMarkets.length">
          <p class="wm-subtitle market-list__region-name" [translate]="region.name"></p>
          <div class="market-list__market-container">
            <ul
              *ngFor="let marketsList of region.columnsWithMarkets"
              class="market-list__market-list"
            >
              <li *ngFor="let market of marketsList" class="market-list__market">
                <div class="market-list__market-header">
                  <div
                    (click)="
                      !market.mine
                        ? makeRequestForMarket(market.code)
                        : handleSectionClick(market.code)
                    "
                    class="market-list__market-element"
                    [matTooltip]="'WM_HOME.HEADER.REQUEST_ACCESS_TOOLTIP' | translate"
                    [matTooltipDisabled]="market.mine || market.pending"
                    matTooltipClass="custom-tooltip"
                  >
                    <h4
                      class="wm-h4 market-list__market-name"
                      [class.market-list__market-name--blocked]="!market.mine"
                    >
                      {{ market.name }}
                    </h4>
                    <wm-arrow
                      *ngIf="market.mine && !market.pending"
                      [size]="24"
                      [isExpanded]="expandedCountryCode === market.code"
                      color="primary-on-contrast-background"
                    ></wm-arrow>
                    <i-feather
                      *ngIf="!market.mine && !market.pending"
                      class="market-list__market-icon"
                      name="lock"
                    ></i-feather>
                  </div>

                  <div *ngIf="market.pending" class="market-list__market-request">
                    <i-feather
                      name="loader"
                      class="market-list__market-icon market-list__market-icon--spin"
                    ></i-feather>
                    <p
                      class="wm-text-small market-list__market-pending"
                      translate="WM_HOME.HEADER.PENDING_BUTTON"
                    ></p>
                  </div>

                  <p
                    *ngIf="
                      market.mine &&
                      ((market.status | lowercase) === (testStatus | lowercase) ||
                        (market.status | lowercase) === (pitchStatus | lowercase))
                    "
                    [ngClass]="{
                      'market-list__market-status--pitch':
                        (market.status | lowercase) === (pitchStatus | lowercase),
                      'market-list__market-status--test':
                        (market.status | lowercase) === (testStatus | lowercase)
                    }"
                    class="wm-caption market-list__market-status"
                  >
                    {{ market.status | titlecase }}
                  </p>
                  <div
                    *ngIf="expandedCountryCode === market.code && brandsLoading$ | async"
                    class="market-list__brands-loader"
                  >
                    <mat-progress-spinner
                      diameter="23"
                      strokeWidth="3"
                      mode="indeterminate"
                    ></mat-progress-spinner>
                  </div>
                </div>

                <ng-container
                  *ngIf="expandedCountryCode === market.code && !(brandsLoading$ | async)"
                >
                  <ul
                    class="market-list__brands"
                    *ngIf="(loadingProjectBuilderProjects$ | async) === false; else loader"
                  >
                    <li *ngFor="let brand of brands" class="market-list__brands-element">
                      <a
                        routerLink="/brands/{{ brand.id }}"
                        class="wm-caption market-list__brands-name"
                      >
                        {{ brand.name }}
                      </a>
                    </li>
                  </ul>

                  <ng-template #loader>
                    <div class="market-list__brands-loader">
                      <mat-progress-spinner
                        diameter="23"
                        strokeWidth="3"
                        mode="indeterminate"
                      ></mat-progress-spinner>
                    </div>
                  </ng-template>

                  <button
                    *ngIf="market.isClientAdmin && viewData?.hasCanAddBrand"
                    mat-stroked-button
                    [routerLink]="['/clients/client-creation', client.id, 'markets-management']"
                    [queryParams]="{
                      open: true,
                      activeMarket: market.code,
                      drawerMode: DrawerMode.BRAND
                    }"
                    class="wm-button-tertiary wm-auto-size wm-dark"
                    translate="WM_HOME.HEADER.ADD_BRAND_BUTTON"
                    data-test="WM_HOME.HEADER.ADD_BRAND_BUTTON"
                  ></button>

                  <button
                    *ngIf="market.isClientAdmin && !viewData?.hasCanAddBrand"
                    mat-stroked-button
                    class="wm-button-tertiary wm-auto-size wm-dark"
                    translate="WM_HOME.HEADER.REQUEST_BRAND_BUTTON"
                    data-test="WM_HOME.HEADER.ADD_BRAND_BUTTON"
                    (click)="handleRequestNewBrand(viewData?.hasCanAddBrand)"
                  ></button>
                </ng-container>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noMarkets>
      <div class="no-markets">
        <h3 class="wm-h3 no-markets__text" translate="WM_HOME.HEADER.NO_MARKETS"></h3>
        <img src="assets/img/no-markets.svg" class="no-markets__image" />
      </div>
    </ng-template>
  </div>
</ng-container>
