import { Injectable } from '@angular/core';
import { FlatTheme, Theme } from '@wppopen/components-library';
import { WppOpenThemeHttpService } from './http/wpp-open-theme-http.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { createTheme } from '@wppopen/components-library';

@Injectable()
export class WppOpenThemeService {
  private theme$ = new BehaviorSubject<Theme | null>(null);
  constructor(private readonly wppOpenThemeHttpService: WppOpenThemeHttpService) {}

  loadTheme(): Observable<void> {
    return this.wppOpenThemeHttpService.getTheme().pipe(
      map((theme) => {
        this.theme$.next(theme.data);
      })
    );
  }

  getTheme(): Observable<Theme | null> {
    return this.theme$.asObservable();
  }

  getFlatTheme(): Observable<FlatTheme> {
    return this.theme$.asObservable().pipe(map((theme) => createTheme(theme)));
  }
}
