import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { Team, User, WmMember } from 'app/state/users/users.model';

@Injectable()
export class UsersHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getMyself(): Observable<User> {
    const request = this.http.get<User>(
      `${UsersHttpService.AUTH_API_URL}/users/myself`,
      UsersHttpService.httpOptions
    );

    return this.handleRequest<User>(request);
  }

  public getUsers(name: string): Observable<WmMember[]> {
    const request = this.http.get<WmMember[]>(
      `${UsersHttpService.CLIENT_DATA_API_URL}/users?search=${name}`,
      UsersHttpService.httpOptions
    );

    return this.handleRequest<WmMember[]>(request);
  }

  public getUserTeams(): Observable<Team[]> {
    const request = this.http.get<Team[]>(
      `${UsersHttpService.CLIENT_DATA_API_URL}/users/me/ownedTeams`,
      UsersHttpService.httpOptions
    );

    return this.handleRequest<Team[]>(request);
  }

  public getGreenDayMarket(countryCode): Observable<string> {
    const params = this.parseQueryParams({countryCode});

    const request = this.http.get<string>(
      `${UsersHttpService.CLIENT_DATA_API_URL}/users/me/default-brand`,
      { ...UsersHttpService.httpOptions, params }
    );

    return this.handleRequest<string>(request);
  }
}
