import { MicrofrontendAppConfig, registerApp, matchingBrandPathname } from './utils';
import { environment } from 'src/environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'mp-comparison',
  windowLibraryName: 'mediaPlansComparisonApp',
  assetsPath: environment.mediaPlanComparisonAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/campaigns/comparison',
  appUrl: environment.mediaPlanComparisonAppUrl,
  appLocation: environment.mediaPlanComparisonAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['campaigns/comparison']),
};

export const registerMediaPlanComparisonApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
