import { createFeatureSelector, createSelector } from '@ngrx/store';

import { constants } from '@config/constants';
import { environment } from '@environment';
import { checkSingleChoicePermission } from 'app/shared/utils';
import { UsersState, usersFeatureKey } from './users.model';

export const selectUsersState = createFeatureSelector<UsersState>(usersFeatureKey);
export const selectMyself = createSelector(selectUsersState, (state) => state.myself);
export const selectOwners = createSelector(selectUsersState, (state) => state.owners);
export const selectMembers = createSelector(selectUsersState, (state) => state.members);
export const selectUserTeams = createSelector(selectUsersState, (state) => state.userTeams);
export const selectTargetPath = createSelector(selectUsersState, (state) => state.targetPath);
export const selectDateLocale = createSelector(
  selectMyself,
  myself => myself && myself.dateLocale
);

export const selectLoading = createSelector(selectUsersState, (state) => state.loading);

export const selectHasAccessToSimplifiedBrandMenu = createSelector(selectMyself, (myself) =>
  checkSingleChoicePermission(myself, constants.USER_PERMISSION_KEYS.SIMPLIFIED_MENU_ACCESS, environment.appsIds.appId),
);
export const selectSimplifiedBrandMenuOn = createSelector(
  selectUsersState,
  selectHasAccessToSimplifiedBrandMenu,
  (state, access) => access && state.simplifiedBrandMenuOn,
);

export const selectDefaultMarket = createSelector(selectUsersState, (state) => state.defaultMarketId);
