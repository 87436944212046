<wm-wrapper>
  <div class="error">
    <div class="error__content">
      <h1 class="error__title" translate="WM_HOME.ERROR.TITLE"></h1>
      <span class="wm-subtitle-small error__desc">
        <div translate="WM_HOME.ERROR.DESCRIPTION_PART_1"></div>
        <span
          [translate]="
            appName === appNameOptions?.WMOS
              ? 'WM_HOME.ERROR.DESCRIPTION_PART_2-WMOS'
              : 'WM_HOME.ERROR.DESCRIPTION_PART_2-GDT'
          "
          [translateParams]="{ email: supportMail }"
        ></span>
      </span>
      <div class="error__button-container">
        <button
          mat-button
          class="wm-button-primary wm-button-primary--small wm-cancel error__button"
          translate="WM_HOME.ERROR.BUTTON_HOME"
          (click)="goToYouPage()"
        ></button>
        <button
          mat-button
          class="wm-button-primary wm-button-primary--small error__button"
          translate="WM_HOME.ERROR.BUTTON_BACK"
          (click)="goBack()"
        ></button>
      </div>
      <span class="error__button-hint" translate="WM_HOME.ERROR.BUTTON_HINT"> </span>
    </div>
    <img class="error__image" [src]="appName | errorImage" />
  </div>
</wm-wrapper>
