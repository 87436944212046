import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ModeCodes, COLORS } from '@mads/wm-ng-components';

import { MODES_ASSETS_PATH, ModeService } from 'app/core/mode.service';
import { ProjectsFacade } from 'app/state/projects';

const BACKGROUND_MODES = {
  [ModeCodes.MONDELEZ]: 'background',
  [ModeCodes.LOREAL]: 'background',
  DEFAULT: 'backgroundContrast',
};

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent {
  @Input() personalModeOn = false;
  @Input() overflowVisible = false;
  @Input() useAgencyBackgroundColor = false;

  private isBrandMenu$ = this.router.events.pipe(
    startWith(this.router.url.includes('brands')), // start
    map(() => this.router.url.includes('brands')), // all later events
  );
  
  public projectModeColorsOn$ = combineLatest([
    this.isBrandMenu$,
    this.projectsFacade.areThereprojectsForCurrentBrand$,
    this.projectsFacade.projectModeOn$,
  ]).pipe(
    map(([isBrandMenu, thereAreProjectsForChosenBrand, isProjectModeOn]) => isBrandMenu
      && thereAreProjectsForChosenBrand
      && isProjectModeOn),
  );

  public backgroundImagePath$ = this.modeService.mode$.pipe(
    // temporary as other agencies for now do not have background animation
    // this should be taken from config, once it is there
    filter((mode) => ModeCodes.WAVEMAKER === mode.code),
    map((mode) => `${MODES_ASSETS_PATH}/${mode.code}/background-animation.svg`)
  );

  public backgroundColor$ = this.modeService.mode$.pipe(
    map((mode) => {
      const boxColor = BACKGROUND_MODES[mode.code] && this.useAgencyBackgroundColor
        ? BACKGROUND_MODES[mode.code]
        : BACKGROUND_MODES.DEFAULT;

      return COLORS[mode.code][boxColor];
    })
  );

  constructor(
    private modeService: ModeService,
    private projectsFacade: ProjectsFacade,
    private router: Router,
  ) {}
}
