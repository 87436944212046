import { environment } from 'src/environments/environment';

import { matchingBrandPathname, MicrofrontendAppConfig, registerApp } from './utils';

const config: MicrofrontendAppConfig = {
  name: 'retail-compass-app',
  windowLibraryName: 'retail-compass',
  assetsPath: environment.retailCompassAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.retailCompassAppUrl,
  baseHref: '/brands/:brandId/retail-compass/',
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.retailCompassAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['retail-compass']),
};

export const registerRetailCompassApp = (customParams?: any) => registerApp({ ...config, customParams });
