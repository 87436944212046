import { environment } from 'src/environments/environment';

import {
  matchingBrandPathname,
  MicrofrontendAppConfig,
  registerApp,
} from './utils';

const config: MicrofrontendAppConfig = {
  name: 'geograph-app',
  windowLibraryName: 'geographApp',
  baseHref: '/brands/:brandId/geograph',
  assetsPath: environment.geographAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.geographAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  appLocation: environment.geographAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['geograph']),
};

export const registerGeographApp = (customParams?: any) => registerApp({ ...config, customParams });

