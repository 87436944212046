<mat-drawer-container class="news-sidebar-container" (clickOutside)="onClickedOutside()">
  <div class="blur-background" *ngIf="newsSidebarOpened" (click)="closeDrawer()"></div>
  <mat-drawer [opened]="newsSidebarOpened" position="end">
    <div class="header">
      <button (click)="closeDrawer()" class="close-btn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="container">
      <div class="panel-header">
        <div class="animated-container">
          <div class="flex-wrapper" (click)="navigateToLatestNews()">
            <h4 class="wm-h4 header-title" translate="WM_HOME.NEWS_SIDEBAR.LATEST_NEWS"></h4>
            <p class="wm-subtitle-small show-more" translate="WM_HOME.NEWS_SIDEBAR.SEE_ALL"></p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!(loading$ | async); else spinner">
        <ng-container *ngIf="newsSidebarOpened && newsList?.length">
          <div *ngIf="newsList?.length > 0" class="news" data-test="HOME.NEWS.CONTAINER">

            <div class="news-row">
              <div data-test="HOME.NEWS.CONTENT" class="news__content" *ngFor="let news of newsList | slice:0:firstRowTileCount;" (click)="goToNews(news);">
                <div class="animated-container">
                  <span class="tile-failsafe-image-holder" *ngIf="!(news | getImageSrc)" [ngStyle]="{'background-image': news.newsFailsafeImage}"></span>
                  <span class="tile-image-holder" *ngIf="(news | getImageSrc)" [ngStyle]="{'background-image': news | getImageSrc}"></span>
                  <span class="animated-container--channel-name">
                    <img *ngIf="news.channelImage" class="animated-container--channel-logo" src="{{news.channelImage}}" alt="Channel Image">
                    {{news.channelName}}
                  </span>
                  <span class="animated-container--value" matTooltip="{{news.title}}">
                      {{news.title}}
                  </span>

                  <div class="animated-container--calendar">
                    <div class="publication-date" matTooltip="{{'YOU_PAGE.NEWS.PUBLICATION_DATE' | translate}}">{{news.publishedDate | appDate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="news-row" *ngFor="let number of restRowsOfTiles">
              <div data-test="HOME.NEWS.CONTENT" class="news__content" *ngFor="let news of newsList | slice:calculateIndexForPatchyGrid(number):calculateIndexForPatchyGrid(number) + maxTilesInGridRow;" (click)="goToNews(news);">
                <div class="animated-container">
                  <span class="tile-failsafe-image-holder" *ngIf="!(news | getImageSrc)" [ngStyle]="{'background-image': news.newsFailsafeImage}"></span>
                  <span class="tile-image-holder" *ngIf="(news | getImageSrc)" [ngStyle]="{'background-image': news | getImageSrc}"></span>
                  <span class="animated-container--channel-name">
                    <img *ngIf="news.channelImage" class="animated-container--channel-logo" src="{{news.channelImage}}" alt="Channel Image">
                    {{news.channelName}}
                  </span>
                  <span class="animated-container--value" matTooltip="{{news.title}}">
                      {{news.title}}
                  </span>

                  <div class="animated-container--calendar">
                    <div class="publication-date" matTooltip="{{'YOU_PAGE.NEWS.PUBLICATION_DATE' | translate}}">{{news.publishedDate | appDate}}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
        <div class="news-loading-error" *ngIf="(loadingError$ | async) || !newsList?.length">
          <div class="news-loading-error__wrapper">
            <img src="assets/img/alert.svg" alt="" />
            <h5
              [translate]="
                (loadingError$ | async)
                  ? 'WM_HOME.NEWS_SIDEBAR.SERVICE_UNAVAILABLE'
                  : 'WM_HOME.NEWS_SIDEBAR.NO_NEWS'
              "
            ></h5>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-template #spinner>
      <div class="spinner-container">
        <div class="mat-progress-wrapper">
          <div class="mat-progress-wrapper__inner">
            <mat-progress-spinner color="warn" strokeWidth="5" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-drawer>
</mat-drawer-container>
