import { Component, ElementRef } from '@angular/core';

import { registerRetailCompassApp } from 'src/single-spa/retail-compass';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-retail-compass-wrapper',
  templateUrl: './retail-compass-wrapper.component.html',
  styleUrls: ['./retail-compass-wrapper.component.scss'],
})
export class RetailCompassWrapperComponent extends MicroAppWrapper {
  protected appName = 'Retail Compass';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerRetailCompassApp(params);
  }
}
