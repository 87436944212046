<div class="redirect-to-wpp-open-popup">
  <wm-wrapper>
    <p class="wm-l-400">Dear user,</p>

    <p class="wm-l-400">
      Data you are looking for have been moved to <span class="wm-l-500"> WPP Open </span> platform.

      <br />

      Click <span class="wm-l-500"> "Redirect" </span> to continue.
    </p>

    <div class="redirect-to-wpp-open-popup__buttons">
      <button mat-button class="wm-button-primary wm-alt" (click)="close()">Close</button>
      <button mat-button class="wm-button-primary" (click)="redirect()">Redirect</button>
    </div>
  </wm-wrapper>
</div>
