import { Component, ElementRef } from '@angular/core';

import { registerProvokeBriefApp } from 'src/single-spa/provoke-brief';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

@Component({
  selector: 'app-provoke-brief-wrapper',
  templateUrl: './provoke-brief-wrapper.component.html',
  styleUrls: ['./provoke-brief-wrapper.component.scss'],
})
export class ProvokeBriefWrapperComponent extends MicroAppWrapper {
  protected appName = 'Provoke the brief';

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams): void {
    this.config = registerProvokeBriefApp(params);
  }
}
