/** @deprecated - to be removed once all apps are using new class actions */
export interface MicroFrontendActions {
  microfrontendUnauthorized: MicrofrontendAction;
  updateHeaderBreadcrumbs: MicrofrontendAction;
  resetBreadcrumbsDetails: MicrofrontendAction;
}

/** @deprecated - to be removed once all apps are using new class actions */
export interface MicrofrontendAction {
  type: string;
  validate?: (action: MicroAppEvent) => boolean;
}

/** @deprecated - to be removed once all apps are using new class actions */
export interface MicroAppEvent<T = {}> {
  type: string;
  data: { name: string; route: string }[];
}

/** @deprecated - to be removed once all apps are using new class actions */
const validateMicrofrontendActionData = (payload: {
  action: MicroAppEvent;
  property: keyof MicroAppEvent['data'][0];
  expectedType: string;
  warning: string;
}) => {
  const { action, property, expectedType, warning } = payload;
  const isValid = Array.isArray(action.data)
    ? action.data.every((item) => typeof item[property] === expectedType)
    : false;

  if (!isValid) {
    console.warn(warning);
  }

  return isValid;
};

/** @deprecated - to be removed once all apps are using new class actions */
export const microFrontendActions = {
  microfrontendUnauthorized: {
    type: '[Microfrontend] User Unauthorized',
  },
  updateHeaderBreadcrumbs: {
    type: '[Microfrontend] Update Header Breadcrumbs',
    validate: (action: MicroAppEvent) =>
      validateMicrofrontendActionData({
        warning:
          '[Microfrontend] Update Header Breadcrumbs action is invalid: action data is missing or is not an array of objects of type {name: string}',
        expectedType: 'string',
        property: 'name',
        action,
      }),
  },
  resetBreadcrumbsDetails: {
    type: '[Microfrontend] Reset Breadcrumbs Details',
  },
  changeRoute: {
    type: '[Microfrontend] Change Route',
    validate: (action: MicroAppEvent) =>
      validateMicrofrontendActionData({
        warning:
          '[Microfrontend] Change Route action is invalid: action data is missing or is not an array of objects of type { route: string }',
        expectedType: 'string',
        property: 'route',
        action,
      }),
  },
};
