export const NOTIFICATION_PREFIX = 'WM_HOME.NOTIFICATIONS.';

export const notifications = {
  NOTIFICATION_DEFAULT_ACTION: `${NOTIFICATION_PREFIX}CLOSE`,
  sendRequest: {
    success: `${NOTIFICATION_PREFIX}SEND_REQUEST_SUCCESS`,
    error: `${NOTIFICATION_PREFIX}SEND_REQUEST_ERROR`,
  },
};

export const snackbarConfig = {
  duration: 5000, // 5s
  types: {
    info: 'info-snackbar',
    error: 'error-snackbar',
  },
};
