export const defaults = {
  maxMockupAPICallDelayMs: 1000,
  flickerDefaults: {
    circular: true,
    duration: 400,
    collectStatistics: false,
    hanger: 0,
    anchor: 0,
  },
  defaultAppContainerWidth: 290,
  regions: [
    {
      name: 'WM_HOME.HEADER.EMEA_REGION',
      id: 1,
      markets: [],
    },
    {
      name: 'WM_HOME.HEADER.ASIA_REGION',
      id: 2,
      markets: [],
    },
    {
      name: 'WM_HOME.HEADER.LATIN_AMERICA_REGION',
      id: 3,
      markets: [],
    },
    {
      name: 'WM_HOME.HEADER.NORTH_AMERICA_REGION',
      id: 4,
      markets: [],
    },
  ],
  defaultLocale: {
    dateLocale: 'en-gb',
    numberLocale: 'en-gb',
  },
  defaultLanguageCode: 'en-US',
  languages: [
    { label: 'WM_HOME.ACCOUNT_SETTINGS.ENGLISH', code: 'en-US' },
    { label: 'WM_HOME.ACCOUNT_SETTINGS.POLISH', code: 'pl-PL' },
    { label: 'WM_HOME.ACCOUNT_SETTINGS.CHINA', code: 'zh-CN' },
    { label: 'WM_HOME.ACCOUNT_SETTINGS.SPANISH', code: 'es-ES' },
  ],
  dateFormats: [
    { label: 'MM/DD/YYYY', code: 'en-US' },
    { label: 'YYYY/MM/DD', code: 'zh-cn' },
    { label: 'DD.MM.YYYY', code: 'de' },
    { label: 'DD/MM/YYYY', code: 'en-gb' },
    { label: 'DD/MM/YY', code: 'it' },
  ],
  numberFormats: [
    { label: '0,000,000,000.000', code: 'en-US' },
    { label: '0.000.000.000,000', code: 'es' },
    { label: '0 000 000 000,000', code: 'pl' },
    { label: '0,00,00,00,000.000', code: 'hi' },
  ],
  marketsStates: [
    { name: 'live', description: 'WM_HOME.MARKET_MANAGEMENT.MARKET_LIVE_DESCRIPTION' },
    { name: 'pitch', description: 'WM_HOME.MARKET_MANAGEMENT.MARKET_PITCH_DESCRIPTION' },
    { name: 'inactive', description: 'WM_HOME.MARKET_MANAGEMENT.MARKET_INACTIVE_DESCRIPTION' },
    { name: 'test', description: 'WM_HOME.MARKET_MANAGEMENT.MARKET_TEST_DESCRIPTION' },
  ],
  creationStatus: {
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
  },
  requiredTeamOwnersNumber: 2,
};
