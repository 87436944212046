import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Brand, BrandsFacade } from 'app/state/brands';
import { Category } from 'app/state/clients';

import { regEx } from 'app/config/regular-expressions';
import { atLeastOneOfFields } from '../../client-creation.validators';
import { LOGOS_LIMIT } from '../add-logo-dialog/add-logo-dialog.model';

@Component({
  selector: 'app-brand-drawer',
  templateUrl: './brand-drawer.component.html',
  styleUrls: ['./brand-drawer.component.scss'],
})
export class BrandDrawerComponent implements OnInit {
  @Input() editedBrand: Brand;
  @Input() categories: Category[];

  @Output() closeDrawer = new EventEmitter<void>();
  @Output() saveBrand = new EventEmitter<Partial<Brand>>();

  public brandForm: UntypedFormGroup;
  public isEdited: boolean;
  public isContactExpand = false;
  public isMediaExpand = false;
  public isCompetitorExpand = false;
  public selectedLogoUrl$ = this.brandsFacade.selectedLogoUrl$;

  constructor(private formBuilder: UntypedFormBuilder, private brandsFacade: BrandsFacade) {}

  public ngOnInit(): void {
    this.isEdited = !!this.editedBrand;
    this.brandsFacade.resetLogosSettings();

    this.createForm();

    if (!this.isEdited) {
      return;
    }

    this.setBrandValues();
  }

  private createForm(): void {
    this.brandForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        category: [null, Validators.required],
        logo: [null],
        logoUrl: [null],
        websiteUrl: ['', Validators.pattern(regEx.url)],
        contactName: ['', Validators.pattern(regEx.name)],
        contactPhone: ['', Validators.pattern(regEx.phoneNumber)],
        contactEmail: ['', Validators.email],
        socialMediaLinks: this.formBuilder.array([this.createSocialMediaGroup()]),
        competitors: this.formBuilder.array([this.formBuilder.control('')]),
      },
      { validator: atLeastOneOfFields(this.isEdited, 'logo', 'logoUrl') }
    );
  }

  get brandName(): AbstractControl {
    return this.brandForm.get('name');
  }

  get website(): AbstractControl {
    return this.brandForm.get('websiteUrl');
  }

  get contactName(): AbstractControl {
    return this.brandForm.get('contactName');
  }

  get contactPhone(): AbstractControl {
    return this.brandForm.get('contactPhone');
  }

  get contactEmail(): AbstractControl {
    return this.brandForm.get('contactEmail');
  }

  get socialMedia(): UntypedFormArray {
    return this.brandForm.get('socialMediaLinks') as UntypedFormArray;
  }

  get competitors(): UntypedFormArray {
    return this.brandForm.get('competitors') as UntypedFormArray;
  }

  private createSocialMediaGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      { name: [''], url: [''] },
      { validators: this.socialMediaValidator() }
    );
  }

  private setBrandValues(): void {
    const { socialMediaLinks, competitors, ...partialBrand } = this.editedBrand;
    const formBrand = { ...partialBrand, category: this.editedBrand.categories[0], logo: null };
    this.brandForm.patchValue(formBrand);

    if (competitors.length) {
      this.competitors.clear();
      competitors.forEach((competitor) =>
        this.competitors.push(this.formBuilder.control(competitor))
      );
    }

    if (socialMediaLinks.length) {
      this.socialMedia.clear();
      socialMediaLinks.forEach((media) => {
        const socialMediaForm = this.createSocialMediaGroup();
        socialMediaForm.patchValue(media);
        this.socialMedia.push(socialMediaForm);
      });
    }
  }

  public loadBrandLogo(logo: File): void {
    this.brandForm.patchValue({ logo, logoUrl: null });
  }

  public loadBrandLogoUrl(logoUrl: string): void {
    this.brandsFacade.setSelectedLogoUrl(logoUrl);

    this.brandForm.patchValue({ logo: null, logoUrl });
  }

  public removeBrandLogoUrl(): void {
    this.brandsFacade.setSelectedLogoUrl(null);
  }

  public toggleContact(): void {
    this.isContactExpand = !this.isContactExpand;
  }

  public toggleMedia(): void {
    this.isMediaExpand = !this.isMediaExpand;
  }

  public addSocialMedia(): void {
    this.socialMedia.push(this.createSocialMediaGroup());
  }

  public socialMediaValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      const nameControl = formGroup.controls['name'];
      const urlControl = formGroup.controls['url'];
      const nameValue = nameControl.value.trim();
      const urlValue = urlControl.value.trim();

      nameControl.setErrors(urlValue && !nameValue ? { required: true } : null);
      urlControl.setErrors(nameValue && !urlValue ? { required: true } : null);

      if (urlValue && !regEx.urlWithProtocol.test(urlValue)) {
        urlControl.setErrors({ pattern: true });
      }

      return null;
    };
  }

  public toggleCompetitors(): void {
    this.isCompetitorExpand = !this.isCompetitorExpand;
  }

  public addCompetitor(): void {
    this.competitors.push(this.formBuilder.control(''));
  }

  public handleClose(): void {
    this.closeDrawer.emit();
  }

  public handleSave(): void {
    const { category, competitors, socialMediaLinks, ...brand } = this.brandForm.value;
    const filledMedia = socialMediaLinks.filter((media) => media.name && media.url);
    const filledCompetitors = competitors.filter((competitor) => competitor);

    this.saveBrand.emit({
      ...this.editedBrand,
      ...brand,
      categoryIds: [category.id],
      socialMediaLinks: filledMedia,
      competitors: filledCompetitors,
    });
  }

  public getLogos(): void {
    const name = this.brandForm.get('name')?.value;
    this.brandsFacade.getLogos(name, LOGOS_LIMIT);
  }
}
