import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromBrandsActions from './brands.actions';
import * as fromBrandsSelectors from './brands.selectors';

import { AppState } from '..';
import { CreateBrandPayload, Brand } from './brands.model';
import { Client, Market } from '../clients';
import { combineLatest, filter, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { statusToMessage } from 'app/shared/utils';

@Injectable()
export class BrandsFacade {
  public brands$ = this.store.select(fromBrandsSelectors.selectBrands);
  public selectedBrand$ = this.store.select(fromBrandsSelectors.selectSelectedBrand);
  public loading$ = this.store.select(fromBrandsSelectors.selectLoading);
  public error$ = this.store.select(fromBrandsSelectors.selectError);
  public logos$ = this.store.select(fromBrandsSelectors.selectLogos);
  public selectedLogoUrl$ = this.store.select(fromBrandsSelectors.selectSelectedLogoUrl);
  public isLogosLoading$ = this.store.select(fromBrandsSelectors.selectIsLogosLoading);

  constructor(private store: Store<AppState>) {}

  public getBrands(id: Client['id'], countryCode: Market['code']): void {
    this.store.dispatch(fromBrandsActions.getBrands({ id, countryCode }));
  }

  public getBrand(id: Brand['id']): void {
    this.store.dispatch(fromBrandsActions.getBrand({ id }));
  }

  public selectBrand$(id: Brand['id']): Observable<Brand> {
    this.store.dispatch(fromBrandsActions.resetError()); // reset old errors in store
    return combineLatest([this.selectedBrand$, this.error$]).pipe(
      // take selectedBrand and error
      tap(([brand]) => {
        if (!brand || brand.id !== id) {
          this.getBrand(id);
        } // if selectedBrand is not correct, distapch getBrand action (gets one brand and sets it as selected)
      }),
      filter(([brand, error]) => brand?.id === id || !!error), // "wait" until there is correct brand or error in store
      switchMap(([brand, error]) => {
        if (brand && !error) {
          return of(brand);
        } // if we have brad without errors, return observable
        // otherwise throw error with message:
        const messagePrefix = statusToMessage[error?.status] || 'Error while getting ';
        // const advertiserId = get(error, 'metadata.clientId'); // just legacy, I don't see it working right now
        const message = `${messagePrefix}brand`;
        return throwError(() => new Error(message));
      })
    );
  }

  public createBrand(createBrandPayload: CreateBrandPayload): void {
    this.store.dispatch(fromBrandsActions.createBrand({ createBrandPayload }));
  }

  public updateBrand(brand: Brand): void {
    this.store.dispatch(fromBrandsActions.updateBrand({ brand }));
  }

  public deleteBrand(brand: Brand, withRedirect?: boolean): void {
    this.store.dispatch(fromBrandsActions.deleteBrand({ brand, withRedirect }));
  }

  public resetBrand(): void {
    this.store.dispatch(fromBrandsActions.resetBrand());
  }

  public getLogos(name: string, limit: number): void {
    this.store.dispatch(fromBrandsActions.getLogos({ name, limit }));
  }

  public setSelectedLogoUrl(selectedLogoUrl: string): void {
    this.store.dispatch(fromBrandsActions.setSelectedLogoUrl({ selectedLogoUrl }));
  }

  public resetLogosSettings(): void {
    this.store.dispatch(fromBrandsActions.resetLogosSettings());
  }
}
