import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';
import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

import { BrandsFacade, Brand } from 'app/state/brands';
import { RouterFacade } from 'app/state/router';
import { BreadcrumbsFacade } from 'app/state/breadcrumbs';

import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupConfig } from '../confirmation-popup/confirmation-popup.config';
import { NavigationService } from 'app/core/navigation.service';
import { NavigationItem } from '@config';
import { TrackerService } from 'app/core/tracker.service';
import { ModeCodes, TrackerActionType } from '@mads/wm-ng-components';
import { ClientManagementDrawerMode } from 'app/state/clients';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { ModeService } from 'app/core/mode.service';
import { ProjectsFacade } from 'app/state/projects';
import { brandMenuModeCodeForProjectBuilder } from '@interfaces';

@Component({
  selector: 'app-brand-menu',
  templateUrl: './brand-menu.component.html',
  styleUrls: ['./brand-menu.component.scss'],
})
export class BrandMenuComponent extends OnDestroyMixin implements OnInit {
  @Input() drawerOpened: boolean;
  @Output() public closeDrawer = new EventEmitter();

  public navigationCategories$ = this.navigationService.mainNavigation$;
  public DrawerMode = ClientManagementDrawerMode;
  public brand: Brand;
  public isDescriptionExpanded = false;
  public isWmMode: boolean;
  public mode: string;
  public ModeCodes = ModeCodes;
  public brandMenuModeCodeForProjectBuilder = brandMenuModeCodeForProjectBuilder;

  readonly transformNavCategory = 'WM_HOME.NAVIGATION_MAIN.TRANSFORM';

  constructor(
    private brandsFacade: BrandsFacade,
    private dialog: MatDialog,
    private routerFacade: RouterFacade,
    private breadcrumbsFacade: BreadcrumbsFacade,
    private navigationService: NavigationService,
    private trackerService: TrackerService,
    private translate: TranslateService,
    private modeService: ModeService,
    private projectsFacade: ProjectsFacade,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getBrandAndMode();
    if (!this.drawerOpened) {
      this.breadcrumbsFacade.resetBreadcrumbs();
    }
  }

  public trackClick(category: string, title: string): void {
    this.trackerService.trackAction({
      category: category,
      type: TrackerActionType.click,
      details: `${category} link ${title}`,
    });
  }

  private getBrandAndMode(): void {
    combineLatest([
      this.brandsFacade.selectedBrand$,
      this.modeService.mode$,
      this.projectsFacade.areThereprojectsForCurrentBrand$,
      this.projectsFacade.projectModeOn$,
    ]).pipe(
        filter(([brand, mode]) => !!brand && !!mode),
        untilComponentDestroyed(this),
      )
      .subscribe(([brand, mode, areThereprojectsForCurrentBrand, projectModeOn]) => {
        this.brand = brand;
        this.mode = (!areThereprojectsForCurrentBrand || !projectModeOn)
          ? mode.code
          : this.brandMenuModeCodeForProjectBuilder;
        this.isWmMode = mode.code === ModeCodes.WAVEMAKER;
      });
  }

  public onNavigationItemClick({ item, categoryTitle }: {
    item: NavigationItem,
    categoryTitle?: string
  }): void {
    if (item.disabled) {
      return;
    }

    if (categoryTitle) {
      this.trackClick(this.translate.instant(categoryTitle), item.title.split('.').slice(-1)[0].toLowerCase());
    }

    if (item.external) {
      window.open(item.path, '_blank');
      return;
    }

    if (item.path) {
      this.closeDrawer.emit();
      this.navigationService.closeAllNavigationItems();
      this.gotoPath(`brands/${this.brand.id}/${item.path}`);
    }

    if (item.opened) {
      return this.navigationService.closeNavigationItem(item.id);
    }
    this.navigationService.openMainNavigationItem(item.id);
  }

  goToCart(): void {
    this.closeDrawer.emit();
    this.navigationService.closeAllNavigationItems();
    this.gotoPath(`brands/${this.brand.id}/cart/powerpoint`);
  }

  gotoPath(path: string): void {
    this.routerFacade.changeRoute({
      linkParams: [path],
    });
  }

  public deleteBrand(brand: Brand): void {
    const config: MatDialogConfig<ConfirmationPopupConfig> = {
      data: {
        body: ['WM_HOME.HEADER.DELETE_BRAND_DIALOG'],
        bodyParams: { name: brand.name },
      },
    };

    const dialogRef = this.dialog.open<
      ConfirmationPopupComponent,
      ConfirmationPopupConfig,
      boolean
    >(ConfirmationPopupComponent, config);

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        untilComponentDestroyed(this)
      )
      .subscribe(() => this.brandsFacade.deleteBrand(brand, true));
  }
}
