import {  registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';

const config: MicrofrontendAppConfig = {
  name: 'cxi-audiences-app',
  windowLibraryName: 'cxiAudiencesApp',
  assetsPath: environment.cxiAudiencesAppUrl.replace('main.js', '').substr(1),
  appUrl: environment.cxiAudiencesAppUrl,
  // below - values to be used in local development
  // assetsPath: 'http://localhost:4400/main.js'.replace('main.js', '').substr(1),
  // appUrl: 'http://localhost:4400/main.js',
  baseHref: '/brands/:brandId/live-audiences', // business name - "Live audiences", "CXI audiences" is a name for developers 
  appLocation: environment.cxiAudiencesAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname(['live-audiences']),
};

export const registerCxiAudiencesApp = (customParams?: any) =>
  registerApp({ ...config, customParams });
