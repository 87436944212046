import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UsersFacade } from 'src/app/state/users';
import { checkAccessToModule } from 'app/shared/utils';

@Injectable()
export class ModuleGuard implements CanActivate {
  constructor(private router: Router, private usersFacade: UsersFacade) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.usersFacade.myself$.pipe(
      first(),
      map((user) =>
        user?.userRoles && checkAccessToModule(user, route.data.moduleKey)
          ? true
          : this.router.createUrlTree(['/'])
      )
    );
  }
}
