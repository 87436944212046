export interface RecentWorkItem {
  id: number;
  title: string;
  logo: string;
  market: string;
  mine?: boolean;
}

export interface App {
  description: string;
  last_used: null | string;
  pinned: null | boolean;
  url: string;
  name: string;
  favourite: null | boolean;
  id: number;
  image?: EncodedImage;
}

export interface EncodedImage {
  encoded_image: string;
  mime: string;
}

export type PersonalState = Readonly<{
  loading: boolean;
  language: string;
  dateFormatLocale: string;
  numberFormatLocale: string;
  recentWork: RecentWorkItem[];
  favouriteApps: App[];
  userOpenForWmChallenges: boolean;
  isInPresentationMode: boolean | null;
}>;

export const initialState: PersonalState = {
  loading: false,
  language: '',
  dateFormatLocale: '',
  numberFormatLocale: '',
  recentWork: [],
  favouriteApps: [],
  userOpenForWmChallenges: null,
  isInPresentationMode: null,
};

export const personalFeatureKey = 'personal';
