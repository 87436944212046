<ng-container>
  <div *ngIf="(isOwnersSelect && myself) || selectedUsers.length" class="users-selection__group">
    <div *ngIf="myself && isOwnersSelect" class="users-selection__group-element">
      <div class="users-selection__group-image">
        <img
          [src]="profilePictureApiUrl + '/' + myself.userPrincipalName"
          class="users-selection__image"
          [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
        />
        <i-feather name="lock" class="users-selection__group-icon"></i-feather>
      </div>
      <p class="wm-text-small users-selection__group-name">
        {{ myself.firstname }} {{ myself.lastname }}
      </p>
    </div>
    <div *ngFor="let user of selectedUsers" class="users-selection__group-element">
      <div class="users-selection__group-image">
        <img
          [src]="profilePictureApiUrl + '/' + user.userPrincipalName"
          class="users-selection__image"
          [alt]="'WM_HOME.MARKET_MANAGEMENT.USER_ALT' | translate"
        />
        <i-feather
          (click)="handleDeleteUser(user.id)"
          name="x"
          class="users-selection__group-icon"
        ></i-feather>
      </div>
      <p class="wm-text-small users-selection__group-name">
        {{ user?.displayName }}
      </p>
    </div>
  </div>
  <wm-input-with-dropdown
    [formControl]="nameControl"
    [placeholder]="
      (isOwnersSelect
        ? 'WM_HOME.MARKET_MANAGEMENT.DRAWER_SEARCH_OWNERS'
        : 'WM_HOME.MARKET_MANAGEMENT.DRAWER_SEARCH_MEMBERS'
      ) | translate
    "
    [isLoading]="isLoading"
    [emptyTitle]="
      'WM_HOME.CLIENT_MANAGEMENT.LIST_TITLE_EMPTY' | translate: { search: nameControl.value }
    "
    [emptyDescription]="'WM_HOME.CLIENT_MANAGEMENT.LIST_DESCRIPTION_EMPTY' | translate"
    icon="search"
  >
    <div
      *ngFor="let user of usersList"
      (click)="!user.selected && !user.disabled ? handleSelectUser(user) : $event.stopPropagation()"
      class="users-selection__element"
      [class.users-selection__element--disabled]="user.disabled"
    >
      <div class="users-selection__info">
        <img
          [src]="profilePictureApiUrl + '/' + user.userPrincipalName"
          class="users-selection__image"
        />
        <div class="users-selection__details">
          <p class="wm-text-small users-selection__text">
            {{ user.displayName }}
          </p>
          <p class="wm-text-small users-selection__text users-selection__text--email">
            {{ user.email }}
          </p>
        </div>
      </div>
      <mat-checkbox
        class="wm-checkbox users-selection__checkbox"
        [checked]="user.selected"
      ></mat-checkbox>
    </div>
  </wm-input-with-dropdown>
</ng-container>
