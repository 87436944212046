import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FlatTheme } from '@wppopen/components-library';

@Injectable()
export class WppOpenThemeAppenderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  appendTheme(theme: FlatTheme): void {
    const parsedTheme = Object.keys(theme)
      .map((property) => {
        return `${property}: ${theme[property]};`;
      })
      .join('');
    const blob = new Blob([`:root{ ${parsedTheme}}`]);
    const url = URL.createObjectURL(blob);
    const element = document.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    element.setAttribute('href', url);

    this.document.head.appendChild(element);
  }
}
