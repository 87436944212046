import { createReducer, on, Action } from '@ngrx/store';

import * as fromQuestionnaire from './questionnaire.actions';
import { QuestionnaireState, initialState } from './questionnaire.model';

const reducer = createReducer(
  initialState,
  on(
    fromQuestionnaire.open,
    (state) => ({
      ...state,
      opened: true,
      displayNotification: false,
    })
  ),
  on(
    fromQuestionnaire.dismiss,
    (state) => ({
      ...state,
      dismissed: true,
      displayNotification: true,
    })
  ),
  on(
    fromQuestionnaire.displayNotification,
    (state) => ({
      ...state,
      displayNotification: true,
    })
  ),
  on(
    fromQuestionnaire.showAnimation,
    (state, payload) => ({
      ...state,
      runAnimationPosition: payload.position,
    })
  ),
  on(
    fromQuestionnaire.hideAnimation,
    (state) => ({
      ...state,
      runAnimationPosition: null,
    })
  )
);

export function questionnaireReducer(state: QuestionnaireState, action: Action): QuestionnaireState {
  return reducer(state, action);
}
