<div class="wrapper" [style.background]="backgroundColor$ | async" [class.light]="personalModeOn">
  <div
    class="content"
    [class.content-overflow-visible]="overflowVisible"
    [class.project-mode]="(projectModeColorsOn$ | async)">
    <ng-content></ng-content>
  </div>
  <div class="background-animation-wrapper" *ngIf="backgroundImagePath$ | async as backgroundImagePath">
    <div
      class="background-animation"
      [class.personal-animation]="personalModeOn"
      [style.background-image]="'url(' + backgroundImagePath + ')'"
    ></div>
  </div>
</div>
