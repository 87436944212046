import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './base-http.service';

@Injectable()
export class TrackerHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  track(params: Object): void {
    const options = {
      ...BaseHttpService.httpOptions,
      params: this.parseQueryParams(params),
    };

    this.http.get(`${BaseHttpService.TRACKER_API_URL}/`, options).subscribe();
  }
}
