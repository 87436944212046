import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FeatherModule } from 'angular-feather';

import { MaterialModule } from './material.module';
import { PermissionDirective } from './permissions.directive';

import {
  ActionButtonsComponent,
  BackgroundComponent,
  BrandMenuComponent,
  DefaultBrandMenuComponent,
  MondelezBrandMenuComponent,
  ConfirmationPopupComponent,
  EntityMenuHeaderComponent,
  LoaderComponent,
  LogoPlaceholderComponent,
  SnackbarComponent,
  WarningSnackbarComponent,
  GlobalStylesComponent,
  TourReminderComponent,
  ProjectBuilderBrandMenuComponent,
  QuestionnaireComponent, // uncomment for @questionnaire
  GreenDayPopupComponent,
} from './components';
import { WmNgComponentsModule } from '@mads/wm-ng-components';
import { HasPermissionInAppDirective } from 'app/shared/has-permission-in-app.directive';
import { AppMutationObserverDirective } from './mutation-observer.directive';
import { LorealBrandMenuComponent } from 'app/shared/components';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from 'app/app.module';
import { RedirectToWppOpenPopupComponent } from './components/redirect-to-wpp-open-popup/redirect-to-wpp-open-popup.component';

const components = [
  ActionButtonsComponent,
  BackgroundComponent,
  BrandMenuComponent,
  ConfirmationPopupComponent,
  EntityMenuHeaderComponent,
  LoaderComponent,
  LogoPlaceholderComponent,
  SnackbarComponent,
  WarningSnackbarComponent,
  GlobalStylesComponent,
  QuestionnaireComponent, // uncomment for @questionnaire
  TourReminderComponent,
  DefaultBrandMenuComponent,
  MondelezBrandMenuComponent,
  ProjectBuilderBrandMenuComponent,
  LorealBrandMenuComponent,
  GreenDayPopupComponent,
  RedirectToWppOpenPopupComponent,
];

const directives = [PermissionDirective, HasPermissionInAppDirective, AppMutationObserverDirective];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    FeatherModule,
    NgxFlickingModule,
    PerfectScrollbarModule,
    WmNgComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [...directives, ...components],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    FeatherModule,
    NgxFlickingModule,
    PerfectScrollbarModule,
    ...directives,
    ...components,
  ],
})
export class SharedModule {}
