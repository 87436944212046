export interface NewsArticle {
  id?: number;
  channelName: string;
  channelImage:	string;
  title: string;
  description: string;
  url: string;
  imageObject: NewsImage;
  publishedDate: string;
  newsFailsafeImage?: string;
  isPinned: boolean;
}

export interface NewsImage {
  id: number;
  image: string;
  imageUrl: string;
}

export interface MyNewsResponse {
  articles?: NewsArticle[];
  count: number;
}

export interface NewsSidebarState {
  opened: boolean;
  myNews: NewsArticle[];
  loading: boolean;
  personalMainVisited: boolean;
  loadingError: boolean;
}

export const initialState: NewsSidebarState = {
  opened: false,
  myNews: [],
  loading: false,
  personalMainVisited: false,
  loadingError: false,
};

export const newsFeatureKey = 'newsSidebar';
