import { Component, OnInit } from '@angular/core';
import { animate, group, state, style, transition, trigger, query } from '@angular/animations';

import { untilComponentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { withLatestFrom } from 'rxjs/operators';

import { constants } from '@config/constants';
import { checkAccessToModule } from 'app/shared/utils';

import { App } from 'app/state/personal';
import { NewsSidebarFacade } from 'app/state/news-sidebar';
import { UsersFacade, User } from 'app/state/users';

@Component({
  selector: 'app-personal-main',
  templateUrl: './personal-main.component.html',
  styleUrls: ['./personal-main.component.scss'],
  animations: [
    trigger('resizeWidget', [
      state('false', style({ height: '280px', width: '266px', position: 'absolute' })),
      state('true', style({ height: '560px', width: '360px', position: 'absolute' })),
      transition(
        'false => true',
        group([
          animate('0.5s ease-in-out'),
          query(
            '.workspace__container-expanded__scrollable',
            [style({ opacity: 0 }), animate('500ms 300ms ease-in-out', style({ opacity: 1 }))],
            { optional: true }
          ),
        ])
      ),
      transition(
        'true => false',
        group([
          query(
            '.workspace__container-expanded__scrollable',
            [style({ opacity: 0 }), animate('500ms 300ms ease-in-out', style({ opacity: 1 }))],
            { optional: true }
          ),
          animate('0.5s ease-in-out'),
        ])
      ),
    ]),
  ],
})
export class PersonalMainComponent extends OnDestroyMixin implements OnInit {
  public isClientWidgetExpanded = false;
  public apps: App[] = [];
  public isAppsDeployed: boolean;

  constructor(
    private newsFacade: NewsSidebarFacade,
    private usersFacade: UsersFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    this.newsFacade.personalMainVisited$
      .pipe(
        withLatestFrom(this.usersFacade.myself$),
        untilComponentDestroyed(this)
      )
      .subscribe(([visited, user]) => {
          this.checkAppsAccess(user);
          !visited && checkAccessToModule(user, constants.MODULE_KEYS.NEWS)
            ? this.newsFacade.getMyNews(constants.NEWS_LIMIT)
            : this.newsFacade.setPersonalMainVisited();
        }
      );

    // @TODO: cleanup all stuff related to recent work, it wont work
    // this.personalFacade.getRecentWork();
  }

  private checkAppsAccess(user: User): void {
    this.isAppsDeployed = checkAccessToModule(user, constants.MODULE_KEYS.PROFILE);
  }

  public toggleWidget(isExpanded: boolean): void {
    this.isClientWidgetExpanded = isExpanded;
  }
}
