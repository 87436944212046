<div class="action-buttons__container">
  <button
    class="wm-button-primary wm-cancel action-buttons__button"
    [disabled]="backDisabled"
    type="button"
    (click)="onGoBack()"
    mat-button
  >
    <span [translate]="backText"></span>
  </button>

  <div
    class="action-buttons__tooltip"
    matTooltipClass="tooltip--center"
    [matTooltip]="continueDisabledTooltipText | translate"
    [matTooltipDisabled]="!continueDisabled || !continueDisabledTooltipText"
  >
    <button
      class="wm-button-primary action-buttons__button"
      [disabled]="continueDisabled"
      (click)="onContinue()"
      mat-button
      data-test="WM_HOME.ACTION_BUTTONS.CONTINUE"
    >
      <span [translate]="continueText"></span>
    </button>
  </div>
</div>
