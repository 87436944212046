import { TrackerActionType } from '@mads/wm-ng-components';

import { environment } from '@environment';
import { WelcomePopupConfigItem } from 'app/welcome-popup/welcome-popup.types';
import { LiveAudiencesDec23Component } from 'app/welcome-popup/welcome-popup-components/live-audiences-dec23/live-audiences-dec23.component';
import { ProductStatsJuly24Component } from 'app/welcome-popup/welcome-popup-components/product-stats-july24/product-stats-july24.component';

const LIVE_AUDIENCE_DEC23: WelcomePopupConfigItem = {
  lockKey: 'LIVE_AUDIENCE_DEC23',
  isTargetPageDefaultBrand: true,
  targetPage: '/live-audiences',
  filters: {
    routes: ['/you/dashboard'],
    startDate: '2023-12-14',
    endDate: '2024-01-01',
    permissionKey: 'INVITATION_POPUP_LIVE_AUDIENCE_DEC23',
  },
  priority: 1,
  lottieAsset: 'assets/lottie/live-audience-dec23.json',
  contentComponent: LiveAudiencesDec23Component,
  trafficTrackerActions: {
    show: {
      category: 'Live Audiences',
      type: TrackerActionType.click,
      details: '[GLOBAL] Live Audiences | Popup | View',
    },
    close: {
      category: 'Live Audiences',
      type: TrackerActionType.click,
      details: '[GLOBAL] Live Audiences | Popup | Discard',
    },
    goToApp: {
      category: 'Live Audiences',
      type: TrackerActionType.click,
      details: '[GLOBAL] Live Audiences | Popup | Accept',
    },
  },
};

const PRODUCT_STATISTICS_JULY24: WelcomePopupConfigItem = {
  lockKey: 'PRODUCT_STATISTICS_JULY24',
  isTargetPageDefaultBrand: false,
  targetPage: '/products-stats/dashboard',
  callToActionText: 'Go to the app',
  filters: {
    routes: ['/you/dashboard'],
    startDate: '2024-07-08T08:00:00',
    endDate: '2024-07-21T23:59:00',
    appId: environment.appsIds.productsStatsAppId,
  },
  priority: 1,
  lottieAsset: 'assets/lottie/product-stats-july24.json',
  contentComponent: ProductStatsJuly24Component,
  trafficTrackerActions: {
    show: {
      category: 'Product Statistics',
      type: TrackerActionType.view,
      details: '[GLOBAL] Product Statistics | Popup | View',
    },
    close: {
      category: 'Product Statistics',
      type: TrackerActionType.click,
      details: '[GLOBAL] Product Statistics | Popup | Discard',
    },
    goToApp: {
      category: 'Product Statistics',
      type: TrackerActionType.click,
      details: '[GLOBAL] Product Statistics | Popup | Accept',
    },
  },
};

export const welcomePopupConfig: Array<WelcomePopupConfigItem> = [
  LIVE_AUDIENCE_DEC23,
  PRODUCT_STATISTICS_JULY24,
];
