import { Component } from '@angular/core';
import { Client, ClientsFacade, CLIENT_CREATION_RESPONSE_TYPE } from 'app/state/clients';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  RequestRejectDialogComponent,
  RequestRejectDialogData,
} from 'app/components/client-request/request-reject-dialog/request-reject-dialog.component';
import { take } from 'rxjs/operators';

export interface RequestRejectDialogResult {
  rejectReason: string;
}

export const REQUEST_REJECT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'request-reject-dialog',
  width: '600px',
  autoFocus: false,
  restoreFocus: false,
  disableClose: true,
  hasBackdrop: true,
};

@Component({
  selector: 'app-client-request',
  templateUrl: './client-request.component.html',
  styleUrls: ['./client-request.component.scss'],
})
export class ClientRequestComponent {
  selectedClient$ = this.clientsFacade.selectedClient$;
  clientCreationRequestState$ = this.clientsFacade.clientCreationRequestState$;

  CLIENT_CREATION_RESPONSE_TYPE = CLIENT_CREATION_RESPONSE_TYPE;

  loaderDiameter = 20;
  loaderStrokeWidth = 2;

  constructor(
    private clientsFacade: ClientsFacade,
    private dialog: MatDialog
  ) { }

  onRejectButtonClick(): void {
    this.selectedClient$.pipe(take(1)).subscribe((client: Client) => {
      const dialogRef = this.dialog.open<
        RequestRejectDialogComponent,
        RequestRejectDialogData,
        RequestRejectDialogResult
      >(RequestRejectDialogComponent, {
        ...REQUEST_REJECT_DIALOG_CONFIG,
        data: {
          name: client.name,
          requester: client.createdBy,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }

        const { rejectReason } = result;

        this.clientsFacade.rejectClientCreationRequest({ id: client.id, rejectReason });
      });
    });
  }

  onAcceptButtonClick(): void {
    this.selectedClient$
      .pipe(take(1))
      .subscribe(({ id }: Client) => this.clientsFacade.acceptClientCreationRequest({ id }));
  }
}
