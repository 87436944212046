import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';

import { UsersFacade } from 'src/app/state/users';
import { constants } from '@config/constants';
import { Actions, ofType } from '@ngrx/effects';
import * as userActions from 'src/app/state/users/users.actions';
import { environment } from '@environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private usersFacade: UsersFacade,
    private router: Router,
    private actions$: Actions
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.usersFacade.myself$.pipe(
      take(1),
      switchMap((userFromStore) => {
        if (userFromStore) {
          return of(true);
        }

        this.usersFacade.saveTargetPath(state.url);
        this.usersFacade.getMyself();
        return this.actions$.pipe(
          ofType(userActions.getMyselfSuccess, userActions.getMyselfFailure),
          take(1),
          map((action) => {
            if (action.type === userActions.getMyselfFailure.type) {
              return false;
            }

            const userLoadedFromBackend = action.user;
            const hasWmosAccessPermission = userLoadedFromBackend.userRoles
              .filter(role => role.applicationId === environment.appsIds.appId)
              .some(role =>
                role.permissions.some(permission => permission.key === constants.USER_PERMISSION_KEYS.CAN_ACCESS)
              );

            return hasWmosAccessPermission || this.router.createUrlTree(['/no-access']);
          })
        );
      })
    );
  }
}
