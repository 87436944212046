import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { Project } from 'app/interfaces';
import { Client } from '@mads/wm-ng-components';

@Injectable()
export class ProjectsHttpService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  public getProjects(workspace: Client['id']): Observable<Project[]> {
    const params = this.parseQueryParams({
      workspace,
      draft: false,
    });
    const opts = {
      ...ProjectsHttpService.httpOptions,
      params,
    };
    const request = this.http.get<Project[]>(
      `${ProjectsHttpService.PROJECT_BUILDER_API_URL}/projects`,
      opts,
    );

    return this.handleRequest<Project[]>(request);
  }
}
