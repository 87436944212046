import { Page } from 'src/app/interfaces';
import { HelpMenuInfo } from '@mads/wm-ng-components';
const defaultLocale = 'en';

/**
 * @deprecated will be removed as a part of WMO-12132
 */
export interface LegacyHelpMenuInfo extends HelpMenuInfo {
  contextualHelpMatomoNumber?: number | null;
}

export const filterPagesForUsersLocale = (
  locale: string,
  global: Page[],
  local: Page[]
): { filteredGlobal: Page[], filteredLocal: Page[] } => {
  if (locale === defaultLocale) {
    return {
      filteredGlobal: filterDefaultLocalePages(global),
      filteredLocal: filterDefaultLocalePages(local),
    };
  }
  const preferredGlobal = global.filter(page => page.locale === locale);
  const preferredLocal = local.filter(page => page.locale === locale);
  const filteredGlobal = preferredGlobal.length
    ? preferredGlobal
    : filterDefaultLocalePages(global);
  const filteredLocal = preferredLocal.length
    ? preferredLocal
    : filterDefaultLocalePages(local);
  return { filteredGlobal, filteredLocal };
};

const filterDefaultLocalePages = (pages: Page[]): Page[] => {
  return pages.filter(page => page.locale === defaultLocale);
};

export  const parsePagesPathsForUsersLocale = (
  global: Page[],
  local: Page[]
): { parsedGlobal: Page[], parsedLocal: Page[] } => {
  const parsedGlobal = addLocaleToPaths(global);
  const parsedLocal = addLocaleToPaths(local);
  return { parsedGlobal, parsedLocal };
};

const addLocaleToPaths = (pages: Page[]): Page[] => {
  return pages.map(page => ({
    ...page,
    path: `${page.locale}/${page.path}`,
  }));
};

export const compareStringNumbers = (x: string | number | undefined, y: string | number | undefined): boolean => {
  if (x === undefined || y === undefined) { return false; }
  let surelyNumberX = x;
  let surelyNumberY = y;
  if (typeof x === 'string') { surelyNumberX = parseInt(x, 10); }
  if (typeof y === 'string') { surelyNumberY = parseInt(y, 10); }
  return surelyNumberX === surelyNumberY;
};
