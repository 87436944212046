import { forEach } from 'lodash-es';

import { Brand, BrandFormDataValidationKeys, NEW_API_CATEGORIES_KEY } from './brands.model';

type ValueOf<T> = T[keyof T];

export const addValueToFormData = (
  formData: FormData,
  brandValue: ValueOf<Brand>,
  brandKey: keyof Brand
): void => {
  if (brandKey === BrandFormDataValidationKeys.LOGO) {
    return brandValue instanceof File && formData.append(brandKey, brandValue);
  }

  if (brandKey === BrandFormDataValidationKeys.LOGO_URL && !brandValue) {
    return;
  }

  if (brandKey === BrandFormDataValidationKeys.CATEGORY_IDS) {
    forEach(brandValue as string[], (propValue) => formData.append(brandKey, propValue as string));
    formData.append(NEW_API_CATEGORIES_KEY, JSON.stringify(brandValue));
    return;
  }

  typeof brandValue === 'string'
    ? formData.append(brandKey, brandValue)
    : formData.append(brandKey, JSON.stringify(brandValue));
};

export const prepareBrandFormData = (brand: Partial<Brand>): FormData => {
  const brandData = new FormData();

  forEach(brand, (brandValue: ValueOf<Brand>, brandKey: keyof Brand) => {
    addValueToFormData(brandData, brandValue, brandKey);
  });

  return brandData;
};
