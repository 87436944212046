import { createAction, Action, props } from '@ngrx/store';
import { Breadcrumbs } from '.';

export const updateHeaderBreadcrumbs = createAction(
  '[Breadcrumbs] Update Header Breadcrumbs',
  props<{ updatedBreadcrumbs: Breadcrumbs | Action }>()
);

export const resetBreadcrumbsDetails = createAction(
  '[Breadcrumbs] Reset Breadcrumbs Details'
);

export const resetBreadcrumbs = createAction(
  '[Breadcrumbs] Reset Breadcrumbs'
);
