<wm-wrapper>
  <nav mat-tab-nav-bar class="wm-main-navigation">
    <a mat-tab-link
       disableRipple
       routerLinkActive
       *ngFor="let link of (navigationItems$ | async)"
       #rla="routerLinkActive"
       [active]="rla.isActive"
       [routerLink]="link?.path"
       [disabled]="link?.disabled">
      <span [translate]="link.title"></span>
    </a>
  </nav>
  <wm-loader *ngIf="showLoader$ | async" mode="indeterminate"></wm-loader>
  <router-outlet></router-outlet>
</wm-wrapper>
