import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { debounceTime, filter, map, startWith, take } from 'rxjs/operators';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import {
  DONT_SHOW_TOUR,
  GuideService,
  HelpMenuCustomItem,
  TrackAnalyticsActionType,
  TrackerActionType,
} from '@mads/wm-ng-components';
import { Router } from '@angular/router';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { compact, flattenDeep, isEmpty } from 'lodash-es';

import { config, UserNavigationSection, UserSectionNavigationName } from '@config/index';
import { User, UsersFacade } from 'app/state/users';
import { ContextualHelpFacade } from 'app/state/contextual-help';
import { Page, Task, tools } from '@interfaces';
import { checkAccessToModule, checkSingleChoicePermission, getSection } from 'app/shared/utils';
import { NavigationService } from 'app/core/navigation.service';
import { ApplicationsFacade } from 'app/state/applications';
import { CLOSE_TOUR_REMINDER } from '@config/project-guide';
import { ContextualHelpDrawerService } from '../contextual-help/contextual-help-drawer/contextual-help-drawer.service';
import { constants, TEST_ENVIRONMENT } from '@config/constants';
// @TODO: questionnaire example
// import { QuestionnaireFacade } from 'app/state/questionnaire/questionnaire.facade';
import { matchRouteToAppName } from '../contextual-help/contextual-help-matomo.utils';
import { WAS_HELP_MENU_INFOTIP_DISMISSED } from '@config/constants';
import { TrackerService } from 'app/core/tracker.service';
import { AnalyticsTrackingService } from 'app/core/analystics-tracking.service';
import { TrackedActions } from '@config/tracked-actions';
import { ProjectsFacade } from 'app/state/projects';
import { GuideMasterService } from 'app/core/guide-master.service';
import { ModeService } from 'app/core/mode.service';
import { environment } from '@environment';
import { QuestionnaireFacade } from 'app/state/questionnaire/questionnaire.facade';

const isBefore5thDecember = new Date() < new Date('2022-12-05'); // TODO: remove with content questionnaire after 5.12.2022

@Component({
  selector: 'app-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrls: ['./header-wrapper.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('200ms', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
    // uncomment for @questionnaire
    trigger('questionnaireAnimation',  [
      transition('void => in', [
        style({transform: 'scale(0)'}),
        animate('400ms', style({transform: 'scale(1.2)'})),
        animate('300ms', style({transform: 'scale(0.8)'})),
        animate('200ms', style({transform: 'scale(1.1)'})),
        animate('200ms', style({transform: 'scale(0.9)'})),
        animate('100ms', style({transform: 'scale(1)'})),
        animate('500ms ease-in-out', style({bottom: '108px', right: '69px'})),
      ]),
      state('in', style({bottom: '108px', right: '69px'})),
    ]),
    // -----------------------------------
  ],
})
export class HeaderWrapperComponent implements OnInit {
  @Input() headerType: string;
  @ViewChild('menuContainer') menuContainer;
  @ViewChild('projectBuilderMenuContainer') projectBuilderMenuContainer;

  public headerTypes = config.constants.HEADER_TYPES;
  public drawerOpened = false;
  public isExternalAgency = this.modeService.isExternalAgencyModeOn;
  public mode = this.modeService.currentMode;

  // help menu mumbo jumbo:
  public goToIconSrc = 'assets/icons/go-to-white.svg';
  public isHelpMenuOpened = false;
  private clicksOutsideOfHelpMenu$ = fromEvent(document, 'click').pipe(
    filter((event) => !this.menuContainer?.nativeElement.contains(event.target))
  );
  private helpMenuOutsideClicksSub: Subscription;

  public showLinkToSitemap$ = this.usersFacade.myself$.pipe(
    filter((user) => !!user),
    take(1),
    map((user) => checkAccessToModule(user, constants.MODULE_KEYS.SITEMAP))
  );

  public helpMenuNotificationsCount = 0;
  public showContextualHelpButton = false;
  public helpMenuWasJustDragged = false;
  private helpMenuCoordinates = {
    x: window.innerWidth - 29,
    y: window.innerHeight - 70,
  };
  readonly helpMenuSize = {
    width: 210, // adjustable in scss
    maxHeight: 122, // this is what you get when there are three items in menu - current max
  };
  readonly turningMargin = 10;
  readonly dotMenusTurningPoints = {
    horizontal: this.helpMenuSize.width + this.turningMargin,
    vertical: this.helpMenuSize.maxHeight + this.turningMargin + 85, // 85 for max header height
  };
  public turnHelpMenuRight = false;
  public turnHelpMenuDown = false;
  public isDuringE2E = localStorage.getItem(TEST_ENVIRONMENT) === 'true';
  public showMenuInfotip =
    !this.isDuringE2E &&
    localStorage.getItem(WAS_HELP_MENU_INFOTIP_DISMISSED) !== 'true' &&
    localStorage.getItem(DONT_SHOW_TOUR) !== 'true';

  // uncomment for @questionnaire
  public showQuestionnaireMenuItem$ = this.usersFacade.myself$.pipe(
    filter(user => !!user),
    take(1),
    map(user => isBefore5thDecember && checkSingleChoicePermission(
      user,
      constants.USER_PERMISSION_KEYS.SHOULD_SEE_QUESTIONNAIRE,
      environment.appsIds.appId
    ))
  );
  public showNotificationForQuestionnaire$ = this.questionnaireFacade.displayNotification$;
  public animationStartingPositionBottom: string;
  public animationStartingPositionRight: string;
  public questionaireAnimationState: string;
  //--------------------------------------------------------------

  // productTour mambo jumbo:
  readonly closeReminderTour = CLOSE_TOUR_REMINDER;
  public userNavigation: UserNavigationSection;
  public sections = UserSectionNavigationName;
  public hasCurrentProductTours$ = this.applicationsFacade.hasCurrentProductTours$;
  public isProductTourReminderDisplayed$ = this.guideService.visibleTooltipId$.pipe(
    map((tooltipId) => tooltipId === this.closeReminderTour.id)
  );

  public helpMenuCustomItems$ = this.applicationsFacade.helpMenuCustomItems$;
  public globalPage$ = this.contextualHelpFacade.globalPage$;
  public localPage$ = this.contextualHelpFacade.localPage$;

  // project builder mumbo jumbo:

  public tasksUnderProjectBuilderBall$ = combineLatest([
    this.projectsFacade.selectedProject$,
    this.router.events.pipe(
      // it has to be updated on route change to check what app is active
      debounceTime(300),
      startWith('whatever')
    ),
  ]).pipe(
    map(([project]) =>
      compact(
        flattenDeep(
          project?.columns.map((column) => {
            // filter only tasks for active app:
            const toolsConfig = tools.find((tool) => this.router.url.includes(tool.urlPattern));
            return toolsConfig && column.tasks.filter((task) => task.source === toolsConfig.tool);
          })
        )
      ).map((task) => ({
        ...task,
        projectId: project.id, // add this to be able to generate link to Project Builder
      }))
    )
  );

  public shouldDisplayProjectBuilderBall$ = combineLatest([
    this.projectsFacade.projectModeOn$,
    this.tasksUnderProjectBuilderBall$,
  ]).pipe(map(([projectModeOn, tasks]) => projectModeOn && !isEmpty(tasks)));

  public projectBuilderBallWasJustDragged = false;
  private projectBuilderBallCoordinates = {
    x: window.innerWidth - 29,
    y: 90,
  };

  public isProjectBuilderMenuOpened = false;
  private clicksOutsideOfProjectBuilderMenu$ = fromEvent(document, 'click').pipe(
    filter((event) => !this.projectBuilderMenuContainer?.nativeElement.contains(event.target))
  );
  private projectBuilderMenuOutsideClicksSub: Subscription;
  public turnProjectBuilderMenuRight = false;
  public turnProjectBuilderMenuDown = true;

  constructor(
    private contextualHelpFacade: ContextualHelpFacade,
    private navigationService: NavigationService,
    private applicationsFacade: ApplicationsFacade,
    private usersFacade: UsersFacade,
    private questionnaireFacade: QuestionnaireFacade,
    private guideService: GuideService,
    private contextualHelpDrawerService: ContextualHelpDrawerService,
    private router: Router,
    private projectsFacade: ProjectsFacade,
    private guideMasterService: GuideMasterService,
    private trackerService: TrackerService,
    private modeService: ModeService,
    private analyticsTrackingService: AnalyticsTrackingService
  ) {}

  public ngOnInit(): void {
    this.initContextualHelpMenu();

    // no unsub because this component never dies:
    this.navigationService.userSectionNavigation$.subscribe((navigationConfig) => {
      this.userNavigation = navigationConfig;
    });
  }

  private initContextualHelpMenu(): void {
    // tag needs to be nullified on app change - onDestroy component events are too slow for single-spa:
    window.addEventListener('single-spa:before-app-change', (evt) => {
      this.contextualHelpFacade.resetContextualHelpMenuInfo();
    });

    // no unsub because this component never dies:
    combineLatest([
      this.usersFacade.myself$,
      this.contextualHelpFacade.globalPage$,
      this.contextualHelpFacade.localPage$,
      // uncomment for @questionnaire:
      this.questionnaireFacade.displayNotification$,
      this.showQuestionnaireMenuItem$,
      // ----------------------------------
    ])
      .pipe(
        map(
          ([myself, globalPage, localPage, // uncomment for @questionnaire:
          displayQuestionnaireNotification,
          showQuestionnaireMenuItem]:
          // -----------------------------------
          [
            User,
            Page,
            Page,
            boolean,
            boolean
          ]) => {
            // new content marking - side effect:
            this.helpMenuNotificationsCount = this.countTrueValues([
              globalPage?.isNewContent,
              localPage?.isNewContent,
              // uncomment for @questionnaire:
              isBefore5thDecember && showQuestionnaireMenuItem && displayQuestionnaireNotification,
              // -----------------------------------
            ]);
            if (myself.isContextualHelpEditor) {
              return true;
            }
            if (!myself.hasContextualHelpAccess) {
              return false;
            }
            return !!(globalPage || localPage);
          }
        ),
        debounceTime(100) // swallow rapid consecutive changes
      )
      .subscribe((showContextualHelpButton) => {
        this.showContextualHelpButton = showContextualHelpButton;
      });

    // no unsub because this component never dies:
    // uncomment for @questionnaire:
    this.questionnaireFacade.runAnimationPosition$.subscribe(position => {
      if (!position) {
        this.questionaireAnimationState = null;
        this.animationStartingPositionBottom = null;
        this.animationStartingPositionRight = null;
        return;
      }
      this.questionaireAnimationState = 'in';
      // 14 is for notification dot radius:
      this.animationStartingPositionBottom = `${window.innerHeight - position.y - 14}px`;
      this.animationStartingPositionRight = `${window.innerWidth - position.x - 14}px`;
    });
    // -----------------------------------
  }

  public toggleDrawer(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.drawerOpened = !this.drawerOpened;
  }

  public closeDrawer(): void {
    this.drawerOpened = false;
  }

  public onHelpMenuClick(): void {
    if (this.helpMenuWasJustDragged) {
      // this blocks click event after dragging - cdkDrag always fires click after dragEnd
      // (thats why we do it here, not in dragend event handler)
      this.helpMenuWasJustDragged = false;
      return;
    }
    this.openHelpMenu();
  }

  private openHelpMenu(): void {
    this.contextualHelpFacade.matomoSection$.pipe(take(1)).subscribe((matomoSection) => {
      const appName = matchRouteToAppName(this.router.url);
      const section = matomoSection || appName.toLowerCase(); // for unmarked sections we fallback to appName
      this.trackHelpMenuClickInMatomo(appName, section);
    });

    this.isHelpMenuOpened = true;
    this.helpMenuOutsideClicksSub = this.clicksOutsideOfHelpMenu$.subscribe((click) =>
      this.closeHelpMenu()
    );
  }

  private trackHelpMenuClickInMatomo(appName: string, matomoSection: string): void {
    this.trackerService.trackAction({
      category: appName,
      type: TrackerActionType.click,
      details: `Help ${matomoSection}`,
    });
  }

  public closeHelpMenu(): void {
    this.isHelpMenuOpened = false;
    this.helpMenuOutsideClicksSub.unsubscribe();
  }

  public onHelpMenuDragStart(): void {
    this.helpMenuWasJustDragged = true;
  }

  public onHelpMenuDragEnd(event: CdkDragEnd): void {
    this.helpMenuCoordinates = {
      x: this.helpMenuCoordinates.x + event.distance.x,
      y: this.helpMenuCoordinates.y + event.distance.y,
    };
    this.turnHelpMenuRight = this.helpMenuCoordinates.x < this.dotMenusTurningPoints.horizontal;
    this.turnHelpMenuDown = this.helpMenuCoordinates.y < this.dotMenusTurningPoints.vertical;
  }

  public onProjectBuilderBallDragStart(): void {
    this.projectBuilderBallWasJustDragged = true;
  }

  public onProjectBuilderBallDragEnd(event: CdkDragEnd): void {
    this.projectBuilderBallCoordinates = {
      x: this.projectBuilderBallCoordinates.x + event.distance.x,
      y: this.projectBuilderBallCoordinates.y + event.distance.y,
    };

    this.turnProjectBuilderMenuRight =
      this.projectBuilderBallCoordinates.x < this.dotMenusTurningPoints.horizontal;
    this.turnProjectBuilderMenuDown =
      this.projectBuilderBallCoordinates.y < this.dotMenusTurningPoints.vertical;
  }

  public onProjectBuilderBallClick(): void {
    if (this.projectBuilderBallWasJustDragged) {
      // this blocks click event after dragging - cdkDrag always fires click after dragEnd
      // (thats why we do it here, not in dragend event handler)
      this.projectBuilderBallWasJustDragged = false;
      return;
    }
    this.tasksUnderProjectBuilderBall$.pipe(take(1)).subscribe((tasks) => {
      if (!tasks?.length) {
        return;
      } // rather impossible here
      if (tasks.length === 1) {
        return this.openProjectBuilderTaskModal(tasks[0]);
      }
      this.openProjectBuilderMenu();
    });
  }

  private openProjectBuilderMenu(): void {
    this.isProjectBuilderMenuOpened = true;
    this.projectBuilderMenuOutsideClicksSub = this.clicksOutsideOfProjectBuilderMenu$.subscribe(
      (click) => this.closeProjectBuilderMenu()
    );
  }

  private closeProjectBuilderMenu(): void {
    this.isProjectBuilderMenuOpened = false;
    this.projectBuilderMenuOutsideClicksSub.unsubscribe();
  }

  public goToTrainingPortal(): void {
    this.closeHelpMenu();

    window.open('https://insidemedia.sharepoint.com/sites/wmgostraining', '_blank');
  }

  public shouldDisplaySection(sectionsNames: string[]): boolean {
    const section = getSection(this.userNavigation, sectionsNames);
    return !section?.hidden;
  }

  public shouldEnableSection(sectionsNames: string[]): boolean {
    const section = getSection(this.userNavigation, sectionsNames);
    return !section?.disabled;
  }

  public runProductTours(): void {
    this.applicationsFacade.runProductTours();
    this.guideMasterService.unlockAndStartGuide();
  }

  public openContextualHelpDrawer(): void {
    this.contextualHelpDrawerService.open();

    this.closeHelpMenu();

    this.analyticsTrackingService.track({
      type: TrackAnalyticsActionType.action,
      payload: TrackedActions.CONTEXTUAL_HELP_CLICKED,
    });

  }

  public onHelpMenuCustomItemClick(id: HelpMenuCustomItem['id']): void {
    this.applicationsFacade.onHelpMenuCustomItemClick(id);
  }

  public openProjectBuilderTaskModal(task: Task, clickEvent?: MouseEvent): void {
    if (clickEvent) {
      this.preventClick(clickEvent);
    }

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/project-builder/projects/${task.projectId}/board`], {
        queryParams: { openTaskId: task.id },
      })
    );

    window.open(url, '_blank');
  }

  private countTrueValues(arg: boolean[]): number {
    if (this.isExternalAgency) {
      return 0;
    } // external agencies only has product tour in help menu (no notifications ever)
    return arg.reduce((sum, cur) => sum + (cur ? 1 : 0), 0);
  }

  // uncomment for @questionnaire:
  public goToQuestionnaire(): void {
    this.questionnaireFacade.open();
  }
  // -----------------------------------

  public preventClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public closeMenuInfotip(event: MouseEvent): void {
    event.stopPropagation();
    localStorage.setItem(WAS_HELP_MENU_INFOTIP_DISMISSED, 'true');
    this.showMenuInfotip = false;
  }
}
