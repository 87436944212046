import { Component, ElementRef } from '@angular/core';

import { registerMediaPlansResultsApp } from 'src/single-spa/mp-results';
import { MicroAppWrapperService } from 'app/core/micro-app-wrapper.service';
import { MicroAppWrapper, MicroAppParams } from '../micro-app-wrapper';

interface MediaPlansResultsAppState {
  pristine: boolean;
}

const appState: MediaPlansResultsAppState = { pristine: true };

@Component({
  selector: 'app-mp-results-wrapper',
  templateUrl: './mp-results-wrapper.component.html',
  styleUrls: ['./mp-results-wrapper.component.scss'],
})
export class MediaPlansResultsWrapperComponent extends MicroAppWrapper {
  protected appName = 'MediaPlansResults';
  public appState = appState;

  constructor(
    protected microAppWrapperService: MicroAppWrapperService,
    protected elementRef: ElementRef
  ) {
    super(microAppWrapperService, elementRef);
  }

  protected registerFn(params: MicroAppParams<{ state: MediaPlansResultsAppState }>): void {
    params.customParams.state = this.appState;

    this.config = registerMediaPlansResultsApp(params);
  }
}
