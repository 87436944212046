import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ProjectBuilderSettings } from '@mads/wm-ng-components';

import { ProjectsFacade } from 'app/state/projects';

@Injectable()
export class ProjectBuilderService {
  public projectBuilderSettings$: BehaviorSubject<ProjectBuilderSettings> = new BehaviorSubject(null);

  constructor(private projectsFacade: ProjectsFacade) {}

  public broadcastProjectBuilderSettingChange(settings: ProjectBuilderSettings): void {
    this.projectBuilderSettings$.next(settings);
  }

  public emitProjectBuilderSettingsFromChild(settings: ProjectBuilderSettings): void {
    this.projectsFacade.setSettingsFromChild(settings);
  }
}
