import { HttpErrorResponse } from '@angular/common/http';
import { Brand, Client, ProjectBuilderSettings } from '@mads/wm-ng-components';
import { createAction, props } from '@ngrx/store';

import { ModeSettings, Project } from 'app/interfaces';

export const getProjects = createAction(
  '[Projects] Get Projects',
  props<{ clientId: Client['id'] }>()
);

export const getProjectsSuccess = createAction(
  '[Projects] Get Projects Success',
  props<{ projects: Project[] }>()
);

export const getProjectsFail = createAction(
  '[Projects] Get Projects Fail',
  props<{ error: HttpErrorResponse }>()
);

export const setProjectMode = createAction(
  '[Projects] Set Project Mode',
  props<{newSetting: ModeSettings}>(),
);

export const setProjectModeBulk = createAction(
  '[Projects] Set Project Mode Bulk',
  props<{newSettings: ModeSettings[]}>(),
);

export const selectProject = createAction(
  '[Projects] Select Project',
  props<{ project: Project; brandId: Brand['id']}>(),
);

export const resetProjects = createAction(
  '[Projects] Reset Projects'
);

export const propagateSettingsToMicrofrontends = createAction(
  '[Projects] Propagate settings to microfrontends'
);

export const setSettingsFromChild = createAction(
  '[Projects] Set settings from child',
  props<ProjectBuilderSettings>(),
);
