import { Component, Inject } from '@angular/core';
import { ContainerPosition } from '@mads/wm-ng-components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';

export interface RequestRejectDialogData {
  name: string;
  requester: string;
}

export interface RequestRejectDialogResult {
  rejectReason: string;
}

const REASON_MAX_LENGTH = 500;

@Component({
  selector: 'app-request-reject-dialog',
  templateUrl: './request-reject-dialog.component.html',
  styleUrls: ['./request-reject-dialog.component.scss'],
})
export class RequestRejectDialogComponent {
  ContainerPosition = ContainerPosition;
  REASON_MAX_LENGTH = REASON_MAX_LENGTH;

  reasonControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(REASON_MAX_LENGTH),
    Validators.minLength(10),
  ]);

  constructor(
    private dialogRef: MatDialogRef<RequestRejectDialogComponent, RequestRejectDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: RequestRejectDialogData
  ) {}

  onReject(): void {
    this.dialogRef.close();
  }

  onAccept(): void {
    if (!this.reasonControl.valid) {
      return;
    }

    this.dialogRef.close({
      rejectReason: this.reasonControl?.value?.trim(),
    });
  }
}
