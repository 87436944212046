import { matchingBrandPathname, registerApp, MicrofrontendAppConfig } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const SCORECARDS_ROUTE_MATCHER = 'scorecards';

export const config: MicrofrontendAppConfig = {
  name: 'scorecards-app',
  windowLibraryName: 'scorecardsApp',
  assetsPath: environment.scorecardsAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/scorecards/',
  appUrl: environment.scorecardsAppUrl,
  appLocation: environment.scorecardsAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname([SCORECARDS_ROUTE_MATCHER]),
};

export const registerScorecardsApp = (customParams?: MicroAppParams): MicrofrontendAppConfig =>
  registerApp({ ...config, customParams });
