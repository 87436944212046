import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from 'app/state';

import * as fromUsers from './users.actions';
import * as usersSelectors from './users.selectors';

@Injectable()
export class UsersFacade {
  public myself$ = this.store.select(usersSelectors.selectMyself);
  public owners$ = this.store.select(usersSelectors.selectOwners);
  public members$ = this.store.select(usersSelectors.selectMembers);
  public userTeams$ = this.store.select(usersSelectors.selectUserTeams);
  public dateLocale$ = this.store.select(usersSelectors.selectDateLocale);
  public loading$ = this.store.select(usersSelectors.selectLoading);
  public targetPath$ = this.store.select(usersSelectors.selectTargetPath);
  public hasAccessToSimplifiedBrandMenu$ = this.store.select(usersSelectors.selectHasAccessToSimplifiedBrandMenu);
  public simplifiedBrandMenuOn$ = this.store.select(usersSelectors.selectSimplifiedBrandMenuOn);
  public defaultMarket$ = this.store.select(usersSelectors.selectDefaultMarket);
  constructor(private store: Store<AppState>) {}

  public getMyself(): void {
    this.store.dispatch(fromUsers.getMyself());
  }

  public getUsers(name: string, getOwners?: boolean): void {
    this.store.dispatch(fromUsers.getUsers({ name, getOwners }));
  }

  public getUserTeams(): void {
    this.store.dispatch(fromUsers.getUserTeams());
  }

  public resetUsers(): void {
    this.store.dispatch(fromUsers.resetUsers());
  }

  public microfrontendUnauthorized(appName: string): void {
    this.store.dispatch(fromUsers.microfrontendUnauthorized({ appName }));
  }

  public logout() {
    this.store.dispatch(fromUsers.logout());
  }

  public saveTargetPath(path: string): void {
    this.store.dispatch(fromUsers.saveTargetPath({ path }));
  }

  public toggleBrandMenuMode() {
    this.store.dispatch(fromUsers.toggleBrandMenuMode());
  }

  public getDefaultMarketId(countryCode: string): void {
    this.store.dispatch(fromUsers.getDefaultMarket({countryCode}));
  }
}
