import { createReducer, on, Action } from '@ngrx/store';

import * as fromContextualHelp from './contextual-help.actions';
import { initialState, ContextualHelpState } from './contextual-help.model';
import {
  compareStringNumbers,
  filterPagesForUsersLocale,
  parsePagesPathsForUsersLocale,
} from './contextual-help.utils';

const reducer = createReducer(
  initialState,
  on(fromContextualHelp.setCurrentTag, (state, { currentTag }) => ({
    // deprecated
    ...state,
    currentTag,
    currentGlobalPage: null,
    currentLocalPage: null,
    pathsToUnclearPages: null,
    loading: !!currentTag,
  })),
  on(
    fromContextualHelp.setContextualHelpMenuInfo,
    (state, { data }): ContextualHelpState => ({
      ...state,
      currentTag: data.contextualHelpArticleTag,
      contextualHelpTrackingSuffix:
        data.contextualHelpTrackingSuffix || data.contextualHelpMatomoNumber?.toString() || null,
      currentGlobalPage: null,
      currentLocalPage: null,
      pathsToUnclearPages: null,
      loading: !!data.contextualHelpArticleTag,
    })
  ),
  on(fromContextualHelp.resetContextualHelpMenuInfo, (state) => ({
    ...state,
    currentTag: null,
    contextualHelpMatomoNumber: null,
    contextualHelpTrackingSuffix: null,
    currentGlobalPage: null,
    currentLocalPage: null,
    pathsToUnclearPages: null,
    loading: false,
  })),
  on(fromContextualHelp.setMatomoSection, (state, { section }) => ({
    ...state,
    matomoSection: section,
  })),
  on(fromContextualHelp.getPagesSuccess, (state, { res, userLanguage }) => {
    const stateCopy = { ...state, loading: false };
    const { global, local } = res.data?.pages.pipSearch;

    if (!Array.isArray(global) || !Array.isArray(local)) {
      const error = 'Unexpected API response in search by tag. List is not an array.';
      console.error(error);
      stateCopy.error = error;
      return stateCopy;
    }

    if (!global.length && !local.length) {
      return stateCopy;
    }

    const { filteredGlobal, filteredLocal } = filterPagesForUsersLocale(
      userLanguage,
      global,
      local
    );
    const { parsedGlobal, parsedLocal } = parsePagesPathsForUsersLocale(
      filteredGlobal,
      filteredLocal
    );

    if (parsedGlobal.length > 1 || parsedLocal.length > 1) {
      const paths = [
        ...parsedGlobal.map((page) => page.path),
        ...parsedLocal.map((page) => page.path),
      ];
      stateCopy.pathsToUnclearPages = paths;
    }

    stateCopy.currentGlobalPage = parsedGlobal[0];
    stateCopy.currentLocalPage = parsedLocal[0];
    return stateCopy;
  }),
  on(fromContextualHelp.getPagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(fromContextualHelp.markPageAsReadSuccess, (state, { res }) => {
    const { pageId } = res;
    const wasGlobalPageMarked = compareStringNumbers(pageId, state.currentGlobalPage?.id);
    if (!wasGlobalPageMarked && !compareStringNumbers(pageId, state.currentLocalPage?.id)) {
      return state;
    }

    const markedPagePath = wasGlobalPageMarked ? 'currentGlobalPage' : 'currentLocalPage';
    return {
      ...state,
      [markedPagePath]: {
        ...state[markedPagePath],
        isNewContent: false,
      },
    };
  })
);

export function contextualHelpReducer(
  state: ContextualHelpState | undefined,
  action: Action
): ContextualHelpState {
  return reducer(state, action);
}
