import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import 'moment/locale/zh-cn';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import { defaults } from '@config/defaults';
import { UsersFacade } from 'app/state/users';
import { PersonalFacade } from 'app/state/personal';

@Pipe({ name: 'appDate', pure: false })
export class AppDatePipe implements PipeTransform, OnDestroy {
  private localeSubscription: Subscription;
  public locale: string;

  constructor(
    private usersFacade: UsersFacade,
    private personalFacade: PersonalFacade,
    private ref: ChangeDetectorRef
  ) {
    this.localeSubscription = combineLatest(
      this.personalFacade.dateFormat$,
      this.usersFacade.dateLocale$
    )
      .pipe(map(([appLocale, userLocale]) => appLocale || userLocale))
      .subscribe((locale) => {
        this.locale = locale;
        this.ref.markForCheck();
      });
  }

  public transform(value: any): string {
    const localeMoment = moment(value).locale(this.locale);
    const hasCustomFormat = defaults.defaultLocale.dateLocale.includes(this.locale);
    const foundFormat = defaults.dateFormats.find((format) => format.code === this.locale);

    // 'L' is an moment format used for displaying data based on locale
    // https://github.com/moment/momentjs.com/blob/master/docs/moment/07-customization/06-long-date-formats.md
    const dateFormat = hasCustomFormat && foundFormat ? foundFormat.label : 'L';

    return localeMoment.format(dateFormat);
  }

  ngOnDestroy(): void {
    // Cannot use due @w11k/ngx-componentdestroyed to this bug - https://github.com/angular/angular/issues/36427
    this.localeSubscription.unsubscribe();
  }
}
