import { Myself } from '@mads/wm-ng-components';

export interface SimplifiedPermission {
  key: string;
  values?: string[];
}

export interface Role {
  id: string;
  name: string;
  applicationId: string;
  countries: string[];
  agencies: string[];
  permissions: SimplifiedPermission[];
}

export interface User extends Myself {
  hasContextualHelpAccess: boolean;
  isContextualHelpEditor: boolean;
}

export interface WmMember {
  id: string;
  displayName?: string;
  email: string;
  userPrincipalName?: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface Team {
  id: string;
  displayName: string;
}

export type UsersState = Readonly<{
  loading: boolean;
  myself: User;
  owners: WmMember[];
  members: WmMember[];
  userTeams: Team[];
  targetPath: string;
  simplifiedBrandMenuOn: boolean;
  defaultMarketId: string;
  loadingDefaultMarketId: boolean;
}>;

export const initialState: UsersState = {
  loading: false,
  myself: null,
  owners: [],
  members: [],
  userTeams: [],
  targetPath: null,
  simplifiedBrandMenuOn: false,
  defaultMarketId: null,
  loadingDefaultMarketId: false,
};

export const usersFeatureKey = 'users';

export const GREEN_DAY_CATEGORY = 'Green Day';
export const GREEN_DAY_SHOW = '[GLOBAL] Earth Day | Popup | Show';
export const GREEN_DAY_DISCARD = '[GLOBAL] Earth Day | Popup | Discard';
export const GREEN_DAY_ACCEPT = '[GLOBAL] Earth Day | Popup | Accept';

export const  AI_SEARCH_CATEGORY = 'AI Search';
export const AI_SEARCH_HEADER_CLICK = 'Header Link | Go to the app';
