<div #workspaceContainer class="workspace__container">
  <div class="workspace__title">
    <p class="workspace__title-header" translate="WM_HOME.WORKSPACE_WIDGET.WORKSPACES_TITLE"></p>
    <mat-icon
      (click)="onToggleWidget()"
      [svgIcon]="isExpanded ? 'wm-minimize' : 'wm-maximize'"
      class="workspace__title-icon"
    ></mat-icon>
  </div>

  <div *ngIf="loading$ | async; else widgetContent" class="workspace__spinner">
    <mat-progress-spinner diameter="50" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
  </div>

  <ng-template #widgetContent>
    <div class="workspace__container-expanded__scrollable">
      <div class="workspace__clients" *ngIf="!isExpanded">
        <div
          *ngFor="let client of myClients"
          [routerLink]="isExpanded ? getRoute(client.id) : null"
          [class.workspace__clients-tile--large]="isExpanded"
          class="workspace__clients-tile"
        >
          <img
            [src]="client.logo"
            [alt]="client.name"
            [class.workspace__clients-tile--disabled]="!isExpanded"
          />
        </div>
      </div>

      <div *ngIf="isExpanded" class="workspace__container-expanded__input">
        <input
          [(ngModel)]="searchValue"
          (keyup)="onChangeSearchValue()"
          placeholder="{{ 'WM_HOME.WORKSPACE_WIDGET.SEARCH_RESULTS' | translate }}"
          class="workspace__container-expanded__input-element"
          #searchInput
        />
        <i-feather
          *ngIf="!searchValue"
          name="search"
          class="workspace__container-expanded__input-icon"
        ></i-feather>
        <i-feather
          *ngIf="searchValue"
          name="x-circle"
          class="workspace__container-expanded__input-icon"
          (click)="clearSearchValue()"
        ></i-feather>
      </div>

      <div *ngIf="!searchMode && isExpanded">
        <mat-tab-group class="workspace__container-expanded__tabs wm-scrollbar--dark">
          <mat-tab [label]="yourClientsLabel | translate" class="workspace__container-expanded">
            <header>
              <p
                class="workspace__title-subheader"
                translate="WM_HOME.WORKSPACE_WIDGET.YOUR_CLIENTS"
              ></p>
              <p
                *ngIf="!myClients?.length"
                class="workspace__title-subheader"
                translate="WM_HOME.WORKSPACE_WIDGET.YOU_DONT_HAVE_CLIENTS"
              ></p>
            </header>
            <div class="workspace__clients">
              <div
                *ngFor="let client of myClients"
                [routerLink]="isExpanded ? getRoute(client.id) : null"
                [class.workspace__clients-tile--large]="isExpanded"
                class="workspace__clients-tile"
              >
                <img
                  [src]="client.logo"
                  [alt]="client.name"
                  [class.workspace__clients-tile--disabled]="!isExpanded"
                />
              </div>
            </div>
            <div class="workspace-link">
              <a
                appHasPermissionInApp
                [permissions]="canAddClientPermissionKey"
                [applicationId]="clientManagementAppId"
                [routerLink]="clientCreationPath"
                translate="WM_HOME.WORKSPACE_WIDGET.CLIENT_NEW"
                class="workspace-link__item"
              ></a>
            </div>
          </mat-tab>

          <mat-tab [label]="otherClientsLabel | translate" class="workspace__container-expanded">
            <div class="workspace-link">
              <p
                [translate]="
                  otherClients.length
                    ? 'WM_HOME.WORKSPACE_WIDGET.REQUEST_ACCESS'
                    : 'WM_HOME.WORKSPACE_WIDGET.NO_MORE_CLIENTS'
                "
                class="workspace__title-subheader"
              ></p>
              <a
                appHasPermissionInApp
                [permissions]="canAddClientPermissionKey"
                [applicationId]="clientManagementAppId"
                [routerLink]="clientCreationPath"
                translate="WM_HOME.WORKSPACE_WIDGET.CLIENT_NEW"
                class="workspace-link__item"
              ></a>
            </div>

            <div
              *ngFor="let client of otherClients"
              [routerLink]="getRoute(client.id)"
              class="workspace__clients-list"
            >
              <div class="workspace__clients-list__img">
                <img [src]="client.logo" [alt]="client.name" />
              </div>
              <p class="workspace__clients-list__name">{{ client.name }}</p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div *ngIf="searchMode && isExpanded">
        <p
          class="workspace__title-subheader"
          translate="WM_HOME.WORKSPACE_WIDGET.SEARCH_RESULTS"
        ></p>
        <div
          *ngFor="let client of allFilteredClients"
          [routerLink]="getRoute(client.id)"
          class="workspace__clients-list"
        >
          <div class="workspace__clients-list__img">
            <img [src]="client.logo" [alt]="client.name" />
          </div>
          <p class="workspace__clients-list__name">{{ client.name }}</p>
        </div>
        <h6
          *ngIf="allFilteredClients.length"
          appHasPermissionInApp
          [permissions]="canAddClientPermissionKey"
          [applicationId]="clientManagementAppId"
          translate="WM_HOME.WORKSPACE_WIDGET.CANNOT_SEE_CLIENT"
          class="wm-h6 search-error-message"
        ></h6>
        <h6
          *ngIf="!allFilteredClients.length"
          translate="WM_HOME.WORKSPACE_WIDGET.NO_MATCHING_CLIENTS"
          class="wm-h6 search-error-message"
        ></h6>
        <a
          appHasPermissionInApp
          [permissions]="canAddClientPermissionKey"
          [applicationId]="clientManagementAppId"
          [routerLink]="clientCreationPath"
          translate="WM_HOME.WORKSPACE_WIDGET.CLIENT_NEW"
          class="workspace-link__item"
        ></a>
      </div>
    </div>
  </ng-template>
</div>
