import {
  checkAccessToModule,
  checkSingleChoicePermission,
  hasPermission,
  hasPermissionInApp,
} from 'app/shared/utils';
import { User } from 'app/state/users';
import { Client, NEW_CLIENT_ROUTE } from 'app/state/clients';

import { constants } from '@config/constants';
import {
  checkAccessToClientEdit,
  NavigationConfigFunctions,
  NavigationSection,
  UserSectionNavigationName,
} from '@config';
import { environment } from '@environment';
import { checkExpressScenariosAccess, NavigationItemSource } from './common';

const { APPS_IDS, USER_PERMISSION_KEYS } = constants;

export const navigation: NavigationConfigFunctions = {
  dashboard: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/',
      hidden: checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/you/dashboard',
      hidden: !checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.APPS',
      title: 'WM_HOME.HEADER.APPS',
      path: '/apps/list',
      disabled: !checkAccessToModule(user, constants.MODULE_KEYS.APPS),
    },
  ],
  main: (countryCode: string, user: User): NavigationSection => [
    {
      id: 'Think',
      title: 'Think',
      description: 'Think about Growth\nin the CX Journey',
      headerColor: '#4F2170',
      textColor: '#A788BD',
      backgroundColor: '#F2EEF4',
      iconPath: 'assets/icons/mondelez-think.svg',
      nav: [
        {
          id: 'Demand Spaces and U&As',
          title: 'Demand Spaces and U&As',
          path: `/`,
          source: NavigationItemSource.MONDELEZ,
        },
        {
          id: 'Unlock Audits',
          title: 'Unlock Audits',
          source: NavigationItemSource.WAVEMAKER,
          nav: [
            {
              id: 'WM_HOME.NAVIGATION_MAIN.SUMMARY',
              title: 'WM_HOME.NAVIGATION_MAIN.SUMMARY',
              path: '/scorecards/summary',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.SITE_AND_APP_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.SITE_AND_APP_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000003/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.PPC_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.PPC_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000001/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.CONTENT_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.CONTENT_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000004/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.SOCIAL_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.SOCIAL_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000002/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.ECOMMERCE_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.ECOMMERCE_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000006/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000005/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.PROGRAMMATIC_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.PROGRAMMATIC_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000007/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.VIDEO_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.VIDEO_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000008/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.TECHNOLOGY_AUDIT',
              title: 'WM_HOME.NAVIGATION_MAIN.TECHNOLOGY_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000009/intro',
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.OPPORTUNITIES',
              title: 'WM_HOME.NAVIGATION_MAIN.OPPORTUNITIES',
              path: `/growth-opportunities/opportunities`,
              disabled: !checkAccessToModule(user, constants.MODULE_KEYS.OPPORTUNITIES),
            },
          ],
        },

        {
          id: 'Momentum & Live Journey Data Toolkit',
          title: 'Momentum & Live Journey Data Toolkit',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'CX Journey Value Exchange Framework',
          title: 'CX Journey Value Exchange Framework',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Ecomm Fusion',
          title: 'Ecomm Fusion',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
      ],
    },
    {
      id: 'Plan',
      title: 'Plan',
      description: 'Senario Plan\nthe CX Business Case',
      headerColor: '#2D6EAA',
      textColor: '#2C6EAA',
      backgroundColor: '#EFF2F8',
      iconPath: 'assets/icons/mondelez-plan.svg',
      nav: [
        {
          id: 'MMM (MDLZ)',
          title: 'MMM (MDLZ)',
          path: `/`,
          source: NavigationItemSource.MONDELEZ,
        },
        {
          id: 'Source of growth',
          title: 'Source of growth',
          path: `/sog/intro`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Velocity CX',
          title: 'Velocity CX',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Ecomm Audience Galaxy',
          title: 'Ecomm Audience Galaxy',
          path: `/audience-galaxy`,
          source: NavigationItemSource.WAVEMAKER,
        },
      ],
    },
    {
      id: 'Create',
      title: 'Create',
      description: 'Create\nthe CX Ecosystem',
      headerColor: '#81A8CC',
      textColor: '#81A8CC',
      backgroundColor: 'rgba(244, 246, 250, 0.5)',
      iconPath: 'assets/icons/mondelez-create.svg',
      nav: [
        {
          id: 'Creative Platform (Partner Agency)',
          title: 'Creative Platform (Partner Agency)',
          path: `/`,
          source: NavigationItemSource.MONDELEZ,
        },
        {
          id: 'Ecom E-Retail Media Guidelines (MDLZ)',
          title: 'Ecom E-Retail Media Guidelines (MDLZ)',
          path: `/`,
          source: NavigationItemSource.MONDELEZ,
        },
        {
          id: 'Brad Exp Ecosystem Strategy Framework',
          title: 'Brad Exp Ecosystem Strategy Framework',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'ROIX Framework',
          title: 'ROIX Framework',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'E@S 2.0',
          title: 'E@S 2.0',
          path: `/`,
          source: NavigationItemSource.MONDELEZ,
        },
      ],
    },
    {
      id: 'Realize',
      title: 'Realize',
      description: 'Realize\nthe CX Ecosystem',
      headerColor: '#E6AF23',
      textColor: '#E6AF23',
      backgroundColor: '#FDF9EF',
      iconPath: 'assets/icons/mondelez-realize.svg',
      nav: [
        {
          id: 'Architect',
          title: 'Architect',
          source: NavigationItemSource.WAVEMAKER,
          nav: [
            {
              id: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
              title: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
              path: `/audiences/map`,
            },
            {
              id: 'Touchpoints Configuration',
              title: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS',
              path: `/touchpoints-ui`,
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS',
              title: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS',
              path: `/campaigns/list`,
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.EXPRESS_SCENARIOS',
              title: 'WM_HOME.NAVIGATION_MAIN.EXPRESS_SCENARIOS',
              path: `/express-scenarios`,
              hidden: !checkExpressScenariosAccess(user),
            },
          ],
        },
        {
          id: 'Immersive Data Graph',
          title: 'Immersive Data Graph',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Content Management Platform',
          title: 'Content Management Platform',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Ecomm Retail Compass',
          title: 'Ecomm Retail Compass',
          path: `/retail-compass/dashboard`,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'T&L Programme Framework',
          title: 'T&L Programme Framework',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
      ],
    },
    {
      id: 'Learn',
      title: 'Learn',
      description: 'Learn\n and Re-apply',
      headerColor: '#287819',
      textColor: '#287819',
      backgroundColor: '#EFF5EE',
      iconPath: 'assets/icons/mondelez-learn.svg',
      nav: [
        {
          id: 'POE Intelligence Database',
          title: 'POE Intelligence Database',
          path: `/`,
          source: NavigationItemSource.WAVEMAKER,
        },
      ],
    },
  ],
  avatarNavigation: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ADMINISTRATION',
      title: 'WM_HOME.HEADER.ADMINISTRATION',
      nav: [
        {
          id: 'WM_HOME.HEADER.OS_PAY',
          title: 'WM_HOME.HEADER.OS_PAY',
          path: '/os-pay',
          disabled: false,
          hidden: !checkSingleChoicePermission(
            user,
            constants.USER_PERMISSION_KEYS.CAN_UI_ACCESS,
            environment.appsIds.osPayId
          ),
        },
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.WM_APPS, [
            USER_PERMISSION_KEYS.CAN_MANAGE_MARKET_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_GLOBAL_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_ALL_APPS,
          ]),
          id: 'WM_HOME.HEADER.APP_SETTING',
          title: 'WM_HOME.HEADER.APP_SETTING',
          path: '/apps/admin-panel',
        },
        {
          hidden: !hasPermission(user, USER_PERMISSION_KEYS.CAN_MANAGE_MARKET),
          id: 'WM_HOME.HEADER.MARKET',
          title: 'WM_HOME.HEADER.MARKET',
          path: '/market-admin/panel',
        },
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.SCORECARDS, [
            USER_PERMISSION_KEYS.CAN_VIEW_SQM,
          ]),
          id: 'WM_HOME.HEADER.SCORECARDS',
          title: 'WM_HOME.HEADER.SCORECARDS',
          path: '/sqm/list',
        },
      ],
    },
    {
      id: 'WM_HOME.HEADER.USER',
      title: 'WM_HOME.HEADER.USER',
      nav: [
        {
          id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          id: 'WM_HOME.HEADER.LOGOUT',
          title: 'WM_HOME.HEADER.LOGOUT',
          path: `${environment.authAppUrl}/logout`,
        },
      ],
    },
  ],
  clientCreation: (client: Client, user: User): NavigationSection => {
    const clientId = (client && client.id) || NEW_CLIENT_ROUTE;
    const marketsDisabled = !client?.markets?.length;

    return [
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        path: `/clients/client-creation/new`,
        hidden: client?.isAccepted || clientId !== NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        title: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        path: `/clients/client-creation/${clientId}/pending`,
        hidden: client?.isAccepted || clientId === NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        path: `/clients/client-creation/${clientId}/client-management`,
        disabled: !checkAccessToClientEdit(user),
        hidden: !client?.isAccepted,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        title: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        path: `/clients/client-creation/${clientId}/markets-management`,
        hidden: !client?.isAccepted || marketsDisabled,
      },
    ];
  },
  userSection: () => [
    {
      name: UserSectionNavigationName.news,
      hidden: false,
      disabled: false,
    },
    {
      name: UserSectionNavigationName.help,
      hidden: false,
      disabled: false,
      children: [
        {
          name: UserSectionNavigationName.helpTrainingPortal,
          hidden: true,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpProductTour,
          hidden: false,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpSitemap,
          hidden: true,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpContextualHelp,
          hidden: true,
          disabled: false,
        },
      ],
    },
  ],
  userSettings: (): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      path: '/user/settings',
    },
  ],
};
