import { registerApp, MicrofrontendAppConfig, matchingBrandPathname } from './utils';
import { environment } from '../environments/environment';
import { MicroAppParams } from 'app/modules-wrappers/micro-app-wrapper';

export const FOUR_MORES_ROUTE_MATCHER = 'sog';

export const config: MicrofrontendAppConfig = {
  name: '4mores-app',
  windowLibraryName: 'fourMoresApp',
  assetsPath: environment.fourMoresAppUrl.replace('main.js', '').substr(1),
  baseHref: '/brands/:brandId/sog/',
  appUrl: environment.fourMoresAppUrl,
  appLocation: environment.fourMoresAppUrl.replace('main.js', ''),
  matchingStrategy: matchingBrandPathname([FOUR_MORES_ROUTE_MATCHER]),
};

export const registerFourMoresApp = (customParams?: MicroAppParams): MicrofrontendAppConfig =>
  registerApp({ ...config, customParams });
