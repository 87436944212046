import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsSidebarState, newsFeatureKey } from './news-sidebar.model';

export const selectNewsState = createFeatureSelector<NewsSidebarState>(newsFeatureKey);


export const selectOpened = createSelector(
  selectNewsState,
  state => state.opened
);

export const selectMyNews = createSelector(
  selectNewsState,
  state => state.myNews
);

export const selectLoading = createSelector(
  selectNewsState,
  state => state.loading
);

export const selectPersonalMainVisited = createSelector(
  selectNewsState,
  state => state.personalMainVisited
);

export const selectLoadingError = createSelector(
  selectNewsState,
  state => state.loadingError
);
